import React, { useEffect, useState } from 'react';
import BetterScroll from 'better-scroll-react';
import {
    TextField,
    Box,
    Tabs, Tab,
    makeStyles, withStyles,
    Typography,
    IconButton,
    Modal
} from '@material-ui/core';
import PropTypes from 'prop-types';
import subHeader from '../../images/subHeader.png';
import CloseIcon from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import * as CONST from './OCConst';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #DFDFDF',
    },
    indicator: {
        backgroundColor: '#5666F6',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontSize: 16,
        fontWeight: '400',
        //   color: '#000000',
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#5666F6',
            opacity: 1,
        },
        '&$selected': {
            color: '#5666F6',
        },
        '&:focus': {
            color: '#5666F6',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    subjectInput: {
        width: "100%",
        height: 50,
        marginTop: 10,
    },
    orderDetailImg: {
        width: "100%",
        height: 52,
        marginTop: 10
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
        height: 60,
    },
    tabs: {
        width: '90%',
        marginLeft: '5%',
        marginTop: 35
    },
    arrowBack: {
        left: '5%',
        marginTop: '1%',
        marginBottom: '1%',
    },
    subHeaderContainer: {
        display: 'flex',
        width: '100vm',
        height: '200px',
        backgroundImage: `url(${subHeader})`,
        backgroundSize: 'cover',
    },
    deactivateContainer: {
        position: 'absolute',
        width: 539,
        height: 341,
        borderRadius: 15,
        backgroundColor: '#FFFFFF',
        padding: theme.spacing(2, 4, 3),
    },
    cancelButton: {
        width: 132,
        height: 40,
        borderRadius: 30,
        border: '1px solid #212759'
    },
    yesButton: {
        width: 132,
        height: 40,
        borderRadius: 30,
        border: '1px solid #212759',
        marginLeft: 45,
        backgroundColor: 'transparent',
        outline: 'none'
    },
    paper: {
        width: 248,
        height: 63,
        borderRadius: 7,
        border: 'trasparent',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginLeft: 110,
        marginTop: 170,
        backgroundColor: 'rgba(134, 136, 152, 0.8)',
        color: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}))

const { DEFAULT_CONFIRMATION_EMAIL } = CONST;

const ConfirmationEmail = (props) => {
    const classes = useStyles();

    useEffect(() => {
        console.log('ConfirmationEmail Effect')
        if (!props.confirmationEmail) {
            props.getConfirmationEmail(props.eventId);
        } else {
            resetEmail();
        }
    }, [props.confirmationEmail]);

    const [subject, setSubject] = useState(props.confirmationEmail ? props.confirmationEmail.subject : DEFAULT_CONFIRMATION_EMAIL.subject);
    const handleSubject = (e) => (
        setSubject(e.target.value)
    )

    const [greetingMessage, setGreetings] = useState(props.confirmationEmail ? props.confirmationEmail.greetingMessage : DEFAULT_CONFIRMATION_EMAIL.greetingMessage);
    const handleGreetings = (e) => (
        setGreetings(e.target.value)
    )

    const [moreInfo, setMoreInformation] = useState(props.confirmationEmail ? props.confirmationEmail.moreInfo : DEFAULT_CONFIRMATION_EMAIL.moreInfo);
    const handleMoreInformation = (e) => (
        setMoreInformation(e.target.value)
    )

    const checkValid = () => {
        if (!props.confirmationEmail)
            return false;

        if (subject != props.confirmationEmail.subject ||
            greetingMessage != props.confirmationEmail.greetingMessage ||
            moreInfo != props.confirmationEmail.moreInfo) {
            return true;
        } else {
            return false;
        }
    }
    const [anchorEl, setAnchorEl] = React.useState(null);


    const [showPreview, setShowPreview] = useState(false);
    const handleUpdate = (event) => {
        setShowPreview(true);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        createOrUpdateConfirmationEmail();
        setPopperClose(true);
        setTimeout(
            () => setPopperClose(false),
            3000
        );
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [popperClose, setPopperClose] = useState(false);


    const [cancel, setCancel] = useState(false);
    const handleCancel = () => {
        setCancel(true);
        resetEmail();
    }

    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(true);
    }
    const [anchorElYes, setAnchorElYes] = React.useState(null);
    const openYes = Boolean(anchorElYes);
    const idYes = openYes ? 'simple-popper' : undefined;
    const [popperYesClose, setPopperYesClose] = useState(false);

    const handleYesModalClose = (event) => {
        setShowModal(false)
        setAnchorElYes(anchorElYes ? null : event.currentTarget);
        setDefault();
        setPopperYesClose(true);
        setTimeout(
            () => setPopperYesClose(false),
            3000
        );
    }
    const handleModalClose = () => {
        setShowModal(false)
    }

    const setDefault = () => {
        setSubject(DEFAULT_CONFIRMATION_EMAIL.subject);
        setGreetings(DEFAULT_CONFIRMATION_EMAIL.greetingMessage);
        setMoreInformation(DEFAULT_CONFIRMATION_EMAIL.moreInfo);
    }

    const resetEmail = () => {
        console.log('resetEmail', props.confirmationEmail)
        setSubject(props.confirmationEmail ? props.confirmationEmail.subject : DEFAULT_CONFIRMATION_EMAIL.subject);
        setGreetings(props.confirmationEmail ? props.confirmationEmail.greetingMessage : DEFAULT_CONFIRMATION_EMAIL.greetingMessage);
        setMoreInformation(props.confirmationEmail ? props.confirmationEmail.moreInfo : DEFAULT_CONFIRMATION_EMAIL.moreInfo);
    }

    const createOrUpdateConfirmationEmail = () => {
        console.log(subject, greetingMessage, moreInfo)
        return props.createOrUpdateConfirmationEmail(props.eventId,
            { subject, greetingMessage, moreInfo }
        );
    }

    const renderBody = () => {
        return (
            <div style={{ height: '100%', width: '100wh' }}>
                <div style={{ height: '100%', width: '100%', marginLeft: '-1%' }}>
                    <div style={{ fontSize: 14, fontWeight: '300', color: "#000000", marginTop: 12 }}>We have set up a default event confirmation email for you. Attendees will receive the email after successfully registering your event.
                    </div>
                    <div style={{ fontSize: 14, fontWeight: '300', color: "#000000" }}>
                        In order to make the event more customized, you can also change, add, and optimize the information you want to fill in this confirmation email.
                    </div>
                    <div style={{ width: "100wh", height: "100%", display: "grid", gridTemplateColumns: '55% 45%', marginTop: 27 }}>
                        <div style={{ width: "100%", height: "80vh", marginTop: 15, backgroundColor: "#FFFFFF", boxShadow: "0px 0px 50px rgba(184, 185, 190, 0.35)", borderRadius: 15 }}>
                            <BetterScroll>
                                <div style={{ width: "100%", height: 70, borderBottom: "1px solid #E0E0E0", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", paddingLeft: 30, paddingRight: 30 }}>
                                    <div style={{ fontSize: 16, fontWeight: "400", color: "#000000", letterSpacing: 0.3 }}>Edit Confirmation Email</div>
                                    <button style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', marginLeft: 5 }} onClick={handleModal}>
                                        <span style={{ fontSize: 10, fontWeight: '400', color: "#BBBBBB", textDecoration: 'underline', letterSpacing: 0.3 }}>Use default content</span>
                                    </button>
                                    <Modal
                                        open={showModal}>
                                        <div style={{ left: '30%', top: '30%', outline: 'none' }} className={classes.deactivateContainer}>
                                            <IconButton
                                                color="inherit"
                                                style={{ marginLeft: '98%', marginTop: -18, }}
                                                onClick={handleModalClose}
                                            >
                                                <CloseIcon style={{ width: 19, height: 19 }} />
                                            </IconButton>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', width: '100%', marginTop: '12%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                Are you sure  to use default email content?
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '15%' }}>
                                                <IconButton
                                                    color="inherit"
                                                    // edge="start"
                                                    className={classes.cancelButton}
                                                    style={{ marginTop: 20, marginBottom: 20 }}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                    disableTouchRipple={true}
                                                    onClick={handleModalClose}
                                                >
                                                    <span style={{ fontSize: 14, fontWeight: '400', color: '#212759' }}>Cancel</span>
                                                </IconButton>
                                                <button
                                                    color="inherit"
                                                    // edge="start"
                                                    className={classes.yesButton}
                                                    style={{ marginTop: 20, marginBottom: 20 }}
                                                    // disableRipple={true}
                                                    // disableFocusRipple={true}
                                                    // disableTouchRipple={true}

                                                    onClick={handleYesModalClose}
                                                    aria-describedby={id}
                                                    type="button"
                                                >
                                                    <span style={{ fontSize: 14, fontWeight: '400', color: '#212759' }}>Yes</span>
                                                </button>
                                                <Popper id={idYes} open={openYes} anchorEl={anchorElYes} open={popperYesClose}>
                                                    <div className={classes.paper}>Update successfully
                                                </div>
                                                </Popper>
                                                {/* <Popper id={id} open={open} anchorEl={anchorEl} open={popperClose}>
                                                    <div className={classes.paper}>Update successfully
                                                </div>
                                                </Popper> */}
                                            </div>

                                        </div>
                                    </Modal>
                                    <button style={{ backgroundColor: "#FFFFFF", borderColor: "transparent", marginLeft: "15%", marginRight: 10, outline: "none", fontSize: 12 }} onClick={handleCancel}>Cancel</button>
                                    {
                                        checkValid() ?
                                            <div>
                                                <button aria-describedby={id} type="button" style={{ backgroundColor: "#5666F6", borderColor: "transparent", width: 95, height: 35, color: "#FFFFFF", borderRadius: 25, outline: "none", fontSize: 12 }} onClick={handleUpdate}>Update</button>
                                                <Popper id={id} open={open} anchorEl={anchorEl} open={popperClose}>
                                                    <div className={classes.paper}>Update successfully
                                                </div>
                                                </Popper>
                                            </div>
                                            :
                                            <button style={{ backgroundColor: "#C8CCE8", borderColor: "transparent", width: 95, height: 35, color: "#FFFFFF", borderRadius: 25, outline: "none", fontSize: 12 }}>Update</button>}
                                </div>
                                <div style={{ width: "100%", paddingLeft: 40, paddingRight: 40 }}>
                                    <div style={{ marginTop: 25 }}>
                                        <div style={{ fontSize: 14, fontWeight: "700", color: "#000000" }}>Subject</div>
                                        <TextField
                                            id="subject"
                                            variant="outlined"
                                            className={classes.subjectInput}
                                            size="small"
                                            onChange={handleSubject}
                                            value={subject}
                                            InputProps={{
                                                style: { fontSize: 14 }
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                        <div style={{ fontSize: 14, fontWeight: "700", color: "#000000" }}>Greetings</div>
                                        <TextField
                                            id="subject"
                                            variant="outlined"
                                            className={classes.subjectInput}
                                            size="medium"
                                            multiline
                                            rows={4}
                                            rowsMax={4}
                                            value={greetingMessage}
                                            onChange={handleGreetings}
                                            InputProps={{
                                                style: { fontSize: 14 }
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginTop: 85 }}>
                                        <div style={{ fontSize: 14, fontWeight: "700", color: "#000000" }}>Ticket Details</div>
                                        <div style={{ width: '100%', height: 52, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 6 }}>
                                            {/* <div style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: 'white' }}> */}
                                            {/* </div> */}
                                            <div style={{ width: "100%", height: 52, backgroundColor: "#F5F5F5", borderRadius: 7, marginTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <div style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: 'white', marginLeft: -10 }}></div>
                                                <div style={{ fontSize: 14, fontWeight: "300", color: "#9E9E9E" }}>Order details show here</div>
                                                <div style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: 'white', marginRight: - 10 }}>
                                                </div>
                                                {/* <div style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: 'white' }}> */}
                                            </div>
                                        </div>
                                        <div style={{ fontSize: 14, fontWeight: "400", color: "#9E9E9E", marginTop: 25 }}>Detailed event information and your QR code can be found in NASUFUN APP.</div>
                                        <div style={{ fontSize: 14, fontWeight: "400", color: "#9E9E9E", marginTop: 8 }}>When checking in / picking up tickets, you can show this email or your QR code to help us verify your information.</div>
                                    </div>
                                    <div style={{ marginTop: 30 }}>
                                        <div style={{ fontSize: 14, fontWeight: "700", color: "#000000" }}>More Information</div>
                                        <TextField
                                            id="subject"
                                            variant="outlined"
                                            className={classes.subjectInput}
                                            size="medium"
                                            multiline
                                            rows={4}
                                            rowsMax={4}
                                            value={moreInfo}
                                            onChange={handleMoreInformation}
                                            InputProps={{
                                                style: { fontSize: 14 }
                                            }}
                                        />
                                        <div style={{ fontSize: 14, fontWeight: "400", color: "#9E9E9E", marginTop: 65, marginBottom: 10 }}>If you have any questions, don’t hesitate to contact us, we look forward to seeing you soon.
                                    </div>
                                    </div>
                                </div>
                            </BetterScroll>
                        </div>
                        {
                            showPreview ?
                                <div style={{ width: "100%", height: "90%", marginTop: 15, backgroundColor: "#FFFFFF", boxShadow: "0px 0px 50px rgba(184, 185, 190, 0.35)", borderRadius: 15, marginLeft: '5%' }}>
                                    <BetterScroll>
                                        <div style={{ width: "100%", height: 70, borderBottom: "1px solid #E0E0E0", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", paddingLeft: 10, paddingRight: 10 }}>
                                            <span style={{ color: '#000000', fontSize: 16, fontWeight: '400', marginLeft: 20, letterSpacing: 0.3 }}>Preview</span>
                                        </div>
                                        <div style={{ width: '80%', marginLeft: '10%' }}>
                                            <img src={require('../../images/nasulogo.png')} style={{ width: 63, height: 34, marginTop: 25 }}></img>
                                            <div style={{ fontSize: 24, fontWeight: '800', color: '#000000', wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line", width: '60%', marginTop: 15 }}>
                                                {/* {subject} */}
                                                {subject}
                                            </div>
                                            <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <div style={{ fontSize: 14, fontWeight: '300', color: '#000000' }}>Dear</div>
                                                <div style={{ backgroundColor: '#F4F4F4', borderRadius: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 5, marginRight: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                    <span style={{ backgroundColor: '#F4F4F4', fontSize: 14, fontWeight: '300', color: '#7C7C7C', fontStyle: 'italic' }}>First / last name </span>
                                                </div>
                                                <div>:</div>
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line", marginTop: 5 }}>{greetingMessage}</div>
                                            <div style={{ width: '100%', height: 350, backgroundColor: '#F8F8F8', borderRadius: 15, marginTop: 20 }}>
                                                <img src={require('../../images/pic1.png')} style={{ height: 160, width: '100%', borderTopLeftRadius: 15, borderTopRightRadius: 15 }}></img>
                                                <div style={{ width: '90%', marginLeft: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'baseline', marginTop: 10 }}>
                                                    <div style={{ fontSize: 15, fontWeight: '500', color: '#0C0033' }}>NASU Northeast Surfing</div>
                                                    <div style={{ fontSize: 13, fontWeight: '300', color: '#B7B7B7', marginTop: 10 }}>Date</div>
                                                    <div style={{ fontSize: 13, fontWeight: '300', color: '#B7B7B7' }}>Location</div>
                                                    <hr style={{ borderColor: '#A8A8A8', width: '100%', marginTop: 10, marginBottom: 10 }} />
                                                    <div style={{ fontSize: 13, fontWeight: '300', color: '#B7B7B7' }}>Ticket</div>
                                                    <div style={{ fontSize: 13, fontWeight: '300', color: '#B7B7B7' }}>Ticket Time</div>
                                                    <div style={{ fontSize: 15, fontWeight: '600', color: '#5666F6', marginTop: 15 }}>Order total: $XX.XX</div>
                                                </div>
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', marginTop: 15 }}>Detailed event information and your QR code can be found in NASUFUN APP.
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', marginTop: 15 }}>
                                                When checking in / picking up tickets, you can show this email or your QR code to help us verify your information.
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', marginTop: 15 }}>{moreInfo}</div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', marginTop: 15 }}>
                                                If you have any questions, don’t hesitate to contact us, we look forward to  seeing you soon
                                            </div>
                                            <div style={{ fontSize: 16, fontWeight: '300', color: '#5666F6', marginTop: 15 }}>
                                                Contact us:
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000', marginTop: 15 }}>
                                                order@nasu.fun
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000' }}>
                                                Phone: 617-943-8913
                                            </div>
                                            <div style={{ fontSize: 14, fontWeight: '300', color: '#000000' }}>
                                                Wechat: NASUassistant / nasuassistant2
                                            </div>
                                        </div>
                                        <hr style={{ borderColor: '#A8A8A8', width: '100%', marginTop: 15 }} />
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                                            <span style={{ fontSize: 16, fontWeight: '700', color: '#000000' }}>Follow us</span>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                                            <img src={require('../../images/facebook.png')} style={{ width: 49, height: 49 }}></img>
                                            <img src={require('../../images/ins.png')} style={{ width: 49, height: 49, marginLeft: 10 }}></img>
                                            <img src={require('../../images/youtube.png')} style={{ width: 49, height: 49, marginLeft: 10 }}></img>
                                            <img src={require('../../images/red.png')} style={{ width: 49, height: 49, marginLeft: 10 }}></img>
                                        </div>
                                    </BetterScroll>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div>
            {renderBody()}
        </div>
    )

}
export default ConfirmationEmail;