import React, {useState, forwardRef, useImperativeHandle} from "react";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Left } from 'react-bootstrap/lib/Media';
import moment from 'moment'
//tickets
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Box, FormControl, FormHelperText, CssBaseline } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import _uniqueId from 'lodash/uniqueId';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import CETicketDrawer from './CETicketDrawer';
//preview
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RoomIcon from '@material-ui/icons/Room';
import Text from 'react-text';
import EventNoteIcon from '@material-ui/icons/EventNote';
import BScroll from '@better-scroll/core'
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import styles from './CEDateAndTimes.module.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import BetterScroll from 'better-scroll-react'
import VisibilityIcon from '@material-ui/icons/Visibility';
import {EventCategoryIdNameMap, EventAction, CEMode} from './constant';
import { DatePicker } from "@material-ui/pickers";

const drawerWidth = 400;
let list = [];

const useStyles = makeStyles((theme) => ({
    dayPickerContainer: {
        height: 384,
        width: 384,
        marginTop: 46,
        borderWidth: 0.7,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        borderStyle: 'solid',
        borderColor: '#101010',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    regularDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#F5A626",
        borderTopLeftRadius: 28,
        borderColor: 'transparent',
        fontSize: 14,
        fontWeight: "700",
        color: "#FFFFFF",
        boxShadow: "0px 8px 7px rgba(213, 169, 104, 0.34)",
        float: 'left',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '44px',
        outline: 'none'
    },
    specialDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#E3E3E3",
        borderTopRightRadius: 28,
        borderColor: 'transparent',
        // fontSize: 14,
        // fontWeight: "700",
        // color: "#FFFFFF",
        float: 'left',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '44px',
        outline: 'none'
    },
    optionalIcon: {
        width: '53px',
        height: '15px',
        marginTop: '5px',
        backgroundColor: "#F1F1F1",
        borderRadius: 28,
        borderColor: 'transparent',
        fontSize: 10,
        fontWeight: "700",
        color: "#FFFFFF",
        float: 'right',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '15px',
        float: 'left',
        marginLeft: -30
    },
    onRegularDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#E3E3E3",
        borderTopLeftRadius: 28,
        borderColor: 'transparent',
        fontSize: 14,
        fontWeight: "700",
        color: "#FFFFFF",
        // boxShadow: "0px 8px 7px rgba(213, 169, 104, 0.34)",
        float: 'left',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '44px',
        outline: 'none'
    },
    onSpecialDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#5666F6",
        borderTopRightRadius: 28,
        borderColor: 'transparent',
        // fontSize: 14,
        // fontWeight: "700",
        // color: "#FFFFFF",
        boxShadow: "0px 8px 15px rgba(86, 102, 246, 0.26)",
        float: 'left',
        display: 'inline',
        textAlign: 'justify',
        lineHeight: '44px',
        outline: 'none',
    },
    onOptionalIcon: {
        width: '53px',
        height: '15px',
        marginTop: '5px',
        backgroundColor: "#DEE4FF",
        borderRadius: 28,
        borderColor: 'transparent',
        fontSize: 10,
        fontWeight: "700",
        color: "#5666F6",
        float: 'right',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '15px',
        float: 'left',
        marginLeft: -30,
    },
    numOfSelected: {
        width: 100,
        // height: 51,
        fontSize: 14,
        fontWeight: '700',
        color: "#000000",
        // marginLeft: 127.5,
        marginTop: 10,
    },

    //tickets
    root: {
        display: 'flex',
    },
    container: {
        display: 'flex',
        minHeight: '800px',
        minWidth: '375px',
        overflowY: 'scroll',
        // backgroundColor: 'pink'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: 0,

    },
    drawerClose: {
        overflowX: 'hidden',
        width: 0,
    },
    paperOpen: {
        width: drawerWidth,
        backgroundColor: '#F9F9F9',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
        borderColor: 'transparent',
    },
    paperClose: {
        overflowX: 'hidden',
        width: 0,
    },
    button: {
        display: 'flex',
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
    addButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 369,
        height: 45,
        borderRadius: 15,
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: 'transparent',
        },
        marginTop: 30,
        marginLeft: 5,
    },
    addButtonText: {
        fontSize: 12,
        fontWeight: '400',
        color: '#8F8F8F',
        marginLeft: 18,
    },
    closeButton: {
        color: "#000000",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 60,
        height: 60,
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    sidebarTitle: {
        fontSize: 12,
        fontWeight: '400',
        letterSpacing: 1,
    },
    errorText: {
        fontSize: 12,
        fontWeight: '400',
        marginLeft: 327,
    },
    errorTextLeft: {
        fontSize: 12,
        fontWeight: '400',
        marginLeft: 34,
    },
    errorTextRight: {
        fontSize: 12,
        fontWeight: '400',
    },
    allTextField: {
        backgroundColor: "#FFFFFF",
        borderRadius: 7,
        fontSize: 12,
        fontWeight: '400',
        lineHeight: '100%',
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
        "& .MuiOutlinedInput-input": {
            fontSize: 12,
        },
    },
    errorTextField: {
        backgroundColor: "#FFFFFF",
        borderRadius: 7,
        fontSize: 12,
        fontWeight: '400',
        lineHeight: '100%',
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF6565",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF6565",
            border: 'solid 1px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF6565",
        },
        "& .MuiOutlinedInput-input": {
            fontSize: 12
        },
    },
    inputText: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: '100%',
    },
    ticketAddedContainer: {
        width: 600,
        height: 'auto',
        borderRadius: 7,
        backgroundColor: '#F4F5FF',
    },
    ticketAddedTitle: {
        width: '80%',
        fontSize: 14,
        fontWeight: '500',
    },
    ticketAddedPrice: {
        fontSize: 18,
        fontWeight: '700',
        color: '#F5A626',
    },
    body1: {
        fontSize: 10,
        fontWeight: '300',
    },
    body2: {
        fontSize: 11,
        fontWeight: '300',
    },
    ticketAddedCutoff: {
        display: 'flex',
        width: 193,
        height: 18,
        borderRadius: 10,
        backgroundColor: "#FFFFFF",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 12,
    },
    editButton: {
        width: 54,
        height: 22,
        backgroundColor: "#FFFFFF",
        borderRadius: 11,
        marginRight: 26,
        marginBottom: 12,
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    editButtonText: {
        fontSize: 10,
        marginLeft: 3,
        fontWeight: '300',
    },
    cancelTicketButton: {
        width: 25,
        height: 25,
        marginLeft: 587,
    },
    popoverPaper: {
        width: 125,
        height: 32,
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
        backgroundColor: 'transparent'
    },
    popoverDialog: {
        width: 120,
        height: 32,
        borderRadius: 7,
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
        backgroundColor: '#C1C1C1',
        position: 'relative',
        left: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '&::before': {
            content: `''`,
            position: 'absolute',
            left: '-10px',
            top: 8,
            width: 10,
            height: 10,
            borderTop: '5px solid transparent',
            borderRight: '5px solid #C1C1C1',
            borderBottom: '5px solid transparent',
        }
    },
    select: {
        "& .MuiSelect-select": {
            transition: "width 2s, height 4s",
        }
    },
    menuText: {
        fontSize: 12,
        fontWeight: '400',
    },
    checkBox: {
        "&:hover": {
            backgroundColor: 'transparent',
        },
        "& .Mui-checked": {
            backgroundColor: 'pink',
            disableTouchRipple: true,
            disableFocusRipple: true,
        },
        "& .MuiCheckbox-colorPrimary": {
            disableTouchRipple: true,
            disableFocusRipple: true,
        },
    },

    //preview
    previewButton: {
        width: 117,
        height: 40,
        borderRadius: 30,
        color: "#5666F6",
    },
    previewCard: {
        width: 344,
        height: 645,
        background: "#FFFFFF",
        boxShadow: "0px 5px 38px rgba(132, 132, 132, 0.25)",
        borderRadius: 15,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: -10,
        // background: "linear-gradient(transparent, #FFFFFF)"
    },
    cardGradient: {
        width: 344,
        height: 645,
        position: 'fixed',
        background: 'linear-gradient(180deg, transparent,transparent,transparent, #FFFFFF 100%)',
        boxShadow: "0px 5px 38px rgba(132, 132, 132, 0.25)",
        borderRadius: 15,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: -645,
        // position: "relative"
    },
    submitEvent: {
        color: "#FFFFFF",
        fontSize: 16,
        fontWeight: "600",
        width: 344,
        height: 45,
        boxShadow: '0px 8px 15px rgba(86, 102, 246, 0.26)'
    },
    gridRoot: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-around',
        // overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    img: {
        marginTop: 9,
        width: 326,
        height: 190,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    followButton: {
        width: 70,
        height: 23,
        borderRadius: 12.5,
        color: "#5666F6",
        borderColor: "#5666F6"
    },
    monthStyle: {
        '& .DayPicker-Month': {
            borderSpacing: '4px',
            borderCollapse: 'separate'
        }
    }

}));

const MenuItems = ({ date, price, selected }) => {
    return (
        <div className={styles.menuItem}>
            <div style={{ fontSize: 14, fontWeight: "300", color: "#000000" }}>
                {date}
            </div>
            <div style={{ fontSize: 11, fontWeight: "300", color: "#000000" }}>
                {price}
            </div>
        </div>
    )
};

const SpecialDate = (props) => {
    const classes = useStyles();
    //tickets
    // const classes = useStyles();
    const { specialDate, onDrawerOpen, onDrawerClose, opened, allTickets, setTickets, getAllTicketNames,} = props;
    const [open, setOpen] = React.useState(false);
    const [editedTicketInfoIndex, setEditedTicketInfoIndex] = React.useState(null)
    // const [allTickets, setAllTickets] = React.useState([]);

    const handleDrawerOpen = () => {
        if(open || opened) 
            return;
        setOpen(true);
        onDrawerOpen()
    };

    const handleDrawerClose = () => {
        setEditedTicketInfoIndex(null);
        setOpen(false);
        onDrawerClose()
    };

    const handleCreate = (ticketInfo) => {
        const newTickets = [...allTickets];
        if(editedTicketInfoIndex !== null) {
          newTickets[editedTicketInfoIndex] = ticketInfo;
        } else {
          newTickets.push(ticketInfo);
        }

        // setAllTickets(newTickets);
        setTickets(specialDate, newTickets)
        handleDrawerClose();
      };
  
    const toggleCloseTicketInfo = (ticketId) => {
        if(open || opened) 
          return;
        const newTickets = allTickets.filter(ticket => ticket.id !== ticketId);
        // setAllTickets(newTickets);
        setTickets(specialDate, newTickets)
    };

    const handleEdit = (index) => {
        if(open || opened) 
            return;
            
        setEditedTicketInfoIndex(index);
        handleDrawerOpen();
    };



    return (
    <div>
        <div style={{marginTop: 20, marginLeft: -10, overflow: 'hidden', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <hr style={{width: 227, color: '#D1D4EE', float: 'left'}}/>
            <span style={{color: '#5666F6', fontSize: 16, fontWeight: '700', float: 'left'}}>{moment(specialDate).format('MMMM DD YYYY')}
            </span>
            <hr style={{width: 227, color: '#D1D4EE', float: 'right',}}/>
        </div>
        <div className={classes.root}>

            <CETicketDrawer 
                allTickets={allTickets} 
                open={open} 
                handleTicketCreate={handleCreate}
                handleDrawerClose={handleDrawerClose}
                editedTicketInfoIndex={editedTicketInfoIndex !== null ? editedTicketInfoIndex: null}
                getAllTicketNames={getAllTicketNames}
                ></CETicketDrawer>
            {/* TODO all ticket filter */}
            <main className={classes.content}>
                {allTickets && allTickets.map((ticket, index) =>
                    <div key={index} style={{ marginTop: 10 }}>
                        <Ticket ticket={ticket} 
                        open={open || opened}
                        index={index}
                        toggleCloseTicketInfo={toggleCloseTicketInfo}
                        handleEdit={handleEdit}></Ticket>
                    </div>
                )}
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx({
                        [classes.addButton]: !open,
                        [classes.hide]: open,
                    })}
                    disableRipple={true}
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                >
                    <span style={{ color: '#8F8F8F', fontSize: 12, fontWeight: '400', marginLeft: 18 }}>Add different ticket</span>
                    <AddIcon style={{ width: 20, height: 20, marginRight: 12 }} />
                </IconButton>
            </main>
        </div>
    </div>);
}

const Ticket = (props) => {
    const classes = useStyles();
    const {ticket, index, handleEdit, toggleCloseTicketInfo, open} = props; 
    return (
        <Box style={{ width: 600 }}>
        {!open && <IconButton
            color="#484D76"
            aria-label="cancle-ticket"
            // edge="start"
            className={classes.cancelTicketButton}
            style={{ position: 'relative', top: 15, right: 3 }}
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
            onClick={() => toggleCloseTicketInfo(ticket.id)}
        >
            <CancelIcon style={{ width: 20, height: 20, alignSelf: 'center' }} />
        </IconButton>}
        <Box className={classes.ticketAddedContainer}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div className={classes.ticketAddedTitle} style={{ marginLeft: 22, marginTop: 10, width: '80%', wordWrap: 'break-word' }}>{ticket.ticketName}</div>
                <span className={classes.ticketAddedPrice} style={{ marginRight: 22, marginTop: 10 }}>${ticket.price}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span className={classes.body1} style={{ marginLeft: 22, marginTop: 5 }}>{ticket.eventStartTime} | {ticket.duration}</span>
                <span className={classes.body1} style={{ marginRight: 22, marginTop: 5 }}>{ticket.unlimited ? "Unlimited tickets" : ""}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Divider style={{ width: 555, marginTop: 10 }} />
            </div>
            <p
                className={classes.body2}
                style={{ width: '80%', wordWrap: 'break-word', textAlign: 'justify', marginTop: 10, marginLeft: 22 }}
            >
                {ticket.ticketDescription}
            </p>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 22 }}>
                <Box className={classes.ticketAddedCutoff}>
                    <span style={{ fontSize: 10, fontWeight: '400', color: '#000000' }}>Cutoff: {ticket.sellingCutoffDate}</span>
                </Box>
                {!open && <IconButton
                    color="inherit"
                    aria-label="edit"
                    className={classes.editButton}
                    disableRipple={true}
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                    onClick={() => handleEdit(index)}
                >
                    <div className={classes.editButtonText}>Edit</div>
                    <EditIcon style={{ marginLeft: 5 }} />
                </IconButton>}
            </div>
        </Box>
    </Box>
    )
}

export const Menu = (listGrid, selected) => listGrid.map(el => {
    const { date, price, day } = el;

    return (
        <MenuItems
            date={date}
            price={price}
            key={day}
            selected={selected}
        />
    );
});

const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};
export const ArrowLeft = () => {
    return (
        <div className={styles.arrowPrev}>
            <ArrowBackIosSharpIcon />
        </div>
    );
};

// const ArrowLeft = Arrow({ text: '<', className: styles.arrowPrev });
const ArrowRight = Arrow({ text: '>', className: styles.arrowNext });

//weekdayElement & navbarElement
function Weekday({ weekday, className, localeUtils, locale }) {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
        <div className={className} title={weekdayName} style={{ color: '#4A5CFF', opacity: 0.41 }}>
            {weekdayName.slice(0, 1)}
        </div>
    );
}

function YearMonthForm({ date, localeUtils }) {
    return (
        <div style={{ fontSize: 19 }}></div>
    )
}

function Navbar({
    month,
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    localeUtils,
}) {
    const months = localeUtils.getMonths();
    const prev = months[previousMonth.getMonth()];
    const next = months[nextMonth.getMonth()];
    const now = months[month.getMonth()];
    const styleLeft = {
        float: 'right',
        backgroundColor: "transparent",
        outline: "none",
        border: "none",
        color: '#5666F6'
    };
    const styleRight = {
        float: 'right',
        backgroundColor: "transparent",
        outline: "none",
        border: "none",
        color: '#5666F6'
    };
    const monthStyle = {
        fontSize: 19,
        color: '#4A5CFF',
        marginLeft: 10,
    }
    return (
        <div className={className}>
            <span style={monthStyle}>{now}</span>
            <button style={styleRight} onClick={() => onNextClick()}>
                {/* {next.slice(0, 3)} → */}
                <ArrowForwardIosSharpIcon />
            </button>
            <button style={styleLeft} onClick={() => onPreviousClick()}>
                {/* ← {prev.slice(0, 3)} */}
                <ArrowBackIosSharpIcon />
            </button>
        </div>
    );
}




// var ticketsDates = [];

const CEDateAndTimes = forwardRef((props, ref) => {
    const { draftEvent, setDraftEvent, setValidation,  preview, setPreview, submitEvent, } = props;

    const modifiersStyles = {
        days: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 19
        },
        entered: {
            backgroundColor: "#F5A626",
            opacity: 0.1
        },
        selected: {
            color: "white",
            backgroundColor: "#F5A626"
        },
        disabled: {
        },
        before: {
            color: '#3E3E3E',
            opacity: 0.31,
        },
        specialDay: {
            color: 'white',
            backgroundColor: '#5666F6',
            opacity: 0.25
        },
        outside: {
            backgroundColor: 'none',
        }
        ,month: { 
            display: 'table',
            margin: '0 1em',
            marginTop: '1em',
            borderSpacing: 3,
            borderCollapse: 'collapse'} 
    };
    const specialDModifiersStyles = {
        days: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 19
        },
        entered: {
            backgroundColor: "#5666F6",
            opacity: 0.1
        },
        selected: {
            color: 'white',
            backgroundColor: '#5666F6',
        },
        disabled: {
        },
        before: {
            color: '#3E3E3E',
            opacity: 0.31,
        },
        regularDay: {
            color: "white",
            backgroundColor: "#F5A626",
            opacity: 0.25
        },
        outside: {
            backgroundColor: 'none',
        }
    }

    //for react-day-picker function
    const [mouseEntered, setMouseEntered] = useState(null);
    const handleDayMouseEnter = (day) => {
        const date = new Date(moment(day).format('YYYY,MM,DD'));
        setMouseEntered(date);
    }

    const [mouseLeaved, setMouseLeaved] = useState(null);
    const handleDayMouseLeave = (day) => {
        const date = new Date(moment(day).format('YYYY,MM,DD'));
        setMouseLeaved(date);
    }

    const [selectedRegularDays, setRegularDays] = useState(draftEvent.dateAndTimes.ticketsDates.filter(t => !t.isSpecial).map(d => new Date(d.date)));
    const [selectedSpecialDays, setSpecialDays] = useState(draftEvent.dateAndTimes.ticketsDates.filter(t => t.isSpecial));
    const [ticketsDates, setTicketsDatest] = useState(draftEvent.dateAndTimes.ticketsDates);
    const handleRegularDayClick = (day, { selected, disabled }) => {
        if (open || preview) return;
        if (disabled) {
            return
        }

        if (selected || open) {
            const selectedIndex = selectedRegularDays.findIndex(selectedDay =>
                DateUtils.isSameDay(new Date(selectedDay), day)
            );
            selectedRegularDays.splice(selectedIndex, 1);
            setRegularDays([...selectedRegularDays]);
            const datesIndex = ticketsDates.findIndex(selectedDay =>
                DateUtils.isSameDay(new Date(selectedDay.date), day)
            );
            ticketsDates.splice(datesIndex, 1)
            setTicketsDatest(ticketsDates)
        } else {
            setRegularDays([...selectedRegularDays, day])
            ticketsDates.push({
                date: day,
                isSpecial: false,
            })
            setTicketsDatest(ticketsDates)
        }
    }
    const handleSpecialDayClick = (day, { selected, disabled }) => {
        if (open || preview) return;
        if (disabled || open) {
            return
        }
        if (selected) {
            const selectedIndex = selectedSpecialDays.map(specialDate => new Date(specialDate.date)).findIndex(selectedDay =>
                DateUtils.isSameDay(new Date(selectedDay), day)
            );
            selectedSpecialDays.splice(selectedIndex, 1);
            setSpecialDays([...selectedSpecialDays]);
            const datesIndex = ticketsDates.findIndex(selectedDay =>
                DateUtils.isSameDay(new Date(selectedDay.date), day)
            );
            ticketsDates.splice(datesIndex, 1)
            setTicketsDatest(ticketsDates)
        } else {
            setSpecialDays([...selectedSpecialDays, {date: day, tickets: []}])
            ticketsDates.push({
                date: day,
                isSpecial: true,
                tickets: []
            });
            setTicketsDatest(ticketsDates)
        }
    }

    const updateSepcialDayTickets = (date, tickets) => {
        const selectedIndex = selectedSpecialDays.map(specialDate => new Date(specialDate.date)).findIndex(selectedDay =>
            DateUtils.isSameDay(new Date(selectedDay), new Date(date))
        );
        const selectdateOrigin = {...selectedSpecialDays[selectedIndex]};
        selectedSpecialDays[selectedIndex] = {...selectdateOrigin, date, tickets};
        setSpecialDays(selectedSpecialDays);
        
        const dateIndex = ticketsDates.findIndex(d => d.isSpecial && d.date === date);
        const ticketdateOrigin = {...ticketsDates[dateIndex]};
        ticketsDates[dateIndex] = {...ticketdateOrigin, date, isSpecial: true, tickets};
        setTicketsDatest(ticketsDates)
    }

    const isRegularDay = (day) => {
        return selectedRegularDays.some(disabledDay =>
            DateUtils.isSameDay(day, disabledDay)
        )
    }
    const isSpecialDay = (day) => {
        return selectedSpecialDays.map(specialDate => new Date(specialDate.date)).some(disabledDay => 
            DateUtils.isSameDay(day, disabledDay)
        )
    }

    const [regularDates, setRegularDates] = useState(true);
    const handleRegularDates = () => {
        if (open) 
            return;
        setRegularDates(true)
    }
    const handleSpecialDates = () => {
        if (open) 
            return;
        setRegularDates(false)
    }
    //for react-day-picker day cell style
    const modifiers = {
        days: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
        before: new Date(),
        entered: mouseEntered,
        specialDay: day => isSpecialDay(day)
    };
    const Specialmodifiers = {
        days: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
        before: new Date(),
        entered: mouseEntered,
        regularDay: day => isRegularDay(day)
    };

    //tickets
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        if(preview) return;
        setOpen(true);
    };

    const handleDrawerClose = () => {
        if(preview) return;
        setOpen(false);
    };

    // TODO ticket filter
    const getAllTicketNames = () => {
        return selectedSpecialDays.flatMap(d => 
                d.tickets.map(t => t.ticketName)
            ).concat(
                draftEvent.tickets.allTickets
                .filter(ticket => 
                    !('action' in ticket && 
                      typeof ticket.id === 'number' &&
                      ticket['action'] === EventAction.DELETE))
                .map(t => t.ticketName)
            );
    }

    //preview
    // const [preview, setPreview] = useState(false);

    // const handlePreviewOpen = () => {
    //     if(open) return;
    //     setPreview(true);
    // }
    const handlePreviewClose = () => {
        if(open) return;
        setPreview(false);
    }

    const [selected, setSelected] = useState(0);
    const [ticketsCard, setTicketsCard] = useState(false);
    const [ticketsDate, setTicketsDate] = useState(null);
    const handleTicketCardClose = () => {
        setSelected(0)
        setTicketsDate(null);
        setTicketsCard(false);
    }

    const handleOnSelect = (key) => {
        setSelected(key)
        setTicketsCard(true);
        setTicketsDate(key)
    }
    
    const getMenuItems = () => {
        const regular_prices = draftEvent.tickets.allTickets
        .filter(ticket => 
            !('action' in ticket && 
              typeof ticket.id === 'number' &&
              ticket['action'] === EventAction.DELETE))
        .map(t => t['price']);


        return ticketsDates.map(d =>  {
            const date = moment(d.date).format('ddd, MMM D');
            let prices = [...regular_prices]
            if(d.tickets && d.tickets.length > 0) {
                // combine special and regular prices
                // prices = d.tickets.map(t => t['price']).concat(regular_prices).sort((t1, t2) => t1 - t2);
                prices = d.tickets.map(t => t['price']).sort((t1, t2) => t1 - t2);
            } else {
                prices = regular_prices.sort((t1, t2) => t1 - t2);
            }
            const price = (prices.length > 1 && prices[prices.length -1] !== prices[0]) ? 
                `$${prices[0]} - ${prices[prices.length -1]}/person` :
                `$${prices[0]}/person`;
            return {date, price, day: d.date};
        })
    }

    const menu = Menu(getMenuItems(), selected);

    const handleDragStart = (e) => e.preventDefault();

    const items = () => {
        if(draftEvent.photos.fileObj.some(obj => Object.keys(obj).length === 0)) return [];
        return draftEvent.photos.fileObj.map((obj,index) => 
            <img key={index} src={URL.createObjectURL(obj)} className={classes.img} onDragStart={handleDragStart} />
        )
    } 

    const getTicketCardItems = () => {
        let tickets = draftEvent.tickets.allTickets.filter(ticket => 
            !('action' in ticket && 
              typeof ticket.id === 'number' &&
              ticket['action'] === EventAction.DELETE)
          );
        const specialTickets = ticketsDates.filter(t => moment(t.date).isSame(ticketsDate) && t.isSpecial);
        // if(specialTickets.length > 0) {
        //     tickets = tickets.concat(specialTickets[0].tickets)
        // } 
        // return tickets;  
        if(specialTickets.length > 0) {
            return specialTickets[0].tickets
        } else {
            return tickets;
        }

    }

    const getCategory = () => {
        const category = `${draftEvent.basicInfo.category}${draftEvent.basicInfo.sportIdx}`;
        return EventCategoryIdNameMap[category] ? EventCategoryIdNameMap[category] : "";
    }

    const getDateSpan = () => {
        if (ticketsDates.length > 1) {
            let ds = ticketsDates.map(t => moment(t.date)).filter(d => d.isValid());
            ds = ds.sort((d1,d2) => d1.isBefore(d2) ? -1 : 1);
            let month = 'Multiple Dates'
            if(ds[0].isSame(ds[ds.length - 1], 'year')) {
                if (ds[0].isSame(ds[ds.length - 1], 'month')) {
                    month = `${ds[0].format('MMM')} | Multiple Dates`;
                } else {
                    month = `${ds[0].format('MMM')}-${ds[ds.length - 1].format('MMM')} | Multiple Dates`;
                }
            } else {
                month = `${ds[0].format('MMM YYYY')} - ${ds[ds.length - 1].format('MMM YYYY')} | Multiple Dates`;
            }

            return month;
        } else if (ticketsDates.length === 1) {
            let d = moment(ticketsDates[0].date);
            if(!d.isValid()) 
                return "No Date";
            
            return d.format('ddd, MMM D');
        } else {
            return "No Date";
        }
    }

    const renderDotsItem = ({ isActive }) => {
        return (
            <div>
                {isActive ?
                    <FiberManualRecordIcon style={{ color: "#5666F6", height: 7, width: 7 }} />
                    :
                    <FiberManualRecordIcon style={{ color: "#C4C4C4", height: 7, width: 7 }} />}
            </div>
        )
    }

    const dots = [1, 2, 3]
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideTo = (i) => {
        setCurrentIndex(i);
    }
    const onSlideChange = (e) => {
        setCurrentIndex(e.item);
    }

    const whatToBring = [
        "Boots & other footwear - Strongly recommended for our event.",
        "You can prepare boots & other footwear",
        "ID (required)",
        "Something else",
        "Correct paddle method, forward, backward, turn, learn basic kayaking skills, and lay the foundation."
    ]

    const keepInMind = [
        "Know how to swim;",
        "Feel comfortable in the surf zone;",
        "Have not been advised by the doctor to stay away from the ocean;",
        "Can perform basic body movements;",
        "Understand the fundamental rules of surf etiquette;"
    ]

    // const renderGallery = () => {
    //     return (
    //         <AliceCarousel
    //             dotsDisabled={true}
    //             buttonsDisabled={true}
    //             slideToIndex={currentIndex}
    //             onSlideChanged={onSlideChange}
    //         >
    //             { items.map((item, i) => <img key={i} className={classes.img} />)}
    //         </AliceCarousel>);
    // }
    // const renderThumbs = () => {
    //     <ul>{dots.map((item, i) =>
    //         <li key={i} onClick={() => slideTo(i)}>{item}</li>
    //     )}</ul>
    // }

    function checkInfoValidation() {
        if (ticketsDates && ticketsDates.length > 0) {
            setValidation(true);
            return;
        }
        setValidation(false);
    };

    useImperativeHandle(ref, () => ({
        saveDraft() {
            draftEvent.dateAndTimes = {ticketsDates};
            setDraftEvent(draftEvent)
        }
      }))

    return (
        <div style={{ marginLeft: 123, marginTop: 73 }}>
            <div style={{ width: 600, }}>
                {
                    regularDates == true ?
                        <div>
                            <p style={{ fontSize: 20, color: "#212759", fontWeight: "400", marginTop: 20, }}>
                                Select regular date
            </p>
                            <p style={{ fontSize: 12, fontWeight: "300", color: "#000000", }}>
                                Please choose regular dates below for your event.
                                After select all you regular dates, you can preview all your event details and then submit it to NASUFUN.
            </p>
                        </div>
                        :
                        <div>
                            <p style={{ fontSize: 20, color: "#212759", fontWeight: "400", marginTop: 20, }}>
                                Add special date (Optional)
            </p>
                            <p style={{ fontSize: 12, fontWeight: "300", color: "#000000", }}>
                                if there are any different tickets you would like to add (e.g. special time on weekends, different from the original one),you can set it separately by adding different tickets on the calendar.
            </p>
                        </div>
                }

                {
                    regularDates == true ?
                        <div>
                            <div style={{ marginTop: 20 }}>
                                <button
                                    className={classes.regularDateButton}
                                    onClick={handleRegularDates}
                                >
                                    <span>
                                        Select Regular date
                                </span>
                                </button>
                                <button
                                    className={classes.specialDateButton}
                                    onClick={handleSpecialDates}
                                >
                                    <span style={{
                                        width: 124,
                                        float: 'left',
                                        fontSize: 14,
                                        fontWeight: "700",
                                        color: "#FFFFFF",
                                    }}>
                                        Add Special date
                                    </span>
                                    <span style={{
                                        width: 50,
                                        // float: 'right',
                                        fontSize: 9,
                                        fontWeight: "500",
                                        color: "#FFFFFF",
                                        marginLeft: -6
                                    }}>
                                        (Optional)
                                    </span>
                                </button>
                                {/* <div className={classes.optionalIcon}>
                                    <span>
                                        Optional
                                </span> */}
                                {/* </div> */}
                            </div>
                            <div className={classes.dayPickerContainer}>
                                <DayPicker
                                    className={classes.monthStyle}
                                    modifiers={modifiers}
                                    modifiersStyles={modifiersStyles}
                                    onDayClick={handleRegularDayClick}
                                    selectedDays={selectedRegularDays}
                                    disabledDays={[
                                        modifiers.specialDay, {
                                        before: modifiers.before
                                    }]}
                                    weekdayElement={<Weekday />}
                                    navbarElement={<Navbar />}
                                    captionElement={({ date, localeUtils }) => (
                                        <YearMonthForm
                                            date={date}
                                            localeUtils={localeUtils}
                                        />
                                    )}
                                // onDayMouseEnter={handleDayMouseEnter}
                                // onDayMouseLeave={handleDayMouseLeave}
                                />
                            </div>
                            <p className={classes.numOfSelected}>
                                {selectedRegularDays.length} selected
                        </p>
                        </div>
                        :
                        <div>
                            <div style={{ marginTop: 20 }}>
                                <button
                                    className={classes.onRegularDateButton}
                                    onClick={handleRegularDates}
                                >
                                    <span>
                                        Select Regular date
                                </span>
                                </button>
                                <button
                                    className={classes.onSpecialDateButton}
                                    onClick={handleSpecialDates}
                                >
                                    <span style={{
                                        width: 124,
                                        float: 'left',
                                        fontSize: 14,
                                        fontWeight: "700",
                                        color: "#FFFFFF",
                                    }}>
                                        Add Special date
                                    </span>
                                    <span style={{
                                        width: 50,
                                        // float: 'right',
                                        fontSize: 9,
                                        fontWeight: "500",
                                        color: "#FFFFFF",
                                        marginLeft: -6
                                    }}>
                                        (Optional)
                                    </span>
                                </button>
                                {/* <div className={classes.onOptionalIcon}>
                                    <span>
                                        Optional
                                </span>
                                </div> */}
                            </div>
                            <div className={classes.dayPickerContainer}>
                                <DayPicker
                                   className={classes.monthStyle}
                                    modifiers={Specialmodifiers}
                                    modifiersStyles={specialDModifiersStyles}
                                    onDayClick={handleSpecialDayClick}
                                    selectedDays={selectedSpecialDays.map(specialDate => new Date(specialDate.date))}
                                    disabledDays={[
                                        Specialmodifiers.regularDay, {
                                            before: Specialmodifiers.before}
                                    ]}
                                    weekdayElement={<Weekday />}
                                    navbarElement={<Navbar />}
                                    captionElement={({ date, localeUtils }) => (
                                        <YearMonthForm
                                            date={date}
                                            localeUtils={localeUtils}
                                        />
                                    )}
                                // onDayMouseEnter={handleDayMouseEnter}
                                />
                            </div>
                            <p className={classes.numOfSelected}>
                                {selectedSpecialDays.length} selected
                        </p>
                        </div>
                }
                {
                    regularDates == false ?
                        selectedSpecialDays.map(specialDate =>   
                             <SpecialDate
                                specialDate={specialDate.date}
                                onDrawerOpen={handleDrawerOpen}
                                onDrawerClose={handleDrawerClose}
                                opened={open || preview}
                                allTickets={specialDate.tickets}
                                setTickets={updateSepcialDayTickets}
                                getAllTicketNames={getAllTicketNames}
                             ></SpecialDate>
                             )
                        : <div></div>
                }
            </div>

            {/* tickets */}
  
            <div>
                {/* <div style={{ width: 117, height: 40, border: "solid 1px", borderColor: "#5666F6", borderRadius: 30, marginTop: 20 }}>
                    <IconButton
                        className={classes.previewButton}
                        onClick={handlePreviewOpen}
                    >
                        <div>Preview</div>
                        <VisibilityIcon style={{ color: "#5666F6", marginLeft: 5 }} />
                    </IconButton>
                </div> */}
                <Drawer
                    variant="permanent"
                    anchor="right"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: preview,
                        [classes.drawerClose]: !preview,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.paperOpen]: preview,
                            [classes.paperClose]: !preview,
                        }),
                    }}
                    style={{ overflow: 'auto', height: '100%' }}
                >
                    <IconButton
                        onClick={handlePreviewClose}
                        className={classes.closeButton}>
                        <CloseIcon style={{ width: 20, height: 20 }} />
                    </IconButton>
                    {   !ticketsCard  &&
                            <div class= "scroll" 
                                className={classes.previewCard}>
                                <BetterScroll>
                                    {/* <div style={{ height: 1669 }}> */}
                                        <div style={{ width: 326, marginLeft: 9, marginBottom: '20px'}}>
                                            <AliceCarousel mouseTracking items={items()}
                                                disableButtonsControls={true}
                                                renderDotsItem={renderDotsItem}
                                            />
                                        </div>
                                        <div style={{ width: 326, display: "flex", flexDirection: "column", alignItems: "baseline", marginLeft: 20, marginTop: -15 }}>
                                            <div style={{ fontSize: 18, fontWeight: "700", color: "#000000", fontFamily: "System" }}>{draftEvent.basicInfo.name}</div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: 3 }}>
                                                <RoomIcon style={{ color: "#5666F6" }} />
                                                <div style={{ fontSize: 14, fontWeight: "300", color: "#000000", wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line", marginLeft: 5 }}>{draftEvent.basicInfo.onlineEvent ? 'Online':  `${draftEvent.basicInfo.address}, ${draftEvent.basicInfo.city} ${draftEvent.basicInfo.selectState}, ${draftEvent.basicInfo.zipCode}` }</div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 3 }}>
                                                <EventNoteIcon style={{ color: "#5666F6", marginTop: 3 }} />
                                                <div style={{ fontSize: 14, fontWeight: "300", color: "#000000", wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line", marginLeft: 5 }}>{getDateSpan()}</div>
                                            </div>
                                            <div style={{ paddingLeft: 5, paddingRight: 5, height: 19, backgroundColor: "rgba(86, 102, 246, 0.1)", borderRadius: 30, color: "#5666F6", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>{getCategory()}</div>
                                        </div>
                                        <hr style={{ width: 326, borderColor: "#C1C1C1" }} />
                                        <div style={{ width: 283, height: 30, display: "flex", flexDirection: "column", alignItems: "baseline", marginLeft: 20 }}>
                                            <div style={{
                                                fontSize: 16, fontWeight: "700", color: "#000000",
                                            }}>Available dates</div>
                                            <div style={{
                                                fontSize: 11, letterSpacing: 0.3, fontWeight: "300"
                                            }}>{ticketsDates.length} dates available</div>
                                        </div>
                                        <div style={{ marginTop: 10, marginLeft: 10 }} className={styles}>
                                            <ScrollMenu
                                                data={menu}
                                                alignCenter={false}
                                                itemClass={styles.menuItemWrapper}
                                                selected={selected}
                                                onSelect={handleOnSelect}

                                            />
                                        </div>
                                        <div style={{ width: 304, marginLeft: 20, marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: "700", color: "#000000" }}>About</div>
                                            <div style={{ fontSize: 14, marginTop: 10, fontWeight: '300', color: "#000000", wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line" }}>{draftEvent.basicInfo.detail}</div>
                                        </div>
                                       { draftEvent.advancedSetting.bringNameList.length > 0 &&  
                                       <div style={{ width: 304, marginLeft: 20, marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: "700", color: "#000000", marginBottom: 15, marginTop: 10 }}>What to bring</div>
                                            {draftEvent.advancedSetting.bringNameList
                                            .filter(item => !(typeof item === 'object' && item.id && item.action === EventAction.DELETE))
                                            .map((item, index) => (
                                                <div>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <img src={require('../../images/yellowRight.png')} style={{ float: "left", marginLeft: 5 }} />
                                                        <div key={index} style={{
                                                            float: 'left', marginLeft: 15,
                                                            width: 224, fontSize: 12, fontWeight: "300", color: '#2F2F2F', letterSpacing: 0.3, wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line"
                                                        }} > {typeof item === 'object' ? item.comment : item}</div>
                                                    </div>
                                                    <hr style={{ width: 304, borderColor: "#DDDDDD" }} />
                                                </div>
                                            ))}
                                        </div>}
                                        <div style={{ width: 304, marginLeft: 20, marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: "700", color: "#000000", marginBottom: 15 }}>The Operator</div>
                                            <div style={{ width: 304, height: 169, borderRadius: 15, backgroundColor: "#F8F8F8", paddingTop: 25, paddingBottom: 15, paddingLeft: 15, paddingRight: 15 }}>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 274 }}>
                                                    <img src={require('../../images/avatar.png')} style={{ float: "left", width: 42, height: 42 }} />
                                                    <div style={{ fontSize: 14, fontWeight: '600', color: '#000000', float: "left", marginLeft: 5 }}>NASUFUN</div>
                                                    <img src={require('../../images/opbadge@4x.png')} style={{ float: "left", marginLeft: 5, width: 13, height: 14 }} />
                                                    {/* <img src={require('../../images/message@4x.png')} style={{ float: "right", marginLeft: 40, width: 22, height: 20 }} />
                                                    <div style={{
                                                        width: 70,
                                                        height: 23,
                                                        borderRadius: 12.5,
                                                        border: "solid 1px",
                                                        borderColor: "#5666F6",
                                                        float: 'right',
                                                        marginLeft: 5
                                                    }}>
                                                        <IconButton className={classes.followButton}>
                                                            +Follow
                                                </IconButton>
                                                    </div> */}
                                                </div>
                                                <div style={{ width: 248, fontSize: 12, fontWeight: "300", color: '#7D7D7D', letterSpacing: 0.3, wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line", marginLeft: 49 }}>NASUFUN focuses on the planning IRL sports and outdoor events. Since the establishment in 2018, NASUFUN has organized more than 600 events and...</div>
                                                {/* <button style={{ backgroundColor: "transparent", borderColor: "transparent", float: "right", outline: "none", marginRight: 2 }}>View profile ></button> */}
                                            </div>
                                        </div>
                                        {draftEvent.advancedSetting.checkedList.keepInMind &&  
                                        <div style={{ width: 304, marginLeft: 20, marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: "700", color: "#000000", marginBottom: 15, marginTop: 10 }}>Things to keep in mind</div>
                                            <div>{draftEvent.advancedSetting.mindText}</div>
                                            {/* {keepInMind.map((item, index) => (
                                                <div style={{ fontSize: 14, fontWeight: "300", color: "#000000", wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line", }}>{index + 1}. {item}</div>
                                            ))} */}
                                        </div>}
                                        <div style={{ width: 304, marginLeft: 20, marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: "700", color: "#000000" }}>Refund policy</div>
                                            <div style={{ fontSize: 14, marginTop: 10, fontWeight: '300', color: "#000000", wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line" }}> Attendees will get a {draftEvent.advancedSetting.refund} refund if they cancel the event {draftEvent.advancedSetting.refundDate} days before. No refund to cancel within {draftEvent.advancedSetting.refundDate} days.</div>
                                        </div>
                                    {/* </div> */}
                                    <div style={{height: '50px'}}></div>
                                </BetterScroll>
                                {/* <div className={classes.cardGradient}></div> */}
                            </div>
                            }
                            {/* // ticket Cards */}
                            { ticketsCard &&
                            <div className={classes.previewCard}>
                                  <BetterScroll>
                                <div style={{ width: 304, marginTop: 45, marginLeft: 20, marginRight: 20, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <ArrowBackIosSharpIcon onClick={handleTicketCardClose} fontSize='large' />
                                    <div style={{ fontSize: 18, fontWeight: '700', color: '#000000', float: "right" }}>{moment(ticketsDate).format('MMM D')}</div>
                                </div>
                                {getTicketCardItems().length && getTicketCardItems().map((ticket,index) => 
                                <div key={index} style={{ marginTop: 30, marginLeft: 20, marginRight: 20 }}>
                                    <div style={{ width: 304, height: 135, borderRadius: 15, backgroundColor: "#F3F5FF", paddingTop: 15, paddingBottom: 15, paddingLeft: 20, paddingRight: 20 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ fontSize: 15, fontWeight: "600", color: "#000000" }}>{ticket.ticketName}</div>
                                            <div style={{ fontSize: 17, fontWeight: "500", color: '#F5A626' }}>$ {ticket.price}</div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <div style={{ fontSize: 10, fontWeight: "400", color: "#5666F6" }}>{ticket.eventStartTime} | {ticket.duration}</div>
                                            <div style={{ fontSize: 12, fontWeight: "300", color: "#000000" }}>{ticket.qty} tickets</div>
                                        </div>
                                        <div style={{ width: 180, fontSize: 11, fontWeight: '300', color: "#000000", wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line" }}>{ticket.ticketDescription}</div>
                                    </div>
                                </div>)
                                } 
                                </BetterScroll>  
                            </div>
                    }
                    <div style={{ width: 344, height: 45, backgroundColor: "#5666F6", boxShadow: "0px 8px 15px rgba(86, 102, 246, 0.26)", borderRadius: 30, marginLeft: "auto", marginRight: "auto", marginTop: 47 }}>
                        <IconButton
                            onClick={submitEvent}
                            className={classes.submitEvent}
                        >
                            Submit my event
                        </IconButton>
                    </div>
                </Drawer>
            </div>
            {checkInfoValidation()}
        </div >
    )
})

export default CEDateAndTimes;
