import React, { Component } from 'react';
import {
    makeStyles, Modal, Button, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import "../../css/Registration.css"
import * as CONST from './RegistrationFormConst';

const useStyles = makeStyles((theme) => ({
    tootipContainer: {
        position: 'relative',
        '&:hover $tootipInfo': {
            display: 'block'
        },
    },
    tootipBody: {
        width: '14px',
        height: '14px',
        marginLeft: '15px',
        marginTop: '2px',
        backgroundColor: '#C1C1C1',
        borderRadius: '15px',
        textAlign: 'center',
        color: '#FFFFFF',
        fontSize: '10px',
        cursor: 'pointer',

    },
    tootipInfo: {
        display: 'none',
        position: 'absolute',
        left: '50px',
        top: '-7px',
        padding: '5px 10px',
        borderRadius: '9px',
        minWidth: '144px',
        fontSize: '10px',
        color: '#FFFFFF',
        backgroundColor: '#C1C1C1',
        zIndex: 101,
        '&::before': {
            position: 'absolute',
            content: '""',
            width: 0,
            height: 0,
            top: '7px',
            left: '-8px',
            borderTop: '6px solid transparent',
            borderBottom: '6px solid transparent',
            borderRight: '10px solid #C1C1C1',
        },
    },
    modalBody: {
        position: 'absolute',
        top: 'calc( 50vh - 270px)',
        left: 'calc(50vw - 170px)',
        display: ' flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '540px',
        height: '340px',
        borderRadius: 15,
        padding: '40px',
        backgroundColor: '#FFFFFF',
        '& #modal-img': {
            padding: '20px'
        },
        '& #modal-title': {
            padding: '20px',
            fontWeight: 'bold'
        },
        '& #modal-btn-dismiss': {
            position: 'absolute',
            right: 0,
            top: 0,
            outline: 'none',
        },
        '& #modal-content': {
            marginBottom: '62px',
        }
    },
    modalActions: {
        '& Button': {
            margin: '23px',
            padding: '6px 36px',
            borderRadius: '24px',
            fontSize: '14px',
            border: '1px solid #5666F6',
        }
    }
}));
const { IMG_PATH } = CONST;
function Tootip(props) {
    const classes = useStyles();

    return (
        <div className={classes.tootipContainer}>
            <div className={classes.tootipBody}>
                <span>?</span>
            </div>
            <div className={classes.tootipInfo}>{props.info}</div>
        </div>
    );
}

function Dialog(props) {
    const classes = useStyles();

    const IMG = props.dialog.text.img ? props.dialog.text.img : null;
    const title = props.dialog.text.title;
    const description = props.dialog.text.description;
    const btnCancelText = props.dialog.text.btnCancelText;
    const btnConfirmText = props.dialog.text.btnConfirmText;

    const onClickConfirm = () => {
        props.onDialogConfirmed(props.dialog);
    };

    const onClickCancel = () => {
        props.onDialogDismissed();
    }


    const onModalDismissed = () => {
        props.onDialogDismissed();
    }

    const actionDismiss = (
        <IconButton
            color="inherit"
            id="modal-btn-dismiss"
            onClick={onModalDismissed}>
            <CloseIcon style={{ width: 19, height: 19 }} />
        </IconButton>
    );

    const actions = (
        < div className={classes.modalActions}>
            <Button id="modal-btn-cancel"
                variant="outlined"
                color="primary"
                disableElevation
                onClick={onClickCancel}>
                <span style={{ textTransform: 'none' }}>{btnCancelText}</span>
            </Button>
            <Button id="modal-btn-confirm"
                variant="outlined"
                color="primary"
                disableElevation
                onClick={onClickConfirm}>
                <span style={{ textTransform: 'none' }}>{btnConfirmText}</span>
            </Button>
        </div >
    );

    const body = (
        <div className={classes.modalBody}>
            {actionDismiss}
            {IMG && <img id="modal-img"
                src={`${IMG_PATH}${IMG}`}
                alt="Congra"
            />}
            <h4 id="modal-title">{title}</h4>
            <div id="modal-content">
                <p id="modal-description">
                    {description}
                </p>
            </div>
            {(btnCancelText && btnConfirmText) && actions}
        </div>
    );

    return (

        <Modal
            open={props.open}
            onClose={onModalDismissed}
        >
            {body}
        </Modal>);

}

function DeepEqual(a, b) {
    if ((typeof a == 'object' && a != null) &&
        (typeof b == 'object' && b != null)) {
        var count = [0, 0];
        for (var key in a) count[0]++;
        for (var key in b) count[1]++;
        if (count[0] - count[1] != 0) { return false; }
        for (var key in a) {
            if (!(key in b) || !DeepEqual(a[key], b[key])) { return false; }
        }
        for (var key in b) {
            if (!(key in a) || !DeepEqual(b[key], a[key])) { return false; }
        }
        return true;
    }
    else {
        return a === b;
    }
}

function Reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export { Tootip, Dialog, DeepEqual, Reorder };
