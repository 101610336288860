import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import {
    Box, Button,
    Tabs, Tab,
    makeStyles, withStyles,
    Typography,
    Modal
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import subHeader from '../../images/DashboardPic@3x.png';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AuthContext } from '../UserAuth/AuthContext';
import { useHistory} from "react-router-dom";
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const AntTabs = withStyles({
    root: {
        borderBottom: '2px solid #DFDFDF',
    },
    indicator: {
        backgroundColor: '#5666F6',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontSize: 16,
        fontWeight: '400',
        //   color: '#000000',
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#5666F6',
            opacity: 1,
        },
        '&$selected': {
            color: '#5666F6',
        },
        '&:focus': {
            color: '#5666F6',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    tabs: {
        width: '90%',
        marginLeft: 47,
        marginTop: 15,
    },
    subHeaderContainer: {
        display: 'flex',
        width: '1004px',
        height: '277px',
        backgroundImage: `url(${subHeader})`,
        backgroundSize: 'cover',
        borderRadius: 15, //add to the picture
        marginLeft: '5%', //5%
        marginTop: '10%', //10%
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 35,
    },
    noEventsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: "-2%",
    },
    renderEventsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        marginLeft: "-2%",
        marginBottom: "+2.5%",
        
    },
    singleEventContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '1004px',
        height: '216px',
        border: '1px solid rgba(255, 255, 255, .5)',
        borderRadius: 30,
        marginTop: 45,
        backgroundColor: '#FFFFFF',
        webkitBackgroundClip: 'padding-box', /* for Safari */
        backgroundClip: 'padding-box', /* for IE9+, Firefox 4+, Opera, Chrome */
        //boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.3)',    
    },
}))

const Dashboard = () => {
        const auth = useContext(AuthContext);
        const history = useHistory();
        const postPublishStatus = ['published', 'expired']
        const [total, setTotal] = React.useState([]);
        const [selectedEventId, setSelectedEventId] = React.useState(null);
        const getAllEventsHost = async () => {
            const userId = auth.user.id;
            await axios
            .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/${userId}/events/`)
            //.get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/${auth.user.id}/events/`)
            .then(res => {
                const unp = res.data.events.filter(event => !postPublishStatus.includes(event.eventStatus));
                    unp.length > 0 ? setHasUnpublishedEvents(unp) : setHasUnpublishedEvents(false);

                const pub = res.data.events.filter(event => event.eventStatus === "published");
                    pub.length > 0 ? setHasPublishedEvents(pub) : setHasPublishedEvents(false);
    
                const exp = res.data.events.filter(event => event.eventStatus === "expired");
                    exp.length > 0 ? setHasEndedEvents(exp) : setHasEndedEvents(false);
                
                let drafts = res.data.drafts;       
                drafts.forEach(d => getDraftEventInfo(d))
                drafts.length > 0 ? setDraftEvents(drafts) : setDraftEvents(false);
                setTotal(res.data.total);
            })
            .catch(err => {
                console.log(err);
            });
        };

        const publishEvent = async (eventId) => {
            const userId = auth.user.id;
            const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/publish/`
            await axios
            .post(url, {userId})
            .then(res => {
                if(res.data.Success) {
                    const event = hasUnpublishedEvents.find(e => e.id === eventId);
                    event.eventStatus = 'published';
                    hasPublishedEvents.unshift(event);
                    setHasPublishedEvents(hasPublishedEvents);
                    const unp = hasUnpublishedEvents.filter(e => e.id !== eventId);
                    unp.length > 0 ? setHasUnpublishedEvents(unp) : setHasUnpublishedEvents(false);

                } else {   
                    // handle failure
                }
            })  
            .catch(err => {
                console.log(err);
            });
            
        }
    
        useEffect(() => {
            getAllEventsHost();
        }, []);
        
        const classes = useStyles();
    
        const [value, setValue] = React.useState(0);
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
    
        const [showModal, setShowModal] = useState(false);
        const handleModal = (id) => {
            setSelectedEventId(id)
            setShowModal(true);
        }
        const handleModalClose = () => {
            setSelectedEventId(null)
            setShowModal(false)
        }
    
        const deleteDraft = () => {
            const userId = auth.user.id;
            const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/delete_draft/`;
            
            axios.post(url, {userId, draftId: selectedEventId})
                .then(response=> {
                    console.log(response)
                    if(!response.data.Success) 
                        return;

                    const drafts = draftEvents.filter(e => e.id !== selectedEventId);
                    drafts.length > 0 ? setDraftEvents(drafts) : setDraftEvents(false);
                    handleModalClose();
                })
                .catch(error=>console.log(error));
        }

        const handleDraftEventEdit = (eventId) => {
            const userId = auth.user.id;
            const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/get_rejected_event/`;
            
            axios.post(url, {userId, eventId})
                .then(response=> {
                    console.log(response)
                    if(!response.data.Success) 
                        return;
                    const { data } = response.data;
                    let pathname = `/create_event`; 
                    history.push({
                        pathname,
                        state: { draft: data, eventId  }
                    })
                })
                .catch(error=>console.log(error));
        }
    
        const [hasUnpublishedEvents, setHasUnpublishedEvents] = useState([]);
        // const handleHasUnpublishedEvents = (e) => {
        //     setHasUnpublishedEvents(e.target.value);
        // }
    
        const [hasPublishedEvents, setHasPublishedEvents] = useState([]);
        // const handleHasPublishedEvents = (e) => {
        //     setHasPublishedEvents(e.target.value);
        // }
    
        const [hasEndedEvents, setHasEndedEvents] = useState([]);
        // const handleHasEndedEvents = (e) => {
        //     setHasEndedEvents(e.target.value);
        // }
        const [draftEvents, setDraftEvents] = useState([]);

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        }
    
        const handleClose = () => {
            setAnchorEl(null);
        }
    
        const[hover, setHover] = useState(-1);
        const handleMouseOver = (idx) => {
            setHover(idx);
        }
        
        const handleMouseOut  = (idx) => {
            setHover(idx);
        }

        const handleEditDraft = (draft) => {
            let pathname = `/create_event`; 
            history.push({
                pathname,
                state: { draft: draft.draftEvent, draftId: draft.id }
            })
        }

        const getDraftEventInfo = (draft) => {
            // Name
            draft.eventName = (((draft || {}).draftEvent || {}).basicInfo || {}).name ||  "Name is required";

            //  Address
            if ((((draft || {}).draftEvent || {}).basicInfo || {}).onlineEvent) {
                draft.eventAddress = "Online"
            } else {
                const state =  (((draft || {}).draftEvent || {}).basicInfo || {}).selectState || "";
                const zipCode =  (((draft || {}).draftEvent || {}).basicInfo || {}).zipCode || "";
                const city =  (((draft || {}).draftEvent || {}).basicInfo || {}).city || "";
                const address =  (((draft || {}).draftEvent || {}).basicInfo || {}).address || "";
                if (state && zipCode && city && address) {
                    draft.eventAddress = `${address}, ${city}, ${state} ${zipCode}`;
                } else {

                }
            }

            // Time 
            let dates = (((draft || {}).draftEvent || {}).dateAndTimes || {}).ticketsDates || [];
            
            let ds = dates.map(t => moment( (t || {}).date || "u" )).filter(d => d.isValid());
            ds = ds.sort((d1,d2) => d1.isBefore(d2) ? -1 : 1);
            if (ds.length > 0) {
                draft.eventStartTime = ds[0].startOf('day')._i;
                draft.eventEndTime = ds[ds.length - 1].endOf('day')._i;
            } 

            // ptice 
            let ts = ((((draft || {}).draftEvent || {}).tickets || {}).allTickets || []).map(t => t.price || 0 );
            ts = ts.concat(
                dates.filter(d => d.isSpecial).map(d => d.tickets || []).flatMap(d => d.flatMap(a => a.price)) 
                )
            ts = ts.sort((t1,t2) => t1 - t2);
            if (ts.length > 0) {
                draft.priceMin = ts[0];
                draft.priceMax = ts[ts.length - 1];
            }
        }
    
        // const history = useHistory();
    
        // const routeChange = () =>{ 
        //     let path = `/create_event`; 
        //     history.push(path);
        // }
    
        const renderSubHeader = () => {
            return (
                <Box className={classes.subHeaderContainer}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginLeft: 61 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ fontSize: 40, fontWeight: '800' }}>Hi </div>
                                <div style={{ fontSize: 40, fontWeight: '800', color: '#5666F6', marginLeft: 10 }}>{auth.user.first_name}</div>
                                <div style={{ fontSize: 40, fontWeight: '800' }}>,</div>
                            </div>
                            <div style={{ fontSize: 30, fontWeight: '800' }}>Welcome Back</div>
                            <Button style={{backgroundColor: '#5666F6', color: 'white', width: 158, height: 45, borderRadius: 30, marginTop: 70, textTransform: 'none', fontSize: 14, boxShadow: '0px 11px 18px rgba(172, 172, 172, 0.53)' , outline: 'none'}} component={Link} to="/create_event"> <span style= {{ fontSize: 20, fontWeight: '500' }}>+  </span> &nbsp;&nbsp;&nbsp; <span>Create Event</span>  
                            </Button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ width: 320, height: 108, backgroundColor: '#FFFFFF', borderRadius: 15, display: 'flex', marginLeft: 310, flexDirection: 'column', marginTop: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                    <img src={require('../../images/DollarSign@3x.png')} style={{ width: 23, height: 23, marginLeft: 24, marginTop: 7 }} />
                                    <div style={{ fontSize: 25, fontWeight: '800', marginLeft: 15, color: '#F5A626' }}>$ {total.sales}</div>
                                </div>
                                <div style={{ fontSize: 15, fontWeight: '400', marginLeft: 65, marginTop: 5 }}>Total Sales</div>
                            </div>
                            <div style={{ width: 320, height: 108, backgroundColor: '#FFFFFF', borderRadius: 15, display: 'flex', marginLeft: 310, flexDirection: 'column', marginTop: 11 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                    <img src={require('../../images/TicketSign@3x.png')} style={{ width: 23, height: 23, marginLeft: 24, marginTop: 7 }} />
                                    <div style={{ fontSize: 25, fontWeight: '800', marginLeft: 15, color: '#F5A626' }}>{total.tickets}</div>
                                </div>
                                <div style={{ fontSize: 15, fontWeight: '400', marginLeft: 65, marginTop: 5 }}>Total Tickets</div>
                            </div>
                        </div>
                    </div>
                </Box>
            )
        }
        const renderTitle = () => {
            return (
                <div className={classes.titleContainer}>
                    <div style={{fontSize: 20, fontWeight: '700'}}>My Events</div>
                </div>
            )
        }
    
        const renderTabs = () => {
            return (
                <div className={classes.tabs}>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Unpublished" />
                        <AntTab label="Published" />
                        <AntTab label="Ended" />
                    </AntTabs>
                    <TabPanel value={value} index={0}>
                        {hasUnpublishedEvents || draftEvents ?
                            renderUnpublishedEventsList()
                            :
                            renderNoEvents("Unpublished")
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {hasPublishedEvents ?
                            renderPublishedEventsList()
                            :
                            renderNoEvents("Published")
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {hasEndedEvents ?
                            renderEndedEventsList()
                            :
                            renderNoEvents("Ended")
                        }
                    </TabPanel>
                    <Typography className={classes.padding} />
                    
                </div>
            )
        }
    
        const renderNoEvents = (eventStatus) => {
            if (eventStatus == "Unpublished") {
                return (
                    <div className={classes.noEventsContainer}>
                        <img src={require('../../images/NoEvents@3x.png')} style={{ width: 80, height: 80 }} />
                        <div style={{ fontSize: 14, fontWeight: '300', marginTop: 20, color: '#A1A1A1' }}>You have no unpublished events yet.</div>
                    </div>
                )
            } else if (eventStatus == "Published") {
                return (
                    <div className={classes.noEventsContainer}>
                        <img src={require('../../images/NoEvents@3x.png')} style={{ width: 80, height: 80 }} />
                        <div style={{ fontSize: 14, fontWeight: '300', marginTop: 20, color: '#A1A1A1' }}>You have no published events yet.</div>
                    </div>
                )
            } else if (eventStatus == "Ended") {
                return (
                    <div className={classes.noEventsContainer}>
                        <img src={require('../../images/NoEvents@3x.png')} style={{ width: 80, height: 80 }} />
                        <div style={{ fontSize: 14, fontWeight: '300', marginTop: 20, color: '#A1A1A1' }}>You have no ended events yet.</div>
                    </div>
                )
            }
        }
    
        const renderUnpublishedEvents = (eventObj) => {
            const hasImage = eventObj.eventImage ? true : false
            const hasLocation = eventObj.eventAddress ? true : false
            const hasTime = eventObj.eventStartTime ? true : false
            const hasPrice = eventObj.priceMax ? true : false

            return (
                <div className={classes.renderEventsContainer}>
                    <div className={classes.singleEventContainer}>
                        { hasImage ? 
                            <img src={eventObj.eventImage} style={{ width: 320, height: 216, borderTopLeftRadius: 30, borderBottomLeftRadius: 30, marginLeft: -1, marginTop: -1 }} />
                            :
                            <div style={{ display: 'flex', backgroundColor: '#F7F7F7', width: 320, height: 214, borderTopLeftRadius: 30, borderBottomLeftRadius: 30, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontSize: 14, fontWeight: '200' }}>Photos are needed</div>
                            </div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 30, marginTop: 5 }}>
                            {/* <Link style={{ fontSize: 20, fontWeight: '300', marginTop: 18 }} to={`/manage_event/${eventObj.id}`}> */}
                                <div style={{ fontSize: 20, fontWeight: '300', marginTop: 18 }}>  
                                  {eventObj.eventName.substring(0,60)} 
                                </div>
                            {/* </Link> */}
                            { hasLocation ?
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                    <img src={require('../../images/HasLocation@3x.png')} style={{ width: 9.75, height: 12, marginTop: 4 }} />
                                    <div style={{ fontSize: 14, fontWeight: '200', marginLeft: 10 }}>{eventObj.eventAddress.substring(0,70)}</div>
                                </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                    <img src={require('../../images/NoLocation@3x.png')} style={{ width: 9.75, height: 12, marginTop: 4 }} />
                                    <div style={{ fontSize: 14, fontWeight: '300', color: '#BDBDBD', marginLeft: 10 }}>Location is needed</div>
                                </div>
                            }
                            { hasTime ? 
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                    <img src={require('../../images/HasTime@3x.png')} style={{ width: 12, height: 10, marginTop: 4 }} />
                                    <div style={{ fontSize: 14, fontWeight: '200', marginLeft: 10 }}>{new Date(eventObj.eventStartTime).toLocaleString("en-us", { month: "short" })} - {new Date(eventObj.eventEndTime).toLocaleString("en-us", { month: "short" })} | {new Date(eventObj.eventStartTime).toLocaleString("en-us", { hour: "numeric" }).toLowerCase().replace(/ /g,'')} - {new Date(eventObj.eventEndTime).toLocaleString("en-us", { hour: "numeric" }).toLowerCase().replace(/ /g,'')}</div>
                                </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                    <img src={require('../../images/NoTime@3x.png')} style={{ width: 12, height: 10, marginTop: 4 }} />
                                    <div style={{ fontSize: 14, fontWeight: '300', color: '#BDBDBD', marginLeft: 10 }}>Date and time are needed</div>
                                </div>
                            }
                            { hasPrice ? 
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                                    <div style={{ fontSize: 13, fontWeight: '300' }}>From</div>
                                    <div style={{ fontSize: 18, fontWeight: '600', marginLeft: 15, color: '#E9A845', marginTop: -5 }}>${eventObj.priceMin} - ${eventObj.priceMax}</div>
                                </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                                    <div style={{ fontSize: 14, fontWeight: '300', color: '#BDBDBD' }}>Price is needed</div>
                                </div>
                            }
                        </div>
                        {renderUnpublishedEventStatus(eventObj)}
                        <div style={{ width: 50, height: 50, marginTop: 170, marginLeft: -15 }}>
                            <MoreHorizIcon
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                event_id={eventObj.id}
                            >
                            </MoreHorizIcon>
                            <Menu
                                id={`simple-menu-${eventObj.id}`}
                                anchorEl={ anchorEl }
                                keepMounted
                                open={ Boolean(anchorEl) && Number(anchorEl.getAttribute('event_id')) === eventObj.id }
                                onClose={ handleClose }
                            >
                                {/* TODO: Restore Edit after edit module finished */}
                                {/* <MenuItem component={Link} to={`/operator_center/event/${eventObj.id}`} style={{outline: 'none'}}>Edit</MenuItem> */}
                                { eventObj.eventStatus === 'draft' &&
                                    <MenuItem  onClick={ () => handleEditDraft(eventObj) } style={{outline: 'none'}}>Edit</MenuItem> 
                                }
                                { eventObj.eventStatus === 'rejected' &&
                                    <MenuItem  onClick={ () => handleDraftEventEdit(eventObj.id) } style={{outline: 'none'}}>Edit</MenuItem> 
                                }
                                <MenuItem onClick={() => handleModal(eventObj.id) }>Delete</MenuItem>
                            </Menu>
                            <Dialog
                                open={ showModal }
                                onClose={() => handleModalClose() }
                            >
                                <DialogContent>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{ fontSize: 14, fontWeight: '300' }}>
                                            The information of this event you created
                                        </div>
                                        <div style={{ fontSize: 14, fontWeight: '300' }}>
                                            will be removed. Do you still want to delete 
                                        </div>
                                        <div style={{ fontSize: 14, fontWeight: '300' }}>
                                            the event?
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button 
                                        style={{ width: 100, height: 30, border: 'solid 1px', borderColor: '#5666F6', borderRadius: 21 }}
                                        onClick={() => handleModalClose() } 
                                        color="primary"
                                    >
                                        <div style={{ fontSize: 10, fontWeight: '500' }}>
                                            No, keep it
                                        </div>
                                    </Button>
                                    <Button 
                                        style={{ width: 100, height: 30, border: 'solid 1px', borderColor: '#5666F6', borderRadius: 21 }}
                                        onClick={() => deleteDraft() } 
                                        color="primary"
                                    >
                                        <div style={{ fontSize: 10, fontWeight: '500' }}>
                                            Delete
                                        </div>
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </div>
            )
        }
        
        const renderUnpublishedEventsList = () => {
            return (<>
            { draftEvents && draftEvents.map((unpEvent, index) =>  <div key={index}>{renderUnpublishedEvents(unpEvent)}</div>)}
            { hasUnpublishedEvents &&  hasUnpublishedEvents.map((unpEvent, index) => <div key={index}>{renderUnpublishedEvents(unpEvent)}</div>)}
            </>)
        }
       
        const renderPublishedEvents = (eventObj) => {
            return (
                <div className={classes.renderEventsContainer}>
                    <div className={classes.singleEventContainer}>
                        <img src={eventObj.eventImage} style={{ width: 320, height: 216, borderTopLeftRadius: 30, borderBottomLeftRadius: 30, marginLeft: -1, marginTop: -1 }} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 30, marginTop: 5 }}>
                        
                            <Link style={{ fontSize: 20, fontWeight: '300', marginTop: 18, width:480 }} to={`/manage_event/${eventObj.id}`}>
                                {eventObj.eventName.substring(0,60)}
                            </Link>

                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <img src={require('../../images/HasLocation@3x.png')} style={{ width: 9.75, height: 12, marginTop: 4 }} />
                                <div style={{ fontSize: 14, fontWeight: '200', marginLeft: 10 }}>{eventObj.eventAddress.substring(0,70)}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                <img src={require('../../images/HasTime@3x.png')} style={{ width: 12, height: 10, marginTop: 4 }} />
                                <div style={{ fontSize: 14, fontWeight: '200', marginLeft: 10 }}>{new Date(eventObj.eventStartTime).toLocaleString("en-us", { month: "short" })} - {new Date(eventObj.eventEndTime).toLocaleString("en-us", { month: "short" })} | {new Date(eventObj.eventStartTime).toLocaleString("en-us", { hour: "numeric" }).toLowerCase().replace(/ /g,'')} - {new Date(eventObj.eventEndTime).toLocaleString("en-us", { hour: "numeric" }).toLowerCase().replace(/ /g,'')}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                                <div style={{ fontSize: 13, fontWeight: '300' }}>From</div>
                                <div style={{ fontSize: 18, fontWeight: '600', marginLeft: 15, color: '#E9A845', marginTop: -5 }}>${eventObj.priceMin} - ${eventObj.priceMax}</div>
                            </div>
                        </div>
                        {renderPublishedEventStatus(eventObj.performance)}
                        <div style={{ width: 50, height: 50, marginTop: 170, marginLeft: -15 }}>
                            <MoreHorizIcon
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                event_id={eventObj.id}
                            >
                            </MoreHorizIcon>                        
                            <Menu
                                id={`simple-menu-${eventObj.id}`}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl) && Number(anchorEl.getAttribute('event_id')) === eventObj.id}
                                onClose={handleClose}
                            >
                            <MenuItem component={Link} to={`/manage_event/${eventObj.id}/edit_date`} style={{outline: 'none'}}> Edit Date</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            )
        }
    
        
    
        const renderPublishedEventsList = () => {
            return (<>{ hasPublishedEvents.map((pubEvent, index) => <div key={index}>{renderPublishedEvents(pubEvent)}</div>)}</>)
        }

        const renderEndedEvents = (eventObj) => {
            return (
                <div className={classes.renderEventsContainer}>
                    <div className={classes.singleEventContainer}>
                        <img src={eventObj.eventImage} style={{ width: 320, height: 216, borderTopLeftRadius: 30, borderBottomLeftRadius: 30, marginLeft: -1, marginTop: -1 }} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 30, marginTop: 5 }}>
                            <Link style={{ fontSize: 20, fontWeight: '300', marginTop: 18 }} to={`/manage_event/${eventObj.id}`}>
                                {eventObj.eventName.substring(0,60)}
                            </Link>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                <img src={require('../../images/HasLocation@3x.png')} style={{ width: 9.75, height: 12, marginTop: 4 }} />
                                <div style={{ fontSize: 14, fontWeight: '200', marginLeft: 10 }}>{eventObj.eventAddress.substring(0,70)}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                                <img src={require('../../images/HasTime@3x.png')} style={{ width: 12, height: 10, marginTop: 4 }} />
                                <div style={{ fontSize: 14, fontWeight: '200', marginLeft: 10 }}>{new Date(eventObj.eventStartTime).toLocaleString("en-us", { month: "short" })} - {new Date(eventObj.eventEndTime).toLocaleString("en-us", { month: "short" })} | {new Date(eventObj.eventStartTime).toLocaleString("en-us", { hour: "numeric" }).toLowerCase().replace(/ /g,'')} - {new Date(eventObj.eventEndTime).toLocaleString("en-us", { hour: "numeric" }).toLowerCase().replace(/ /g,'')}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                                <div style={{ fontSize: 13, fontWeight: '300' }}>From</div>
                                <div style={{ fontSize: 18, fontWeight: '600', marginLeft: 15, color: '#E9A845', marginTop: -5 }}>${eventObj.priceMin} - ${eventObj.priceMax}</div>
                            </div>
                        </div>
                        {renderEndedEventStatus("Expired")}
                        <div style={{ width: 50, height: 50, marginTop: 170, marginLeft: -15 }}>
                            <MoreHorizIcon
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                            </MoreHorizIcon>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>Reactivate</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            )
        }
    
    
    
        const renderEndedEventsList = () => {
            return (<>{hasEndedEvents.map((endEvent, index) => <div key={index}>{renderEndedEvents(endEvent)}</div>)}</>)
        }
    
        const renderUnpublishedEventStatus = (eventObj) => {
            const unpublishedEventStatus = eventObj.eventStatus
            if (unpublishedEventStatus == "draft") {
                return (
                    <div style={{display: 'flex', width: 100, height: 30, borderRadius: 7, marginLeft: 'auto', marginTop: 25, backgroundColor: '#F7F7F7', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 12, fontWeight: '600', color: '#BEBEBE' }}>Draft</div>
                    </div>
                )
            } else if (unpublishedEventStatus == "under_review") {
                return (
                    <div style={{display: 'flex', width: 100, height: 30, borderRadius: 7, marginLeft: 'auto', marginTop: 25, backgroundColor: '#FFF7EA', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 12, fontWeight: '600', color: '#F5A626' }}>Under Review</div>
                    </div>
                )
            } else if (unpublishedEventStatus == "rejected") {
                return (
                    <div style={{display: 'flex', width: 100, height: 30, borderRadius: 7, marginLeft: 'auto', marginTop: 25, backgroundColor: '#FFF6F6', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 12, fontWeight: '600', color: '#CF2E2E' }}>Rejected</div>
                        <img src={require('../../images/QuestionMark@3x.png')} style={{ width: 14, height: 14, marginLeft: 5 }} />
                    </div>
                )
            } else if (unpublishedEventStatus == "approved") {
                return (
                    <Button 
                    onClick={()=>publishEvent(eventObj.id)}
                    style={{ display: 'flex', width: 194, height: 61, borderRadius: 35.5, marginLeft: 'auto', marginTop: 25, backgroundColor: '#5666F6', justifyContent: 'center', alignItems: 'center', textTransform: 'none', boxShadow: '0px 8px 15px rgba(86, 102, 246, 0.26)', }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontSize: 12, fontWeight: '300', color: '#FFFFFF' }}>Approved!</div>
                            <div style={{ fontSize: 14, fontWeight: '700', color: '#FFFFFF' }}>Publish now</div>
                        </div>
                    </Button>
                )
            }
        }
    
        const renderPublishedEventStatus = (eventObj) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: 124, height: 36, marginLeft: "auto", marginTop: 28, alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ fontSize: 12, fontWeight: '400' }}>Total Sales: </div>
                        <div style={{ fontSize: 12, fontWeight: '700', color: '#5666F6', marginLeft: 5 }}>${eventObj.sales}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10}}>
                        <div style={{ fontSize: 12, fontWeight: '400' }}>Tickets Sold: </div>
                        <div style={{ fontSize: 12, fontWeight: '700', color: '#5666F6', marginLeft: 5 }}>{eventObj.tickets}</div>
                    </div>
                </div>
            )
        }
    
        const renderEndedEventStatus = (endedEventStatus) => {
            if (endedEventStatus == "Expired") {
                return (
                    <div style={{display: 'flex', minWidth: 100, height: 30, borderRadius: 7, marginLeft: 'auto', marginTop: 25, border: 'solid 1px', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 12, fontWeight: '600' }}>Expired</div>
                    </div>
                )
            } else if (endedEventStatus == "Deactivated") {
                return (
                    <div style={{display: 'flex', width: 100, height: 30, borderRadius: 7, marginLeft: 'auto', marginTop: 25, border: 'solid 1px', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 12, fontWeight: '600' }}>Deactivated</div>
                    </div>
                )
            }
        }
    
    
        return (
            
                <div>
                    {renderSubHeader()}
                    {renderTitle()}
                    {renderTabs()}
                </div>
           
        )
    
}



export default Dashboard;