import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, OutlinedInput} from '@material-ui/core';
import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 90vh; 
  display: flex;
  flex-direction:row;
  margin-top: 20px;
  margin-bottom:10px
`;

export const MainContainer = styled.div`
  position: relative;
  height: 100%;
  padding-top: 70px;
  padding-left: 140px;
`;

export const LabelText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #212759;
  opacity: 0.82;
  margin-bottom: 14px;
  opacity: 0.82;
`;

export const NormalText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  opacity: 0.82;
`;

export const BolderNormalText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ? props.color : "#000000"};
`;

export const FormTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 15px;
`;

export const SliderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #000000;
  opacity: 0.82;
`;

export const FormUnit = styled.div`
  margin-bottom: 50px;
  width: 100%;
  max-width: 650px;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FlexFooter = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom ? props.bottom : '30px'};
  display: flex;
  flex-direction: column;
`;

export const LeftGrid = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const RightGrid = styled.div`
  position: fixed;
  height: 100%;
  width: 400px;
  right: 0;
  top:0;
  box-shadow: rgb(0 0 0 / 10%) -9px 0px 10px 3px;
`;

export const ErrorMessage = styled.div`
  color: #FF6565;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px
`;

export const TagLabel = ({ name='', content, ...props}) => {
  return (
    <div style={{width:'fit-content', backgroundColor:'#F4F5FF', borderRadius:'12px', height:'25px', display:'flex', flexDirection:'row', alignItems:'center',marginRight:'20px', padding:'5px 15px', marginBottom: '10px'}}>
      <BolderNormalText style={{color:'#5666F6'}}>{name}</BolderNormalText>
      {content}
    </div>
  )
}

export const StyledInput = withStyles((theme) => ({
  root: {
    width: (props) => props.width || '100%',
    height: '45px',
    borderRadius: '7px',
  },
  input: {
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.03em',
  }
}))((props) => <OutlinedInput {...props}/>);

export const StyledButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    height: (props) => props.height || '40px',
    color: (props) => props.color || '#5666F6',
    '&:not(:first-child)': {
      marginLeft: '16px',
    },
    '&:hover':{
      backgroundColor: '#5666F6',
      color: '#fff',
    },
    '&$focused':{
      backgroundColor: '#5666F6',
      color: '#fff',
    },
    borderRadius: '30px',
    backgroundColor: (props) => props.backgroundColor || null,
  },
  label: {
    fontSize: (props) => props.fontSize || null,
  },
  outlined: {
    border: (props) => props.border || '1px solid #5666F6',
    '&:hover':{
      border: '1px solid #5666F6',
    },
    '&$focused':{
      border: '1px solid #5666F6',
    },
    padding: (props) => props.padding || '5px 15px',
  }
}))((props) => <Button {...props} variant={props.variant ? props.variant : 'outlined'} />);

