import React, { useState, useContext, useEffect } from 'react';
import {
    Box, Button,
    makeStyles, Modal, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useRouteMatch, useHistory, Link, Switch, Route } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import { AuthContext } from '../UserAuth/AuthContext';
import Dashboard from './Dashboard';
import ManageCouponIndex from './ManageCoupon';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
        height: 50, //65
        zIndex: 10,
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    drawer: {
        width: 280, //240
        height:837,
        flexShrink: 0,
        zIndex: 0,
        position: 'absolute'
    },
    drawerPaper: {
        width: 240,
        backgroundColor: '#EBEBF1',
    },
    modalBody: {
        position: 'absolute',
        top: 'calc( 50vh - 157px)',
        left: 'calc(50vw - 170px)',
        display: ' flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '340px',
        height: '314px',
        borderRadius: 15,
        padding: '0px 40px 40px 40px',
        backgroundColor: '#FFFFFF',
        '& #modal-img': {
            padding: '0 20px 20px 20px'
        },
        '& #modal-title': {
            marginTop: '-50px',
            fontWeight: 'bold',
            color: '#5666F6'
        },
        '& #modal-btn-dismiss': {
            position: 'absolute',
            right: 0,
            top: 0,
            outline: 'none',
        },
        '& #modal-content': {
            marginBottom: '62px',
        }
    },
    modalActions: {
        '& Button': {
            margin: '23px',
            padding: '6px 36px',
            borderRadius: '24px',
            fontSize: '14px',
            border: '1px solid #5666F6',
        }
    }
}))


const LINKS = [
    {text: "My Event", segment: "dashboard"},
    {text: "Balance", segment: "transfer_balance"},
    {text: "Coupon Setting", segment: "coupon_setting"}
]


const OperatorCenter = (props) => {
    const auth = useContext(AuthContext);
    let { path, url } = useRouteMatch();
    const classes = useStyles();
    const [showModal, setShowModal] = useState(props.location.state ? props.location.state.eventCreated: false); 
    const history = useHistory();

    const onTabClick = (link) => {
        history.push(`${url}/${link}`);
    }

    const renderHeader = () => {
        return (
            <Box className={classes.header}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{marginLeft: 50}}>
                        <img src={require('../../images/NasuLogo@3x.png')} style={{ width: 48, height: 25, marginTop: -5}} />
                    </div>
                    <Button style={{ marginLeft: 20, fontSize:14, fontWeight: '500', textTransform: 'capitalize' }}> home </Button>
                    <Button style={{ marginLeft: 20, fontSize: 14, fontWeight: '500', textTransform: 'capitalize' }}> explore </Button>
                    <Button style={{ marginLeft: 950 }}>
                        <img src={require('../../images/MessageIcon@3x.png')} style={{ width: 22, height: 19 }} />
                    </Button>
                    <Button style={{ marginLeft: -5 }}>
                        <img src={auth.user.avatarUrl} style={{ width: 35, height: 35, borderRadius: '50%' }} />
                    </Button>
                </div>
            </Box>
        )
    }
    const renderDashSide = () => {
        return (
                    <List>
                        <ListItem button key='My Event' style={{ width: 248, height: 60, backgroundColor: '#FFFFFF', marginTop: 55, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                            <Link style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}> My Event </Link>
                            {/* <div >{link.text}</div> */}
                        </ListItem>
                        {/* <ListItem button key='Balance' style={{ width: 248, height: 60, marginTop: 2, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                            <Link to={`${url}/transfer_balance`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}>Balance </Link>
                        </ListItem> */}
                        <ListItem  onClick={() => onTabClick('coupon_setting')} button key='Coupon Setting' style={{ width: 248, height: 60, marginTop: 2, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                        <Link to={`${url}/coupon_setting`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}>Coupon Setting </Link>
                        {/* <div >{link.text}</div> */}
                        </ListItem>
                        </List>
        )
    }
    const renderBalanceSide = () => {
        return (
            <List>
                        <ListItem button key='My Event' style={{ width: 248, height: 60,  marginTop: 55, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                            <Link to={`${url}/dashboard`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}> My Event </Link>
                            {/* <div >{link.text}</div> */}
                        </ListItem>
                        {/* <ListItem button key='Balance' style={{ width: 248, height: 60, marginTop: 2, marginLeft: 32,backgroundColor: '#FFFFFF', borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                            <Link to={`${url}/transfer_balance`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}>Balance </Link>
                        </ListItem> */}
                        <ListItem button key='Coupon Setting' style={{ width: 248, height: 60, marginTop: 2, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                        <Link to={`${url}/coupon_setting`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}>Coupon Setting </Link>
                        {/* <div >{link.text}</div> */}
                        </ListItem>
                        </List>
        )
    }
    const renderCouponSide =()=>{
        return (
            <List>
                        <ListItem onClick={() => onTabClick('dashboard')} button key='My Event' style={{ width: 248, height: 60,  marginTop: 55, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                            <Link to={`${url}/dashboard`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}> My Event </Link>
                            {/* <div >{link.text}</div> */}
                        </ListItem>
                        {/* <ListItem button key='Balance' style={{ width: 248, height: 60, marginTop: 2, marginLeft: 32, borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                            <Link to={`${url}/transfer_balance`} style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}>Balance </Link>
                        </ListItem> */}
                        <ListItem button key='Coupon Setting' style={{ width: 248, height: 60, marginTop: 2, marginLeft: 32, backgroundColor: '#FFFFFF',borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}>
                        <Link  style={{ fontSize: 15, fontWeight: '800', marginLeft: 20, textDecoration: 'none' , color: 'black'}}>Coupon Setting </Link>
                        {/* <div >{link.text}</div> */}
                        </ListItem>
                        </List>
        )
    }
    
    const renderSidebar = () => {
        const renderSideTag = window.location.href.indexOf('/transfer_balance')>-1 ? renderBalanceSide() : window.location.href.indexOf('/coupon_setting')>-1 ? renderCouponSide() : renderDashSide();
        return (
            <div>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <Toolbar></Toolbar>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={auth.user.avatarUrl} style={{ width: 60, height: 60, borderRadius: '50%',  marginTop:50 }} />
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 15, marginTop: 17 }}>
                            <div style={{ fontSize: 18, fontWeight: '800' }}>{auth.user.first_name}</div>
                            <img src={require('../../images/OPBage@3x.png')} style={{ width: 25, height: 28, marginTop: 0 }} />
                        </div>
                    </div>
                    {renderSideTag}
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 100, alignItems: 'center' }}>
                        <Button style={{ display: 'flex', width: 60, height: 60, borderRadius: '50%', marginTop: 25, backgroundColor: '#5666F6', justifyContent: 'center', alignItems: 'center', textTransform: 'none', boxShadow: '0px 8px 15px rgba(86, 102, 246, 0.26)', outline: 'none' }} component={Link} to="/create_event">
                            <div style={{ fontSize: 22, fontWeight: '400', color: '#FFFFFF' }}>+</div>
                        </Button>
                        <div style={{ fontSize: 15, fontWeight: '350', color: '#6372F6', marginTop: 21}}>Create Event</div>
                    </div>
                </Drawer>
            </div>
        )
    }
    const renderContent = () => {
        return (
            <Switch>
                <Route exact path={path}>
                    <Dashboard />
                </Route>
                <Route exact path={`${path}/dashboard`}>
                    <Dashboard />
                </Route>
                <Route path={`${path}/transfer_balance`}>
                    {/* <Dashboard /> */}
                </Route>
                <Route path={`${path}/coupon_setting`}>
                    <ManageCouponIndex />
                </Route>


            </Switch>
        )
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    const renderDialog = () => {
        const IMG = true;
        const title = 'Submitted for review';
        const actionDismiss = (
            <IconButton
                color="inherit"
                id="modal-btn-dismiss"
                onClick={handleModalClose}>
                <CloseIcon style={{ width: 19, height: 19 }} />
            </IconButton>
        );
        return (
                <Modal
                    open={showModal}
                    onClose={handleModalClose}>
                    <div className={classes.modalBody}>
                        {actionDismiss}
                        {IMG && <img id="modal-img"
                            src={require("../../assets/submitCheck.png")}
                            alt="Congra"
                        />}
                        <h4 id="modal-title">{title}</h4>
                        {/* {(btnCancelText && btnConfirmText) && actions} */}
                    </div>
                </Modal>);
    }

    return (
        <div style={{position: 'fixed',
        height: '100%',
        width: '100%',
        backgroundColor: '#F9F9F9',
        overflowY: 'scroll',}}>
            {renderHeader()}
            {renderSidebar()}            
            <div style={{paddingLeft: '240px', marginLeft: '5%', marginTop: '10%',}}>
                {renderContent()}
            </div>
            {renderDialog()}
        </div>
    )

}
export default OperatorCenter;