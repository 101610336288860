import React, { Component } from 'react';

import "../../css/articles.css"

class Articles extends Component {
    render() {
      return (
          <div className="container">
            <div className="articles__box-title">
                <h2>NASU Selected Articles</h2>
            </div>
            <div className="row">
                {
                    this.props.articles.map(article => (
                        <div 
                            key={article.id}
                            className="col-lg-12"
                            style={{ marginBottom: "30px" }}
                        >
                            <div className="articles__box">
                                <div className="articles__box-image-container">
                                    <img className="articles__box-image" src={article.articleImageLink} alt={article.articleImageLink}/>
                                </div>
                                <div className="articles__box-text">
                                    <a href={article.articleLink} target="_blank">
                                        <h4>{article.articleName}</h4>
                                    </a>
                                    <p>
                                        { article.articleDescription.length < 200 ? `${article.articleDescription}` : `${article.articleDescription.substring(0, 197)}...` }
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    ))
                }
            </div>
          </div>
      )
    };
}

export default Articles;