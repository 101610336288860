import React, { Component } from 'react';
import { Row,Col } from 'react-bootstrap';
import _ from 'lodash';

import NewStripePayment from './NewStripePayment';

class ShoppingCart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedGrades: [],
      description: "",
      shoppingCartItems: [],
    };
    this.countChange = this.countChange.bind(this);
  }

  componentDidMount() {
    var amount = 0;
    var des = "";
    let shoppingCartItems = window.localStorage.getItem('shoppingCartItems');
    shoppingCartItems = shoppingCartItems ? JSON.parse(shoppingCartItems) : [];
    this.setState({
      shoppingCartItems: shoppingCartItems,
      description: des,
    });
  }

  countChange(item, event) {
    const count = event.target.value;
    let shoppingCartItems = window.localStorage.getItem('shoppingCartItems');
    shoppingCartItems = !!shoppingCartItems ? JSON.parse(shoppingCartItems) : [];
    // filter out currentItem, add count
    const itemIndex = shoppingCartItems.findIndex((i => i.eventId == item.eventId && i.gradeId == item.gradeId));
    let currentItem = shoppingCartItems[itemIndex];
    currentItem['count'] = parseInt(count);
    console.log(shoppingCartItems);
    this.setState({shoppingCartItems: shoppingCartItems});
    window.localStorage.setItem('shoppingCartItems', JSON.stringify(shoppingCartItems));
  }

  removeItem(item, event) {
    let shoppingCartItems = window.localStorage.getItem('shoppingCartItems');
    shoppingCartItems = !!shoppingCartItems ? JSON.parse(shoppingCartItems) : [];
    // remove the item
    shoppingCartItems = _.remove(shoppingCartItems, i => !(i.eventId == item.eventId && i.gradeId == item.gradeId));
    this.setState({shoppingCartItems: shoppingCartItems});
    window.localStorage.setItem('shoppingCartItems', JSON.stringify(shoppingCartItems));
  }

  render() {
    const { shoppingCartItems } = this.state;
    if (!shoppingCartItems) {
      return <p>loading</p>;
    }
    let totalAmount = 0;
    let description = ""
    shoppingCartItems.forEach((item) => {
      totalAmount += parseInt(item.gradePrice) * parseInt(item.count);
      description = description + item.count + " " + item.eventName + " " + item.gradeName + "; "
    });
    
    return (
      <div>
        <Row>
          <Col xs={8} md={8} mdOffset={2} xsOffset={2}>
            <table className="table table-hover table-condensed">
              <thead>
                <tr>
                  <th style={{width:"50%"}}>Product </th>
                  <th style={{width:"10%"}}> Price </th>
                  <th style={{width:"8%"}}>Quantity</th>
                  <th style={{width:"22%"}} className="text-center">Subtotal</th>
                  <th style={{width:"10%"}}></th>
                </tr>
              </thead>

              <tbody>
                {
                  shoppingCartItems.map(item => (
                      <tr>
                        <td data-th="Product">
                          <Row>
                            <Col xs ={3} md={3}>
                              <img src="https://s3.us-east-2.amazonaws.com/nasu-dev/nasu.png" style={{maxHeight: "100%", maxWidth: "100%"}}/>
                            </Col>
                            <Col xs={9} md={9}>
                              <h4>{item.eventName}</h4>
                              <p>{item.gradeName}</p>
                            </Col>
                          </Row>
                        </td>
                        <td data-th="Price">${item.gradePrice}</td>
                        <td data-th="Quantity">
                          <div>
                            <input type="number" min="1" max="100" defaultValue={ item.count} onChange={ (e) => this.countChange(item, e) } />
                            <button onClick={ (e) => this.removeItem(item, e) }>remove</button>
                          </div>
                        </td>
                        <td data-th="Subtotal" className="text-center">${item.gradePrice * item.count}</td>
                        {/* <td class="actions" data-th="">
                          <button class="btn btn-info btn-sm"><i class="fa fa-refresh"></i></button>
                          <button class="btn btn-danger btn-sm"><i class="fa fa-trash-o"></i></button>
                          </td> */}
                      </tr>
                  ))
                }

              </tbody>

              <tfoot>
                <tr className="visible-xs">
                  <td className="text-center"><strong>Total {totalAmount}</strong></td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="2" className="hidden-xs"></td>
                  <td class="hidden-xs text-center"><strong>Total ${totalAmount}</strong></td>
                </tr>
              </tfoot>

            </table>

          </Col>
        </Row>
          <Col xs={8} md={8} mdOffset={2} xsOffset={2}>
            <NewStripePayment amount={totalAmount} des={description}/>
          </Col>
        <Row>

        </Row>

      </div>
    )
  };
}

export default ShoppingCart;