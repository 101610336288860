import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
    makeStyles, Grid, TextField
} from '@material-ui/core';
import * as CONST from './RegistrationFormConst';
// import "../../css/Registration.css";
import { Dialog } from './RegistrationFormUtil';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginBottom: '30px',
    },
    dropdownList: {
        display: 'grid',
        marginLeft: '10%',
        '&>*': {
            marginBottom: '15px',
        },
        "&>*:last-child": {
            marginBottom: '0px'
        },
    },
    flex: {
        display: 'flex'
    },
    grid: {
        display: 'grid'
    },
    optionItemAdd: {
        marginLeft: '50px',
        marginTop: '10px',
        marginBottom: '10px',
        color: '#5666F6',
        cursor: 'pointer',
        '& span': {
            marginRight: '12px'
        }
    },
    formDisplayDelete: {
        height: '21px',
        width: '21px',
        borderRadius: '15px',
        textAlign: 'center',
        color: '#FFFFFF',
        backgroundColor: '#868686',
        transform: 'rotate(45deg)',
        fontSize: ' 23px',
        lineHeight: 0.73,
        cursor: 'pointer'
    },

}));

const { REGISTRATION_FORM_DROP_DOWN_OPTIONS, TEXT_LIMITS, IMG_PATH, CHECK_BOX_IMG, DIALOG_TYPES, DIALOG_INIT, DIALOG_DELETE_OPTION } = CONST;

function FormCheckBoxItem(props) {
    const classes = useStyles();

    const onInputChange = (e) => {
        const data = { index: props.index, text: e.target.value };
        props.onInputChange(data);
    };
    const onDeleteClick = (e) => {
        props.onDeleteClick(props.index);
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={1} >
                <img
                    src={`${IMG_PATH}${CHECK_BOX_IMG}`}
                    alt="edit"
                />
            </Grid>
            <Grid item xs={10} >
                <TextField style={{ width: '100%' }}
                    id={`FormDropdownItem_${props.index}`}
                    variant="outlined"
                    placeholder={`Option ${props.index + 1}`}
                    value={props.option.text}
                    onChange={onInputChange}
                />
            </Grid>
            <Grid item xs={1} >
                {props.deletable && <div onClick={onDeleteClick} className={classes.formDisplayDelete}>+</div>}
            </Grid>
        </Grid >
    );
}

function FormCheckBox(props) {
    const classes = useStyles();
    const IntialOptions = REGISTRATION_FORM_DROP_DOWN_OPTIONS.map(item => ({ ...item }));
    const ItialState = props.subForm ? props.subForm : IntialOptions;

    const [dropdowns, setDropdown] = React.useState(ItialState);
    const [dialog, setDialog] = React.useState(DIALOG_INIT);

    const onOpenDialog = (text, data) => {
        setDialog({
            open: true,
            dialog: {
                text: text,
                data: data
            }
        });
    };

    const onDialogDismissed = (e) => {
        setDialog(DIALOG_INIT);
    };

    const isFormValid = () => {
        const violations = dropdowns.filter(option => !option.text || option.text.length < TEXT_LIMITS.MIN);
        props.validate(violations.length === 0);
    };

    useEffect(() => {
        setDropdown(props.subForm ? props.subForm : IntialOptions);
        isFormValid();
    }, [props.subForm])

    const onClickCreateBtn = () => {
        const newItem = { text: "" };
        const newDropdowns = [...dropdowns, newItem];
        setDropdown(newDropdowns);
        props.onChange(newDropdowns);
    };

    const onDeleteClick = (e) => {
        onOpenDialog(DIALOG_DELETE_OPTION, e);
    };

    const DeleteItemById = (id) => {
        let newDropdowns = [...dropdowns];
        newDropdowns.splice(id, 1);
        setDropdown(newDropdowns);
        props.onChange(newDropdowns);
    };

    const onInputChange = (e) => {
        const newDropdowns = [...dropdowns];
        newDropdowns[e.index].text = e.text;
        setDropdown(newDropdowns);
        isFormValid();
        props.onChange(newDropdowns);
    };

    const deletable = () => {
        return dropdowns.length > 2;
    };

    const onDialogConfirmed = (e) => {
        setDialog(DIALOG_INIT);

        if (!e)
            return;

        switch (e.text.type) {
            case DIALOG_TYPES.DELETE_OPTION:
                return DeleteItemById(e.data);
        }
    };

    const FormDropdownList = (
        <div className={clsx(classes.dropdownList)}>
            {dropdowns.map((option, index) => (
                <FormCheckBoxItem
                    key={index}
                    index={index}
                    option={option}
                    onDeleteClick={onDeleteClick}
                    onInputChange={onInputChange}
                    deletable={deletable()}
                ></FormCheckBoxItem>
            ))}
            <div className={classes.optionItemAdd}
                onClick={onClickCreateBtn}>
                <span>+</span>Add another option</div>
        </div>
    );

    return (
        <div>
            { FormDropdownList}
            <Dialog
                open={dialog.open}
                dialog={dialog.dialog}
                onDialogDismissed={onDialogDismissed}
                onDialogConfirmed={onDialogConfirmed}
            ></Dialog>
        </div>
    );
}

export default FormCheckBox;