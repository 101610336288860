import React, {useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { 
  TextField, 
  Box, 
  FormControl, 
  FormHelperText,
  Drawer,
  CssBaseline,
  Divider,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  Popover,
  IconButton,
 } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import _uniqueId from 'lodash/uniqueId';

const drawerWidth = 400;

const TicketInfoInit = () =>  {return {
  id: _uniqueId(),
  ticketName: "",
  ticketDescription: "",
  eventStartTime: "13:00",
  duration: "2 hrs",
  sellingCutoffDate: "1 day before the event starts",
  price: 0,
  qty: 1,
  isPriceValid: true,
  isQtyValid: true,
  unlimited: false,
  isNameAlreadyExists: false,
}};

const BlueCheckbox = withStyles({
  root: {
      '&$checked': {
      color: '#5666F6',
      },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    minHeight: '800px',
    minWidth: '375px',
    overflowY: 'scroll',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 0,
    
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 0,
  },
  paperOpen: {
    width: drawerWidth,
    backgroundColor: '#F9F9F9',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
    borderColor: 'transparent',
  },
  paperClose: {
    overflowX: 'hidden',
    width: 0,
  },
  button: {
    display: 'flex', 
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  addButtonText: {
      fontSize: 12,
      fontWeight: '400',
      color: '#8F8F8F',
      marginLeft: 18, 
  },
  closeButton: {
    color: "#000000",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 60,
    height: 60,
    "&:hover": {
        backgroundColor: 'transparent'
    }
  },
  sidebarTitle: {
      fontSize: 12,
      fontWeight: '400',
      letterSpacing: 1,
  },
  errorText: {
      fontSize: 12,
      fontWeight: '400',
      marginLeft: 327,
  },
  errorTextLeft: {
    fontSize: 12,
    fontWeight: '400',
    marginLeft: 34,
  },
  errorTextRight: {
      fontSize: 12,
      fontWeight: '400',
  },
  allTextField: {
    display: 'flex',
    justifyContent:' center',
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '100%',
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  errorTextField: {
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '100%',
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF6565",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF6565",
      border: 'solid 1px',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF6565",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12
    },
  },
  inputText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '120%',
    alignSelf:'center',
    display:'flex',
    width: '100%',
  },
  popoverPaper: {
    width: 125,
    height: 32,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    backgroundColor: 'transparent'
  },
  popoverDialog: {
    width: 120,
    height: 32,
    borderRadius: 7, 
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    backgroundColor: '#C1C1C1',
    position: 'relative',
    left: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&::before': {
      content: `''`,
      position: 'absolute',
      left: '-10px',
      top: 8,
      width: 10,
      height: 10,
      borderTop: '5px solid transparent',
      borderRight: '5px solid #C1C1C1',
      borderBottom: '5px solid transparent',
    }
  },
  select: {
  "& .MuiSelect-select": {
    transition: "width 2s, height 4s",
  }
  },
  menuText: {
    fontSize: 12, 
    fontWeight: '400',
  },
  checkBox: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    "& .Mui-checked": {
      backgroundColor: 'pink',
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
    "& .MuiCheckbox-colorPrimary": {
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
  }
}));

const CETicketDrawer = (props) => {
  const classes = useStyles();
  const { handleDrawerClose, handleTicketCreate, allTickets, open, editedTicketInfoIndex, getAllTicketNames } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  
  const [ticketInfo, setTicketInfo] = useState(TicketInfoInit());
  const initTicketInfo = () => {
    setTicketInfo( editedTicketInfoIndex !== null ? 
      {...allTickets[editedTicketInfoIndex]}:
      TicketInfoInit());
  };

  useEffect(() => {
    initTicketInfo();
  }, [editedTicketInfoIndex]);

  const onDrawerClose = () => {
      handleDrawerClose();
  };

  const handlePopoverClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
      setAnchorEl(null);
  };

  const handlePrice = (event) => {
    const priceRegex = /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/g;

    if ( priceRegex.test(event.target.value) ) {
      setTicketInfo({ ...ticketInfo, isPriceValid: true, price: Number(event.target.value)});
    } else {
      setTicketInfo({ ...ticketInfo, isPriceValid: false, price: event.target.value});
    }
  }

  const handleQty = (event) => {
    const qtyRegex = /^[1-9]([0-9]*)?$/g;
    
    if (ticketInfo.unlimited) {
      setTicketInfo({ ...ticketInfo, isQtyValid: true, qty: ""});
    } else if ( qtyRegex.test(event.target.value) ) {
      setTicketInfo({ ...ticketInfo, isQtyValid: true, qty:  Number(event.target.value)});
    } else {
      setTicketInfo({ ...ticketInfo, isQtyValid: false, qty: event.target.value});
    }
  };

  const handleTicketName = (currTicketName) => {
    let areadyExists = false;
    if(getAllTicketNames().some(n => n === currTicketName))   
      areadyExists = true;
    areadyExists ? 
    setTicketInfo({ ...ticketInfo, isNameAlreadyExists: true, ticketName: currTicketName })
    :
    setTicketInfo({ ...ticketInfo, isNameAlreadyExists: false, ticketName: currTicketName });
  };

  const checkValid = () => {
    if (ticketInfo.ticketName.length > 0 && ticketInfo.ticketName.length < 76 && !ticketInfo.isNameAlreadyExists) {
      if (ticketInfo.ticketDescription.length >= 0 && ticketInfo.ticketDescription.length < 301) {
        if ( ticketInfo.price >= 0 && ticketInfo.isPriceValid ) {
          if ((ticketInfo.qty > 0 && ticketInfo.isQtyValid) || ticketInfo.unlimited) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        } 
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onCreate = () => {
    handleTicketCreate(ticketInfo)
    initTicketInfo();
  };

    return (
        
        <Drawer
          variant="permanent"
          anchor="right"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.paperOpen]: open,
              [classes.paperClose]: !open,
            }),
          }}
          style={{ overflow: 'auto', height: '100%' }}
        >
          <IconButton 
            onClick={onDrawerClose}
            className={classes.closeButton}>
              <CloseIcon style={{ width: 20, height: 20 }} />
          </IconButton>
          <p className={classes.sidebarTitle} style={{ marginTop: 90, marginLeft: 40}}>Ticket Name</p>
          <TextField  
                  className={ ticketInfo.ticketName.length > 75 || ticketInfo.isNameAlreadyExists ? classes.errorTextField : classes.allTextField}
                  style={{ width: 332, height: 45, alignSelf:'center' }}
                  size="small"
                  error={ ticketInfo.ticketName.length > 75 || ticketInfo.isNameAlreadyExists }
                  id="ticket-name" 
                  variant="outlined" 
                  multiline
                  // rows={2}
                  maxRows={2}
                  InputProps={{
                      className: classes.inputText,
                      endAdornment: <InputAdornment style={{position: 'relative', top: 10, right: 0}}>
                                      {ticketInfo.ticketName.length > 75 
                                      ?<span style={{color: '#FF6565', fontSize: 12 }}>{ticketInfo.ticketName.length} / 75</span>
                                      :<span style={{fontSize: 12, color:'#6B6B71'}}>{ticketInfo.ticketName.length} / 75</span>
                                      }
                                      </InputAdornment>,
                  }}
                  value={ticketInfo.ticketName}
                  onChange={(event) => handleTicketName(event.target.value)}
              />
              <span style={{ color: '#FF6565', fontSize: 10, fontWeight: '400', position: 'absolute', top: 225, right:34}}>
                    { ticketInfo.isNameAlreadyExists ?
                    "*This ticket name is being used"
                    :
                    ""
                    }
              </span>

          <p className={classes.sidebarTitle} style={{ marginTop: 30, marginLeft: 40}}>Ticket Description</p>
          <TextField  
                  className={ ticketInfo.ticketDescription.length > 300 ? classes.errorTextField : classes.allTextField}
                  style={{width: 332, height: 120, alignSelf: 'center'}}
                  size="small"
                  error={ ticketInfo.ticketDescription.length > 300}
                  id="ticket-description" 
                  variant="outlined" 
                  multiline 
                  rows={8}
                  maxRows={8}
                  InputProps={{
                      className: classes.inputText,
                      endAdornment: <InputAdornment style={{position: 'relative', top: 50, right: 0}}>
                                      {ticketInfo.ticketDescription.length > 300 
                                      ?<span style={{color: '#FF6565', fontSize: 12 }}>{ticketInfo.ticketDescription.length} / 300</span>
                                      :<span style={{fontSize: 12, color:'#6B6B71'}}>{ticketInfo.ticketDescription.length} / 300</span>
                                      }
                                      </InputAdornment>,
                  }}
                  value={ticketInfo.ticketDescription}
                  onChange={(event) => setTicketInfo({ ...ticketInfo, ticketDescription: event.target.value })}
              />

          <Divider style={{ width: 332, alignSelf: 'center', marginTop: 30 }}/>

          <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div>
                  <p className={classes.sidebarTitle} style={{ marginTop: 30, marginLeft: 40 }}>Event Start Time</p>
                  <TextField
                  id="time"
                  type="time"
                  value={ticketInfo.eventStartTime}
                  onChange={(event) => {setTicketInfo({ ...ticketInfo, eventStartTime: event.target.value })}}
                  size="small"
                  className={classes.allTextField}
                  style={{ width: 139, height: 45, marginLeft: 34, display: 'flex', justifyContent: 'center' }}
                  inputProps={{
                  step: 300, // 5 min
                  }}
                  variant="outlined"
                  />
              </div>

              <div>
                  <p className={classes.sidebarTitle} style={{ marginTop: 30, marginLeft: 6 }}>Estimate Duration</p>
                  <FormControl variant="outlined" className={classes.allTextField} style={{ width: 139, height: 45, marginRight: 34}}>
                  <Select
                  id="estimate-duration"
                  value={ticketInfo.duration}
                  onChange={(event) => {setTicketInfo({ ...ticketInfo, duration: event.target.value })}}
                  className={classes.select}
                  >
                  <MenuItem value={"30 mins"} className={classes.menuText}>30 mins</MenuItem>
                  <MenuItem value={"1 hr"} className={classes.menuText}>1 hr</MenuItem>
                  <MenuItem value={"1.5 hrs"} className={classes.menuText}>1.5 hrs</MenuItem>
                  <MenuItem value={"2 hrs"} className={classes.menuText}>2 hrs</MenuItem>
                  <MenuItem value={"2.5 hrs"} className={classes.menuText}>2.5 hrs</MenuItem>
                  <MenuItem value={"3 hrs"} className={classes.menuText}>3 hrs</MenuItem>
                  <MenuItem value={"3.5 hrs"} className={classes.menuText}>3.5 hrs</MenuItem>
                  <MenuItem value={"4 hrs"} className={classes.menuText}>4 hrs</MenuItem>
                  <MenuItem value={"5 hrs"} className={classes.menuText}>5 hrs</MenuItem>
                  <MenuItem value={"6 hrs"} className={classes.menuText}>6 hrs</MenuItem>
                  </Select>
              </FormControl>
              </div>

          </div>

          <p className={classes.sidebarTitle} style={{ marginTop: 30, marginLeft: 40 }}>Selling Cutoff Date</p>
          <FormControl variant="outlined" className={classes.allTextField} style={{ width: 332, height: 45, alignSelf: 'center' }}>
                  <Select
                  id="selling-cutoff"
                  defaultValue="1 day before the event starts"
                  value={ticketInfo.sellingCutoffDate}
                  onChange={(event) => {setTicketInfo({ ...ticketInfo, sellingCutoffDate: event.target.value })}}
                  >
                  <MenuItem value={"1 hour before the event starts"} className={classes.menuText}>1 hour before the event starts</MenuItem>
                  <MenuItem value={"2 hours before the event starts"} className={classes.menuText}>2 hours before the event starts</MenuItem>
                  <MenuItem value={"6 hours before the event starts"} className={classes.menuText}>6 hours before the event starts</MenuItem>
                  <MenuItem value={"1 day before the event starts"} className={classes.menuText}>1 day before the event starts</MenuItem>
                  <MenuItem value={"2 days before the event starts"} className={classes.menuText}>2 days before the event starts</MenuItem>
                  <MenuItem value={"3 days before the event starts"} className={classes.menuText}>3 days before the event starts</MenuItem>
                  <MenuItem value={"7 days before the event starts"} className={classes.menuText}>7 days before the event starts</MenuItem>
                  <MenuItem value={"14 days before the event starts"} className={classes.menuText}>14 days before the event starts</MenuItem>
                  <MenuItem value={"30 days before the event starts"} className={classes.menuText}>30 days before the event starts</MenuItem>
                  </Select>
              </FormControl>

          <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'absolute', top: 620, width: '100%' }}>
              <div>
                  <p className={classes.sidebarTitle} style={{ marginTop: 25, marginLeft: 40 }}>Price</p>

                  <TextField  
                  className={ ticketInfo.isPriceValid ? classes.allTextField : classes.errorTextField}
                  style={{ width: 139, height: 45, marginLeft: 34} }
                  // size="small"
                  error={ !ticketInfo.isPriceValid }
                  id="price" 
                  variant="outlined" 
                  multiline
                  rows={1}
                  maxRows={1}
                  placeholder="0.00"
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{ alignItems: 'center' }} >
                                      <span style={{ fontSize: 12, fontWeight: '300', color: '#757575' }}>$</span>
                                    </InputAdornment>,
                  }}
                  value={ticketInfo.price}
                  onChange={handlePrice}
                  />
                  <div style={{ width: 139, height: 40, marginLeft: 34, position: 'absolute', top:100 }}>
                    <span style={{ color: '#FF6565', fontSize: 10, fontWeight: '400', position: 'absolute', top: 3, right:0 }}>
                    {ticketInfo.isPriceValid ?
                    ""
                    :
                    "*Price unrecognized"
                    }
                    </span>

                    <span style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 8, right: 0 }}>
                      <span style={{ color: '#6B6B71', fontSize: 10, fontWeight: '400' }}>Your earnings: ${ ticketInfo.isPriceValid ? ( ticketInfo.price - ((ticketInfo.price * 0.94) * 0.03) ).toFixed(2):'0.00'}</span>
                        <IconButton
                          color="#6B6B71"
                          aria-label="open-helper"
                          onClick={handlePopoverClick}
                          edge="start"
                          disableRipple={true}
                          disableFocusRipple={true}
                          disableTouchRipple={true}
                          className={classes.button}
                          >
                              <HelpIcon style={{ marginLeft: 5 }}></HelpIcon>
                        </IconButton>
                    </span>
                          <Popover
                          id={openPopover ? 'simple-popover' : undefined}
                          classes={{
                            paper: classes.popoverPaper,
                          }}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                          }}
                          transformOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                          }}
                          >
                            <div className={classes.popoverDialog}>
                              <div style={{ fontSize: 8, fontWeight: '400', color: '#FFFFFF', marginLeft: 16, marginRight: 16 }}>Service fee: 6%</div>
                              <div style={{ fontSize: 8, fontWeight: '400', color: '#FFFFFF', marginLeft: 16, marginRight: 16 }}>Processing fee: 3%</div>
                            </div>
                          </Popover>
                  </div>
              </div>

              <div>
                  <p className={classes.sidebarTitle} style={{ marginTop: 25, marginLeft: 6 }}>Qty</p>
                  <TextField  
                  className={ ticketInfo.isQtyValid ? classes.allTextField : classes.errorTextField}
                  style={{ width: 139, height: 45, marginRight: 34} }
                  // size="small"
                  error={ !ticketInfo.isQtyValid }
                  id="qty" 
                  variant="outlined" 
                  multiline
                  rows={1}
                  rowsMax={1}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" style={{ alignItems: 'center' }} >
                                      <span style={{ fontSize: 12, fontWeight: '300', color: '#757575' }}>tickets</span>
                                    </InputAdornment>,
                  }}
                  value={ticketInfo.qty}
                  onChange={handleQty}
                  />
                  <div style={{ width: 139, height: 40, position: 'absolute', top:100 }}>
                    <span style={{ color: '#FF6565', fontSize: 10, fontWeight: '400', position: 'absolute', top: 3, right:0}}>
                    { ticketInfo.isQtyValid ?
                    ""
                    :
                    "*Qty unrecognized"
                    }
                    </span>
                    <span style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 8, left: 0 }}>
                          <BlueCheckbox
                            checked={ticketInfo.unlimited}
                            onChange={(event) => setTicketInfo({ ...ticketInfo, unlimited: event.target.checked, isQtyValid: true, qty: ""})}
                            className={classes.checkBox}
                            disableRipple={true}
                            disableFocusRipple={true}
                            disableTouchRipple={true}
                          />

                          <FormHelperText style={{ color: '#000000', fontSize: 12, fontWeight: '400' }}>Unlimited tickets</FormHelperText>
                    </span>
                  </div>
              </div>
          </div>
          { checkValid() ?
              <div style={{ borderColor: '#000000', width: 95, height: 40, borderRadius: 30, border: 'solid 1px', alignSelf: 'center', position: 'absolute', top: 800 }}>          
                <IconButton 
                style={{width: 95, height: 40, borderRadius: 30, alignSelf: 'center'}}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                onClick={onCreate}
                className={classes.button}
                >
                  <div style={{ fontSize: 12, fontWeight: '400', color: '#000000' }}>{editedTicketInfoIndex !== null ? 'Update' : 'Create'}</div>
                </IconButton>
              </div>
              : 
              <div style={{ borderColor: '#000000', width: 95, height: 40, borderRadius: 30, opacity: 0.3, border: 'solid 1px', alignSelf: 'center', position: 'absolute', top: 800 }}>          
                <IconButton 
                  style={{width: 95, height: 40, borderRadius: 30, alignSelf: 'center'}}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  disabled={true}
                  className={classes.button}
                  >
                    <div style={{ fontSize: 12, fontWeight: '400', color: '#000000' }}>{editedTicketInfoIndex !== null ? 'Update' : 'Create'}</div>
                </IconButton>
              </div>
          }
        </Drawer>
    );
};

export default CETicketDrawer;