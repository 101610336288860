import React, { useEffect } from 'react';
import {
    Box, Button, makeStyles, Paper, Divider
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditRounded from '@material-ui/icons/Edit';
// import "../../css/Registration.css";
import * as CONST from './RegistrationFormConst';
import RegistrationFormSettingInit from './RegistrationFormSettingInit';
import RegistrationFormQuestion from './RegistrationFormQuestion';
import { Dialog, Reorder } from './RegistrationFormUtil';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
    header: {
        position: 'relative',
        padding: '29px 32px 19px 44px ',
    },
    body: {
        position: 'relative',
        padding: '35px 44px',
    },
    formSettingActions: {
        width: '100%',
        boxShadow: '-8px -4px 14px #EEEEEE',
        '&> *': {
            minHeight: '65px',
            fontSize: '16px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        '&>:first-child': {
            borderBottomLeftRadius: 15,
        },
        '&>:last-child': {
            borderBottomRightRadius: 15,
        },
        '& .btnCreate': {
            width: '100%',
            backgroundColor: '#5666F6',

        },
        '& .btnGenerate': {
            width: '50%',
        },
        '& .btnGenerate:not(.Mui-disabled)': {
            backgroundColor: '#5666F6',

        },
        '& .btnCancel': {
            width: '50%',
            backgroundColor: '#FFFFFF',
        }
    },
    formGenerated: {
        padding: '15%',
        textAlign: 'center',
        '&>.formGeneratedTitle': {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '15px',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#5666F6',
        }
    },
    questionItemAdd: {
        marginLeft: '20px',
        marginTop: '29px',
        marginBottom: '10px',
        color: '#5666F6',
        cursor: 'pointer',
        '& span': {
            marginRight: '12px'
        }
    }
}));

const { MODES, QUESTION_TYPES, DEFAULT_QUESTION_TYPES, DIALOG_TYPES, DIALOG_INIT, DIALOG_DELETE_QUESTION, DIALOG_DELETE_FORM, DIALOG_ABORT_EDIT } = CONST;

function RegistrationFormSetting(props) {
    const classes = useStyles(props);
    // Questions State
    const [questions, setQuestions] = React.useState(props.questions);
    // Mode State
    const [mode, setMode] = React.useState(props.settingMode);
    // Edit Status State
    const [editState, setEditState] = React.useState({
        editItemIndex: null
    });

    // Used Default Types State
    const getUsedDefaultTypes = (questions = questions) => {
        if (!questions || questions.length === 0)
            return [];

        const defaultTypeQuestions = questions.filter(q =>
            DEFAULT_QUESTION_TYPES.includes(QUESTION_TYPES[q.type])
        );
        const UsedDefaultTypes = defaultTypeQuestions.map(q => q.type);
        return UsedDefaultTypes;
    }

    const [usedDefaultTypes, setUsedDefaultTypes] = React.useState(getUsedDefaultTypes());

    useEffect(() => {
        console.log('effect', props)
        setQuestions(props.questions);
        setMode(props.settingMode);
        setUsedDefaultTypes(getUsedDefaultTypes(props.questions));
    }, [props.questions, props.settingMode])


    const [dialog, setDialog] = React.useState(DIALOG_INIT);

    const onOpenDialog = (text, data) => {
        setDialog({
            open: true,
            dialog: {
                text: text,
                data: data
            }
        });
    };

    const onDialogDismissed = (e) => {
        setDialog(DIALOG_INIT);
    };

    const onQuestionsUpdated = (e) => {
        props.onQuestionsUpdated(e)
    };

    const onModeUpdated = (e) => {
        props.onModeUpdated(e)
    };

    const onSettingClickEdit = (e) => {
        const mode = MODES.SETTING_EDIT;
        // setMode(mode);
        onModeUpdated(mode);
    };

    const onSettingClickCheck = (e) => {
        const mode = MODES.DISPLAY;
        // setMode(mode);
        onModeUpdated(mode);
    };

    const onSettingClickCancel = (e) => {
        const mode = MODES.DISPLAY;
        // setMode(mode);
        onModeUpdated(mode);
    };

    const onClickCreateBtn = (e) => {
        const mode = questions.length === 0 ? MODES.CREATE : MODES.DISPLAY;
        // setMode(mode);
        onModeUpdated(mode);
    };

    const onClickGenerateBtn = (e) => {
        const mode = MODES.GENERATED;
        // setMode(mode);
        onModeUpdated(mode);
    };

    const onClickCancelBtn = (e) => {
        onOpenDialog(DIALOG_ABORT_EDIT, e);
    }

    const onEditingAborted = (e) => {
        props.onEditingAborted(e);
    }

    const onClickEditBtn = (e) => {
        setMode(MODES.DISPLAY);
        onModeUpdated(MODES.DISPLAY);
    };

    const onClickAdd = (e) => {
        setMode(MODES.CREATE);
        onModeUpdated(MODES.CREATE);
    };

    const onClickDeleteBtn = (e) => {
        onOpenDialog(DIALOG_DELETE_FORM, e);
    }

    const deletable = () => {
        const minLength = questions && questions.length > 0;
        return mode === MODES.DISPLAY && minLength;
    };

    const isQuestionDefault = (question) => {
        return DEFAULT_QUESTION_TYPES.includes(QUESTION_TYPES[question.type])
    };

    const hasDefaultQuestions = (question) => {
        return DEFAULT_QUESTION_TYPES.includes(QUESTION_TYPES[question.type])
    };

    const getPromt = (question) => {
        switch (QUESTION_TYPES[question.type]) {
            case QUESTION_TYPES.NAME: return 'Your Name';
            case QUESTION_TYPES.ADDRESS: return 'Your Address';
            default: return question.prompt;
        }
    };

    const liftDefaultToTop = (question, mode) => {
        const isQuestionEmpty = !questions || questions.length === 0;
        if (isQuestionEmpty)
            return [...questions, question];

        let newQuestions = [...questions];
        let index = 0;
        //edit
        if (mode === MODES.EDIT)
            newQuestions = newQuestions.filter(q => q.id !== question.id)

        // create & edit
        if (newQuestions.length > 0 && QUESTION_TYPES[newQuestions[0].type] === QUESTION_TYPES.NAME) {
            index = 1;
        }

        newQuestions.splice(index, 0, question);
        return newQuestions;
    };

    const onQuestionClickDelete = (e) => {
        if (questions && questions.length === 1) {
            onOpenDialog(DIALOG_DELETE_FORM, e);
        } else {
            onOpenDialog(DIALOG_DELETE_QUESTION, e);
        }
    };

    const onQuestionDelete = (e) => {
        // TODO: add warning prompt
        if (!deletable())
            return;

        let newQuestions = [...questions];
        const editItemIndex = questions.findIndex(q => q.id === e.question.id);
        newQuestions.splice(editItemIndex, 1);
        // setQuestions(newQuestions);
        // setMode(MODES.DISPLAY);
        setUsedDefaultTypes(getUsedDefaultTypes(newQuestions));
        onQuestionsUpdated(newQuestions);
        onModeUpdated(MODES.DISPLAY)
    };

    const onDeleteForm = (e) => {
        props.onDeleteForm(e);
    }

    const onQuestionClickCheck = (e) => {
        if (e.mode === MODES.CREATE) {
            let newQuestions;
            if (isQuestionDefault(e.question)) {
                e.question.prompt = getPromt(e.question);
                newQuestions = liftDefaultToTop(e.question, e.mode);
            } else {
                newQuestions = [...questions, e.question];
            }
            // setQuestions(newQuestions);
            // setMode(MODES.DISPLAY);
            setUsedDefaultTypes(getUsedDefaultTypes(newQuestions));
            onQuestionsUpdated(newQuestions);
            onModeUpdated(MODES.DISPLAY);
        }

        if (e.mode === MODES.EDIT) {
            let newQuestions;
            if (isQuestionDefault(e.question)) {
                e.question.prompt = getPromt(e.question);
                newQuestions = liftDefaultToTop(e.question, e.mode);
            } else {
                newQuestions = [...questions];
                const editItemIndex = questions.findIndex(q => q.id === e.question.id);
                newQuestions[editItemIndex] = e.question;
            }
            // setQuestions(newQuestions);
            // setMode(MODES.DISPLAY);
            setUsedDefaultTypes(getUsedDefaultTypes(newQuestions));
            setEditState({ editItemIndex: null });
            onQuestionsUpdated(newQuestions);
            onModeUpdated(MODES.DISPLAY);
        }
    };

    const onQuestionClickCancel = (e) => {
        if (questions.length === 0) {
            return onOpenDialog(DIALOG_ABORT_EDIT, e);
        }

        setMode(MODES.DISPLAY);
        setEditState({ editItemIndex: null });
        onModeUpdated(MODES.DISPLAY);
    };

    const onQuestionClickEdit = (e) => {
        const editItemIndex = questions.findIndex(q => q.id === e.question.id);
        setEditState({ editItemIndex });
        setMode(MODES.EDIT);
        onModeUpdated(MODES.EDIT);
    };

    const onDialogConfirmed = (e) => {
        setDialog(DIALOG_INIT);

        if (!e)
            return;

        switch (e.text.type) {
            case DIALOG_TYPES.DELETE_QUESTION:
                return onQuestionDelete(e.data);
            case DIALOG_TYPES.ABORT_EDIT:
                return onEditingAborted(e.data);
            case DIALOG_TYPES.DELETE_FORM:
                return onDeleteForm(e.data);
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        let desInd = result.destination.index;
        if (result.destination.index < usedDefaultTypes.length) {
            console.log('asdasd')
            desInd = usedDefaultTypes.length;
        }

        const newQuestions = Reorder(
            [...questions],
            result.source.index,
            desInd
        );

        // console.log(result, newQuestions)
        onQuestionsUpdated(newQuestions);
    };

    const Header = (
        <div>
            <h5>Registration form (Optional)</h5>
            <p>
                You can set up a registration form for gathering attendees information.<br /><br />
                Unless necessary, we don’t recommend you require a registration form of your attendees.
            </p>
        </div>
    );

    const btnStartCreate = (
        <div>
            {(mode === MODES.INIT) &&
                <div className={classes.formSettingActions}>

                    <Button className='btnCreate'
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onClickCreateBtn}>
                        <span style={{ textTransform: 'none' }}>
                            {(questions && questions.length > 0) ? 'Save' : 'Start to create'}
                        </span>
                    </Button>
                </div>}
            {(mode === MODES.DISPLAY) &&
                <div className={classes.formSettingActions}>
                    <Button className='btnCancel'
                        variant="contained"
                        disableElevation
                        onClick={onClickCancelBtn}>
                        <span style={{ textTransform: 'none' }}>Cancel</span>
                    </Button>
                    <Button className='btnGenerate'
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={!props.formChanged}
                        onClick={onClickGenerateBtn}>
                        <span style={{ textTransform: 'none' }}>Save</span>
                    </Button>
                </div>
            }
            {(mode === MODES.GENERATED) &&
                <div className={classes.formSettingActions}>
                    <Button className='btnCancel'
                        variant="contained"
                        disableElevation
                        onClick={onClickDeleteBtn}>
                        <span style={{ textTransform: 'none' }}>Delete form</span>
                    </Button>
                    <Button className='btnGenerate'
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onClickEditBtn}>
                        <span style={{ textTransform: 'none' }}>Edit form <EditRounded></EditRounded></span>
                    </Button>
                </div>
            }
        </div >
    );

    const questionList = (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                {/* Question List */}
                <Droppable droppableId={'RF_Questions'}>
                    {droppableProvided => (
                        <div id="Registration-Form-Question-List"
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}>
                            {questions.map((questionItem, index) => {
                                const isDragDisabled = mode !== MODES.DISPLAY || DEFAULT_QUESTION_TYPES.includes(QUESTION_TYPES[questionItem.type]);
                                return <Draggable
                                    draggableId={questionItem.id + ""}
                                    index={index}
                                    key={questionItem.id}
                                    isDragDisabled={isDragDisabled}>
                                    {(draggableProvided, snapshot) => (
                                        <div {...draggableProvided.draggableProps}
                                            // {...draggableProvided.dragHandleProps}
                                            ref={draggableProvided.innerRef}>
                                            <RegistrationFormQuestion
                                                onDrag={draggableProvided.dragHandleProps}
                                                isDragDisabled={isDragDisabled}
                                                isDragging={snapshot.isDragging}
                                                key={questionItem.id}
                                                question={questionItem}
                                                mode={editState.editItemIndex === index ? MODES.EDIT : MODES.DISPLAY}
                                                listMode={mode}
                                                deletable={deletable()}
                                                onEditClick={onQuestionClickEdit}
                                                onClickCheck={onQuestionClickCheck}
                                                onClickCancel={onQuestionClickCancel}
                                                onDeleteClick={onQuestionClickDelete}
                                                usedDefaultTypes={usedDefaultTypes}></RegistrationFormQuestion>
                                        </div>
                                    )}
                                </Draggable>;
                            }
                            )}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {/* Question Add */}
            {(mode === MODES.DISPLAY) &&
                <div className={classes.questionItemAdd}
                    onClick={onClickAdd}>
                    <span>+</span>Add registration question</div>}
            {/* Question Init */}
            {(mode === MODES.CREATE) &&
                <RegistrationFormQuestion
                    question={{
                        id: Date.now().toString(),
                        prompt: "",
                        type: "",
                        data: {}
                    }}
                    mode={MODES.CREATE}
                    onClickCheck={onQuestionClickCheck}
                    onClickCancel={onQuestionClickCancel}
                    usedDefaultTypes={usedDefaultTypes}
                ></RegistrationFormQuestion>}
        </div>
    );

    const formSettingEdit = (
        < div >
            <RegistrationFormSettingInit
                mode={mode}
                setting={props.setting}
                eventGrades={props.eventGrades}
                onEditClick={onSettingClickEdit}
                onSettingUpdated={props.onSettingUpdated}
                onSettingClickCheck={onSettingClickCheck}
                onSettingClickCancel={onSettingClickCancel}
            ></RegistrationFormSettingInit>
            {questionList}
        </div >
    );

    const formSettingGenerated = (
        <div className={classes.formGenerated}>
            <div className='formGeneratedTitle'>
                <CheckCircleIcon fontSize="large"></CheckCircleIcon>
                <span style={{ paddingLeft: '15px' }}>Form Generated</span>
            </div>
            <p>For checking the registration info of each user, you can click guestlist in the <strong>Manage event &gt; Manage </strong> \guest to view their information.</p>
        </div>
    );

    const formSetting = (
        <div>
            {mode === MODES.GENERATED ?
                (formSettingGenerated) :
                (formSettingEdit)}
        </div>
    );

    return (
        <Box className="RegistrationFormSetting">
            <Paper elevation={12}
                style={{
                    boxShadow: '0px 24px 50px rgba(184, 185, 190, 0.35)',
                    borderRadius: '15px'
                }}>
                <section className={classes.header}>
                    {Header}
                </section>
                <Divider />
                <section className={classes.body}>
                    {formSetting}
                </section>
                <section>
                    {btnStartCreate}
                </section>
            </Paper>
            <Dialog
                open={dialog.open}
                dialog={dialog.dialog}
                onDialogDismissed={onDialogDismissed}
                onDialogConfirmed={onDialogConfirmed}
            ></Dialog>
        </Box >);
}

export default RegistrationFormSetting;