import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const StyledDialog = withStyles((theme) => ({
  paper: {
    maxWidth:'800px',
    width:(props) => props.width ? props.width : '500px',
    minHeight:'495px',
    backgroundColor:'#FFFFFF',
    borderRadius:'15px',
  },
}))(Dialog)

const UseDialog = ({visible, onClose, content, ...props}) => {
  return (
  <StyledDialog open={visible} onClose={onClose} {...props}>
    {onClose && 
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
        <IconButton aria-lable='close' style={{color:'#000', marginTop:'5px', marginRight:'15px'}} onClick={onClose}>
          <CloseIcon style={{width:'20px', height:'20px'}}/>
        </IconButton>
      </div>
    }
    {content}
  </StyledDialog>
  )
}

export default UseDialog;