import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
    makeStyles, MenuItem, FormControl, Button,
    Select, TextField, InputAdornment,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

// import "../../css/Registration.css"
import FormDropdown from './RegistrationFormQuestionDropdown';
import FormCheckBox from './RegistrationFormQuestionCheckBox';
import * as CONST from './RegistrationFormConst';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginBottom: '30px',
        "&:last-child": {
            marginBottom: '0px'
        },
    },
    flex: {
        display: 'flex'
    },
    grid: {
        display: 'grid'
    },
    questionItem: {
        position: 'relative',
        marginTop: '24px',
    },
    qeustionItemActions: {
        display: 'flex',
        marginTop: '18px',
        justifyContent: 'flex-end',
    },
    qeustionItemActionBtn: {
        width: '64px',
        height: '36px',
        borderRadius: '30px',
        marginLeft: '12px',
    },
    qeustionItemActionBtnPrimay: {
        backgroundColor: '#5666F6',
    },
    questionTypeMenuItemDivider: {
        borderBottom: '0.5px solid #888888'
    },
    questionDropDown: {

    },
    formGroupLabel: {
        display: 'flex',
        marginLeft: '2px',
        marginBottom: '12px',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    formGroupOutline: {
        padding: '24px 28px',
        borderRadius: '7px',
        backgroundColor: '#F8F8FC',
        position: 'relative',
    },
    formGroupOutlineDisplay: {
        borderLeft: '6px solid #5666F6',
    },
    formLabel: {
        display: 'flex',
        marginLeft: '2px',
        marginBottom: '12px',
        fontSize: '12px',
        fontWeight: 'bold'
    },
    formDisplay: {
        fontSize: '12px',
        lineHeight: '14px',
        marginBottom: '9px',
        width: 'calc(100% - 20px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        "&:last-child": {
            marginBottom: '0px'
        },
        "& span": {
            marginLeft: "12px",

        }
    },
    formDisplayEdit: props => ({
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        cursor: 'pointer',
        userSelect: 'none',
        display: props.isDragging ? 'none' : 'block'
    }),
    formDisplayDelete: props => ({
        position: 'absolute',
        right: '-6px',
        top: '-6px',
        height: '21px',
        width: '21px',
        borderRadius: '15px',
        textAlign: 'center',
        color: '#FFFFFF',
        backgroundColor: '#868686',
        transform: 'rotate(45deg)',
        fontSize: ' 23px',
        lineHeight: 0.73,
        cursor: 'pointer',
        userSelect: 'none',
        display: props.isDragging ? 'none' : 'block'
    }),
    formDrag: props => ({
        position: 'absolute',
        right: '-26px',
        cursor: props.isDragDisabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
    }),
    formDrop: {
        height: '4px',
        width: '80%',
        backgroundColor: '#cccccc',
        borderRadius: '5px',
        position: 'absolute',
        left: 'calc(10%)',
        top: '-15px'
    }
}));

const { MODES, QUESTION_TYPES, DEFAULT_QUESTION_TYPES, SUBFORM_QUESTION_TYPES,
    TEXT_LIMITS, IMG_PATH, EDIT_IMG, DRAG_IMG, TOP_IMG } = CONST;

function RegistrationFormQuestion(props) {
    const classes = useStyles(props);

    const InitialState = {
        id: props.question.id,
        prompt: props.question.prompt,
        type: props.question.type,
        subForm: props.question.subForm
    };

    const [question, setQeustion] = React.useState(InitialState);
    const [subFormValid, setSubFormValid] = React.useState(false);

    useEffect(() => {
        if (InitialState.id !== question.id)
            setQeustion(InitialState);

    }, [props.question])

    const onEditClick = (event) => {
        const data = { mode: props.mode, question };
        props.onEditClick(data);
    };

    const onClickCheck = () => {
        const data = { mode: props.mode, question };
        props.onClickCheck(data);
    };

    const onDeleteClick = (event) => {
        const data = { mode: props.mode, question };
        props.onDeleteClick(data);
    };

    const onClickCancel = () => {
        setQeustion(InitialState);
        props.onClickCancel();
    };

    const onPromptChange = (event) => {
        if (event.target.value.length > TEXT_LIMITS.MAX)
            return;
        setQeustion({ ...question, prompt: event.target.value });
    };

    const onTypeChange = (event) => {
        setQeustion({ ...question, type: event.target.value, subForm: null });
    };

    const onSubFormChange = (event) => {
        const newSubForm = event.map(item => ({ ...item }));
        setQeustion({ ...question, subForm: newSubForm });
    };

    const isFormTypeDefault = () => {
        return DEFAULT_QUESTION_TYPES.includes(QUESTION_TYPES[question.type]);
    }

    const isFormValid = () => {
        const minTextLenth = TEXT_LIMITS.MIN;
        const isTypeValid = Object.keys(QUESTION_TYPES).includes(question.type);
        const isPromptValid = (isFormTypeDefault()) ? true : (question.prompt.length >= minTextLenth);
        const isSubFormValid = (isFormHasSubForm()) ? subFormValid : true;
        return isTypeValid && isPromptValid && isSubFormValid;
    }

    const isFormHasSubForm = () => {
        return SUBFORM_QUESTION_TYPES.includes(QUESTION_TYPES[question.type]);
    };

    const subFormValidate = (e) => {
        setSubFormValid(e);
    };

    // const handleDrag = (e, item) => {
    //     console.log(e, item)
    // };
    // const handleDragi = (e, item) => {
    //     console.log(e, item)
    // };
    // const handleMove = (e, item) => {
    //     console.log(e, item)
    // };

    const renderSubForm = () => {
        const subForm = question.subForm;
        switch (QUESTION_TYPES[question.type]) {
            case QUESTION_TYPES.DROP_DOWN:
                return (<FormDropdown
                    subForm={subForm}
                    onChange={onSubFormChange}
                    validate={subFormValidate}></FormDropdown>);
            case QUESTION_TYPES.CHECK_BOX:
                return (<FormCheckBox
                    subForm={subForm}
                    onChange={onSubFormChange}
                    validate={subFormValidate}></FormCheckBox>);
            default: return (<span></span>);
        }
    };

    const isDefaultQuestionTypeUsed = (key) => {
        return props.usedDefaultTypes.includes(key);
    }

    const FormDisplay = (
        <div className={classes.questionItem}>
            {/* <div className={classes.formDrop} ></div> */}
            <div className={clsx(classes.formGroupOutline, classes.formGroupOutlineDisplay, classes.flex)} >
                <div style={{ width: 'calc(100% - 30px)' }}>
                    <div className={classes.formDisplay}>
                        <strong> Question prompt</strong><span>{props.question.prompt}</span>
                    </div>
                    <div className={classes.formDisplay}>
                        <strong> Question type</strong><span>{QUESTION_TYPES[props.question.type]}</span>
                    </div>
                </div>
                {props.listMode === MODES.DISPLAY &&
                    < div onClick={onEditClick} className={classes.formDisplayEdit}>
                        <img
                            src={`${IMG_PATH}${EDIT_IMG}`}
                            alt="edit"
                        />
                    </div>
                }
                {props.deletable && <div onClick={onDeleteClick} className={classes.formDisplayDelete}>+</div>}
                {/* TODO Drag */}
                {(props.listMode === MODES.DISPLAY) &&
                    <div className={classes.formDrag}>
                        {!props.isDragDisabled ?
                            <img
                                {...props.onDrag}
                                src={`${IMG_PATH}${DRAG_IMG}`} alt="drag" /> :
                            <img
                                src={`${IMG_PATH}${TOP_IMG}`} alt="top" />
                        }
                    </div>
                }
            </div>
        </div >
    )

    const FormSetting = (
        <div className={classes.questionItem}>
            {/* <div className={classes.formGroupLabel}>Form Setting</div> */}
            <div className={clsx(classes.formGroupOutline, classes.grid)}>
                <FormControl className={classes.formControl}>
                    <div className={classes.formLabel}>Question prompt: </div>
                    <TextField
                        id=""
                        variant="outlined"
                        disabled={isFormTypeDefault()}
                        value={isFormTypeDefault() ? '' : question.prompt}
                        className={classes.textField}
                        onChange={onPromptChange}
                        onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, TEXT_LIMITS.MAX)
                        }}
                        InputProps={{
                            maxLength: TEXT_LIMITS.MAX,
                            endAdornment: <InputAdornment position="end">{question.prompt.length}/{TEXT_LIMITS.MAX}</InputAdornment>
                        }}
                    />
                </FormControl>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}>
                    <div className={classes.formLabel}>Question type: </div>
                    <Select
                        id=""
                        value={question.type}
                        renderValue={(key) => QUESTION_TYPES[key]}
                        onChange={onTypeChange}
                    >
                        {Object.keys(QUESTION_TYPES).map((key, index) =>
                            <MenuItem key={index}
                                disabled={isDefaultQuestionTypeUsed(key)}
                                className={clsx({
                                    [classes.questionTypeMenuItemDivider]: index === 1
                                })}
                                value={key}>{QUESTION_TYPES[key]}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                {renderSubForm()}
            </div>
            <div className={classes.qeustionItemActions}>
                <Button className={classes.qeustionItemActionBtn}
                    onClick={onClickCancel}>
                    <span style={{ textTransform: 'none', fontSize: '16px' }}>Cancel</span>
                </Button>
                <Button className={clsx(classes.qeustionItemActionBtn, classes.qeustionItemActionBtnPrimay)}
                    disabled={!isFormValid()}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={onClickCheck}>
                    <CheckIcon style={{
                        width: '22px',
                        height: '22px'
                    }} />
                </Button>
            </div>
        </div>
    );



    return (
        <div>
            { (props.mode === MODES.DISPLAY) ? FormDisplay : FormSetting}
        </div>
    );


}

export default RegistrationFormQuestion;