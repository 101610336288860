import React, { useState, useEffect } from 'react';
import {
    Box, Button,
    Tabs, Tab,
    makeStyles, withStyles,
    Typography,
    Modal
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import subHeader from '../../images/subHeader.png';
import ManageEvent from './ManageEvent';
import ConfirmationEmail from './ConfirmationEmail';
import RegistrationFormContainer from './RegistrationForm/RegistrationFormContainer';
import axios from 'axios';
import * as CONST from './OCConst';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export const AntTabs = withStyles({
    root: {
        borderBottom: '2px solid #DFDFDF',
    },
    indicator: {
        backgroundColor: '#5666F6',
    },
})(Tabs);

export const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontSize: 16,
        fontWeight: '400',
        //   color: '#000000',
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif',
            'Apple Color Emoji',
            'Segoe UI Emoji',
            'Segoe UI Symbol',
        ].join(','),
        '&:hover': {
            color: '#5666F6',
            opacity: 1,
        },
        '&$selected': {
            color: '#5666F6',
        },
        '&:focus': {
            color: '#5666F6',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    subjectInput: {
        width: "100%",
        height: 50,
        marginTop: 10,
    },
    orderDetailImg: {
        width: "100%",
        height: 52,
        marginTop: 10
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
        height: 60,
    },
    tabs: {
        width: '90%',
        marginLeft: '5%',
        marginTop: 35
    },
    arrowBack: {
        left: '5%',
        marginTop: '1%',
        marginBottom: '1%',
    },
    subHeaderContainer: {
        display: 'flex',
        width: '100vm',
        height: '225px',
        backgroundImage: `url(${subHeader})`,
        backgroundSize: 'cover',
    },
    deactivateContainer: {
        position: 'absolute',
        width: 539,
        height: 341,
        borderRadius: 15,
        backgroundColor: '#FFFFFF',
        padding: theme.spacing(2, 4, 3),
    },
    cancelButton: {
        width: 132,
        height: 40,
        borderRadius: 30,
        border: '1px solid #212759'
    },
    yesButton: {
        width: 132,
        height: 40,
        borderRadius: 30,
        border: '1px solid #212759',
        marginLeft: 45,
        backgroundColor: 'transparent',
        outline: 'none'
    },
    paper: {
        width: 248,
        height: 63,
        borderRadius: 7,
        border: 'trasparent',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginLeft: 110,
        marginTop: 170,
        backgroundColor: 'rgba(134, 136, 152, 0.8)',
        color: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const { PERFORMANCE_INIT, DEFAULT_CONFIRMATION_EMAIL } = CONST;

const ManageEventIndex = (props) => {
    const classes = useStyles();
    const { eventId } = useParams();
    const history = useHistory();

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [subject, setSubject] = useState("Thanks for registerring! Please upload your photo here");
    const handleSubject = (e) => (
        setSubject(e.target.value)
    )

    const [greetings, setGreetings] = useState("Thank you for choosing NASUFUN. This email is to confirm that you’ve registered the following event successfully.");
    const handleGreetings = (e) => (
        setGreetings(e.target.value)
    )

    const [moreInformation, setMoreInformation] = useState("");

    const [performance, setPerformance] = useState(PERFORMANCE_INIT);
    const [dailyPerformance, setDailyPerformance] = useState([]);
    const [datePerformance, setDatePerformance] = useState(null);

    const [confirmationEmail, setConfirmationEmail] = useState(null);

    const [eventGrades, setEventGrades] = useState(null);

    const handleMoreInformation = (e) => (
        setMoreInformation(e.target.value)
    )

    useEffect(() => {
        getEventPerformance(eventId);
        getDailyPerformance(eventId);
        getPerformanceByDate(eventId);
    }, []);

    const getEventPerformance = async (eventId) => {
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/performance/`;
        let res = await axios
            .get(url);

        if (res.data && res.data.Success) {
            setPerformance(res.data.data)
        }
    }

    const getDailyPerformance = async (eventId) => {
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/list_daily_performance/`;
        let res = await axios
            .get(url);

        if (res.data && res.data.Success) {
            setDailyPerformance(res.data.data)
        }
    }

    const getPerformanceByDate = async (eventId, dateId) => {
        const body = dateId ? { dateId } : {};
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/grades_performance_by_date/`;
        let res = await axios
            .post(url, body);

        if (res.data && res.data.Success) {
            setDatePerformance(res.data.data)
        }
    }

    const getConfirmationEmail = async (eventId) => {
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/get_confirmation_email/`;
        let res = await axios
            .get(url);

        if (res.data && res.data.Success) {
            setConfirmationEmail(res.data.data)
        } else {
            setConfirmationEmail(DEFAULT_CONFIRMATION_EMAIL)
        }
    }

    const createOrUpdateConfirmationEmail = async (eventId, form) => {
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/create_or_update_confirmation_email/`;
        let res = await axios
            .post(url, form);

        if (res.data && res.data.Success) {
            setConfirmationEmail(form)
        }
    }

    const checkValid = () => {
        if (subject != "Thanks for registerring! Please upload your photo here" || greetings != "Thank you for choosing NASUFUN. This email is to confirm that you’ve registered the following event successfully." || moreInformation) {
            return true;
        } else {
            return false;
        }
    }
    const [anchorEl, setAnchorEl] = React.useState(null);


    const [showPreview, setShowPreview] = useState(false);
    const handleUpdate = (event) => {
        setShowPreview(true);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setPopperClose(true);
        setTimeout(
            () => setPopperClose(false),
            3000
        );
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [popperClose, setPopperClose] = useState(false);


    const [cancel, setCancel] = useState(false);
    const handleCancel = () => {
        setCancel(true);
        setSubject("Thanks for registerring! Please upload your photo here");
        setGreetings("Thank you for choosing NASUFUN. This email is to confirm that you’ve registered the following event successfully.");
        setMoreInformation('');
    }

    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(true);
    }
    const [anchorElYes, setAnchorElYes] = React.useState(null);
    const openYes = Boolean(anchorElYes);
    const idYes = openYes ? 'simple-popper' : undefined;
    const [popperYesClose, setPopperYesClose] = useState(false);
    const handleYesModalClose = (event) => {
        setShowModal(false)
        setAnchorElYes(anchorElYes ? null : event.currentTarget);
        setPopperYesClose(true);
        setTimeout(
            () => setPopperYesClose(false),
            3000
        );
    }
    const handleModalClose = () => {
        setShowModal(false)
    }

    const onMyEventClicked = () => {
        history.push({
            pathname: "/operator_center"
        })
    }

    const renderHeader = () => {
        return (
            <Box className={classes.header}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2%' }}>
                    <Button
                        onClick={onMyEventClicked}
                        startIcon={<ArrowBackIosIcon />}
                        className={classes.arrowBack}
                        size="small"
                    >
                        <div style={{ fontSize: 16, fontWeight: '400', textTransform: 'none' }}>
                            My event
                        </div>
                    </Button>
                </div>
            </Box>
        )
    }

    const renderSubHeader = () => {
        const { eventName, totalQuantity, totalAmount } = performance;
        return (
            <Box className={classes.subHeaderContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>                    
                    <div style={{ fontSize: 30, fontWeight: '700', marginLeft: 101 }}>{eventName}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: -15}}>
                        <div style={{ width: 327, height: 81, backgroundColor: '#FFFFFF', borderRadius: 15, display: 'flex', alignItems: 'center', marginLeft: 101 }}>
                            <img src={require('../../images/dollarIcon.png')} style={{ width: 37, height: 37, marginLeft: 24 }} />
                            <div style={{ fontSize: 25, fontWeight: '800', marginLeft: 22 }}>$ {Math.round(totalAmount)}</div>
                            <div style={{ fontSize: 15, fontWeight: '400', marginLeft: 22 }}>Event Sales</div>
                        </div>
                        <div style={{ width: 272, height: 81, backgroundColor: '#FFFFFF', borderRadius: 15, display: 'flex', alignItems: 'center', marginLeft: 25 }}>
                            <img src={require('../../images/ticketIcon.png')} style={{ width: 42, height: 42, marginLeft: 24 }} />
                            <div style={{ fontSize: 25, fontWeight: '800', marginLeft: 22 }}>{totalQuantity}</div>
                            <div style={{ fontSize: 15, fontWeight: '400', marginLeft: 22 }}>Tickets Sold</div>
                        </div>
                    </div>
                </div>
            </Box>
        )
    }

    const renderTabs = () => {
        return (
            <div className={classes.tabs}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2.85%' }}>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Manage event" />
                        <AntTab label="Email" />
                        <AntTab label="Registration form" />
                        {/* <AntTab label="Edit" /> */}
                    </AntTabs>
                    <TabPanel value={value} index={0}>
                        <ManageEvent
                            eventId={eventId}
                            dailyPerformance={dailyPerformance}
                            datePerformance={datePerformance}
                            getPerformanceByDate={getPerformanceByDate} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ConfirmationEmail
                            eventId={eventId}
                            confirmationEmail={confirmationEmail}
                            getConfirmationEmail={getConfirmationEmail}
                            createOrUpdateConfirmationEmail={createOrUpdateConfirmationEmail}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <RegistrationFormContainer
                            eventId={eventId} />
                    </TabPanel>
                    <TabPanel value={value} index={3} />
                    <Typography className={classes.padding} />
                </div>
            </div >
        )
    }

    const renderModal = () => {
        return (
            <Modal
                open={showModal}>
                <div style={{ width: 539, height: 341, borderBlockColor: 'white', borderRadius: 15 }}>

                </div>
            </Modal>
        )
    }

    return (
        <div>
            {renderHeader()}
            {renderSubHeader()}
            {renderTabs()}
        </div>
    )

}
export default ManageEventIndex;