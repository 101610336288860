import React, { Component } from 'react';
import axios from 'axios';

import GradeDetail from '../CommonUtils/GradeDetail';
import GeoUtil from '../CommonUtils/GeoUtil';

import { Row,Col } from 'react-bootstrap';

import "../../css/event.css"

class Order extends Component {

    state = {
        orderData: {}
    }

    componentDidMount() {
        const orderId = this.props.match.params.id;
        console.log(orderId);
        this.getOrderDetail(orderId);
    }

    getOrderDetail(orderId) {
        axios
            .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/order/${orderId}/`)
            .then(res => {
                this.setState({orderData: res.data})
            })
            .catch(err => {
                console.log(err);
        });
    }

    render() {
        const { orderData } = this.state;

        if (Object.entries(orderData).length === 0) {
            return (
                <div>
                    loading
                </div>
            )
        };

        if (orderData.error) {
            return (
                <div>
                    {orderData.error} Please make sure the order id is correct. 
                </div>
            )
        }

        const { user, event, tickets, order } = orderData;
        const eventDate = (String(event.eventStartTime));

        return (
            <div className="event_detail-top">
            <Row>
                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>User Name</h4>
                    <small>{user.first_name} {user.last_name}</small>
                </Col>

                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>User Email</h4>
                    <small>{user.email}</small>
                </Col>
                
                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>Event Name</h4>
                    <small>{event.eventName}</small>
                </Col>

                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>Event Start Date</h4>
                    <small>{event.eventStartTime.substring(0,10)}</small>
                </Col>

                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>Event Address</h4>
                    <small>{event.eventAddress}</small>
                </Col>

                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>Tickets Detail</h4>
                    {tickets.map((t) => {
                        return (
                            <Col>
                                <small>{t.gradeName} : ${t.gradePrice} x {t.ticketNumber}</small>
                            </Col>
                        )
                    })}
                </Col>

                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>Order Placed Date</h4>
                    <small>{order.created.substring(0,10)}</small>
                </Col>

                <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                    <h4>Order Amount</h4>
                    <small>${order.orderAmount}</small>
                </Col>
            </Row>
            
            </div>
        )
    };
}

export default Order;