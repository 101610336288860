export const PERFORMANCE_INIT = {
    eventName: "Event",
    totalAmount: 0.0,
    totalQuantity: 0
}

export const MONTH_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
];

export const DEFAULT_CONFIRMATION_EMAIL = {
    subject: "Your tickets at NASUFUN confirmed",
    greetingMessage: "Thank you for choosing NASUFUN. This email is to confirm that you’ve registered the following event successfully.",
    moreInfo: "",
}