import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from "./components/CommonUtils/Header";
import Home from "./components/CommonUtils/Home";
import axios from 'axios';
import Events from "./components/Events/Events";
import Articles from "./components/Articles/Articles";

import Event from "./components/Events/Event";
import Order from "./components/Orders/Order";
import NGO from "./components/CommonUtils/NGO";
// import Privacy from "./components/CommonUtils/PrivacyPolicy";
import Waiver from "./components/CommonUtils/Waiver";
import UserProfile from "./components/UserAuth/UserProfile";
import LoginForm from "./components/UserAuth/LoginForm";
import SignupForm from "./components/UserAuth/SignupForm";
import ProtectedRoute from "./components/UserAuth/ProtectedRoute";
import ShoppingCart from "./components/Payment/ShoppingCart";
import { AuthProviderWithRouter } from "./components/UserAuth/AuthContext";
import LaunchApp from './components/CommonUtils/LaunchApp';
import CreateEventHome from "./components/Create/CreateEventHome";
import OperatorCenter from "./components/OperatorCenter/OperatorCenter";
import ManageEventIndex from "./components/OperatorCenter/ManageEventIndex";
import ManageGuest from './components/ManageGuest/index';
import EditDate from './components/OperatorCenter/EditDate';

class App extends Component {

  state = {
    events: [],
    events_host: [],
    articles: [],
  }

  componentDidMount() {
    this.getAllEvents();
    this.getAllArticles();
  }
  
  getAllEvents() {
    axios
      .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/`)
      .then(res => {
        this.setState({ events: res.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getAllArticles() {
    axios
      .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/articles/`)
      .then(res => {
        this.setState({ articles: res.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    // true for local development, false for prod
    const development = false;
    const nasu_backend_url = development ? 'http://127.0.0.1:8000' : 'https://northamericasportsunion.org';
    window.localStorage.setItem('nasu_backend_url', nasu_backend_url);
    
    return (

      <div>
        <BrowserRouter>
          <AuthProviderWithRouter>
            <Header />
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/event/:id" component={Event} />
              <Route path="/order/:id" component={Order} />
              <Route path="/login" component={LoginForm} />
              <Route path="/signup" component={SignupForm} />
              <Route path="/cart" component={ShoppingCart} />
              <Route path="/ngo" component={NGO} />
              {/* <Route path="/privacy" component={Privacy}/> */}
              <Route path="/waiver" component={Waiver} />
              <Route
                path='/all_events'
                render={(props) => <Events {...props} events={this.state.events} />}
              />
              <Route
                path='/all_articles'
                render={(props) => <Articles {...props} articles={this.state.articles} />}
              />
              <Route path='/launch_app/' component={LaunchApp} />
              <Route path='/create_event' component={CreateEventHome} />
              <ProtectedRoute path='/operator_center' component={OperatorCenter} />
              <Route exact path='/manage_event/:eventId' component={ManageEventIndex} />
              <Route
                path='/manage_event/:eventId/manage_guest'
                render={(props) => <ManageGuest {...props} />}
              />
              <Route path='/manage_event/:eventId/edit_date' component={EditDate} />
              <ProtectedRoute path="/userprofile" component={UserProfile} />
            </Switch>
          </AuthProviderWithRouter>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;