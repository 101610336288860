import React, { Component } from 'react';
import {
    makeStyles, Select, FormGroup, FormControlLabel, Grid,
    TextField, Checkbox, MenuItem

} from '@material-ui/core';

// import "../../css/Registration.css";
import * as CONST from './RegistrationFormConst';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
    preivewItem: {
        marginBottom: '36px',
        '&:last-child': {
            marginBottom: '0px',
        },
        '& .previewItemPrompt': {
            fontWeight: 'bold'
        },
        '& .previewItemBody': {
            marginTop: '16px'
        }
    },
    textField: {
        width: '100%',
        borderRadius: '7px'
    }
}));
const { QUESTION_TYPES } = CONST;

function RegistrationFormPreviewItem(props) {
    const classes = useStyles();

    const getPromt = () => {
        switch (QUESTION_TYPES[props.previewItem.type]) {
            case QUESTION_TYPES.NAME: return 'Your Name';
            case QUESTION_TYPES.ADDRESS: return 'Your Address';
            default: return props.previewItem.prompt;
        }
    };
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const previewShortText = (
        <TextField
            className={classes.textField}
            variant="outlined"
            value={''}
            placeholder="Short text"
        />
    );

    const previewLongText = (
        <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={4}
            id={`previewLongText_${props.index}`}
            placeholder="Long text" />
    );

    const previewName = (

        <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="First Name"
                    className={classes.textField}
                />
            </Grid>
            <Grid item xs={6} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="Last Name"
                    className={classes.textField}
                />
            </Grid>
        </Grid >

    );

    const previewAddress = (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={8} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="Street address"
                    className={classes.textField}
                />
            </Grid>
            <Grid item xs={4} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="Suite"
                    className={classes.textField}
                />
            </Grid>
            <Grid item xs={4} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="City"
                    className={classes.textField}
                />
            </Grid>
            <Grid item xs={4} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="State"
                    className={classes.textField}
                />
            </Grid>
            <Grid item xs={4} >
                <TextField
                    variant="outlined"
                    value={''}
                    placeholder="Zipcode"
                    className={classes.textField}
                />
            </Grid>
        </Grid>
    );

    const previewDate = (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );

    const previewDropDown = (
        <Select
            className={classes.textField}
            variant="outlined"
            multiple
            displayEmpty
            value={[]}
        >
            {QUESTION_TYPES[props.previewItem.type] === QUESTION_TYPES.DROP_DOWN &&
                props.previewItem.subForm.map((item, index) =>
                    <MenuItem key={index} value={index}>{item.text}</MenuItem>
                )}
        </Select>

    );

    const previewCheckBox = (
        <FormGroup>
            {QUESTION_TYPES[props.previewItem.type] === QUESTION_TYPES.CHECK_BOX &&
                props.previewItem.subForm.map((item, index) =>
                    <FormControlLabel style={{ height: '16px' }}
                        key={index}
                        control={
                            <Checkbox
                                name={item.text}
                                color="primary" />}
                        label={item.text}
                    />
                )}
        </FormGroup>

    );

    const renderInput = () => {
        switch (QUESTION_TYPES[props.previewItem.type]) {
            case QUESTION_TYPES.NAME: return (previewName);
            case QUESTION_TYPES.ADDRESS: return (previewAddress);
            case QUESTION_TYPES.SHORT_TEXT: return (previewShortText);
            case QUESTION_TYPES.LONG_TEXT: return (previewLongText);
            case QUESTION_TYPES.DROP_DOWN: return (previewDropDown);
            case QUESTION_TYPES.CHECK_BOX: return (previewCheckBox);
            case QUESTION_TYPES.DATE_CALENDAR: return (previewDate);
            default: return ({});
        };
    };

    return (
        <div className={classes.preivewItem}>
            <div className='previewItemPrompt'>{getPromt()}</div>
            <div className='previewItemBody'>
                {renderInput()}
            </div>
        </div>
    );
}

export default RegistrationFormPreviewItem;