import React, {useState, useRef, useContext } from 'react';
import {
    AppBar, Box, Button,
    Container, Drawer, Hidden, IconButton,
    makeStyles,
    Paper,
    Toolbar, Typography,
    Step, Stepper, StepContent, StepLabel, SvgIcon,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import MenuIcon from '@material-ui/icons/Menu';
import MobileStepper from '@material-ui/core/MobileStepper';
import { AuthConsumer, AuthContext } from '../UserAuth/AuthContext';
import CEBasicInfo from './CEBasicInfo';
import CEPhotos from './CEPhotos';
import CEWaivers from './CEWaivers';
import CEAdvancedSetting from './CEAdvancedSetting';
import CETickets from './CETickets';
import CEDateAndTimes from './CEDateAndTimes';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Link } from 'react-router-dom';
import { defaultEvent, EventAction } from './constant';
import { useHistory } from "react-router-dom";
import {EventCategoryIdNameMap, refundDateOptions, CEMode} from './constant';
import moment from 'moment'
import axios from 'axios';
import{ DateUtils } from 'react-day-picker';
const completeDraft = (draft) => {
    // process category
     for (const [key, value] of Object.entries(EventCategoryIdNameMap)) {
        if (value === draft.basicInfo.categoryName) {
            draft.basicInfo.category = parseInt(key[0]); 
            draft.basicInfo.sportIdx = parseInt(key[1]);
        }
    }
    // process address, address filled via backend cannot be processed
    if (draft.basicInfo.formattedAddress) {
        const addressSegments = draft.basicInfo.formattedAddress.split(',');
        if(addressSegments.length === 3) {
            draft.basicInfo.address = addressSegments[0].trim();
            draft.basicInfo.zipCode = addressSegments[addressSegments.length - 1].trim();
            const address2Segments = addressSegments[1].trim().split(' ');
            draft.basicInfo.city = address2Segments[0].trim();
            draft.basicInfo.selectState = address2Segments[address2Segments.length - 1].trim();

        }
    }

    if (draft.advancedSetting) {
        draft.advancedSetting.bringName = "";
    }
    const ticketsDates = draft.dateAndTimes.ticketsDates.map(
        t => {return {...t};}
    );

    const dateAndTimesOrigin = {ticketsDates};
    draft['dateAndTimesOrigin'] = dateAndTimesOrigin;
    return draft;
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex', 
        flexDirection: 'row',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        // minHeight: '800px',
        minWidth: '375px',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },

    actionsContainer: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    createEventButton: {
        left: '5%',
        marginTop: '1%',
        marginBottom: '1%',
        textTransform: 'none', 
        fontSize: '17px'
    },
    saveAsDraftButton: {
        width: '110px',
        height: '30px',
        left: '55%',
        right: '5%',
        marginTop: '30px',
        marginBottom: '1%',
        textTransform: 'none',
        border: '1px solid #000000',
        borderRadius: '30px',
        fontSize: '14px',
        textAlign: 'center'
        
    },
    menuContainer: {
        flex: 1,
    },
    bodyContainer: {
        flex: 5,
        width: '100%', 
        boxShadow: 'rgb(0 0 0 / 5%) -10px 0px 20px 0px'
    },
    labelRoot: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        paddingLeft: '25px',
        height: '55px',
    },
    labelActiveRoot: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        paddingLeft: '25px',
        height: '55px',
        backgroundColor: 'white',
        borderTopLeftRadius:'30px',
        borderBottomLeftRadius: '30px',
        zIndex: 999,
        boxShadow: 'rgb(0 0 0 / 5%) -10px 0px 20px 0px'
    },
    iconCompleted:{
        '& .MuiStepIcon-root':{
            color: '#5666F6',
            width: '23px',
            height: '23px',
        },
        '& .MuiStepIcon-text': {
            fontWeight: 600,
            fontSize: '10px',
        },
        paddingRight: '15px',
    },
    iconContainer: {
        '& .MuiStepIcon-root.MuiStepIcon-active':{
            color: '#5666F6',
            width: '23px',
            height: '23px',
        },
        '& .MuiStepIcon-root': {
            color: '#CDCFE2',
            width: '23px',
            height: '23px',
        },
        '& .MuiStepIcon-text': {
            fontWeight: 600,
            fontSize: '10px',
        },
        paddingRight: '15px',
    },
    labelCompleted:{
        '& .MuiStepLabel-label': {
            fontSize: '15px',
            fontWeight: 700,
            color: '#5666F6',
        },
        color: '#5666F6',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    labelContainer: {
        '& .MuiStepLabel-label': {
            fontSize: '15px',
            fontWeight: 700,
            color: '#CDCFE2',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            fontSize: '15px',
            fontWeight: 700,
            color: '#5666F6',
        },
        color: '#5666F6',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    stepContentRoot: {
        paddingTop: '2px',
        paddingLeft: '63px', // iconWidth+iconPaddingRight+labelRootPaddingLeft
        borderLeft: 0,
        marginLeft: 0,
    },
    mobileStepperRoot: {
        minWidth: '700px',
        backgroundColor: 'white',
        paddingLeft: '30px',
        paddingTop: '12px',
        "& .MuiButton-containedPrimary:hover": {
            backgroundColor:'#5666F6',
        },
    },
    stepperDot: {
        margin: '0 4px',
        width: '7px',
        height: '7px',
    },
    stepperCompletedDot: {
        margin: '0 4px',
        width: '7px',
        height: '7px',
        backgroundColor: '#5666F6',
    },
    stepperActiveDot: {
        backgroundColor: '#5666F6',
    },
    disabledButton: {
        border: "1px solid #CDCFE2",
        borderRadius: "30px",
        height: "40px",
        width: "95px",
        fontSize: "16px",
        textTransform: 'none',
        color: '#CDCFE2'
    },
    backButton: {
        border: "1px solid #5666F6",
        borderRadius: "30px",
        height: "40px",
        width: "95px",
        fontSize: "16px",
        textTransform: 'none',
        color: '#5666F6',
    },
    nextButton: {
        textTransform: 'none',
        backgroundColor: '#5666F6',
        borderRadius: "30px",
        height: "40px",
        width: "95px",
        fontSize: "16px",
        color: 'white',
    },
    containedPrimary: {
        '&:hover': {
            backgroundColor:'#5666F6',
        },
    },
    backDrop: {
        backgroundColor: "rgba(134, 136, 152, 0.5)",
        color: '#FFFFFF',
        width: "140px",
        height: "63px",
        borderRadius: "7px"
    }
}));

function getSteps() {
    return ['Basic Info', 'Photos', 'Waivers', 'Advanced Setting', 'Tickets', 'Date and times'];
};

function getStepperStepContent(step) {
    switch (step) {
        case 3:
            return (
                <div style={{paddingTop:'14px', fontSize:'12px', fontWeight:200}}>
                    <p>What to bring</p>
                    <p>Things to keep in mind</p>
                    <p>Cancelation Policy</p>
                    <p>Covid-19 Policy</p>
                </div>
            );
        default:
            return '';
    }
}

function getBodyStepContent(props) {
    const { step, draftEvent, setDraftEvent, setValidation, CEBasicInfoRef, CEPhotosRef, CEWaiversRef, CEAdvancedSettingRef, CETicketsRef, CEDateAndTimesRef, preview, setPreview, submitEvent, mode} = props;
    switch (step) {
        case 0:
            return (<CEBasicInfo draftEvent={draftEvent} setDraftEvent={setDraftEvent} setValidation={setValidation} ref={CEBasicInfoRef}/>);
        case 1:
            return (<CEPhotos draftEvent={draftEvent} setDraftEvent={setDraftEvent} setValidation={setValidation} ref={CEPhotosRef} mode={mode}/>);
        case 2:
            return (<CEWaivers draftEvent={draftEvent} setDraftEvent={setDraftEvent} setValidation={setValidation} ref={CEWaiversRef}/>);
        case 3:
            return (<CEAdvancedSetting draftEvent={draftEvent} setDraftEvent={setDraftEvent} setValidation={setValidation} ref={CEAdvancedSettingRef} mode={mode}/>);
        case 4:
            return (<CETickets draftEvent={draftEvent} setDraftEvent={setDraftEvent} setValidation={setValidation} ref={CETicketsRef} mode={mode}/>);
        case 5:
            return (<CEDateAndTimes draftEvent={draftEvent} setDraftEvent={setDraftEvent} setValidation={setValidation} ref={CEDateAndTimesRef}  preview={preview} setPreview={setPreview} submitEvent={submitEvent} mode={mode}/>);
        default:
            submitEvent(draftEvent)
            return `Preview: ${JSON.stringify(draftEvent)}`;
    }
};

function sendFile2AWS(file, name) {
    const type = file.type;
    const region = 'us-east-2';
    const now = Date.now();
    const urlKey = `eventsPicture/${name}_${now}.png`;
    const bucket = 'nasu-productiion';
    const formedUrl = `https://${bucket}.s3.${region}.amazonaws.com/${urlKey}`;
    var AWS = require('aws-sdk');
    var s3 = new AWS.S3({ 
        accessKeyId: 'AKIASQ4WL5MSPIQKZLET', 
        secretAccessKey: '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', 
        region: region });
    var params = { Bucket: bucket, Key: urlKey, ContentType: type};
    
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const b64DataString = reader.result;
      const base64Data = new Buffer.from(b64DataString.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      s3.putObject(
          {...params,
            Body: base64Data,
            ContentEncoding: 'base64',
          }, (er, d) => {
            if(er) {
              console.log(er)
              return;
            }
          }
        )
    });
    reader.readAsDataURL(file);
    return formedUrl;
    
  }


const stepValidationInit = { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true };

const CreateEventHome = (props) => {
    const CEBasicInfoRef = useRef();
    const CEPhotosRef = useRef();
    const CEWaiversRef = useRef();
    const CEAdvancedSettingRef = useRef();
    const CETicketsRef = useRef();
    const CEDateAndTimesRef = useRef();
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const { state } = props.location;
    const getMode = () => {
        if(state && state.draft && !state.eventId) 
            return CEMode.EDIT_DRAFT
        if(state && state.draft && state.eventId) 
            return CEMode.EDIT_REJECTED
        if(!state || !state.draft) 
            return CEMode.CREATE_DRAFT
    }
    const [mode, setMode] = useState(getMode())

    const getInitalDraftEvent = () => {
        switch(mode) {
            case CEMode.EDIT_REJECTED:      // pre process rejected event inorder to fill the missing filed
                return completeDraft({...state.draft}) 
            case CEMode.EDIT_DRAFT:   
                return state.draft
            case CEMode.CREATE_DRAFT:       // default draft form;
                return {...defaultEvent}
            default:
                return {...defaultEvent}
        }
    }

    const [draftEvent, setDraftEvent] = useState(getInitalDraftEvent());
    const getDraftId = () => {
        if(mode === CEMode.EDIT_DRAFT)
            return state.draftId
         
        return null;
    }

    const [draftId, setDraftId] = useState(getDraftId());
    const [stepValidation, setStepValidation] = useState(stepValidationInit);
    const [savingDraft, setSavingDraft] = useState(false);
    const [open, setOpen] = useState(false);
    const [preview, setPreview] = useState(false);
    const [openSavedDialog, setOpenSavedDialog] = useState(false);
    const setValidation = valid => {
        const _stepValidation = {...stepValidation};
        if (_stepValidation[activeStep] === valid) return; 
        _stepValidation[activeStep] = valid;
        setStepValidation(_stepValidation);
    };
    const auth = useContext(AuthContext);

    const getCategory = () => {
        const category = `${draftEvent.basicInfo.category}`; // Others
        
        if (draftEvent.basicInfo.category !== 4) {   
            const category = `${draftEvent.basicInfo.category}${draftEvent.basicInfo.sportIdx}`;
        }

        return EventCategoryIdNameMap[category] ? EventCategoryIdNameMap[category] : "Others";
    }

    const getRefundPolicy = () => {
        if(draftEvent.advancedSetting.refund === 'Zero')
            draftEvent.advancedSetting.refundDate = '1'; 
        const option = refundDateOptions[draftEvent.advancedSetting.refund]
        .find(option => option.key === draftEvent.advancedSetting.refundDate.toString())
        ['name'];
       return option;
    }

    const getEventTime = () => {
        let ds = draftEvent.dateAndTimes.ticketsDates.map(t => moment.utc(t.date)).filter(d => d.isValid());
        ds = ds.sort((d1,d2) => d1.isBefore(d2) ? -1 : 1);

        draftEvent.dateAndTimes.eventStartTime = ds[0].startOf('day');
        draftEvent.dateAndTimes.eventEndTime = ds[ds.length - 1].endOf('day');

        draftEvent.dateAndTimes.ticketsDates.forEach(d => {
            // TEMP
            d.startTime = moment(d.date).startOf('day')
            d.endTime = moment(d.date).endOf('day')
        })

        if(mode === CEMode.EDIT_REJECTED) {
            for (let ticketDateOrigin of draftEvent.dateAndTimesOrigin.ticketsDates) {
                ticketDateOrigin.action = EventAction.DELETE;
                for (let ticketDate of draftEvent.dateAndTimes.ticketsDates) {
                    // ticketDate has instance in db
                    if ('id' in ticketDate && ticketDateOrigin.id === ticketDate.id) {
                        
                        const dateOrigin = new Date(ticketDateOrigin.date)
                        const dateObj = new Date(ticketDate.date)
                        ticketDateOrigin.action = DateUtils.isSameDay(dateOrigin, dateObj)  ? null : EventAction.UPDATE ;                
                        
                        if (ticketDateOrigin.isSpecial) {
                            for (let ticketOrigin of ticketDateOrigin.tickets) {
                                ticketOrigin.action = EventAction.DELETE;
                                
                                for (let ticket of ticketDate.tickets) {
                                    if ('id' in ticket && 'action' in ticket && ticket.id === ticketOrigin.id) {
                                        let updated = false;
                                        updated = ticket.duration !== ticketOrigin.duration ||
                                        ticket.eventStartTime !== ticketOrigin.eventStartTime ||
                                        ticket.price !== ticketOrigin.price ||
                                        ticket.ticketDescription !== ticketOrigin.ticketDescription ||
                                        ticket.ticketName !== ticketOrigin.ticketName ||
                                        ticket.unlimited !== ticketOrigin.unlimited ||
                                        ticket.sellingCutoffDate !== ticketOrigin.sellingCutoffDate ||
                                        ticket.qty !== ticketOrigin.qty 
                                        ticket.action = updated ?  EventAction.UPDATE : null;
                                        ticketOrigin.action = null;
                                    }

                                    if (!('action' in ticket)) {
                                        ticket.action = EventAction.CREATE;
                                        ticket.ticketDate = ticketDate.id
                                    }
                                }

                            }
                        }
                    } 

                    if (!('id' in ticketDate) && !('action' in ticketDate)) {
                        ticketDate.action = EventAction.CREATE;
                    }
                } 
            }

            let date_op = [];
            let ticket_op = [];
            for (let ticketDateOrigin of draftEvent.dateAndTimesOrigin.ticketsDates) {
                if (ticketDateOrigin.isSpecial) {
                    for(let ticket of ticketDateOrigin.tickets) {
                        if (ticket.action !== null) {
                            ticket_op.push(ticket)
                        }
                    }
                }

                if (ticketDateOrigin.action !== null) {
                    date_op.push(ticketDateOrigin)
                }
            }

            for (let ticketDate of draftEvent.dateAndTimes.ticketsDates) {
                if( ticketDate.action === 'create') {
                    date_op.push(ticketDate)
                }
                if (ticketDate.isSpecial) {

                    for(let ticket of ticketDate.tickets) {
                        if (ticket.action !== null) {
                            ticket_op.push(ticket)
                        }
                    }
                }
            }

            draftEvent.dateAndTimesOP = {date_op, ticket_op }
        }
    }

    const getWaiverText = () => {
        draftEvent.waivers.wavierText = draftEvent.waivers.ifChecked ? draftEvent.waivers.wavierText :
        `I hereby assume all risks of participating in the ${draftEvent.basicInfo.name} event held by ${draftEvent.waivers.organName} the Date chosen. Including by way of example and not limited to, any risks that may arise from negligence or carelessness on the part of ${draftEvent.waivers.organName}, and/or their directors, officers, agents, coaches, representatives, or volunteers (collectively, “NASU”), from dangerous or defective equipment or property owned, maintained, or controlled by them, or because of their possible liability without fault.
         I certify I am physically fit and that there are no health-related reasons which preclude my participation in this event. I certify that I have not been advised to not participate in this event by any qualified medical professional.  I acknowledge that this waiver may be used by __NAME__. 

        I hereby take action for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows: 
        (a) I waive, release, and discharge __NAME__ from any and all liability, including but not limited to, liability arising from the negligence or fault of the entities or persons released, for my death, disability, personal injury, property damage, property theft, or actions of any kind which may hereafter occur to me including my traveling to and from this event. 
        (b) I indemnify, hold harmless, and promise not to sue __NAME__ for any and all liabilities or claims made as a result of participation in this event, whether caused by negligence or otherwise. 
        (c) I acknowledge that this event may carry with it the potential for death, serious injury, and personal loss. The risks may include, but are not limited to, those caused by terrain, facilities, weather, lack of hydration, equipment, negligence, vehicular traffic, actions of myself and of other people, including, but not limited to, {eventName} organizers, participants, volunteers, spectators, and coaches. 
        (d) I release and forever discharge __NAME__ from any claim whatsoever which arises or may hereafter arise on account of any first-aid treatment or other medical services rendered to me in connection with an emergency during my time with __NAME__.
        (e) I consent and agree that __NAME__ may take photographs and/or digital recordings of me as participant during this event and I grant unto __NAME__ all right, title, and interest in these materials. I further consent that my identity may be revealed therein or by description text or commentary. I waive any rights, claims or interest to any royalties, proceeds, or other benefits derived from such photographs or recordings.
        (f) I understand that my behavior during this event must comply with all rules and regulations in place at ${draftEvent.basicInfo.onlineEvent ? draftEvent.basicInfo.onlineLink : 
            `${draftEvent.basicInfo.address}, ${draftEvent.basicInfo.city} ${draftEvent.basicInfo.selectState}, ${draftEvent.basicInfo.zipCode}`}, and release __NAME__ from any liability for my behavior.
        (g) The accident waiver, release of liability and image release shall be construed broadly and to provide a release and waiver to the maximum extent permissible under the laws of the Commonwealth of Massachusetts. 
        I certify that I have read this document, and I fully understand its content. I am aware that this is a release of liability and a contract and I sign it freely and voluntarily.

        By checking I agree the terms above and forfeit all right to bring a suit against the party Organizer. I will take full responsibility to any injury that may occur to me and my family members.`
    }

    const getPhotoUrls = () => {
        let collection = [];
        //console.log(props.location.state.ticket.grade.gradeName)

        const name = draftEvent.basicInfo.name.replaceAll(" ", "").trim();
        draftEvent.photos.fileObj.map((image, index) => {
          const formedUrl = sendFile2AWS(image, name);
          if(mode === CEMode.EDIT_REJECTED) {
              collection.push({imageUrl: formedUrl, action: EventAction.CREATE})
          } else {
              collection.push(formedUrl);
          }
        });

        if(mode === CEMode.EDIT_REJECTED) {
            draftEvent.photos.urls = draftEvent.photos.urls.concat(collection);
        } else {
            draftEvent.photos.urls = collection;
        }
    }

    const formatAddress = () => {
        return draftEvent.basicInfo.formattedAddress = draftEvent.basicInfo.onlineEvent ? draftEvent.basicInfo.onlineLink : 
         `${draftEvent.basicInfo.address}, ${draftEvent.basicInfo.city} ${draftEvent.basicInfo.selectState}, ${draftEvent.basicInfo.zipCode}`
    }

    const processDuration = (tickets) => {
        tickets.forEach(ticket => {
            const durationStrs = ticket.duration.split(' ');
            ticket.durationUnit = durationStrs[1] === 'hrs' ? 'hours' : "minutes";
            ticket.durationQty = parseInt(durationStrs[0]);
            const cutOffStrs = ticket.sellingCutoffDate.split(' ');
            ticket.sellingCutoffDateUnit = cutOffStrs[1] === 'days' || cutOffStrs[1] === 'day'? 'days' : "hours";
            ticket.sellingCutoffDateQty = parseInt(cutOffStrs[0]);
        }) 
    }

    const processAdvanceSetting = () => {
        const {bringName, keepInMind} = draftEvent.advancedSetting.checkedList;
        draftEvent.advancedSetting.mindText = keepInMind ?  "" : draftEvent.advancedSetting.mindText;
        draftEvent.advancedSetting.bringNameList = bringName ?  [] : draftEvent.advancedSetting.bringNameList;
    }

    const processEvent = () => {
        // get CategoryName
        draftEvent.basicInfo.categoryName = getCategory();
        // get RefundPolicy
        draftEvent.advancedSetting.refundPolicy = getRefundPolicy();
        // upload photo and get urls
        getPhotoUrls();
        // format address
        formatAddress();
        // process waiver
        getWaiverText();
        // process adv text 
        processAdvanceSetting();
        // process duration
        processDuration(draftEvent.tickets.allTickets);
        let tickets = draftEvent.dateAndTimes.ticketsDates.filter(t => t.isSpecial).map(t => t.tickets)
        tickets.forEach(t => processDuration(t))
        // get EventStart Time and EndTime
        getEventTime();
    }

    const submitEvent = () => {
        processEvent();

        if(mode === CEMode.EDIT_REJECTED) {
            submitRejectedEvent()
        } else {
            submitDraftEvent()
        }

    }

    const submitDraftEvent = () => {
        const userId = auth.user.id;
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/create_new_event/`;
        
        axios.post(url, {draftEvent, userId})
            .then(response=> {
                console.log(response);
                history.push({
                    pathname: '/operator_center',
                    state: { eventCreated: true  }
                })
            })
            .catch(error=>console.log(error));
    }

    const submitRejectedEvent = () => {
        const userId = auth.user.id;
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/update_rejected_event/`;
        console.log(state.eventId, draftEvent)
        axios.post(url, {draftEvent, userId, eventId: state.eventId})
            .then(response=> {
                console.log(response);
                history.push({
                    pathname: '/operator_center',
                    state: { eventUpdated: true  }
                })
            })
            .catch(error=>console.log(error));
    }

    const createOrUpdateDraft = () => {
        const userId = auth.user.id;
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/save_or_update_draft/`;
        
        axios.post(url, {draftEvent, userId, draftId})
            .then(response=> {
                console.log(response)
                if(response.data.Success) 
                    setDraftId(response.data.draftId)
            })
            .catch(error=>console.log(error));
    }

    const [mobileOpen, setMobileOpen] = useState(false);
    const steps = getSteps();

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onSaveDraft = () => {
        switch (activeStep) {
            case 0:
                CEBasicInfoRef.current.saveDraft();
                break; 
            case 1:
                CEPhotosRef.current.saveDraft();
                break; 
            case 2:
                CEWaiversRef.current.saveDraft();
                break; 
            case 3:
                CEAdvancedSettingRef.current.saveDraft();
                break; 
            case 4:
                CETicketsRef.current.saveDraft();
                break; 
            case 5:
                CEDateAndTimesRef.current.saveDraft();
                break; 
            // default:
            //     submitEvent(draftEvent)
            //     return `Preview: ${JSON.stringify(draftEvent)}`;
        }

        if(!state || !state.eventId)
            createOrUpdateDraft();
    }

    const handleNext = () => {
        onSaveDraft();
        if (activeStep < steps.length) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handlePreview = () => {
        onSaveDraft();
        // if (activeStep < steps.length) {
        //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }
        setPreview(true)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleSavedClickOpen = () => {
        onSaveDraft();
        setOpenSavedDialog(true);
    };

    const handleSavedCloseDialog = () => {
        setOpenSavedDialog(false);
    };

    
    const renderHeader = () => {
        return (
            <Box className={classes.header}>
                <Button
                    startIcon={<ArrowBackIosIcon />}
                    className={classes.createEventButton}
                    size="small"
                    onClick={handleClickOpen}
                >
                    Create Event
                </Button>
                <Dialog
                    open={open}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <Box textAlign='center'>
                            <Typography>
                                {mode !== CEMode.EDIT_REJECTED && 'You didn’t save your draft.'}
                                {mode === CEMode.EDIT_REJECTED && 'Leave will lose your change'}
                            </Typography>
                            <Typography>
                                Do you still want to leave without saving?
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'center'}}>
                        <Button 
                            style={{textTransform: 'none', border: "1px solid #5566F6", borderRadius: '21px', color: '#5566F6'}}
                            variant="outlined" 
                            onClick={handleCloseDialog} 
                            component={Link} to={{pathname: `/operator_center`}}
                        >
                            Leave anyway
                        </Button>
                        {mode !== CEMode.EDIT_REJECTED &&                  
                            <Button
                                style={{textTransform: 'none', border: "1px solid #5566F6", borderRadius: '21px', color: '#5566F6'}}
                                variant="outlined" 
                                onClick={() => onSaveDraft()}
                                component={Link} to={{pathname: `/operator_center`}}
                                autoFocus
                            >
                                Save as draft
                            </Button>
                         }
                    </DialogActions>
                </Dialog>
                {mode !== CEMode.EDIT_REJECTED &&  
                                <Button
                                variant="outlined"
                                className={classes.saveAsDraftButton}
                                size="small"
                                onClick={handleSavedClickOpen}
                            >
                                Save as draft
                            </Button>
                }
                <Dialog
                    PaperProps={{
                        classes: {
                            root: classes.backDrop
                        }
                    }}
                    open={openSavedDialog}
                    onClose={handleSavedCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <DialogContent>
                    <Box textAlign='center'>
                        <Typography style={{fontSize: '14px'}}>
                            Draft saved.
                        </Typography>
                    </Box>
                </DialogContent>
                </Dialog>
            </Box>
        )
    };

    const drawerHeader = (
        <Box className={classes.header}>
            <AppBar position="fixed" style={{ background: 'white', color: 'blue' }}>
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                    {steps[activeStep]}
                </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );

    const drawer = (
        <Box style={{ backgroundColor: '#F4F5FF', height: 'calc(100vh - 76px)', boxShadow: 'rgb(0 0 0 / 5%) -10px 0px 20px 0p'}}>
            <Stepper 
                activeStep={activeStep} 
                orientation="vertical" 
                nonLinear
                connector={null}
                style={{ backgroundColor: '#F4F5FF', color: 'blue', paddingLeft: '25px', paddingRight: 0, paddingTop: '60px' }}
            >
                {steps.map((label, index) => (
                    <Step style={{ zIndex: 102}} key={label}>
                        <StepLabel
                            classes={{
                                root: activeStep === index ? classes.labelActiveRoot : classes.labelRoot, 
                                iconContainer: activeStep > index ? classes.iconCompleted : classes.iconContainer, 
                                labelContainer: activeStep > index ? classes.labelCompleted : classes.labelContainer}}
                        >
                            {label}
                        </StepLabel>
                        <StepContent classes={{root: classes.stepContentRoot}}>
                            <Typography>{getStepperStepContent(index)}</Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Box square elevation={3} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Box>
            )}
        </Box>
    );

    const renderBody = () => {
        return (
            <Box className={classes.body} >
                <Box className={classes.menuContainer}>
                    <Hidden smUp implementation="css">
                        {drawerHeader}
                        <Drawer
                            variant="temporary"
                            anchor='left'
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        {drawer}
                    </Hidden>
                </Box>

                <Box className={classes.bodyContainer} style={{ height:'calc(100vh - 76px)', overflowY: 'hidden', position: 'relative', zIndex: 101}}>
                    <Box style={{ height:'calc(100vh - 137px)', overflowY: 'scroll'}}borderBottom={1}>{getBodyStepContent({ 
                        "step": activeStep, 
                        draftEvent, 
                        setDraftEvent, 
                        setValidation, 
                        CEBasicInfoRef,
                        CEPhotosRef, 
                        CEWaiversRef, 
                        CEAdvancedSettingRef, 
                        CETicketsRef, 
                        CEDateAndTimesRef,
                        preview,
                        setPreview,
                        submitEvent,
                        mode
                    })}</Box>

                    <Container justify="center" className={classes.actionsContainer}>
                        <MobileStepper 
                            variant="dots"
                            steps={6}
                            position='static'
                            activeStep={activeStep}
                            classes={{root: classes.mobileStepperRoot, dot: classes.stepperDot, dotActive:classes.stepperActiveDot}}
                            nextButton={
                                activeStep < steps.length - 1 ?
                                <Button
                                    disabled={!stepValidation[activeStep]}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.nextButton}
                                    classed={{containedPrimary: classes.containedPrimary}}
                                >
                                    Next
                                </Button> :
                                <Button
                                    disabled={!stepValidation[activeStep]}
                                    variant="contained"
                                    color="primary"
                                    onClick={handlePreview}
                                    className={classes.nextButton}
                                    classed={{containedPrimary: classes.containedPrimary}}>
                                Preview
                                </Button>
                            }
                            backButton={
                                activeStep === 0 ? 
                                    <div></div>
                                    :
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                            }
                        />
                    </Container>
                </Box>

            </Box>
        )
    }

    return (
        <AuthConsumer>
            {({ isAuth, username, user, handle_logout }) => (
                <Paper elevation={0}>
                    {renderHeader()}
                    {renderBody()}
                </Paper>
            )}
        </AuthConsumer>
    )
}

export default CreateEventHome;

//this is front end data type and format
// {
//     "basicInfo": {
//         "eventName": "api test for timi",
//         "eventDescription": "this is EventDescription",
//         "placeType": "Indoor",
//         "eventCategoryName": "fishing",
//         "eventAddress": "Rose Drive 3rd St, Mountain View, CA. 95112",
//         "latitude": 20.32,
//         "longitude": 19.53
//     },
//     "photos": {
//         "files": ["https://nasu-app-file-bucket.s3.us-east-2.amazonaws.com/event-images/event_1595447810974.jpg"]
        
//     },
//     "waivers": {
//         "eventOrganizer": "nasu",
//         "eventWavier": "this is waiver template"
//     },
//     "advancedSetting": {
        
//         "eventComment": ["brain", "arm", "leg"],
//         "eventBody": "this is what you need to know this is what you need to know this is what you need to know",
//         "refundType": 1,
//         "refundInDate": 7,
//         "organizerCommitment": true,
//         "customerRequirement": true
//     },
//     "tickets": [
//         {
//             "gradeIdx": 0,   
//             "gradeName": "this is ticket name",
//             "gradeDescription": "this is ticket description",
//             "gradePrice": 500.2,
//             "gradeQuantity": 200,
           
//             "sellingCutOffDuration": 720,
//             "isUnlimitedGrade": true,
           
//             "gradeStartTime": "2021-03-01T08:00:00-05:00",
//             "gradeDuration": 560
           
           
//         },
        
//         {
//             "gradeIdx": 1,
//             "gradeName": "ticket two",
//             "gradeDescription": "this is ticket two description",
//             "gradePrice": 102.2,
//             "gradeQuantity": 30,
           
//             "sellingCutOffDuration": 38,
//             "isUnlimitedGrade": false,
//             "gradeStartTime": "2021-03-10T18:00:00-05:00",
//             "gradeDuration":560
           
           
            
//         }
//     ],
  
//     "dateAndTimes": [
//         {
//             "gradeIdx": 0,
//             "eventStartTime": "2021-03-01T08:00:00-05:00",
//             "eventEndTime": "2021-03-10T08:30:00-04:00",
//             "isSpecificDate": false
//         },
//         {
//             "gradeIdx": 1,
//             "eventStartTime": "2021-03-15T18:00:00-05:00",
//             "eventEndTime": "2021-03-24T18:00:00-04:00",
//             "isSpecificDate": true
//         }
//     ]
   
// }

