import React, {useState, useEffect} from 'react';
import { makeStyles, OutlinedInput,TextField, Button, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width:'800px',
    height:'495px',
    backgroundColor:'#FFFFFF',
  },
  headerContainer:{
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center',
    marginTop:'-30px',
    marginBottom: '18px',
    fontSize:'16px',
    lineHeight:'19px',
    fontWeight: 300,
  },
  divideLine: {
    width: '100%',
    borderTop:'0.5px solid #D9D9D9',
  },
  bodyContainer: {
    padding: '20px 50px',
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center',
  },
  introText: {
    fontSize:'12px',
    lineHeight:'16px',
    fontWeight: 300,
  },
  helperText: {
    fontSize:'12px',
    lineHeight:'16px',
    fontWeight: 300,
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'flex-end', 
    position: 'absolute',
    bottom: 0,
    right: -10,
  },
  noteText: {
    width:'390px',
    fontSize:'12px',
    lineHeight:'15px',
    fontWeight: 300,
    color:"#525252",
    opacity: 0.77,
    marginBottom:'15px',
  },
  errorMessage: {
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'flex-end', 
    color:'red',
    fontSize:'12px'
  },
  sentButton: {
    width: '338px',
    height:'45px',
    borderRadius:'30px',
    fontSize: '16px',
    textTransform:'none',
    color: '#FFF',
    backgroundColor:'#5666F6',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor:'#FFF',
      color:'#5666F6',
      border:'1px solid #5666F6',
    },
    '&:disabled': {
      backgroundColor:'#CDCFE2',
      color: '#FFF',
      color: '#FFF',
    }
  },
  rowDispaly: {
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'space-between',
    alignItems: 'center',
    marginTop:'20px',
    width:'100%'
  },
  labelText: {
    fontWeight: "300",
    fontSize: "12px",
    lineHeight:'14px',
    color:'#000000',
    marginBottom:'8px',
  },
  errorMessage: {
    color: "#FF6565",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
  },
  selectIcon: {
    top: 'calc(50%-)',
    height: '25px',
    weight: '25px',
  },
  select:{
    "&:focus": {
        backgroundColor: "#fff",
    }
  },
  introText: {
    fontSize:'12px',
    lineHeight:'14px',
    fontWeight: 300,
  },
  textField: {
    width: '100%',
  },
}))

const StyledInput = withStyles((theme) => ({
  root: {
    width: (props) => props.width || '100%',
    height: '45px',
    borderRadius: '7px',
  },
  input: {
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.03em',
  }
}))((props) => <OutlinedInput {...props}/>);

const CancelRequest = ({
  error,
  cancelObj,
  handleOnChange,
  validateCancel,
  validateRequired,
  handleSubmit,
  reasonsList,
  handleChange,
  handleImageChange,
  ...props
}) => {
  const [count, setCount] = useState(0);
  const classes = useStyles();

  const[hover, setHover] = useState(-1);

    const handleMouseOver = (idx) => {
        setHover(idx);
    }
    
    const handleMouseOut  = (idx) => {
        setHover(idx);
    }

    const removeImg =(index) => {
        const arr = cancelObj.images.filter((item, idx) => idx !== index);
        handleImageChange(arr);
    }

  return (
    <div className={classes.dialogContainer}>
      <div className={classes.headerContainer}>
        Cancel request
      </div>
      <div className={classes.divideLine}></div>

      <div className={classes.bodyContainer}>
        <div className={classes.introText}>
          You can only cancel event by the following reasons without penalty. If you have other reason, feel free to contact us.        
        </div>
        <div className={classes.rowDispaly}>
          <div style={{width:'47%'}}>
            <div className={classes.labelText}>
                Email Address
            </div>
            <StyledInput
              id="email"
              name="email"
              value={cancelObj.email}
              onChange={(e) => handleOnChange(e, validateRequired)}
              error={!!error.email}
            />
            {error.email && 
              <div className={classes.errorMessage}>{error.email}</div>
            }
          </div>
          <div style={{width:'47%'}}>
            <div className={classes.labelText}>
              Phone number
            </div>
            <StyledInput
              id="phone"
              name="phone"
              value={cancelObj.phone}
              onChange={(e) => handleOnChange(e, validateRequired)}
              error={!!error.phone}
            />
            {error.phone && 
              <div className={classes.errorMessage}>{error.phone}</div>
            }
          </div>
        </div>
        <div className={classes.rowDispaly}>
        <div style={{width:'100%'}}>
            <div className={classes.labelText}>
              Reason for cancellation
            </div>
              <Select 
                name="reason"
                value={cancelObj.reason}
                onChange={(e) => handleOnChange(e, validateRequired)}
                variant="outlined" 
                classes={{icon: classes.selectIcon, select: classes.select}} 
                input={<StyledInput />}
              >
                {reasonsList.map((option) => (
                  <MenuItem key={option.id} value={option.key}>
                      {option.name}
                  </MenuItem>
                ))}
              </Select>
              {error.reason && 
              <div className={classes.errorMessage}>{error.reason}</div>
            }
          </div>
        </div>     
        <div className={classes.rowDispaly}>
          <div style={{width:'100%'}}>
            <div className={classes.labelText}>
              Details
            </div>
            <div style={{marginBottom:'10px', width:'100%'}}>
              <TextField
                name="details"
                id="details"
                value={cancelObj.details}
                helperText={`${count}/300`}
                multiline
                rows={3}
                variant="outlined"
                inputProps={{ maxLength: 300, }}
                InputProps={{
                    classes:{
                        input: classes.introText,
                    }
                }}
                FormHelperTextProps={{
                  className: classes.helperText
                }}
                className={classes.textField}
                onChange={(e) => {
                  handleOnChange(e, validateRequired);
                  validateCancel(e.target.value, e.target.name);
                  setCount(e.target.value.length);
                }
              }
                error={!!error.details}
              />
              {error.details && <div className={classes.errorMessage} style={{display: 'flex', justifyContent:'flex-end', marginLeft: 'auto'}}>{error.details}</div>}
            </div>
          </div>
        </div>

        <div className={classes.rowDispaly}>
          <div style={{width:'100%'}}>
            <div className={classes.labelText}>
            Upload relevant images as evidence
            </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>    
                {cancelObj.images.length !== 0
                ?<div>
                    {cancelObj.images.map((obj,idx) => {
                        return(
                            <img key={idx} 
                              src={hover===idx ?require("../../../assets/delete.png") :URL.createObjectURL(obj)}
                              alt="upload file" 
                              style={{width: '80px', height:'80px', cursor:"pointer", marginLeft: '3px', marginRight: '3px', borderRadius:'5px'}} 
                              onClick={() => removeImg(idx)}
                              onMouseOver={() => handleMouseOver(idx)}
                              onMouseOut={() => handleMouseOut(-1)}
                            />
                        )   
                    })}
                    <label htmlFor="files">
                      <img src={require("../../../assets/addImage.png")} style={{width:'80px', height:'80px',marginLeft: '3px', marginRight: '3px', borderRadius:'5px'}}/>
                    </label>
                    <input id="files"  style={{visibility:"hidden"}} type="file" accept="image/x-png,image/gif,image/jpeg" onChange={handleChange} multiple />    
                </div>
                :<div style={{width:'100%'}}>
                    <label htmlFor="files">
                      <img src={require("../../../assets/addImage.png")} style={{width:'80px', height:'80px',marginLeft: '3px', marginRight: '3px', borderRadius:'5px'}}/>
                    </label>
                    <input id="files"  style={{visibility:"hidden"}} type="file" accept="image/x-png,image/gif,image/jpeg" onChange={handleChange} multiple />    
                </div>
                }
            </div>
          </div>
        </div>

        <div className={classes.noteText}>
          If this request is approved, your ticket at this time will be automatically cancelled. Your registered attendees will get a notification and a full refund.
        </div>
        <Button
          variant="contained"
          className={classes.sentButton}
          disabled={!(cancelObj.email && cancelObj.phone && cancelObj.reason && cancelObj.details && cancelObj.images.length > 0 && !error.email && !error.phone && !error.details)}
          size="medium"
          onClick={handleSubmit}
        >
            Send
        </Button>
      </div>
    </div>
  )
}

export default CancelRequest;