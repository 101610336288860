import React, {useState, forwardRef, useImperativeHandle} from "react";
import { TextField } from '@material-ui/core';
import { Left } from 'react-bootstrap/lib/Media';
import styles from  "./CEPhotos.module.css";
import { EventAction, CEMode } from "./constant"
// import {hoverImage} from "../../assets/Rectangle\ 484.png";

const instruction = {
    fontWeight: 300, 
    fontFamily:' -apple-system, BlinkMacSystemFont, sans-serif',
    width: '85%',
    marginTop: '-8px'
}

const photoContainer = {
    marginLeft: '15%',
    marginTop: '5%',
    width: '45%'
}
const CEPhotos = forwardRef((props, ref) => {
    const { draftEvent, setDraftEvent, setValidation, mode } = props;
    const setInitalPhotoObjs = () => {
        if(!draftEvent.photos.fileObj)
            return [];

        const val = draftEvent.photos.fileObj.filter(obj => obj instanceof File )
        if(val.length === 0)
            return [];

        return draftEvent.photos.fileObj;
    }

    const [fileObj, setFileObj] = useState(setInitalPhotoObjs());
    const setInitalUrls = () => {
        if(!draftEvent.photos.urls)
            return [];

        return draftEvent.photos.urls;
    }
    const [urls, setUrls] = useState(setInitalUrls());
    const [fileArr, setFileArr] = useState([]);
    const[hover, setHover] = useState(-1);
    const[isDragOver, setIsDragOver] = useState(false);
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
    // useEffect(() => {
    //     if(fileObj.length > 0){
    //         setFileArr([...fileArr, URL.createObjectURL(fileObj[fileObj.length-1])]);
    //     }
    // }, [fileObj])
    
    
    const photoNameOnchange = (e) => {
        draftEvent.photos["eventImage"] = e.target.value;
        setDraftEvent(draftEvent)
    };
    const handleChange =  (e) => {
        if(e.target.files[0]){
            const file = e.target.files[0];
            setFileObj(prevObj =>  [...prevObj, file]);
        }
    }

    const handleMouseOver = (idx) => {
        // setImgUrl(url);
        setHover(idx);
    }
    
    const handleMouseOut  = (idx) => {
        // setImgUrl(url);
        setHover(idx);
    }

    const removeImg =(index) => {
        // console.log("------it is in remove func now!-------");
        const arr = fileObj.filter((item, idx) => idx !== index);
        // console.log(arr);
        // setFileArr(arr);
        setFileObj(arr);
    }

    const urlsLength = () => urls.filter(u => u.action !== EventAction.DELETE).length;

    const removeUrl = (url) => {
        urls.forEach(u => {
            if(u.id === url.id) 
                u.action = EventAction.DELETE
        });
        setUrls(urls);
    }

    const handleDrag = (e) => {
        e.preventDefault();
        // console.log(e);
        setIsDragOver(true);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        const len = fileObj.length;
        for(let i = 0; i < Math.min(9-len, files.length); i++){
            if(fileObj.length < 9){
                if (validTypes.indexOf(files[i].type) !== -1) {
                    setFileObj(prevObj =>  [...prevObj, files[i]]);
                }
            }       
        }
        
        setIsDragOver(false);
    }

    function checkInfoValidation() {
        if ((mode === CEMode.CREATE_DRAFT || mode === CEMode.EDIT_DRAFT) &&  fileObj.length >= 1 && fileObj.length <= 9) {
            setValidation(true);
            return;
        } else if (mode === CEMode.EDIT_REJECTED &&  (fileObj.length + urlsLength() >= 1 && fileObj.length + urlsLength() <= 9)) {
            setValidation(true);
            return;
        }
            
        setValidation(false);
    }

    useImperativeHandle(ref, () => ({
        saveDraft() {       
            draftEvent.photos = {fileObj: fileObj, urls: urls};
            setDraftEvent(draftEvent)
        }
    }))
    return (
        <div style={photoContainer}>
            <p style={{fontSize: '22px',color: '#363f91',fontWeight: '300', fontFamily:' -apple-system, BlinkMacSystemFont, sans-serif'}}>Please upload photos for your event.</p>
            <p style={instruction}>- You need to upload 1-9 high resolution photos which accurately reflect your event. </p>
            <p style={instruction}>- Photos you choose should visually describe your event location, action and details.</p>
            <p style={instruction}>- The first photo is used as the cover image by default.</p>
            <p style={instruction}>- Remember to avoid copyrighted work.</p>

            <div className={isDragOver ?styles.uploadContainerWithDrag :styles.uploadContainer}
                // style={{border: '1px dashed #C5C5C5', borderRadius: "10px", marginBottom: '20px', marginTop: '40px', width:'100%', minHeight: "500px"}}
                onDragOver={e => handleDrag(e)}
                onDrop={e => handleDrop(e)}    
            >
                {fileObj.length !== 0 || urlsLength() !== 0
                // gridColumnGap:'10px', gridRowGap: '20px',
                ?<div className={styles.displayPhoto}>
                      {urls.filter(u => u.action !== EventAction.DELETE)
                      .map((url,idx) => {
                        return(
                            // <div>
                            <img key={idx} 
                                // src={URL.createObjectURL(obj)} 
                                src={hover===idx ? require("../../assets/delete\ btn+background.png") : url.imageUrl}
                                alt="upload file" 
                                style={{width: '190px', height:'133px', cursor:"pointer", marginLeft: 'auto', marginRight: 'auto', }} 
                                onClick={() => removeUrl(url)}
                                onMouseOver={() => handleMouseOver(idx)}
                                onMouseOut={() => handleMouseOut(-1)}
                                
                            >
                            </img>
                            // {/* {hover===idx && <button style={{color:'white', marginTop:'60px', height: '40px', width:'100px', borderColor:'white', backgroundColor: 'transparent'}}>Deleted</button>} */}
                            // {/* </div> */}
                        )
                        
                    })}
                    {fileObj.map((obj,idx) => {
                        return(
                            // <div>
                            <img key={idx + urlsLength()} 
                                // src={URL.createObjectURL(obj)} 
                                src={hover===(idx +urlsLength()) ?require("../../assets/delete\ btn+background.png") :URL.createObjectURL(obj)}
                                alt="upload file" 
                                style={{width: '190px', height:'133px', cursor:"pointer", marginLeft: 'auto', marginRight: 'auto', }} 
                                onClick={() => removeImg(idx)}
                                onMouseOver={() => handleMouseOver(idx + urlsLength())}
                                onMouseOut={() => handleMouseOut(-1)}
                                
                            >
                            </img>
                            // {/* {hover===idx && <button style={{color:'white', marginTop:'60px', height: '40px', width:'100px', borderColor:'white', backgroundColor: 'transparent'}}>Deleted</button>} */}
                            // {/* </div> */}
                        )
                        
                    })}
                    {fileObj.length < 9
                    ?<div style={{backgroundColor: '#FAFAFA', width: '190px', height:'133px', marginLeft: 'auto', marginRight: 'auto'}}>
                        <div 
                        style={{width: '90%', height:'133px', }}
                        >
                            <label htmlFor="files" className={styles.uploadPhotoWithExists}>
                            </label>
                            <input id="files"  style={{visibility:"hidden"}} type="file" accept="image/x-png,image/gif,image/jpeg" onChange={handleChange} multiple />
                        </div>
                        
                       
                    </div>
                    :null}
                </div>
                :<div style={{width: '100%', marginTop: '200px', textAlign: 'center'}}>
                    <div>
                    <label htmlFor="files" className={styles.uploadPhoto}></label>
                    <input id="files"  style={{visibility:"hidden"}} type="file" accept="image/x-png,image/gif,image/jpeg" onChange={handleChange} multiple />
                    </div>
                   
                    <p style={{marginTop: '-30px'}}>Drag/drop/browse 3-9 images </p>
                    
                </div>
                }
                {checkInfoValidation()}
            </div>
        </div>
    )
})

export default CEPhotos;