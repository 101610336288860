import React, { useState } from 'react';
import { useHistory, useParams} from "react-router-dom";
import {
    Box, 
    IconButton, 
    makeStyles,
    Step, Stepper, StepContent, StepLabel,
    Typography,
    Button, Container
} from '@material-ui/core';
import styles from './EditEvent.module.css';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import activeSidebar from '../../assets/activeSidebar.png';
import MobileStepper from '@material-ui/core/MobileStepper';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
    },
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        width: 299,
        minHeight: '1440px',
        backgroundColor: '#F4F5FF',
    },
    steps: {
        fontSize: 15, 
        fontWeight: '700',
        color: '#CDCFE2',
        marginLeft: 24,
    },
    activeStep: {
        width: 299,
        height: 90,
        backgroundImage: `url(${activeSidebar})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row',
        alignItems:'center',
        marginTop: 21
    },
    previewButton: {
        width: 117,
        height: 40,
        borderRadius: 30,
        color: "#5666F6",
    },

    // calendar
    dayPickerContainer: {
        height: 384,
        width: 384,
        marginTop: 46,
        borderWidth: 0.7,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        borderStyle: 'solid',
        borderColor: '#101010',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    specialDayPickerContainer:{
        height: 384,
        width: 384,
        marginTop: 0,
        borderWidth: 0.7,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        borderStyle: 'solid',
        borderColor: '#101010',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    regularDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#F5A626",
        borderTopLeftRadius: 28,
        borderColor: 'transparent',
        fontSize: 14,
        fontWeight: "700",
        color: "#FFFFFF",
        boxShadow: "0px 8px 7px rgba(213, 169, 104, 0.34)",
        float: 'left',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '44px',
        outline: 'none'
    },
    specialDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#E3E3E3",
        borderTopRightRadius: 28,
        borderColor: 'transparent',
        // fontSize: 14,
        // fontWeight: "700",
        // color: "#FFFFFF",
        float: 'left',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '44px',
        outline: 'none'
    },
    optionalIcon: {
        width: '53px',
        height: '15px',
        marginTop: '5px',
        backgroundColor: "#F1F1F1",
        borderRadius: 28,
        borderColor: 'transparent',
        fontSize: 10,
        fontWeight: "700",
        color: "#FFFFFF",
        float: 'right',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '15px',
        float: 'left',
        marginLeft: -30
    },
    onRegularDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#E3E3E3",
        borderTopLeftRadius: 28,
        borderColor: 'transparent',
        fontSize: 14,
        fontWeight: "700",
        color: "#FFFFFF",
        // boxShadow: "0px 8px 7px rgba(213, 169, 104, 0.34)",
        float: 'left',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '44px',
        outline: 'none'
    },
    onSpecialDateButton: {
        height: 44,
        width: 192,
        backgroundColor: "#5666F6",
        borderTopRightRadius: 28,
        borderColor: 'transparent',
        // fontSize: 14,
        // fontWeight: "700",
        // color: "#FFFFFF",
        boxShadow: "0px 8px 15px rgba(86, 102, 246, 0.26)",
        float: 'left',
        display: 'inline',
        textAlign: 'justify',
        lineHeight: '44px',
        outline: 'none',
    },
    onOptionalIcon: {
        width: '53px',
        height: '15px',
        marginTop: '5px',
        backgroundColor: "#DEE4FF",
        borderRadius: 28,
        borderColor: 'transparent',
        fontSize: 10,
        fontWeight: "700",
        color: "#5666F6",
        float: 'right',
        display: 'inline',
        textAlign: 'center',
        lineHeight: '15px',
        float: 'left',
        marginLeft: -30,
    },
    numOfSelected: {
        width: 100,
        // height: 51,
        fontSize: 14,
        fontWeight: '700',
        color: "#000000",
        // marginLeft: 127.5,
        marginTop: 10,
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '800px',
        minWidth: '375px',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    createEventButton: {
        left: '5%',
        marginTop: '1%',
        marginBottom: '1%',
    },
    saveAsDraftButton: {
        left: '55%',
        right: '5%',
        marginTop: '1%',
        marginBottom: '1%',
    },
    menuContainer: {
        flex: 1,
    },
    bodyContainer: {
        flex: 5,
        width: '100%', 
        boxShadow: 'rgb(0 0 0 / 5%) -10px 0px 20px 0px'
    },
    labelRoot: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        paddingLeft: '25px',
        height: '55px',
    },
    labelActiveRoot: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        paddingLeft: '25px',
        height: '55px',
        backgroundColor: 'white',
        borderTopLeftRadius:'30px',
        borderBottomLeftRadius: '30px',
    },
    iconCompleted:{
        '& .MuiStepIcon-root':{
            color: '#5666F6',
            width: '23px',
            height: '23px',
        },
        '& .MuiStepIcon-text': {
            fontWeight: 600,
            fontSize: '10px',
        },
        paddingRight: '15px',
    },
    iconContainer: {
        '& .MuiStepIcon-root.MuiStepIcon-active':{
            color: '#5666F6',
            width: '23px',
            height: '23px',
        },
        '& .MuiStepIcon-root': {
            color: '#CDCFE2',
            width: '23px',
            height: '23px',
        },
        '& .MuiStepIcon-text': {
            fontWeight: 600,
            fontSize: '10px',
        },
        paddingRight: '15px',
    },
    labelCompleted:{
        '& .MuiStepLabel-label': {
            fontSize: '15px',
            fontWeight: 700,
            color: '#5666F6',
        },
        color: '#5666F6',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    labelContainer: {
        '& .MuiStepLabel-label': {
            fontSize: '15px',
            fontWeight: 700,
            color: '#CDCFE2',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            fontSize: '15px',
            fontWeight: 700,
            color: '#5666F6',
        },
        color: '#5666F6',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    stepContentRoot: {
        paddingTop: '2px',
        paddingLeft: '63px', // iconWidth+iconPaddingRight+labelRootPaddingLeft
        borderLeft: 0,
        marginLeft: 0,
    },
    mobileStepperRoot: {
        minWidth: '700px',
        backgroundColor: 'white',
        paddingLeft: '30px',
        paddingTop: '12px',
        "& .MuiButton-containedPrimary:hover": {
            backgroundColor:'#5666F6',
        },
    },
    stepperDot: {
        margin: '0 4px',
        width: '7px',
        height: '7px',
    },
    stepperCompletedDot: {
        margin: '0 4px',
        width: '7px',
        height: '7px',
        backgroundColor: '#5666F6',
    },
    stepperActiveDot: {
        backgroundColor: '#5666F6',
    },
    disabledButton: {
        border: "1px solid #CDCFE2",
        borderRadius: "30px",
        height: "40px",
        width: "95px",
        fontSize: "16px",
        textTransform: 'none',
        color: '#CDCFE2'
    },
    backButton: {
        border: "1px solid #5666F6",
        borderRadius: "30px",
        height: "40px",
        width: "95px",
        fontSize: "16px",
        textTransform: 'none',
        color: '#5666F6',
    },
    nextButton: {
        textTransform: 'none',
        backgroundColor: '#5666F6',
        borderRadius: "30px",
        height: "40px",
        width: "95px",
        fontSize: "16px",
        color: 'white',
    },
    containedPrimary: {
        '&:hover': {
            backgroundColor:'#5666F6',
        },
    }
}));

function getSteps() {
    return ['Basic Info', 'Photos', 'Waivers', 'Advanced Setting', 'Tickets', 'Date and times'];
};

const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};
export const ArrowLeft = () => {
    return (
        <div className={styles.arrowPrev}>
            <ArrowBackIosSharpIcon />
        </div>
    );
};

// const ArrowLeft = Arrow({ text: '<', className: styles.arrowPrev });
const ArrowRight = Arrow({ text: '>', className: styles.arrowNext });

//weekdayElement & navbarElement
function Weekday({ weekday, className, localeUtils, locale }) {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
        <div className={className} title={weekdayName} style={{ color: '#4A5CFF', opacity: 0.41 }}>
            {weekdayName.slice(0, 1)}
        </div>
    );
}

function YearMonthForm({ date, localeUtils }) {
    return (
        <div style={{ fontSize: 19 }}></div>
    )
}

function Navbar({
    month,
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    localeUtils,
}) {
    const months = localeUtils.getMonths();
    const prev = months[previousMonth.getMonth()];
    const next = months[nextMonth.getMonth()];
    const now = months[month.getMonth()];
    const styleLeft = {
        float: 'right',
        backgroundColor: "transparent",
        outline: "none",
        border: "none",
        color: '#5666F6'
    };
    const styleRight = {
        float: 'right',
        backgroundColor: "transparent",
        outline: "none",
        border: "none",
        color: '#5666F6'
    };
    const monthStyle = {
        fontSize: 19,
        color: '#4A5CFF',
        marginLeft: 10,
    }
    return (
        <div className={className}>
            <span style={monthStyle}>{now}</span>
            <button style={styleRight} onClick={() => onNextClick()}>
                {/* {next.slice(0, 3)} → */}
                <ArrowForwardIosSharpIcon />
            </button>
            <button style={styleLeft} onClick={() => onPreviousClick()}>
                {/* ← {prev.slice(0, 3)} */}
                <ArrowBackIosSharpIcon />
            </button>
        </div>
    );
}

var ticketsDates = [];
var ticketsCol = [];
const EditDate = (props) => {
    const { eventId } = useParams();
    const classes = useStyles();
    const handleNext = () => {
        onSubmitForm();
        history.push(`/manage_event/${eventId}`);
    };
    //for react-day-picker day cell style
    const modifiers = {
        days: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
        before: new Date(),
        entered: mouseEntered,
    };
    const Specialmodifiers = {
        days: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
        before: new Date(),
        entered: mouseEntered,
    };

    const modifiersStyles = {
        days: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: 19
        },
        entered: {
            backgroundColor: "#F5A626",
            opacity: 0.1
        },
        selected: {
            color: "white",
            backgroundColor: "#F5A626"
        },
        disabled: {
            color: "white",
            backgroundColor: "#5666F6",
            opacity: 0.25
        },
        before: {
            color: '#3E3E3E',
            opacity: 0.31,
        }
    };
    const specialDModifiersStyles = {
        days: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: 19
        },
        entered: {
            backgroundColor: "#5666F6",
            opacity: 0.1
        },
        selected: {
            color: 'white',
            backgroundColor: '#5666F6',
        },
        disabled: {
            color: "white",
            backgroundColor: "#F5A626",
            opacity: 0.25
        },
        before: {
            color: '#3E3E3E',
            opacity: 0.31,
        }
    }

    //for react-day-picker function
    const [mouseEntered, setMouseEntered] = useState(null);
    const handleDayMouseEnter = (day) => {
        const date = new Date(moment(day).format('YYYY,MM,DD'));
        setMouseEntered(date);
    }

    const [mouseLeaved, setMouseLeaved] = useState(null);
    const handleDayMouseLeave = (day) => {
        const date = new Date(moment(day).format('YYYY,MM,DD'));
        setMouseLeaved(date);
    }

    const [selectedRegularDays, setRegularDays] = useState([]);
    const [selectedSpecialDays, setSpecialDays] = useState([]);
    const handleRegularDayClick = (day, { selected, disabled }) => {
        if (disabled) {
            return
        }
        if (selected) {
            const selectedIndex = selectedRegularDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedRegularDays.splice(selectedIndex, 1);
            setRegularDays([...selectedRegularDays]);
            const datesIndex = ticketsDates.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay.date, day)
            );
            ticketsDates.splice(datesIndex, 1)
            ticketsCol.splice(datesIndex,1)
        } else {
            setRegularDays([...selectedRegularDays, day])
            ticketsDates.push({
                date: day,
                isSpecial: false,
            })
            ticketsCol.push({
                date: moment(day).format()
            })

        }
    }
    const handleSpecialDayClick = (day, { selected, disabled }) => {
        if (disabled) {
            return
        }
        if (selected) {
            const selectedIndex = selectedSpecialDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedSpecialDays.splice(selectedIndex, 1);
            setSpecialDays([...selectedSpecialDays]);
            const datesIndex = ticketsDates.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay.date, day)
            );
            ticketsDates.splice(datesIndex, 1)
            ticketsCol.splice(datesIndex,1)
        } else {
            setSpecialDays([...selectedSpecialDays, day])
            ticketsDates.push({
                date: day,
                isSpecial: true,
            })
            ticketsCol.push({
                date: moment(day).format()
            })
        }
    }

    const isRegularDay = (day) => {
        return selectedRegularDays.some(disabledDay =>
            DateUtils.isSameDay(day, disabledDay)
        )
    }
    const isSpecialDay = (day) => {
        return selectedSpecialDays.some(disabledDay =>
            DateUtils.isSameDay(day, disabledDay)
        )
    }

    const [regularDates, setRegularDates] = useState(true);
    const handleRegularDates = () => {
        setRegularDates(true)
    }
    const handleSpecialDates = () => {
        setRegularDates(false)
    }

    // back to operator center
    const history = useHistory();

    const routeChange = () =>{ 
        let path = {pathname: `/operator_center`}; 
        history.push(path);
    }
    const onSubmitForm =() =>{
        ticketsCol.map((obj)=> {
            console.log(obj);
        axios
            .post(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/edit_date/`,{
                eventId: eventId,
                date: obj.date,
            }).then(res=>console.log(res)).catch(err=>console.log(err));
        }
        )
    }
    const renderHeader =() => {
        return (
            <Box className={classes.header}>
                <IconButton
                    onClick={routeChange}
                    style={{ marginLeft: 30 }}
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <div style={{fontSize: 16, fontWeight: '400', color: '#000000', marginLeft: 20 }}>Edit date</div>
            </Box>
        )
    }

    const renderSideBar = () => {
        return (
            <div className={classes.sideBar} >
                <div style={{dispaly: 'flex', flexDirection:' row', alignItems: 'center', marginTop: 121 }}>
                    <img src={require('../../assets/step1.png')} style={{marginLeft: '20%'}}/>
                    <span className={classes.steps}>Basic Info</span>
                </div>
                <div style={{dispaly: 'flex', flexDirection:' row', alignItems: 'center', marginTop: 42 }}>
                    <img src={require('../../assets/step2.png')} style={{marginLeft: '20%'}}/>
                    <span className={classes.steps}>Photos</span>
                </div>
                <div style={{dispaly: 'flex', flexDirection:' row', alignItems: 'center', marginTop: 42 }}>
                    <img src={require('../../assets/step3.png')} style={{marginLeft: '20%'}}/>
                    <span className={classes.steps}>Waviers</span>
                </div>
                <div style={{dispaly: 'flex', flexDirection:' row', alignItems: 'center', marginTop: 42 }}>
                    <img src={require('../../assets/step4.png')} style={{marginLeft: '20%'}}/>
                    <span className={classes.steps}>Advanced Setting</span>
                </div>
                <div style={{dispaly: 'flex', flexDirection:' row', alignItems: 'center', marginTop: 42 }}>
                    <img src={require('../../assets/step5.png')} style={{marginLeft: '20%'}}/>
                    <span className={classes.steps}>Tickets</span>
                </div>
                <div className={classes.activeStep}>
                    <img src={require('../../assets/step6.png')} style={{ marginLeft: 44}}/>
                    <span style={{fontSize: 15, fontWeight: '800', color: '#5666F6', marginTop: -15, marginLeft: 10}}>Date and time</span>
                </div>
            </div>
        ) 
    }
    const renderBody = () => {
        return (
            <div style={{ marginTop: 73, marginLeft: 200 }}>
                {
                    regularDates == true ?
                        <div>
                            <p style={{ fontSize: 20, color: "#212759", fontWeight: "400", marginTop: 20, }}>
                                Select regular date
            </p>
                            <p style={{ fontSize: 12, fontWeight: "300", color: "#000000", width: 600 }}>
                                Please choose regular dates below for your event.
                                After select all you regular dates, you can preview all your event details and then submit it to NASUFUN.
            </p>
                        </div>
                        :
                        <div>
                            <p style={{ fontSize: 20, color: "#212759", fontWeight: "400", marginTop: 20, }}>
                                Add special date (Optional)
            </p>
                            <p style={{ fontSize: 12, fontWeight: "300", color: "#000000", }}>
                                if there are any different tickets you would like to add (e.g. special time on weekends, different from the original one),you can set it separately by adding different tickets on the calendar.
            </p>
                        </div>
                }

                {
                    regularDates == true ?
                        <div>
                            <div style={{ marginTop: 20 }}>
                                <button
                                    className={classes.regularDateButton}
                                    onClick={handleRegularDates}
                                >
                                    <span>
                                        Select Regular date
                                </span>
                                </button>
                                <button
                                    className={classes.specialDateButton}
                                    onClick={handleSpecialDates}
                                >
                                    <span style={{
                                        width: 124,
                                        float: 'left',
                                        fontSize: 14,
                                        fontWeight: "700",
                                        color: "#FFFFFF",
                                    }}>
                                        Add Special date
                                    </span>
                                    <span style={{
                                        width: 50,
                                        // float: 'right',
                                        fontSize: 9,
                                        fontWeight: "500",
                                        color: "#FFFFFF",
                                        marginLeft: -6
                                    }}>
                                        (Optional)
                                    </span>
                                </button>
                                {/* <div className={classes.optionalIcon}>
                                    <span>
                                        Optional
                                </span> */}
                                {/* </div> */}
                            </div>
                            <div className={classes.dayPickerContainer}>
                                <DayPicker
                                    modifiers={modifiers}
                                    modifiersStyles={modifiersStyles}
                                    onDayClick={handleRegularDayClick}
                                    selectedDays={selectedRegularDays}
                                    disabledDays={isSpecialDay}
                                    weekdayElement={<Weekday />}
                                    navbarElement={<Navbar />}
                                    captionElement={({ date, localeUtils }) => (
                                        <YearMonthForm
                                            date={date}
                                            localeUtils={localeUtils}
                                        />
                                    )}
                                // onDayMouseEnter={handleDayMouseEnter}
                                // onDayMouseLeave={handleDayMouseLeave}
                                />
                            </div>
                        </div>
                        :
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div style={{ marginTop: 20}}>
                                <button
                                    className={classes.onRegularDateButton}
                                    onClick={handleRegularDates}
                                >
                                    <span>
                                        Select Regular date
                                </span>
                                </button>
                                <button
                                    className={classes.onSpecialDateButton}
                                    onClick={handleSpecialDates}
                                >
                                    <span style={{
                                        width: 124,
                                        float: 'left',
                                        fontSize: 14,
                                        fontWeight: "700",
                                        color: "#FFFFFF",
                                    }}>
                                        Add Special date
                                    </span>
                                    <span style={{
                                        width: 50,
                                        // float: 'right',
                                        fontSize: 9,
                                        fontWeight: "500",
                                        color: "#FFFFFF",
                                        marginLeft: -6
                                    }}>
                                        (Optional)
                                    </span>
                                </button>
                                {/* <div className={classes.onOptionalIcon}>
                                    <span>
                                        Optional
                                </span>
                                </div> */}
                            </div>
                            <div className={classes.specialDayPickerContainer}>
                                <DayPicker
                                    // month={new Date()}
                                    modifiers={Specialmodifiers}
                                    modifiersStyles={specialDModifiersStyles}
                                    onDayClick={handleSpecialDayClick}
                                    selectedDays={selectedSpecialDays}
                                    disabledDays={isRegularDay}
                                    weekdayElement={<Weekday />}
                                    navbarElement={<Navbar />}
                                    captionElement={({ date, localeUtils }) => (
                                        <YearMonthForm
                                            date={date}
                                            localeUtils={localeUtils}
                                        />
                                    )}
                                // onDayMouseEnter={handleDayMouseEnter}
                                />
                            </div>
                        </div>
                }
            </div>
        )
    }
    const renderBodyWithFooter = () => {
        return (
            <Box className={classes.body} >

                <Box className={classes.bodyContainer}>
                    <Box style={{ height: '90%' }} borderBottom={1}>{renderBody()}</Box>

                    <Container justify="center" className={classes.actionsContainer}>
                        <MobileStepper 
                            variant="dots"
                            steps={7}
                            position='static'
                            activeStep={6}
                            classes={{root: classes.mobileStepperRoot, dot: classes.stepperDot, dotActive:classes.stepperActiveDot}}
                            nextButton={
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.nextButton}
                                    classed={{containedPrimary: classes.containedPrimary}}
                                >
                                    Update
                                </Button>
                            }
                            backButton={
                                <Button
                                    onClick={routeChange}
                                    className={classes.backButton}
                                >
                                    Back
                                </Button>
                            }
                        />
                    </Container>
                </Box>

            </Box>
        )
    }


    return (
        <div>
            {renderHeader()}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '100%'}}>
                {renderSideBar()}
                {renderBodyWithFooter()}
            </div>
        </div >
    )
}

export default EditDate;
