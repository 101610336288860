import {useState} from 'react';

const usePopOver = (initVisit = false) => {
  const [visible, setVisible] = useState(initVisit || false);
  const open = () => {
    setVisible(true);
  }
  const close = () => {
    setVisible(false);
  };
  return [visible, open, close];
};

export default usePopOver;