import React, { useState, useEffect } from 'react';
import { Box, Button, makeStyles,Paper, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { ArrowBack, ContactSupport } from '@material-ui/icons/';
import { usePopOver, UseDialog } from './commons';
import PersonInfo from './personalInfo';
import NotifyAll from './NotifyAll';
import CancelRequest from './CancelRequest';
import avatarPNG from '../../assets/avatar.png';
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import axios from 'axios';
import { format } from 'date-fns';
// import { keys } from '@material-ui/core/styles/createBreakpoints';
// import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
// import { validate } from '@material-ui/pickers';


function getDataUrl(img) {
  // Create canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // Set width and height
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw the image
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL('image/jpeg');
}
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: "100px",
    paddingLeft:'70px',
    height: '59px',
    alignItems: 'center',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
  },
  manageGuestButton: {
    left: '5%',
    marginTop: '1%',
    marginBottom: '1%',
    fontSize:'14px',
    textTransform: 'none',
  },
  ticketCancelText: {
    marginLeft: '22px',
    width:'122px',
    height:'20px',
    backgroundColor:'#EEEEEE',
    borderRadius:'7px',
    padding:'3px 15px',
    fontSize:'12px',
    lineHeight:'16px',
  },
  notifyAllButton: {
    marginRight: '40px',
    border: "1px solid #212759",
    borderRadius: '30px',
    textTransform: 'none',
    height: '35px',
    width: '140px',
    fontSize:'14px',
    color:'#212759',
    '&:hover': {
      color: 'white',
      boxShadow: 'none',
      backgroundColor:'#5666F6',
      //color:'#5666F6',
      //border:'1px solid #5666F6',
    },
    '& img': {
      height:'13px',
      width:'13px',
      marginLeft:'15px',
      marginTop:'-2px',
    },
    '&:hover img': {
      filter: 'brightness(0) invert(1)',
    }
  },
  cancelTicketButton: {
    border: "1px solid #212759",
    borderRadius: '30px',
    height: '35px',
    width: '140px',
    textTransform: 'none',
    fontSize:'14px',
    color:'#212759',
    '&:hover': {
      //boxShadow: '10px 5px 5px red',
      color: 'white',
      backgroundColor:'#5666F6',
      //color:'#5666F6',
      //border:'1px solid #5666F6',
    },
    '& img': {
      height:'18px',
      width:'20px',
      marginLeft:'5px',
      marginTop:'-2px',
    },
    '&:hover img': {
      filter: 'brightness(0) invert(1)',
    }
  },
  bodyContainter: {
    padding: '40px 100px',
    width:'100%',
  },
  dateText: {
    fontSize: '16px',
    lineHeight:'19px',
    fontWeight: 'bold',
    color:'#5666F6',
    marginBottom:'12px',
  },
  topContainer: {
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'flex-start', 
  },
  cancelContainer: {
    width:'215px',
    borderRadius:'7px',
    backgroundColor:'#F5F5F5',
    marginLeft:'27px',
    padding:'0 23px',
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center',
    fontSize:'12px',
    marginBottom:'40px',
  },
  ticketContainer: {
    width: '600px',
    borderRadius:'7px',
    backgroundColor:'#F4F5FF',
    marginBottom:'40px',
    padding: '20px',
  },
  canceledTicketContainer: {
    width: '600px',
    borderRadius:'7px',
    backgroundColor:'#EEEEEE',
    marginBottom:'40px',
    padding: '20px',
  },
  ticketText:{
    fontSize:'14px',
    color:'#000',
    lineHeight:'17px',
    fontWeight: 500,
  },
  priceText: {
    fontSize:'18px',
    color:'#F5A626',
    lineHeight:'22px',
    fontWeight:'bold',
  },
  subText: {
    fontSize:'10px',
    lineHeight:'12px',
    fontWeight:300,
  },
  ticketContentText: {
    fontSize:'11px',
    lineHeight:'13px',
    width:'450px',
    wordBreak:'break-word',
    fontWeight:300,
    marginBottom:'10px',
  },
  noteText:{
    backgroundColor:'#FFF',
    borderRadius:'10px',
    fontSize:'10px',
    lineHeight:'12px',
    color:'#000',
    padding: '3px 10px',
    width:'fit-content',
  },
  tableContainer: {
    borderRadius:'15px',
    border:'1px sold #000000',
    boxShadow:'0px 24px 50px rgba(184, 185, 190, 0.35)',
    minHeight: '500px',
    width:'100%',

  },
  table: {
    position:'relative',
    borderCollapse:'collapse',
    width:'100%'
  },
  tableHeader: {
    backgroundColor:'#F2F2F2',
  },
  tableRow:{
    color:'#212759',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor:'#5666F6',
      //color:'#5666F6',
      //border:'1px solid #5666F6',
    },
  },
}))

const reasonsList = [
  {id:0 , key:'Weather Issue', name:'Weather Issue'},
  {id:1 , key:'Emergency Issue', name:'Emergency Issue'},
  {id:2 , key:'Attendees do not meet the minimum number', name:'Attendees do not meet the minimum number'},
]

const ManageGuest = (props) => {
  const classes = useStyles();
  const { eventId } = useParams();
  const [orders, setOrders] = useState([]);
  // const [currentRow, setCurrentRow] = useState();
  const [personInfo, openPersonInfo, closePersonInfo] = usePopOver();
  const [notifyAll, openNotifyAll, closeNotifyAll] = usePopOver();
  const [cancelRequest, openCancelRequest, closeCancelRequest] = usePopOver();
  const [personData, setPersonData] = useState();
  const [error, setError] = useState({});
  const [notify, setNotify] = useState('');
  const [cancelObj, setCancelObj] = useState({
    email: '',
    phone: '',
    reason: '',
    details: '',
    images: [],
  })
  const [successSent, setSuccessSent] = useState(false);
  const [successCancel, setSuccessCancel] = useState(false);
  const [cancelStep, setCancelStep] = useState(1);
  const history = useHistory();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    const { eventId, date, ticket: { grade } } = props.location.state;
    const dateId = date.id;
    const gradeId = grade.id;
    getOrders(eventId, dateId, gradeId);
  }, []);

  const checkStatusOnChange = (rowId) => {
    const newData = [...orders];
    const index = orders.findIndex((item) => item.id === rowId);
    newData[index].checkStatus = !orders[index].checkStatus;
    setOrders(newData);
  };

  const getOrders = async (eventId, dateId, gradeId) => {
    const body = { dateId, gradeId };
    const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/get_grade_order_by_date/`;
    let res = await axios
      .post(url, body);

    if (res.data && res.data.Success) {
        setOrders(res.data.data)
    }
  }

  const getOrder = async (orderId) => {
    //console.log(orderId)
    const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/order/${orderId}`;
    let res = await axios
      .get(url);

    if (res.data && res.data.Success) {
        setPersonData(res.data.data)
        openPersonInfo();
    }
  }

  const handleClickAvatar = (rowId) => {
    console.log(rowId)
    getOrder(rowId.id)
    // setCurrentRow(1);
    // call api to get person info to show popup
    // make sure if there is some registration info

    return;
  }

  const orderExport = (data) => {
    let objs = [...data];
    objs = objs.map(obj => {
     return {
      "Order_Number": obj.orderNumber,
      "Order_date": format(new Date(obj.created), 'yyyy-MM-dd HH:mm:ss'),
      "Buyer's_info": `${obj.userName}`,
      "Email":  obj.userEmail,
      /* TODO: this should be gradeprice * ticket number*/
      // "Ticket_total": obj.totalPrice,
      "Number_of_tickets": obj.ticketNumber,
      "Coupon_applied": obj.promotionCode,
      "Coupon_discount":  obj.promotionDiscount ? `$${Number.parseFloat(obj.promotionDiscount).toFixed(2)}` : '',
      "Tax":  `$${Number.parseFloat(0).toFixed(2)}`,
      "Buyer_paid":  `$${Number.parseFloat(obj.orderAmount).toFixed(2)}`
      };
    });
    const { date: { eventStartTime }, ticket: { grade: { gradeName }} } = props.location.state;
    const filename = `Order list-${gradeName}-${format(new Date(eventStartTime), 'LLL d, yyyy')}.csv`;
    download_as_csv(objs, filename);
  }

  const onCouponClick = (row) => {
    console.log('coupon cliked');
  }

  const addError = (key, msg) => {
    setError({...error, [key]:msg})
  }

  const validateNotify = (value, key) => {
    if(!value){
       
        addError(key, '*This is required');
        return;
    }
    if(value.length < 50){
      addError(key, '*Please input more than 50 characters');
        return;
    }
    addError(key, null);
    return true;
  }

  const validateCancel = (value, key) => {
    if(!value){
       
        addError(key, '*This is required');
        return;
    }
     if(value.length < 50){
      addError(key, '*Please input more than 50 characters');
        return;
    }
    if(value.length > 300){
      addError(key, '*Please input less than 300 characters');
        return;
    }
    addError(key, null);
    return true;
  }

  const validateRequired = (value, key) => {
    if(!value){
        addError(key, '*This is required');
        return;
    }
    addError(key, null);
    return true;
  }

  const handleOnChange = (e, validFunction) => {
    setCancelObj({...cancelObj, [e.target.name]:e.target.value});
    if(validFunction){
      validFunction(e.target.value, e.target.name)
    }
  }

  const handleImageChange = (value) => {
    setCancelObj({...cancelObj, images: [...value]})
  }

  const handleChange =  (e) => {
    if(e.target.files[0]){
      const file = e.target.files[0];
      const newImages = [...cancelObj.images, file];
      handleImageChange(newImages);
    }
  }

  const notifyOnChange = (e, validFunction) => {
    setNotify(e.target.value)
    
    //if(validFunction){
     // validFunction(e.target.value, e.target.name)
     // console.log(e.target.name)
    
    //}
  }
  const sendCancelRequest =() =>{
    let collection = [];
    //console.log(props.location.state.ticket.grade.gradeName)
    cancelObj.images.map((image, index) => {
      const formedUrl = sendFile2AWS(image);

      //console.log(formedUrl);
      collection.push(formedUrl);
    });
    //console.log(collection);

    
  
    const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/create_cancel_request/`;
    
    axios.post(url, {
        email: cancelObj.email,
        phone: cancelObj.phone,
        reason: cancelObj.reason,
        details: cancelObj.details,
        gradeName: props.location.state.ticket.grade.gradeName,
        images: collection,

        
      }).then(response=> console.log(response)).catch(error=>console.log(error));

   
    
  }

  const sendFile2AWS = (file) => {
    const type = file.type;
    
    const region = 'us-east-2';
    const now = Date.now();
    const urlKey = `grade_${props.location.state.ticket.grade.gradeName}_${now}.png`;
    const bucket = 'cancel-request';
    const formedUrl = `https://${bucket}.s3.${region}.amazonaws.com/${urlKey}`;
    var AWS = require('aws-sdk');
    var s3 = new AWS.S3({ accessKeyId: 'AKIASQ4WL5MSPIQKZLET', secretAccessKey: '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', region: region });
    var params = { Bucket: bucket, Key: urlKey, ContentType: type};
    
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const b64DataString = reader.result;
      const base64Data = new Buffer.from(b64DataString.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      s3.putObject(
          {...params,
            Body: base64Data,
            ContentEncoding: 'base64',
          }, (er, d) => {
            if(er) {
              console.log(er)
              return;
            }
            //console.log("UPLOAD SUCCESSFULLY:")
            
            
          }
        )
    });
    reader.readAsDataURL(file);
    return formedUrl;
    
  }

  const handleSubmitCancel = () => {
  
    closeCancelRequest()
    
    sendCancelRequest();
    // call submit cancel request api
    setSuccessCancel(true)
    setCancelStep(2)
    setCancelObj({
      email: '',
      phone: '',
      reason: '',
      details: '',
      images: [],
    })
    setError({})
  }

  const handleSubmitNotify = () => {
   
    // call submit notify api
    if(validateNotify(notify, 'notify')){
      closeNotifyAll()
      setSuccessSent(true)
      setNotify('')
      setError({})
    }
    else{
      validateNotify(notify, 'notify')
    }
    
  }

  if(successSent){
    setTimeout(function() {
      setSuccessSent(false)
    },6000)
  }
  if(successCancel){
    setTimeout(function() {
      setSuccessCancel(false)
    },6000)
  }

  const columns =[
    {
      key: 'orderNumber',
      header: '#Order number',
      style: {
        fontWeight: 500,
        fontSize:'12px',
      },
    },
    {
      key: 'name',
      header: 'Name',
      component: (row) => {
        const { userName, userAvatarUrl} = row;
        return <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
          <div style={{marginRight: '10px'}} onClick={() => handleClickAvatar(row)}>
            <img src={userAvatarUrl} style={{height:'30px', width:'30px', borderRadius: '50%'}}/>
          </div>
          <div style={{marginRight: '10px'}}>
            {`${userName}`}
          </div>
          <div onClick={() => console.log("****click massage")}>
            <img src={require('../../assets/massage.png')} style={{height:'14px', width:'17px'}}/>
          </div>
        </div>
      },
      style: {
        width: '350px',
        fontWeight: 500,
        fontSize:'12px',
      },
    },
    {
      key: 'quantity',
      header: 'Quantity',
      align: 'center',
      style: {
        fontWeight: 500,
        fontSize:'12px',
      },
      component: (row) => {
        return <div>{row.ticketNumber}</div>
      },
    },
    {
      key: 'totalPrice',
      header: 'Total Price',
      component: (row) => {
        return <div>${row.orderAmount}</div>
      },
      align: 'center',
      style: {
        width: '250px',
        fontWeight: 500,
        fontSize:'12px',
      },
    },
    {
      key: 'time',
      header: 'Purchased Time',
      align: 'center',
      style: {
        width: '250px',
        fontWeight: 500,
        fontSize:'12px',
      },
      component: (row) => {
        return <div>{format(new Date(row.created), 'LLL d, yyyy, p')}</div>
      },
    },
    {
      key: 'coupon',
      header: 'Coupon',
      align: 'center',
      component: (row) => {
        return <div>{row.promotionCode ?
                <div style={{color: '#5666F6'}} onClick={() => onCouponClick(row)}>{row.promotionCode}</div> : 
                <div>--</div>}</div>
      },
      style: {
        width: '150px',
        fontWeight: 500,
        fontSize:'12px',
      },
    },
    {
      key: 'checkStatus',
      header: 'Check in status',
      component: (row) => {
        return <div onClick={() => checkStatusOnChange(row.id)}>
          {row.checkStatus 
          ? <img src={require('../../assets/checked.png')} style={{height:'20px', width:'50px'}}/>
          : <img src={require('../../assets/unChecked.png')} style={{height:'20px', width:'50px'}}/>
          }
        </div>
      },
      align: 'center',
      style: {
        width: '150px',
        fontWeight: 500,
        fontSize:'12px',
      },
    },
  ]


  const onGoBackClick = () => {
    const { prev } = props.location.state;

    history.push({
        pathname: `/manage_event/${eventId}`,
        // search: '?query=abc',
        state: { prev  }
    })
  }
  const onCouponSettingClick = () => {
    const { prev } = props.location.state;
    //console.log(prev);
    history.push({
        pathname: `/operator_center/coupon_setting/create_coupon`,
        // search: '?query=abc',
        state: { prev  }
    })
  }

  const renderHeader = () => {
    return (
        <Box className={classes.header}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
              <Button
                  onClick={onGoBackClick}
                  startIcon={<ArrowBack />}
                  className={classes.manageGuestButton}
                  size="small"
              >
                  Manage guest
              </Button>
              {cancelStep === 2 && !successCancel &&
                <div className={classes.ticketCancelText}>
                  Ticket Cancelled
                </div>
              }
            </div>
            <div>
              
              <Button
                  variant="outlined"
                  className={classes.notifyAllButton}                  
                  size="small"
                  //disabled={cancelStep === 2 && !successCancel}
                  onClick={() => openNotifyAll()}
              >
             
                  Notify all                  
                  <img src={require('../../assets/notify.png')} />
              </Button>
              <Button
                  variant="outlined"
                  className={classes.cancelTicketButton}
                  size="small"
                  //disabled={cancelStep === 2 && !successCancel}
                  onClick={() => openCancelRequest()}
              >
               
                  Cancel Ticket
                  <img src={require('../../assets/ticket.png')} />
              </Button>
            </div>  
        </Box>
    )
  };

  const renderTicket = () => {
    const { date, ticket: { grade, totalQuantity} } = props.location.state;
    const dateText = format(new Date(date.eventStartTime), 'LLL d, yyyy');
    return (
      <div>
        <div className={classes.dateText}>
          {dateText}
        </div>
        <div className={classes.topContainer}>
          <div className={cancelStep === 2 && !successCancel ? classes.canceledTicketContainer : classes.ticketContainer}>
            <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', marginBottom:'5px'}}>
              <div className={classes.ticketText}>
                {grade.gradeName}
              </div>
              <div className={classes.priceText}>
                $ {grade.gradePrice}
              </div>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', marginBottom:'8px'}}>
              <div className={classes.subText}>
                {parseTimeString(grade.gradeStartTime)} |  {parseDuration(grade.gradeDuration)}
              </div>
              <div className={classes.subText}>
                <strong>Ticket Sold:</strong> {totalQuantity}   / {grade.gradeQuantity}
              </div>
            </div>
            <div style={{borderBottom:'1px solid #7588A3', width:'100%', marginBottom:'8px',}}></div>
            <div className={classes.ticketContentText}>
              {grade.gradeDescription}
            </div>
            <div className={classes.noteText}>
              Cutoff: {grade.sellingCutoffDate ? grade.sellingCutoffDate: 0} days before the event starts
            </div>
          </div>
          {
            successCancel && 
            <div className={classes.cancelContainer}>
              * The cancellation request is being processed, your registration for this event is suspended during this period.
            </div>
          }
        </div>
      </div>
    )
  }

  const renderTable = () => {
    return(
    <div>
      {(orders && orders.length !== 0) && 
        <div style={{float: 'right', margin: '21px', textDecoration: 'underline', color: '#9A9A9A', cursor: 'pointer'}}
           onClick={() => orderExport(orders)}>Export CSV Document</div>}
      <TableContainer className={classes.tableContainer}>
  
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key} style={column.style} align={column.align}>
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{width:'100%', position:'relative'}}>
            {orders && orders.length !== 0  
              ? orders.map((row, index) => {
                return (
                  <TableRow key={row.id} className={classes.tableRow}>
                    {columns.map((column) => (
                      <TableCell key={column.key} style={{fontSize:'14px', fontWeight:300, lineHeight:'17px'}} align={column.align}>
                        {column.component ? (
                          <column.component {...row} />
                          ): (
                            <div>{row[column.key]}</div>
                            )}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              }) : (
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'600px', position:'absolute', left:'25%'}}>
                  <div style={{marginTop:'150px',marginBottom:'60px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                    {/* <img src={require('../../assets/NoEvents.png')} style={{height:'80px', width:'80px', marginBottom:'20px'}}/> */}
                    <div style={{fontSize:'14px', lineHeight:'17px',color:'#A2A2A2'}}>
                      Wait for attendees to register
                    </div>
                  </div>
                  <div style={{width:'100%',backgroundColor:'#EDEFFF', borderRadius:'15px', padding:'20px 33px',display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{width:'310px', fontSize:'12px', lineHeight:'17px', fontWeight:300}}>
                      You can add coupons for your event to boost sales. 
                      Want promotion support for your coupon? Contact us
                    </div>
                    <Button style={{border:'1px solid #5666F6', borderRadius:'30px', backgroundColor:'#5666F6', color: '#F5F5F5', width:'123px', height:'32px', textTransform:'none'}} onClick={onCouponSettingClick}>
                      <div style={{fontSize: '17.5px', marginLeft: '-7px', marginRight: '11px'}}>+</div>
                      <div style={{fontSize: '11.49px', marginRight: '-3px'}}>Add coupon</div>     
                    </Button>
                  </div>
                </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>)
  }

  const renderBody = () => {
    return (
      <div className={classes.bodyContainter}>
      {renderTicket()}
      {renderTable()}
    </div>
    )
  }

  return (
    <>
      <UseDialog 
        open={personInfo}
        onClose={closePersonInfo}
        content={
          // TODO: rename prop
          <PersonInfo person={personData}/>
        }
      />
      <UseDialog
        width='800px' 
        open={notifyAll}
        onClose={closeNotifyAll}
        content={
          <NotifyAll 
            error={error}
            notify={notify}
            notifyOnChange={notifyOnChange}
            validateNotify={validateNotify}
            handleSubmit={handleSubmitNotify}
          />
        }
      />
      <UseDialog 
        width='800px'
        open={cancelRequest}
        onClose={closeCancelRequest}
        content={
          <CancelRequest 
            error={error}
            cancelObj={cancelObj}
            handleOnChange={handleOnChange}
            validateCancel={validateCancel}
            validateRequired={validateRequired}
            handleSubmit={handleSubmitCancel}
            reasonsList={reasonsList}
            handleChange={handleChange}
            handleImageChange={handleImageChange}
          />
        }
      />
      <Paper elevation={0}>
          {renderHeader()}
          {renderBody()}
          {
            successSent && 
            <div style={{position: 'fixed', bottom:'80px', display:'flex', width:'100%', flexDirection:'row', justifyContent:'center'}}>
              <div style={{backgroundColor:'#868898', opacity:0.8, color:'#FFF', fontSize:'11px', fontWeight:400, borderRadius:'10px', padding:'12px 18px'}}>
                Your notification has successfully sent
              </div>
            </div>
          }
          {
            successCancel && 
            <div style={{position: 'fixed', bottom:'80px', display:'flex', width:'100%', flexDirection:'row', justifyContent:'center'}}>
              <div style={{backgroundColor:'#868898', opacity:0.8, color:'#FFF', fontSize:'11px', fontWeight:400, borderRadius:'10px', padding:'12px 18px'}}>
                Your request has successfully sent
              </div>
            </div>
          }
      </Paper>
    </>     
  )
}

const MONTH_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
];


function parseTimeString(timStr) {
  if (!timStr)
      return;
  const time = timStr.split(':'); // split it at the colons

  return `${parseHour(time[0])}:${time[1]} ${+time[0] >= 12 ? 'PM' : 'AM'}`;
}

function parseHour(hr) {
  if (!hr)
      return;
  return (+hr) > 12 ?
      (
          hr - 12 >= 10 ?
              (hr - 12).toString() :
              '0' + (hr - 12)
      ) :
      hr;
}
function parseDuration(timStr) {
  if (!timStr)
  return;
  
  const time = timStr.split(':'); // split it at the colons
  return `${+time[0] > 0 ? +time[0] + 'hr' : ''}${+time[1] > 0 ? +time[1] + 'min' : ''}`
}

function download_as_csv(data, fileName = 'export.csv') {
  const replacer = (key, value) => value === null ? '' : value; 
  const separator = ',';
  // Construct csv
  const header = Object.keys(data[0]);
  let csv = data.map(row => header.map(key => JSON.stringify(row[key], replacer)).join(separator));
  csv.unshift(header.join(separator));
  const csv_string = csv.join('\r\n');
  // Download it
  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default ManageGuest;
