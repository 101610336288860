import React, { useState, useEffect, useRef } from "react";
import styles from "./CEBasicInfo.module.css";
let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

function fillInAddress(addressObject) {
  if(typeof addressObject !== 'object' || !addressObject.address_components)
    return;
  
  const query = addressObject.formatted_address;
  let address1 = '', postcode = '', city = '', state = '';
  for (const component of addressObject.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name} ${address1}`;
        break;
      }

      case "route": {
        address1 += component.short_name;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      // case "postal_code_suffix": {
      //   console.log(component)
      //   postcode = `${postcode}-${component.long_name}`;
      //   break;
      // }
      case "locality":
        city = `${component.long_name}`;
        break;

      case "administrative_area_level_1": {
        state = `${component.long_name}`;
        break;
      }

      default:
        break;
    }
  }
  return {query, address1, city, state, postcode};
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  updateQuery(addressObject);
  console.log(addressObject);
}

function SearchLocationInput(props) {
  const { setZipCode, setCity, setState, setAddress, address } = props;
  const [query, setQuery] = useState(address);
  const autoCompleteRef = useRef(null);
  const updateQuery = (e) => {
    const {query, address1, city, state, postcode} = fillInAddress(e)
    setQuery(address1);
    setZipCode(postcode);
    setCity(city);
    setState(state);
    setAddress(address1)
  }
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBwoytRHIZEKH0QFY-X4PBJKzF7M9Q91Ew"}&libraries=places`,
      () => handleScriptLoad(updateQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div className="search-location-input">
      <input
        className={styles.offlineInput}
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder="Input offline location"
        value={query}
       
      />
    </div>
  );
}

export default SearchLocationInput;