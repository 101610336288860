import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import "../../css/events.css"

class Events extends Component {
    render() {
      return (
          <div className="container">
            <div className="events__box-title">
                <h2>NASU Selected Events</h2>
            </div>
            <div className="row">
                {
                    this.props.events.map(event => (
                        <div 
                            key={event.id}
                            className="col-md-4"
                            style={{ marginBottom: "2rem" }}
                        >
                            <div className="events__box">
                                <img className="events__box-image" src={event.eventImage} alt={event.eventName}/>
                                <div className="events__box-text">
                                    <Link to={{
                                        pathname: `/event/${event.id}`,
                                        state: {event: event}
                                    }}>
                                        <h4>{event.eventName}</h4>
                                    </Link>
                                    <p>
                                        { event.eventDescription.length < 60 ? `${event.eventDescription.padEnd(60, ' ')}` : `${event.eventDescription.substring(0, 57)}...` }
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    ))
                }
            </div>
          </div>
      )
    };
}

export default Events;