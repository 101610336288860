import React, { Component } from 'react';
import axios from 'axios';
import "../../css/events.css"

import Events from "../Events/Events";
import Articles from "../Articles/Articles";

class Home extends Component {

  state = {
    events : [],
    articles : [],
  }

  componentDidMount() {
    this.mounted = true;
    this.getHomeEvents();
    this.getHomeArticles();

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getHomeEvents() {
    axios
      .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/selected/`)
      .then(res => {
        if(this.mounted)
          this.setState({events : res.data});
      })
      .catch(err => {
        console.log(err);
      });
  }

  getHomeArticles() {
    axios
      .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/articles/selected/`)
      .then(res => {
        if(this.mounted)
          this.setState({articles : res.data});
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        <Events events={this.state.events}/>
        <Articles articles={this.state.articles}/>
      </div>
    );
  }
}

export default Home;
