import React, { Component } from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {Link} from 'react-router-dom';
import axios from 'axios';

class NewCheckout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            userName: "",
            userEmail: "",
            waiver: false,
        };
        this.submit = this.submit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleWaiverChange = this.handleWaiverChange.bind(this);
    }

    handleNameChange(event) {
        this.setState({userName: event.target.value});
    }

    handleEmailChange(event) {
        this.setState({userEmail: event.target.value});
    }

    handleWaiverChange(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            waiver: value
        });
    }

    async submit(ev) {
        ev.preventDefault();

        if(this.state.waiver)
        {
            const userName = this.state.userName;
            const userEmail = this.state.userEmail;
            let {token} = await this.props.stripe.createToken({name: "Name"});
    
            axios
                .post(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/payment/charge/`, {
                    token: token.id,
                    userName: userName,
                    userEmail: userEmail,
                    amount: this.props.amount,
                    eventName: this.props.des
                })
                .then( (response) => {
                    console.log(response);
                    if(response.status == '200') {
                        this.setState({
                            complete: true
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else
        {
            window.alert("Please check the waiver...")
        }
        
    }

    render() {
        if (this.state.complete) return <h3>Purchase Complete, please check your inbox for a confirmation email.</h3>;
        return (
            <div className="checkout">
                <h3 style={{ fontSize: "15px"}}> Event Registration: </h3>

                <form onSubmit={this.submit}>
                    <label className="payment-label">
                        <p>Name: </p>
                        <input className="payment-input" name="userName" type="text" placeholder="Jane Doe"  onChange={this.handleNameChange} required/>
                    </label>
                    <label className="payment-label">
                        <p>Email: </p>
                        <input
                            className="payment-input"
                            name="email"
                            type="email"
                            placeholder="jane.doe@example.com"
                            onChange={this.handleEmailChange}
                            required
                        />
                    </label>
                    <label className="payment-label">
                        <p>Card Information: </p>
                        <CardElement />
                    </label>
                    <label>
                        I have read and agreed the   
                        <Link to="/waiver"> Waiver </Link>
                        <input
                            name="waiver"
                            type="checkbox"
                            checked={this.state.waiver}
                            onChange={this.handleWaiverChange} />
                    </label>
                    <p> * You will be charged {this.props.amount} dollars for {this.props.des}</p>
                    <button className="payment-button">Pay</button>
                </form>
            </div>

        );
    }
}

export default injectStripe(NewCheckout);