import React from 'react';
import { withRouter} from 'react-router-dom';
import {AuthConsumer} from './AuthContext';
import { Row,Col} from 'react-bootstrap';
import "../../css/signup.css";
import question_mark from "../../images/Question-Mark.png";
import hide_password from "../../images/Hide_Password.svg";
import show_password from "../../images/Show_Password.svg";
import {Tooltip, followCursor, withStyles, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText, Icon, Button} from '@material-ui/core';

class SignupForm extends React.Component {
  state = {
    user:{
      email: '',
      username: '',
      password: '',
      confirmpassword: '',
      city: '',
      state: '',
      first_name: '',
      last_name: '',
    },
    showPassword: false,
    isAuth: false,
  };

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState.user[name] = value;
      return newState;
    });
  };

  /*
  handle_submit = (e) => {
    if(this.state.isAuth)
    {
      this.props.history.push('/')
    }
  }*/

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handle_click_login = (e) => {
    //link to other page
    this.props.history.push('/login')
  }

  handle_click_privacy = (e) => {
    //link to other page
    this.props.history.push('/waiver')
  }

  validateUserID() {
    const userid = this.state.user.username;
    if (userid.length > 16){
      return false 
    }
    else {
      return true
    }
  }

  validateEmail() {
    const email = this.state.user.email;
    
    if(email.length === 0)
    {
      //return null;
      return true
    }
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if( re.test(String(email).toLowerCase()))
    {
      //return 'success';
      return true
    }
    else
    {
      //return 'error';
      return false
    }
  };

  validatePassword() {
    const password = this.state.user.password;
    if(password.length < 6 && password.length > 0)
    {
      //return 'error';
      return false
    }
    else if(password.length >= 6)
    {
      //return 'success';
      return true
    }
    //else return null;
    else return true
  }

  validateConfirmPassword() {
    const password = this.state.user.password;
    const confirmpassword = this.state.user.confirmpassword;
    if(confirmpassword.length === 0)
    {
      //return null;
      return true
    }
    else if(confirmpassword === password)
    {
      //return 'success';
      return true
    }
    //else return 'error';
    return false
  }

  customTooltip = withStyles({
    tooltip: {
      color: "#FFFFFF",
      backgroundColor: "#C7C7C7",
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "115.1%",
      borderRadius: "10px",
      marginRight: "10px",
    },

    arrow: {
      color: "#C7C7C7",
      fontSize: "25px",
      //transform:"rotate(45deg)"
    }
  })(Tooltip); 

  render() {
    console.log(this.state.user);
    const click_login = <a style={{color: '#302EA1'}} onClick={this.handle_click_login}>Log In</a>;
    const click_privacy = <a style={{color: '#3672EA'}} onClick={this.handle_click_privacy}>"terms and conditions" and "privacy statement"</a>;
    const userid_tooltip = <this.customTooltip title="Once a user ID is finalized, it can't be changed in any way" arrow placement='top-start'>
                              <img src={question_mark} style={{marginTop:'-10px', width:'20px',height:'auto'}}></img></this.customTooltip>
    const validate_userid = this.validateUserID();
    const validate_email = this.validateEmail();
    const validate_password = this.validatePassword();
    const validate_confirmpassword = this.validateConfirmPassword();
    const signup_form_input_text_detail = {fontFamily:'285-apple-system, BlinkMacSystemFont, sans-serif%', fontSize:'13px', fontStyle:'normal', fontWeight:'bold', color:'black'};

    return (
      <AuthConsumer>
            {({ handle_signup, signup_email_error_meg, signup_userid_error_meg }) => (
              <div>
                <Row>
                  <Col>
                    <form onSubmit={e => {handle_signup(e, this.state);}}>
                      <div>
                        <Row>
                          <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={4} smOffset={4} mdOffset={4} lgOffset={4} xlOffset={4}
                          className='signup_form_signup_detail'>Sign Up</Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={4} smOffset={4} mdOffset={4} lgOffset={4} xlOffset={4}
                          className='signup_form_click_login_detail'>
                            Already have a NASUFUN Account? {click_login}</Col>
                        </Row>
                      </div>
                      
                      <br/>
                      
                      <div>
                        <Row>
                          <Col xs={4} sm={3} md={3} lg={2} xl={1} xsOffset={4} smOffset={4} mdOffset={4} lgOffset={4} xlOffset={4}>
                            <InputLabel
                              style={signup_form_input_text_detail}
                              htmlFor='username'
                              ><b>User ID {userid_tooltip}</b></InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type="text"
                                error={!validate_userid || signup_userid_error_meg != '' ? true : false}
                                name="username"
                                value={this.state.user.username}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                              />
                            </FormControl>
                            {!validate_userid || signup_userid_error_meg != '' ? <FormHelperText style={{color:'red', width:'100%', fontSize:'70%', textAlign:'left', marginBottom:'-8%'}}>*{!validate_userid ? 'Please enter at most 16 chars' : signup_userid_error_meg}</FormHelperText> : null}
                          </Col>
                      
                          <Col xs={4} sm={3} md={3} lg={2} xl={1}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='email'
                              ><b>Email</b></InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type="text"
                                name="email"
                                error={!validate_email || signup_email_error_meg != '' ? true : false}
                                value={this.state.user.email}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                              />
                            </FormControl>
                            {!validate_email || signup_email_error_meg != '' ? <FormHelperText style={{color:'red', width:'100%', fontSize:'70%', textAlign:'left', marginBottom:'-8%'}}>*{!validate_email ? 'Please enter a valid email' : signup_email_error_meg}</FormHelperText> : null}
                          </Col>
                        </Row>
                      </div>
                    
                      <br/>

                      <div>
                        <Row>
                          <Col xs={4} sm={3} md={3} lg={2} xl={1} xsOffset={4} smOffset={4} mdOffset={4} lgOffset={4} xlOffset={4}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='first_name'><b>First Name</b>
                            </InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type="text"
                                name="first_name"
                                value={this.state.user.first_name}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                              />
                            </FormControl>
                          </Col>
                        
                          <Col xs={4} sm={3} md={3} lg={2} xl={1}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='last_name'><b>Last Name</b>
                            </InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type="text"
                                name="last_name"
                                value={this.state.user.last_name}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                      </div>
                      <br/>

                      <div>
                        <Row>
                          <Col xs={4} sm={3} md={3} lg={2} xl={1} xsOffset={4} smOffset={4} mdOffset={4} lgOffset={4} xlOffset={4}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='city'><b>City</b>
                            </InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type="text"
                                name="city"
                                value={this.state.user.city}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                              />
                            </FormControl>
                          </Col>
                            
                          <Col xs={4} sm={3} md={3} lg={2} xl={1}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='state'><b>State</b>
                            </InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type="text"
                                name="state"
                                value={this.state.user.state}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                      </div>
                      <br/>

                      <div>
                        <Row>
                          <Col xs={4} sm={3} md={3} lg={2} xl={1} xsOffset={4} smOffset={4} mdOffset={4} lgOffset={4} xlOffset={4}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='password'
                              ><b>Password</b></InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type={this.state.showPassword ? "text" : "password"}
                                error={!validate_password ? true : false}
                                name="password"
                                value={this.state.user.password}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      onMouseDown={this.handleMouseDownPassword}
                                      edge="end"
                                      >
                                      {this.state.showPassword ? <Icon><img style={{width:'100%',height:'auto', marginBottom:'5px'}} src={show_password}/></Icon>: <Icon><img style={{width:'100%',height:'auto', marginBottom:'5px'}} src={hide_password}/></Icon>}
                                    </IconButton>
                                  </InputAdornment>}/>
                            </FormControl>
                            {!validate_password ? <FormHelperText style={{color:'red', width:'80%', fontSize:'70%', textAlign:'left', marginBottom:'-5%'}}>*Please Enter At Least 6 Chars</FormHelperText> : null}
                          </Col>

                          <Col xs={4} sm={3} md={3} lg={2} xl={1}>
                            <InputLabel 
                              style={signup_form_input_text_detail}
                              htmlFor='confirmpassword'
                              ><b>Comfirm Password</b></InputLabel>
                            <FormControl variant="standard">
                              <OutlinedInput
                                type={this.state.showPassword ? "text" : "password"}
                                error={!validate_confirmpassword ? true : false}
                                name="confirmpassword"
                                value={this.state.user.confitmpassword}
                                onChange={this.handle_change}
                                style={{width:'180px',height:40,fontSize:'15px'}}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      onMouseDown={this.handleMouseDownPassword}
                                      edge="end"
                                      >
                                      {this.state.showPassword ? <Icon><img style={{width:'100%',height:'auto', marginBottom:'5px'}} src={show_password}/></Icon>: <Icon><img style={{width:'100%',height:'auto', marginBottom:'5px'}} src={hide_password}/></Icon>}
                                    </IconButton>
                                  </InputAdornment>}/>
                            </FormControl>
                            {!validate_confirmpassword ? <FormHelperText style={{color:'red', width:'80%', fontSize:'70%', textAlign:'left', marginBottom:'-5%'}}>*Please Comfirm Your Password</FormHelperText> : null}
                          </Col>
                        </Row>
                      </div>

                      <br/>
                      <div>
                        <Row>
                          <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}>
                            <Button
                              variant="contained"
                              disabled={validate_userid && validate_email && validate_password && validate_confirmpassword ? false : true}
                              type="submit"
                              style={{width:'290px',height:45, marginLeft:'-30px', borderRadius:'26px', marginBottom:'10px', backgroundColor:'#5566F6', WebkitBoxShadow: 'none'}}>
                              <p className="signup_form_signup_button_detail">Sign Up</p></Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4} sm={3} md={3} lg={2} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5} className='signup_form_click_privacy_detail'>By tapping sign up, you agree to our {click_privacy}</Col>
                        </Row>
                      </div>

                    </form>

                  </Col>
                </Row>
              </div>
            )}
          
      </AuthConsumer>
    );
  }
}

export default withRouter(SignupForm);