import React, {useState, useContext, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddIcon from '@material-ui/icons/Add';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { useRouteMatch, Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../UserAuth/AuthContext';


const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    //   backgroundColor: '#F9F9F9'
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
})(Tabs);
  
const AntTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: '700',
      fontSize: '14px',
      marginRight: theme.spacing(0),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: '700',
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(3),
    },
    tabBackground: {
    //   backgroundColor: '#F9F9F9',
    },
}));

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
}))(Tooltip);

const ManageCoupon = (props) => {

    let { path, url } = useRouteMatch();
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openedCode, setOpenedCode] = useState(null);

    const isDialogOpen = (code) => {
        return code === openedCode;
    }

    const handleClickOpen = (code) => {
        setAnchorEl(null);
        setOpenedCode(code);
    };

    const handleCloseDialog = () => {
        setOpenedCode(null);
    };

    const handleEndCoupon = couponObj => {
        props.handleEndCoupon(couponObj);
        setOpenedCode(null);
    }

    const renderActiveCoupons = (couponObj) => {
        return (
            <Box bgcolor='white' height='180px' width='600px' borderRadius={5} mt={-5} ml={-3} marginBottom='70px' boxShadow='10px 26px 28px rgba(185, 185, 185, 0.1)'>
                <Box pt={2.5} pl={3} width='420px' height='100px'>
                    <Typography gutterBottom>
                        <Box fontWeight='700' fontSize='14px'>
                            {couponObj.eventName}
                        </Box>
                    </Typography>
                <Box mt={2}>
                    <Typography gutterBottom>
                        <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                            Uses:
                        </Box>
                        <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                            {couponObj.uses} / {couponObj.totalAmount ? couponObj.totalAmount : "Unlimited"}
                        </Box>
                    </Typography>
                    <Typography gutterBottom>
                        <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                            Valid:  
                        </Box>
                        <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                            {new Date(couponObj.validFrom).toLocaleDateString([], {year: 'numeric', month: 'numeric', day: 'numeric'})} - {new Date(couponObj.validTo).toLocaleDateString([], {year: 'numeric', month: 'numeric', day: 'numeric'})}
                        </Box>
                    </Typography>
                    {couponObj.grades.length !== 0 ? 
                        <Typography gutterBottom>
                            <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                Coupon for:  
                            </Box>
                            <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                Specific tickets
                            </Box>
                            <LightTooltip 
                                title={
                                    <React.Fragment>
                                        {couponObj.grades.map(grade => (
                                            <Typography>{grade}</Typography>
                                        ))}
                                    </React.Fragment>
                                }
                                placement="right-start">
                                <Box ml={2} component='div' display='inline' fontWeight='300' fontSize='12px' color='#9A9A9A'>
                                    <u>view</u>
                                </Box>
                            </LightTooltip>
                        </Typography>
                        :
                        <Typography gutterBottom>
                                <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                Coupon for:  
                            </Box>
                            <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                All tickets
                            </Box>
                        </Typography>
                    }
                    {couponObj.forNewUser ?
                        <Typography gutterBottom>
                            <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                More:
                            </Box>
                            <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                Only new users can apply
                            </Box>
                        </Typography>
                        :
                        null
                    }
                </Box>
                <Box textAlign="center" bgcolor='#F3F5FF' mt={couponObj.forNewUser ? -15.5 : -13} ml={50} height='30px' width='148px' borderRadius={30} >
                    <Typography style={{color: '#5666F6', fontWeight: '300', fontSize: '16px', padding: '3px 0px'}} >
                        {couponObj.code}
                    </Typography>
                </Box>
                <Box mt={1} ml={57.6} height='30px' width='100px'>
                    {couponObj.discountRatio ?
                        <Typography gutterBottom>
                            <Box color='#F5A626' fontWeight='700' fontSize='16px'>
                                {couponObj.discountRatio}% off
                            </Box>
                        </Typography>
                        :
                        <Typography gutterBottom>
                            <Box color='#F5A626' fontWeight='700' fontSize='16px'>
                                ${couponObj.discountAmount} off
                            </Box>
                        </Typography>
                    }
                </Box>
                <Box mt={5} ml={64}>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        coupon-code={couponObj.code}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl) && anchorEl.getAttribute("coupon-code") === couponObj.code}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleClickOpen(couponObj.code)}>End coupon</MenuItem>
                    </Menu>
                </Box>
                <Dialog
                    open={isDialogOpen(couponObj.code)}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <Box textAlign='center'>
                            <Typography>
                                There are {couponObj.uses} users have applied your coupon.
                            </Typography>
                            <Typography>
                                It seems your promotion is on the way.
                            </Typography>
                            <Typography>
                                Do you still wish to end it? 
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'center'}}>
                        <Button 
                            style={{textTransform: 'none', border: "1px solid", borderRadius: '21px'}}
                            variant="outlined" 
                            onClick={handleCloseDialog} 
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{textTransform: 'none', border: "1px solid", borderRadius: '21px'}}
                            variant="outlined" 
                            onClick={() => handleEndCoupon(couponObj)} 
                            autoFocus
                        >
                            End coupon
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
        )
    }

    const renderEndedCoupons = (couponObj) => {
        return (
            <Box bgcolor='white' height='180px' width='600px' borderRadius={5} mt={-5} ml={-3} marginBottom='70px' boxShadow='10px 26px 28px rgba(185, 185, 185, 0.1)'>
                    <Box pt={2.5} pl={3} width='420px' height='100px'>
                        <Typography gutterBottom>
                            <Box fontWeight='700' fontSize='14px'>
                                {couponObj.eventName} 
                            </Box>
                        </Typography>
                        <Box mt={2}>
                            <Typography gutterBottom>
                                <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                    Uses:
                                </Box>
                                <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                    {couponObj.uses} / {couponObj.totalAmount ? couponObj.totalAmount : "Unlimited" }
                                </Box>
                            </Typography>
                            <Typography gutterBottom>
                                <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                    Valid:  
                                </Box>
                                <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                    {new Date(couponObj.validFrom).toLocaleDateString([], {year: 'numeric', month: 'numeric', day: 'numeric'})} - {new Date(couponObj.validTo).toLocaleDateString([], {year: 'numeric', month: 'numeric', day: 'numeric'})}
                                </Box>
                            </Typography>
                            {couponObj.grades.length !== 0 ? 
                                <Typography gutterBottom>
                                    <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                        Coupon for:  
                                    </Box>
                                    <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                        Specific tickets
                                    </Box>
                                    <LightTooltip 
                                        title={
                                            <React.Fragment>
                                                {couponObj.grades.map(grade => (
                                                    <Typography>{grade}</Typography>
                                                ))}
                                            </React.Fragment>
                                        } 
                                        placement="right-start">
                                        <Box ml={2} component='div' display='inline' fontWeight='300' fontSize='12px' color='#9A9A9A'>
                                            <u>view</u>
                                        </Box>
                                    </LightTooltip>
                                </Typography>
                                :
                                <Typography gutterBottom>
                                     <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                        Coupon for:  
                                    </Box>
                                    <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                        All tickets
                                    </Box>
                                </Typography>
                            }
                            
                            {couponObj.forNewUser ?
                                <Typography gutterBottom>
                                    <Box component='div' display='inline' fontWeight='500' fontSize='12px' color='#212759'>
                                        More:
                                    </Box>
                                    <Box ml={1} component='div' display='inline' fontWeight='300' fontSize='12px' color='#212759'>
                                        Only new users can apply
                                    </Box>
                                </Typography>
                                :
                                null
                            }
                        </Box>
                        <Box textAlign="center" bgcolor='#F3F3F3' mt={couponObj.forNewUser ? -15.5 : -13} ml={53} height='30px' width='130px' borderRadius={30} >
                            <Typography style={{color: '#808080', fontWeight: '300', fontSize: '16px', padding: '3px 0px'}}>
                                {couponObj.code}
                            </Typography>
                        </Box>
                        <Box mt={1} ml={57.6} height='30px' width='100px'>
                        {couponObj.discountRatio ?
                            <Typography gutterBottom>
                                <Box color='#808080' fontWeight='700' fontSize='16px'>
                                    {couponObj.discountRatio}% off
                                </Box>
                            </Typography>
                            :
                            <Typography gutterBottom>
                                <Box color='#808080' fontWeight='700' fontSize='16px'>
                                    ${couponObj.discountAmount} off
                                </Box>
                            </Typography>
                        }
                        </Box>
                    </Box>
            </Box>
        )
    }

    return (
        <div class='scroll'>
                <Box>
                    <Typography gutterBottom>
                        <Box fontWeight='300' fontSize='20px' color='#212759'>
                            My Coupons
                        </Box>
                    </Typography>
                </Box>
                <Box mt={3.5} bgcolor='#EDEFFF' height='75px' width='600px' borderRadius={15}>
                    <Box pl={3} pt={2}>
                        <Box position="absolute" zIndex="modal">
                            <Typography gutterBottom>
                                <Box fontWeight='300' fontSize='12px'>
                                    You can add coupons for your event to boost sales.
                                </Box>
                            </Typography>
                            <Typography gutterBottom>
                                <Box fontWeight='300' fontSize='12px'>
                                    Want promotion support for your coupon? <u>Contact us</u>
                                </Box>
                            </Typography>
                        </Box>
                        <Box position="absolute" ml={60} mt={-5.2}>
                            <img src={require("../../Assets/CouponGiftBox@3x.png")} height='70px' width='70px' ></img>
                        </Box>
                        <Box justifyItems='center' mt={1} ml={55} position="absolute" >
                            <Button
                                startIcon={<AddIcon style={{height: '14px', width: '14px', paddingBottom: '2px'}}/>}
                                variant="contained"
                                style={{textTransform: 'none', backgroundColor:'#5666F6', color:'white', width:'123', borderRadius: 30, height: '32', outline: 'none'}}
                                component={Link} to={`${url}/create_coupon`}
                            >
                                Add Coupon
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box mt={1}>
                    <div className={classes.tabBackground}>
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab label="Active" {...a11yProps(0)}/>
                            <AntTab label="Ended" {...a11yProps(1)}/>
                        </AntTabs>
                        <Typography className={classes.padding} />
                        <TabPanel value={value} index={0}>
                            {props.activeCoupon ?
                                <div> 
                                    {props.activeCoupon.map((actCoupon, index) => (
                                        <div>{renderActiveCoupons(actCoupon, index)}</div>
                                    ))}
                                
                                </div>
                                :
                                <Box height='800' width='600px' mt={10} ml={30}>
                                    <Box>
                                        <img src={require("../../Assets/CouponNotFound@1x.png")}></img>
                                    </Box>
                                    <Box ml={-1.6} mt={3}>
                                        <Typography variant="h6" gutterBottom>
                                            <Box fontWeight='300' color='#A1A1A1'>
                                                No active coupons yet.
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {props.endedCoupon ?
                                <div> 
                                    {props.endedCoupon.map((endCoupon, index) => (
                                        <div>{renderEndedCoupons(endCoupon, index)}</div>
                                    ))}
                                </div>
                                :
                                <Box height='800' width='600px' mt={10} ml={30}>
                                    <Box>
                                        <img src={require("../../Assets/EndedCouponNotFound@1x.png")}></img>
                                    </Box>
                                    <Box ml={-1.6} mt={3}>
                                        <Typography variant="h6" gutterBottom>
                                            <Box fontWeight='300' color='#A1A1A1'>
                                                No ended coupons yet.
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </TabPanel>
                    </div>
                </Box>
        </div>
    )
}

export default ManageCoupon;