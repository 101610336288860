import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import { Modal } from 'react-bootstrap';
import "../../css/gradeDetail.css";


const SAMPLE_EVENT_GRADES = [
    {"id": "1", "gradeName": "An error happened, contact NASU directly", "gradePrice": 0},
];
const MAX_COUNT_SIZE = 20;

class GradeDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gradeType: -1,
            count: 1,
            show: false,
        };

        this.gradeChange = this.gradeChange.bind(this);
        this.countChange= this.countChange.bind(this);
        this.addToCart= this.addToCart.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    gradeChange(event){
         this.setState({gradeType: event.target.value});
    }

    countChange(event){
        this.setState({count: event.target.value});
    }

    addToCart(event){
        const currentEvent = this.props.event;
        const eventGrades = this.props.grades || SAMPLE_EVENT_GRADES;
        const selectedGrade = (this.state.gradeType == -1) ? eventGrades[0] : eventGrades.filter((item) => { return item.id == this.state.gradeType})[0];
        const count = this.state.count;

        let shoppingCartItems = window.localStorage.getItem('shoppingCartItems');
        shoppingCartItems = !!shoppingCartItems ? JSON.parse(shoppingCartItems) : [];
        // filter out currentItem, add count
        const itemIndex = shoppingCartItems.findIndex((item => item.eventId == currentEvent.id && item.gradeId == selectedGrade.id));
        let currentItem = shoppingCartItems[itemIndex];
        if (!!currentItem) {
            currentItem['count'] = parseInt(currentItem['count']) + parseInt(count);
        } else {
            currentItem = {
                eventId: currentEvent.id,
                eventName: currentEvent.eventName,
                gradeId: selectedGrade.id,
                gradeName: selectedGrade.gradeName,
                gradePrice: selectedGrade.gradePrice,
                count: count
            };
            shoppingCartItems.push(currentItem);
        }
        console.log(shoppingCartItems);
        window.localStorage.setItem('shoppingCartItems', JSON.stringify(shoppingCartItems));
        this.handleShow();
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        debugger;
        {console.log(this.props.grades)}
        const eventGrades = this.props.grades || SAMPLE_EVENT_GRADES;
        const selectedGrade = (this.state.gradeType == -1) ? eventGrades[0] : eventGrades.filter((item) => { return item.id == this.state.gradeType})[0];
        const showModal = this.state.show;
        const description = this.state.count + " " + this.props.event.eventName + " " + selectedGrade.gradeName;

        return (
            <div>
                <p><strong>Unit Price: $</strong>{selectedGrade.gradePrice}</p>
                <div class="form-group">
                    <label for="grade-detail-label">Event Type:</label>
                    <select class="form-control" id="grade" onChange={this.gradeChange}>
                        {eventGrades.map((g) => <option key={g.id} value={g.id}> {g.gradeName} </option>)}
                    </select>
                    {/* <label for="grade-detail-label">Count:</label>
                    <select class="form-control" id="count" onChange={this.countChange}>
                        { _.range(1, MAX_COUNT_SIZE + 1).map(value => <option key={value} value={value}>{value}</option>) }
                    </select> */}
                </div>
                {/* TEMP BLOCK <button onClick={this.addToCart} className="add-to-cart-button">Add to Cart</button> */}

                {showModal && 
                <Modal.Dialog>
                    <Modal.Header onClick={this.handleClose} closeButton>
                        {/* <Modal.Title>Successfully added!</Modal.Title> */}
                        <Modal.Title>Sorry!</Modal.Title>
                    </Modal.Header>

                    {/* <Modal.Body>
                        <p>You just added {description} into your cart, click checkout now or find it out later at your shopping cart.</p>
                    </Modal.Body> */}

                    <Modal.Body>
                        <p>Checkout is not yet available.</p>
                        <p>Please go to our APP to checkout.</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="add-to-cart-close-button" onClick={this.handleClose}>Return</button>
                        {/* <button className="add-to-cart-close-button" onClick={this.handleClose}>Keep Shopping</button> */}
                        {/* <Link to={{ pathname: `/cart` }} className="add-to-cart-button">Checkout</Link> */}
                    </Modal.Footer>
                </Modal.Dialog>}
            </div>            
        );
    };
}

export default GradeDetail;