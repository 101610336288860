import React, { Component } from 'react';

class NGO extends Component {

  render() {    
    return (
      <div>
        <p>NASU, short for North america sports union, is a 501.c(3) non-profit organization</p>
        <p>authorized by federal goverment.</p>
      </div>
    );
  }
}

export default NGO;
