import React, { Component } from 'react';
import Geocode from "react-geocode";
import GoogleMapReact from 'google-map-react';
import "../../css/common.css";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GeoUtil extends Component {

    static defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
      };

    render() {
        Geocode.setApiKey("AIzaSyAqY-ycUa62o1HMe5d9e0S8v2Cl05wZLis");
        Geocode.enableDebug();
        Geocode.fromAddress("Boston University").then(
            response => {
              const {lat, lng} = response.results[0].geometry.location;
              console.log(response.results[0].geometry.location);
              console.log(lat, lng);
            },
            error => {
              console.error(error);
            }
          );
        return (
            <div style={{ height: '25vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAqY-ycUa62o1HMe5d9e0S8v2Cl05wZLis" }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={42.351780}
                        lng={-71.116790}
                        text={'BU GYM'}
                    />
                </GoogleMapReact>
            </div>
        )
    };
}

export default GeoUtil;