import React, { Component } from 'react';
import axios from 'axios';

import GradeDetail from '../CommonUtils/GradeDetail';
import GeoUtil from '../CommonUtils/GeoUtil';

import { Row,Col } from 'react-bootstrap';

import "../../css/event.css"

class Event extends Component {

    state = {
        event: {}
    }

    componentDidMount() {
        const event = this.props.location.state.event;
        this.setState({event: event});
        this.getEventGrades(event);
    }

    getEventGrades(event) {
        const eventId = event.id;
        axios
            .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/grades/`)
            .then(res => {
                this.setState({grades : res.data});
            })
            .catch(err => {
                console.log(err);
        });
    }

    render() {
        const { event, grades } = this.state;
        const eventDate = (String(event.eventStartTime));

        return (
            <div>
                <div className="container">
                    <Row>
                        <Col xs={12} md={12}>
                            
                            <div className="event_detail">
                                
                                <div className="event_detail-top">
                                    <Row>
                                        <Col xs={8} md={8}>
                                            <img className="" src={event.eventImage} alt="Having problem display"/>
                                        </Col>

                                        <Col xs={4} md={3} mdOffset={1} xsOffset={1}>
                                            <h4>{event.eventName}</h4>
                                            <small>       - By NASU </small>
                                            <h5 style={{ marginTop: "30px" }}> {eventDate.substring(0,10)} </h5>
                                        </Col>
                                    </Row>
                                    
                                </div>

                                <div className="event_detail-middle">
                                    <Row>
                                        <Col xs={7} md={7}>
                                            <h3 style={{ fontSize: "15px"}}>
                                                Description:
                                            </h3>
                                            <p>
                                                {event.eventBody}
                                            </p>
                                        </Col>
                                        <Col xs={4} md={4} mdOffset={1} xsOffset={1}>
                                            <h3 style={{ fontSize: "15px"}}>
                                                Location:
                                            </h3>
                                            <p>
                                                {event.eventBody}
                                            </p>
                                            <h3 style={{ fontSize: "15px"}}>
                                                Price:
                                            </h3>
                                            <p>
                                                {event.eventPrice}
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                
                                <div className="event_detail-middle">
                                    <GeoUtil />
                                </div>
                                
                                <div className="event_detail-bottom">
                                    <GradeDetail event={event} grades={grades}/>
                                </div>

                            </div>


                        </Col>

                    </Row>

                </div>
            </div>
            
        )
    };
}

export default Event;