export const QUESTION_TYPES = {
    NAME: 'First name/ Last name (Default)',
    ADDRESS: 'Address (Default)',
    SHORT_TEXT: 'Short text',
    LONG_TEXT: 'Long text',
    DROP_DOWN: 'Dropdown',
    CHECK_BOX: 'Checkbox',
    DATE_CALENDAR: 'Date Calendar',
};
export const DEFAULT_QUESTION_TYPES = [QUESTION_TYPES.NAME, QUESTION_TYPES.ADDRESS];
export const SUBFORM_QUESTION_TYPES = [QUESTION_TYPES.CHECK_BOX, QUESTION_TYPES.DROP_DOWN];
export const MODES = {
    INIT: 'init',
    SETTING_EDIT: 'setting_edit',
    CREATE: 'create',
    DISPLAY: 'display',
    EDIT: 'edit',
    GENERATED: 'generated',
    LOADING: 'loading'
};
export const REGISTRATION_FORM_SETTING_INIT = {
    selectedTickets: [],
    selectAll: true,
    collectFrom: 'THE_BUYER',
    collectDue: 'AFTER'
};
export const REGISTRATION_FORM_COLLECT_FROM_OPTIONS = {
    THE_BUYER: 'The buyer',
    EACH_ATTENDEE: 'Each attendee',
};
export const REGISTRATION_FORM_COLLECT_DUE_OPTIONS = {
    BEFORE: 'Before checkout',
    AFTER: 'After checkout (recommended)',
};
export const REGISTRATION_FORM_DROP_DOWN_OPTIONS = [{
    text: '',
},
{
    text: '',
},
{
    text: '',
}];
export const TEXT_LIMITS = {
    MIN: 1,
    MAX: 75,
};

export const DIALOG_INIT = {
    open: false,
    dialog: {
        text: {},
        data: {}
    },
};

export const DIALOG_TYPES = {
    DELETE_QUESTION: "DELETE_QUESTION",
    DELETE_FORM: "DELETE_FORM",
    DELETE_OPTION: "DELETE_OPTION",
    CONGRA: "CONGRA",
    ABORT_EDIT: "ABORT_EDIT",
    SELECT_DUE: "SELECT_DUE"
};

export const DIALOG_DELETE_QUESTION = {
    type: DIALOG_TYPES.DELETE_QUESTION,
    title: "",
    description: "Do you wish to remove that question",
    btnCancelText: "Cancel",
    btnConfirmText: "Remove",
};

export const DIALOG_DELETE_FORM = {
    type: DIALOG_TYPES.DELETE_FORM,
    title: "",
    description: "The information you input will be removed. Do you wish to delete form?",
    btnCancelText: "No",
    btnConfirmText: "Yes",
};

export const DIALOG_DELETE_OPTION = {
    type: DIALOG_TYPES.DELETE_OPTION,
    title: "",
    description: "Do you wish to remove that option?",
    btnCancelText: "Cancel",
    btnConfirmText: "Remove",
};

export const DIALOG_CONGRA = {
    img: 'registration_form_congra_72_72.png',
    type: DIALOG_TYPES.CONGRA,
    title: "Congratulations!",
    description: "Your registration form has generated successfully. You can click guestlist in the Manage event > Manage guest to view attendees’ registration information.",
    btnCancelText: null,
    btnConfirmText: null
};

export const DIALOG_ABORT_EDIT = {
    type: DIALOG_TYPES.ABORT_EDIT,
    title: "",
    description: "Changes you’ve done at this time will be removed. Do you wish to continue?",
    btnCancelText: "No",
    btnConfirmText: "Yes",
};

export const DIALOG_SELECT_DUE = {
    type: DIALOG_TYPES.SELECT_DUE,
    title: "",
    description: "If there are questions required to be filled before checkout, you can choose the option. Otherwise, we recommend choosing After checkout for preventing customer churn (frustration of inputting registration form). Attendees still need to submit form before their QR Codes have scanned.",
    btnCancelText: "Cancel",
    btnConfirmText: "Confirm",
};

// Assetss
export const IMG_PATH = process.env.PUBLIC_URL + '/assets/img/';
export const EDIT_IMG = 'registration_form_edit_30_30.png';
export const NO_EVNET_IMG = 'registration_form_no_events_80_80.png';
export const DROP_DOWN_ARROW_IMG = 'registration_form_arrow_right_25_25.png';
export const CHECK_BOX_IMG = 'registration_form_check_box_14_14.png';
export const DRAG_IMG = 'registration_form_drag_14_14.png';
export const CONGRA_IMG = 'registration_form_congra_72_72.png';
export const TOP_IMG = 'registration_form_top_19_19.png';