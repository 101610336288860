import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AuthConsumer } from '../UserAuth/AuthContext';

class Header extends Component {
    render() {
        const isOrderPage = window.location.pathname.includes('/order');
        const isCreateEventPage = window.location.pathname.includes('/create_event');
        const isOperatorCenter = window.location.pathname.includes('/operator_center');
        const isManageEvent = window.location.pathname.includes('/manage_event');
        const Cart = (
            <li>
                <Link to="/cart" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-shopping-cart" style={{ 'marginRight': '6px' }}></span>
                        Cart
                </Link>
            </li>
        );
        const NGO = (
            <li>
                <Link to="/ngo" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-paperclip" style={{ 'marginRight': '6px' }}></span>
                        NGO
                </Link>
            </li>
        );
        const PRIVACY = (
            <li>
                <Link to="/privacy" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-info-sign" style={{ 'marginRight': '6px' }}></span>
                        Privacy
                </Link>
            </li>
        );
        const ALLEVENTS = (
            <li>
                <Link to="/all_events" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-calendar" style={{ 'marginRight': '6px' }}></span>
                        Events
                </Link>
            </li>
        );
        const ALLARTICLES = (
            <li>
                <Link to="/all_articles" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-book" style={{ 'marginRight': '6px' }}></span>
                        Articles
                </Link>
            </li>
        );
        const CREATEEVENT = (
            <li>
                <Link to="/create_event" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-book" style={{ 'marginRight': '6px' }}></span>
                        Create Event
                </Link>
            </li>
        );
        const OPERATORCENTER = (
            <li>
                <Link to="/operator_center" style={{ textDecoration: 'none', color: 'white' }}>
                    <span className="glyphicon glyphicon-book" style={{ 'marginRight': '6px' }}></span>
                        Operator
                </Link>
            </li>
        );
        return (
            <div>
                <AuthConsumer>
                    {({ isAuth, username, user, handle_logout }) => (
                        <div>
                            {isCreateEventPage || isOperatorCenter || isManageEvent ? null
                                :
                                <nav className="navbar navbar-inverse">
                                    <div className="container-fluid">
                                        <div className="navbar-header">
                                            {!isOrderPage ?
                                                <Link to="/" className="navbar-brand" style={{ textDecoration: 'none', color: 'white' }}>
                                                    Welcome to NASU !
                                            </Link>
                                                :
                                                <p className="navbar-brand" style={{ textDecoration: 'none', color: 'white' }}>
                                                    Welcome to NASU !
                                            </p>
                                            }
                                        </div>


                                        {isOrderPage || isCreateEventPage || isOperatorCenter || isManageEvent ? null : isAuth ? (
                                            <ul className="nav navbar-nav navbar-right">
                                                {user && user.is_host && CREATEEVENT}
                                                {ALLEVENTS}
                                                {ALLARTICLES}
                                                {NGO}
                                                {PRIVACY}
                                                {/* TEMP BLOCK {Cart} */}
                                                {OPERATORCENTER}
                                                <li>
                                                    <Link to="/userprofile" style={{ textDecoration: 'none', color: 'white' }}>
                                                        <span className="glyphicon glyphicon-user" style={{ 'marginRight': '6px' }}></span> {username}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={handle_logout} to="/" style={{ textDecoration: 'none', color: 'white' }}>
                                                        <span className="glyphicon glyphicon-log-out" style={{ 'marginRight': '6px' }}></span> Logout
                                                </Link>
                                                </li>
                                            </ul>
                                        ) : (
                                            <ul className="nav navbar-nav navbar-right">
                                                {ALLEVENTS}
                                                {ALLARTICLES}
                                                {NGO}
                                                {PRIVACY}
                                                {/* TEMP BLOCK {Cart} */}
                                                <li>
                                                    <Link to="/signup" style={{ textDecoration: 'none', color: 'white' }}>
                                                        <span className="glyphicon glyphicon-list-alt" style={{ 'marginRight': '6px' }}></span>
                                                        Sign Up
                                                </Link>
                                                </li>
                                                <li>
                                                    <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
                                                        <span className="glyphicon glyphicon-log-in" style={{ 'marginRight': '6px' }}></span>
                                                        Login
                                                </Link>
                                                </li>
                                            </ul>
                                        )}

                                    </div>
                                </nav>
                            }
                        </div>
                    )}

                </AuthConsumer>
            </div>
        )
    };
}

export default withRouter(Header);