import React, { useState } from 'react';
import { withRouter} from 'react-router-dom';
import {AuthConsumer} from './AuthContext';
import "../../css/login.css";
import hide_password from "../../images/Hide_Password.svg";
import show_password from "../../images/Show_Password.svg";
import caps_lock from "../../images/Caps_Lock.svg"
import { Row,Col} from 'react-bootstrap';
import {InputAdornment, IconButton, FormControl, InputLabel, Input, OutlinedInput, Icon, FormHelperText, Button} from '@material-ui/core';

class LoginForm extends React.Component {

  state = {
    user:{
      username: '',
      password: '',
      rememberMe: false
    },
    showPassword: false,
    capsActive: false,
    isAuth: false,
  };

  componentDidMount() {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const username = rememberMe ? localStorage.getItem('username') : '';
    const password = rememberMe ? localStorage.getItem('password') : '';
    this.setState({
      user: {username: username, password: password, rememberMe: rememberMe}
    })
  };

  handle_submit = (e) => {
      const {user} = this.state;
      localStorage.setItem('rememberMe', user.rememberMe);
      localStorage.setItem('username', user.rememberMe ? user.username : '');
      localStorage.setItem('password', user.rememberMe ? user.password : '');
  }

  handle_change = e => {
    const name = e.target.name;
    {/*const value = e.target.value;*/}
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState.user[name] = value;
      return newState;
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
};

  handleMouseDownPassword = (event) => {
    event.preventDefault();
};

  handleCapsLockActive = (event) => {
    if (event.getModifierState('CapsLock')) {
      this.setState({
        capsActive: true
      });
    }
    else {
      this.setState({
        capsActive: false
      });
    }
  };

  /*
  handle_submit = (e) => {
      setTimeout( () => {
          if(this.state.isAuth)
          {
            this.props.history.push('/')
          }
        }, 500
      )
  };*/

  handle_click_signup = (e) => {
    {/* link to other page */}
    this.props.history.push('/signup')
  };

  handle_click_forget_password = (e) => {
    {/* link to other page */}
    this.props.history.push('/')
  };

  

  render() {
    console.log(this.state)
    const click_signup = <a style={{color: '#302EA1'}} onClick={this.handle_click_signup}>Sign Up</a>;
    const click_forget_passwordt = <a style={{color: '#302EA1'}} onClick={this.handle_click_forget_password}>Forget Password</a>;
    const login_form_input_text_detail = {fontFamily:'285-apple-system, BlinkMacSystemFont, sans-serif%', fontSize:'90%', fontStyle:'normal', fontWeight:'bold', color:'black'};

    return (      
      <AuthConsumer>
        {({ handle_login, login_error_meg }) => (
          <Row>
            <Col>
              <form onSubmit={e => {handle_login(e, this.state); this.handle_submit(e, this.state);}}>
                <div>
                  <Row>
                    <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}
                      className='login_form_login_detail'>
                      Log In</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}
                      className='login_form_click_signup_detail'>
                      Do not have a NASUFUN Account? {click_signup}</Col>
                  </Row>
                </div>
                
                <br/>
                
                <div>
                  <Row>
                    <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}>
                      <InputLabel style={login_form_input_text_detail} htmlFor="username">Email*</InputLabel>
                      <FormControl variant="standard">
                        <OutlinedInput
                          name="username"
                          type='text'
                          error={login_error_meg ? true : false}
                          value={this.state.user.username}
                          onChange={this.handle_change}
                          style={{width:'280PX',height:40,fontSize:'15px'}}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </div>
                <br/>

                <div>
                  <Row>
                    <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}>
                      <InputLabel style={login_form_input_text_detail} htmlFor="password">Password*</InputLabel>
                      <FormControl variant="outlined">
                        <OutlinedInput
                          name="password"
                          error={login_error_meg ? true : false}
                          type={this.state.showPassword ? 'text' : 'password'}
                          value={this.state.user.password}
                          onChange={this.handle_change}
                          style={{width:'280PX',height:40,fontSize:'15px'}}
                          onKeyPress={this.handleCapsLockActive}
                          endAdornment={
                            <InputAdornment position="end">
                              {this.state.capsActive ? <Icon><img style={{width:'100%',height:'auto'}} src={caps_lock}/></Icon> : null}
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                                >
                                {this.state.showPassword ? <Icon><img style={{width:'100%',height:'auto', marginBottom:'5px'}} src={show_password}/></Icon>: <Icon><img style={{width:'100%',height:'auto', marginBottom:'5px'}} src={hide_password}/></Icon>}
                              </IconButton>
                            </InputAdornment>}
                        />
                      </FormControl>
                      <FormHelperText style={{color:'red', width:'80%', fontSize:'80%', textAlign:'left', marginBottom:'-8%'}}>{login_error_meg ? '*Your login info is wrong' : null}</FormHelperText>
                      <br/>
                    </Col>
                  </Row>
                </div>
                <br/>
                <br/>
                <div>
                  <Row>
                    <Col xs={3} sm={3} md={2} lg={2} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}>
                      <label>
                        <input 
                          name="rememberMe"
                          checked={this.state.user.rememberMe}
                          onChange={this.handle_change}
                          type="checkbox"/> Remember me
                      </label>
                    </Col>
                    <Col xs={4} sm={3} md={2} lg={2} xl={1}>
                      {click_forget_passwordt}
                    </Col>
                  </Row>
                </div>
                <br/>

                <div>
                  <Row>
                    <Col xs={12} sm={9} md={6} lg={3} xl={1} xsOffset={5} smOffset={5} mdOffset={5} lgOffset={5} xlOffset={5}>
                      <Button
                        type="submit"
                        style={{width:'280px', height:45, borderRadius:'26px', marginBottom:'10px', backgroundColor:'#5566F6', WebkitBoxShadow: 'none'}}>
                      <p className='login_form_login_button_detail'>Log In</p></Button>
                    </Col>
                  </Row>
                </div>
              </form>
            </Col>
          </Row>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(LoginForm);