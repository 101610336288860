import React, {useState, forwardRef, useImperativeHandle, useEffect} from "react";
import { Checkbox, FormControlLabel, Button, TextField, Divider } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { 
    FlexFooter, 
    FlexRow, 
    FormContainer, 
    FormTitle, 
    FormUnit, 
    LabelText, 
    MainContainer, 
    NormalText, 
    StyledButton, 
    StyledInput,
    BolderNormalText,
    FlexBetween,
    LeftGrid,
    RightGrid,
    PageContainer,
    SliderTitle,
} from '../../componentLibrary/page';
import { YellowCheck, EditPencil } from '../../componentLibrary/iconLibrary';
import Waiver from '../CommonUtils/Waiver';

const BlueCheckbox = withStyles({
    root: {
        '&$checked': {
        color: '#5666F6',
        },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5666F6",
            boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
          },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5666F6",
            boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
            border: 'solid 1px',
        },
    },
    textField: {
        width: '600px',
        marginTop: '20px',
    },
    size: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
    },
    rightContent: {
        backgroundColor: '#F9F9F9',
        padding: '30px 40px',
        overflowY: 'auto',
        height: '100%'
    },
    divider:{
        marginBottom: '10px',
    }
}))


const CEWaivers = forwardRef((props, ref) => {
    const classes = useStyles();
    const { draftEvent, setDraftEvent, setValidation } = props;
    const [organName, setOrganName] = useState(draftEvent.waivers.organName);
    // const [eventName, setEventName] = useState(draftEvent.waivers.eventName);
    const [eventAddress, setEventAddress] = useState(draftEvent.waivers.eventAddress);
    const [wavierText, setWavierText] = useState(draftEvent.waivers.wavierText);
    const [ifDisabled, setIfDisabled] = useState(false);
    const [ifChecked, setIfChecked] = useState(draftEvent.waivers.ifChecked);
    const [getWavierInfo, setGetWavierInfo] = useState(draftEvent.waivers.getWavierInfo);
    const [openWavier, setOpenWavier] = useState(false);

    const organNameOnchange = (e) => {
        if(e.target.value.length > 0){
            setIfDisabled(false);
        } else {
            setIfDisabled(true);
        }
        setOrganName(e.target.value);
        // draftEvent.waivers.organName = e.target.value;
        // setDraftEvent(draftEvent)
    };

    // const eventNameOnchange = (e) => {
    //     if(e.target.value.length > 0){
    //         setIfDisabled(false);
    //     } else {
    //         setIfDisabled(true);
    //     }
    //     setEventName(e.target.value);
    // };

    const eventAddressOnchange = (e) => {
        if(e.target.value.length > 0){
            setIfDisabled(false);
        } else {
            setIfDisabled(true);
        }
        setEventAddress(e.target.value);
    };

    const handleGenerate = () => {
        setGetWavierInfo(true);
    }

    const handleEdit = () => {
        setGetWavierInfo(false);
    }
    
    const customizeWaiversOnChange = (e) => {
        setWavierText(e.target.value);
    }

    const checkedWavierOnChange = (e) => {
        if(e.target.checked){
            setIfChecked(true);
            setGetWavierInfo(false);
            // setOrganName("")
            setIfDisabled(true);
        } else {
            setIfChecked(false);
            setIfDisabled(false);
        }
    }

    function checkInfoValidation() {
        (organName.length > 0 && !ifChecked) ||
        (ifChecked && wavierText.length > 0) ? setValidation(true) : setValidation(false);
    };

    useEffect(() => {
        checkInfoValidation();
    }, [organName, ifChecked, wavierText])

    useImperativeHandle(ref, () => ({
        saveDraft() {
            draftEvent.waivers = {organName, ifChecked, wavierText, getWavierInfo};
            setDraftEvent(draftEvent)
        }
    }))

    return (
        <PageContainer>
            <LeftGrid>
                <MainContainer>
                {ifChecked ? (
                    <FormUnit>
                        <LabelText>
                            Type your own wavier
                        </LabelText>
                        <TextField
                            value={wavierText}
                            multiline
                            rows={25}
                            placeholder="Please type your wavier here"
                            variant="outlined"
                            InputProps={{
                                classes:{
                                    input: classes.size,
                                }
                            }}
                            className={classes.textField}
                            onChange={customizeWaiversOnChange}
                        />
                    </FormUnit>
                    ) : (
                        <FormUnit>
                            <LabelText>
                                Use waiver template
                            </LabelText>
                            <NormalText>
                                Please input your organizer name. We will automatically generate wavier for you  
                            </NormalText>
                            {
                                !getWavierInfo ?
                                <FormContainer>
                                    <FormTitle>
                                        Organization Name
                                    </FormTitle>
                                    <FlexRow>
                                        <div className={classes.root}>
                                        <StyledInput
                                            required
                                            width='370px'
                                            name="organName"
                                            value={organName}
                                            onChange={organNameOnchange}
                                        />
                                        </div>
                                    {/* </FlexRow><br></br>
                                    <FlexRow>
                                        <StyledInput
                                            required
                                            width='370px'
                                            name="eventName"
                                            value={eventName}
                                            onChange={eventNameOnchange}
                                        />
                                    </FlexRow><br></br>
                                    <FlexRow>
                                        <StyledInput 
                                            required
                                            width='370px'
                                            name="eventAddress"
                                            value={eventAddress}
                                            onChange={eventAddressOnchange}
                                        /> 
                                        </FlexRow><br></br>
                                    <FlexRow>    
                                        */}
                                        <StyledButton 
                                            onClick={handleGenerate}
                                            fontSize="12px"
                                            disabled={ifDisabled}
                                            padding="5px 20px"
                                        >
                                            Generate
                                        </StyledButton>
                                    </FlexRow>
                                </FormContainer>
                                :
                                <FormContainer style={{backgroundColor: '#FAFAFA', padding:'27px 40px', width: '600px'}}>
                                    <FlexBetween>
                                        <div>
                                            <FlexRow>
                                                <BolderNormalText style={{color: "#C7C7C7"}}>
                                                    Organization Name
                                                </BolderNormalText>
                                                <Button 
                                                    onClick={handleEdit}
                                                    // onClick={setOnEdit(true)}
                                                    disableRipple 
                                                    style={{textTransform:'none', minWidth:'40px', backgroundColor:'#EDEDED', borderRadius:'7px', padding:0, marginLeft: '20px'}}>
                                                    Edit
                                                    <EditPencil style={{width: '7.5px', height:'7.5px'}}/>
                                                </Button>
                                            </FlexRow>
                                            <BolderNormalText>
                                                {organName}
                                            </BolderNormalText>
                                        </div>
                                        <YellowCheck style={{height: '50px', width:'50px'}}/>
                                    </FlexBetween>
                                    <FlexBetween style={{marginTop:'16px', marginBottom: '16px'}}>
                                        <div>
                                            <BolderNormalText style={{color: "#C7C7C7"}}>
                                                Event Name
                                            </BolderNormalText>
                                            <BolderNormalText>
                                                {draftEvent.basicInfo.name}
                                            </BolderNormalText>
                                        </div>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <div>
                                            <BolderNormalText style={{color: "#C7C7C7"}}>
                                                Event Address/ Online link  
                                            </BolderNormalText>
                                            <BolderNormalText>
                                            {draftEvent.basicInfo.onlineEvent ? 'Online':  `${draftEvent.basicInfo.address}, ${draftEvent.basicInfo.city} ${draftEvent.basicInfo.selectState}, ${draftEvent.basicInfo.zipCode}`}
                                            </BolderNormalText>
                                        </div>
                                        <Button 
                                            disableRipple
                                            onClick={() => setOpenWavier(true)}
                                            style={{height:'40px', color:'#000', borderRadius: '30px', border:'1px solid #000', width:'130px', textTransform:'none', fontSize: '12px'}}>
                                            View your wavier
                                        </Button>
                                    </FlexBetween>
                                </FormContainer>
                            }
                        </FormUnit>   
                    )
                }
                    
                </MainContainer>
                    <FlexFooter style={{position: 'relative', marginLeft: '141px'}}>
                        <FormControlLabel
                            control={
                                <BlueCheckbox
                                    color="primary"
                                    checked={ifChecked}
                                    onChange={checkedWavierOnChange}
                                    name='checkedWavier'
                                />
                            }
                            label={<BolderNormalText>I want to use my own wavier</BolderNormalText>}
                        />                
                    </FlexFooter>
            </LeftGrid>
            {openWavier &&
                <RightGrid>
                    <div className={classes.rightContent}>
                        <div style={{marginBottom:'10px', display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <ClearIcon 
                                style={{width:'18px', height:'18px', color:'#000000', marginRight:'20px'}} 
                                onClick={() => setOpenWavier(false)}
                            />
                            <SliderTitle>Wavier Preview</SliderTitle>
                        </div>
                        <Divider classes={{root: classes.divider}}/>
                        <Waiver orgName={organName}
                                eventName={draftEvent.basicInfo.name}
                                address={draftEvent.basicInfo.onlineEvent ? 'Online':  `${draftEvent.basicInfo.address}, ${draftEvent.basicInfo.city} ${draftEvent.basicInfo.selectState}, ${draftEvent.basicInfo.zipCode}`}
                        />
                    </div>
                </RightGrid>
            }
        </PageContainer>
    )
})

export default CEWaivers;