import React, {useState, forwardRef, useImperativeHandle} from "react";
import { Grid, MenuItem, TextField, Typography, Input} from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import styles from "./CEBasicInfo.module.css";
import Box from '@material-ui/core/Box';

import SearchLocationInput from "./SearchLocationInput";


const basicInfo = {
    marginLeft: '15%',
    marginTop: '5%',
    marginBottom: '30px',
    width: '50%'
}
const container = {
    marginBottom: '5%',
    
}
const title = {
    fontSize: '22px',
    color: '#363f91',
    fontWeight: '300',
    fontFamily:' -apple-system, BlinkMacSystemFont, sans-serif'
}

const useSingleInputStyle = makeStyles({
    input: {
      height: 40,
      fontSize: 12
    },
})

const useStyles = makeStyles({
    
    root: {
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#5666F6",
          boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
          // borderRadius: '24px',
        },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#5666F6",
          boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
          border: 'solid 1px',
          // borderRadius: '24px',
      },
      "& .MuiOutlinedInput-input": {
          fontSize: 12,
          
      },
      
    
    },
    error: {
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "red",
          boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
          color: '#FF6565'
          // borderRadius: '24px',
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "red",
          boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
          border: 'solid 1px',
          color: '#FF6565'
          // borderRadius: '24px',
      },
      "& .MuiOutlinedInput-input": {
          fontSize: 12,
          
      }
    },
    helperText: {
        fontSize: 10,
        textAlign: 'end'
    },
    toggleButton: {
        border: 'none', 
        backgroundColor: 'transparent', 
        display: 'block', 
        textTransform: 'none',
        "&.MuiToggleButton-root.Mui-selected":{
            backgroundColor: 'transparent'
        }
    },
    sportName: {
        color: 'black', 
        marginTop: '10px',
        fontSize: '14px'
    }, 
    resize: {
        width: '150px',
        height: '3px',
    }
  
  });

const CEBasicInfo = forwardRef((props, ref) => {
    const stateList = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 
    'Colorado', 'Connecticut', 'Delaware', 'D.C.', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 
    'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 
    'Wisconsin', 'Wyoming'];
    const { draftEvent, setDraftEvent, setValidation} = props;
    const classes  = useStyles();
    const singleInputClass = useSingleInputStyle();
    const [name, setName] = useState(draftEvent.basicInfo.name);
    const [detail, setDetail] = useState(draftEvent.basicInfo.detail);
    const [detailLengthValidation, setDetailLengthValidation] = useState(true);
    const [category, setCategory] = useState(draftEvent.basicInfo.category);
    const [sportIdx, setSportIdx] = useState(draftEvent.basicInfo.sportIdx);
    const [onlineEvent, setOnlineEvent] = useState(draftEvent.basicInfo.onlineEvent);
    const [address, setAddress] = useState(draftEvent.basicInfo.address);
    const [city, setCity] = useState(draftEvent.basicInfo.city);
    const [selectState, setSelectState] = useState(draftEvent.basicInfo.selectState);
    const [zipCode, setZipCode] = useState(draftEvent.basicInfo.zipCode);
    const [onlineLink, setOnlineLink] = useState(draftEvent.basicInfo.onlineLink);
    const onStateChange = (event) => {
        setSelectState(event.target.value);
    };
    const onZipCodeChange = (event) => {
        setZipCode(event.target.value);
    };
    const onOnlineLinkChange = (event) => {
        setOnlineLink(event.target.value);
    }
    const onCityChange = (event) => {
        setCity(event.target.value);
    }

    const renderNameChange = (value) => {
        setName(value);
        setDraftEvent(draftEvent);
    }
    const renderDetailChange = (value) => {
        setDetail(value);
        setDetailLengthValidation(detail.length <= 2500 && detail.length > 1);
    } 
    const handleCategory = (idx) => {
        setSportIdx([]);
        setCategory(idx);
    }

   function checkInfoValidation() {
        const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        console.log()
        if (name.length > 0 && 
            (detail.length > 1 && detail.length <= 2500) && 
            category !== -1 && 
            sportIdx !== -1 && 
            (
                (!onlineEvent && selectState !== null && (zipCode !== null && zipRegex.test(zipCode)) && (city !== null && city.trim().length > 0) && (address !== null && address.trim().length > 0)) || 
                (onlineEvent && onlineLink && onlineLink.length > 0)
            )) {
            setValidation(true);
            return;
        }
        setValidation(false);
    };

    useImperativeHandle(ref, () => ({
        saveDraft() {
            draftEvent.basicInfo = {name, detail, category, sportIdx, onlineEvent, selectState, zipCode, city, address, onlineLink};
            setDraftEvent(draftEvent)
        }
    }))

    return (
        <div style={basicInfo}>
            <div style={container}>
                <p style={title}>Event Name</p>
                <p style={{fontWeight: 300, fontFamily:' -apple-system, BlinkMacSystemFont, sans-serif'}}> Create an apposite and descriptive name for the event.</p>
                <TextField  
                    className={name.length > 75 ?classes.error :classes.root}
                    style={{width: '100%'}}
                    id="outlined-basic" 
                    variant="outlined"
                    value={name}
                    InputProps={{
                        endAdornment: <InputAdornment style={{position: 'absolute', bottom: '10px', right: '10px'}}>
                                        {name.length > 75 
                                        ?<span style={{color: '#FF6565', fontSize: 12,}}>{name.length} of 75</span>
                                        :<span style={{fontSize: 12, color:'#6B6B71'}}>{name.length} of 75</span>
                                        }
                                       </InputAdornment>,
                        className: singleInputClass.input
                    }}
                    onChange={e => renderNameChange(e.target.value)}
                />
                
            </div>

            <div style={container}>
                <p style={title}>Event Detail</p>
                <p style={{fontWeight: 300, fontFamily:' -apple-system, BlinkMacSystemFont, sans-serif'}}> Describe your event specifically and accurately. You can introduce which unique parts your guests could expect to, and what they’ll be gained from this experience.  </p>
                    <TextField
                        className={classes.root}
                        error={!detailLengthValidation}
                        style={{width: '100%'}}
                        id="outlined-multiline-static"
                        value={detail}
                        multiline
                        rows={15}
                        rowsMax={15}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment style={{position: 'absolute', bottom: '10px', right: '10px'}}>
                                            <span style={{color: detailLengthValidation ? '#6B6B71' : '#FF6565',fontSize: 12}}>{detail.length} of 2500</span>
                                           </InputAdornment>   
                        }}
                        FormHelperTextProps={{
                            className: classes.helperText
                        }}
                        onChange={e => renderDetailChange(e.target.value)}
                        helperText="*Input more than 1 characters for event detail"
                    />
                {/* } */}
                             
            </div>
            <div style={container}>
                <p style={title}>Choose a category</p>
                <div style={{marginBottom: '20px'}}>
                    <button className={category === 0 ? styles.categoryBtnFocus :styles.categoryBtn} onClick={() => handleCategory(0)} >Ball Sports</button> 
                    <button className={category === 1 ? styles.categoryBtnFocus :styles.categoryBtn} onClick={() => handleCategory(1)}>Water Sports</button>
                    <button className={category === 2 ? styles.categoryBtnFocus :styles.categoryBtn} onClick={() => handleCategory(2)}>Outdoor</button>
                    <button className={category === 3 ? styles.categoryBtnFocus :styles.categoryBtn} onClick={() => handleCategory(3)}>Indoor</button> 
                    <button className={category === 4 ? styles.categoryBtnFocus :styles.categoryBtn} onClick={() => handleCategory(4)}>Others</button>  
                </div>
                
                    {category == 0 && 
                    <div className={styles.categoryContainer}>
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(0)}>
                                {sportIdx === 0 ?<img src={require("../../assets/Group\ 4217.png")}></img>
                                                :<img src={require("../../assets/tennisIcon.png")}></img>}

                            </button>
                            <p>Tennis</p>
                        </div>  
                        <div style={{textAlign: 'center'}}>                   
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(1)}>
                                {sportIdx === 1 ?<img src={require("../../assets/Group\ 4211.png")}></img>
                                                :<img src={require("../../assets/tableTennisIcon.png")} ></img>}              
                            </button> 
                            <p>Table tennis</p>
                        </div>  
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(2)}>
                                {sportIdx === 2 ?<img src={require("../../assets/Group\ 4212.png")}></img>
                                                :<img src={require("../../assets/soccerIcon.png")}></img>}
                            </button> 
                            <p>Football</p>
                        </div>  
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(3)}>
                                {sportIdx === 3 ?<img src={require("../../assets/Group\ 4213.png")}></img>
                                                :<img src={require("../../assets/bowlingIcon.png")}></img>}
                            </button>
                            <p>Bowling</p>
                        </div>  
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(4)}>
                                {sportIdx === 4 ?<img src={require("../../assets/Group\ 4214.png")}></img>
                                                :<img src={require("../../assets/basketBallIcon.png")}></img>}
                            </button>
                            <p>Basketball</p>
                        </div>  
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(5)}>
                                {sportIdx === 5 ?<img src={require("../../assets/Group\ 4215.png")}></img>
                                                :<img src={require("../../assets/badmintonIcon.png")}></img>}
                            </button>
                            <p>Badminton</p>
                        </div>  
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(6)}>
                                {sportIdx === 6 ?<img src={require("../../assets/Group\ 4216.png")}></img>
                                                :<img src={require("../../assets/golfIcon.png")}></img>}
                            </button>
                            <p>Golf</p>
                        </div>  
                    </div>
                    }
                    {category == 1 && 
                    <div className={styles.categoryContainer}>
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(0)}>
                                {sportIdx === 0 ?<img src={require("../../assets/Group\ 4195.png")}></img>
                                                :<img src={require("../../assets/SailingIcon.png")}></img>}

                            </button> 
                            <p>Sailing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(1)}>
                                {sportIdx === 1 ?<img src={require("../../assets/Group\ 4196.png")}></img>
                                                :<img src={require("../../assets/KayakingIcon.png")} ></img>}              
                            </button> 
                            <p>Kayaking</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(2)}>
                                {sportIdx === 2 ?<img src={require("../../assets/Group\ 4197.png")}></img>
                                                :<img src={require("../../assets/FishingIcon.png")}></img>}
                            </button> 
                            <p>Fishing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(3)}>
                                {sportIdx === 3 ?<img src={require("../../assets/Group\ 4198.png")}></img>
                                                :<img src={require("../../assets/DivingIcon.png")}></img>}
                            </button>
                            <p>Diving</p>
                        </div> 
                    </div>
                    }
                    {category == 2 && 
                    <div className={styles.categoryContainer}>
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(0)}>
                                {sportIdx === 0 ?<img src={require("../../assets/Group\ 4199.png")}></img>
                                                :<img src={require("../../assets/Zipline.png")}></img>}
                            </button> 
                            <p>Zipline</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(1)}>
                                {sportIdx === 1 ?<img src={require("../../assets/Group\ 4205.png")}></img>
                                                :<img src={require("../../assets/Skiing.png")} ></img>}              
                            </button> 
                            <p>Skiing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(2)}>
                                {sportIdx === 2 ?<img src={require("../../assets/Group\ 4206.png")}></img>
                                                :<img src={require("../../assets/Running.png")}></img>}
                            </button> 
                            <p>Running</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(3)}>
                                {sportIdx === 3 ?<img src={require("../../assets/Group\ 4207.png")}></img>
                                                :<img src={require("../../assets/Horseriding.png")}></img>}
                            </button>
                            <p>Horseriding</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(4)}>
                                {sportIdx === 4 ?<img src={require("../../assets/Group\ 4208.png")}></img>
                                                :<img src={require("../../assets/Hiking.png")}></img>}
                            </button>
                            <p>Hiking</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(5)}>
                                {sportIdx === 5 ?<img src={require("../../assets/Group\ 4209.png")}></img>
                                                :<img src={require("../../assets/Climbing.png")}></img>}
                            </button>
                            <p>Climbing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(6)}>
                                {sportIdx === 6 ?<img src={require("../../assets/Group\ 4210.png")}></img>
                                                :<img src={require("../../assets/Camping.png")}></img>}
                            </button>
                            <p>Camping</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(7)}>
                                {sportIdx === 7 ?<img src={require("../../assets/Group\ 4219.png")}></img>
                                                :<img src={require("../../assets/Ballooning.png")}></img>}
                            </button>
                            <p>Air Sports</p>
                        </div> 
                    </div>
                    }
                    {category == 3 && 
                    <div className={styles.categoryContainer}>
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(0)}>
                                {sportIdx === 0 ?<img src={require("../../assets/Group\ 4189.png")}></img>
                                                :<img src={require("../../assets/YogaIcon.png")}></img>}

                            </button> 
                            <p>Yoga</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(1)}>
                                {sportIdx === 1 ?<img src={require("../../assets/Group\ 4191.png")}></img>
                                                :<img src={require("../../assets/FencingIcon.png")} ></img>}              
                            </button> 
                            <p>Fencing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(2)}>
                                {sportIdx === 2 ?<img src={require("../../assets/Group\ 4192.png")}></img>
                                                :<img src={require("../../assets/DancingIcon.png")}></img>}
                            </button> 
                            <p>Dancing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(3)}>
                                {sportIdx === 3 ?<img src={require("../../assets/Group\ 4193.png")}></img>
                                                :<img src={require("../../assets/BoxingIcon.png")}></img>}
                            </button>
                            <p>Boxing</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(4)}>
                                {sportIdx === 4 ?<img src={require("../../assets/Group\ 4194.png")}></img>
                                                :<img src={require("../../assets/Ellipse\ 1425.png")}></img>}
                            </button>
                            <p>Archery</p>
                        </div> 
                        <div style={{textAlign: 'center'}}>
                            <button className={styles.categoryIcon} onClick={() => setSportIdx(5)}>
                                {sportIdx === 5 ?<img src={require("../../assets/Group\ 4218.png")}></img>
                                                :<img src={require("../../assets/Game.png")}></img>}
                            </button>
                            <p>E-sports</p>
                        </div> 
                       
                    </div>
                }
                
            </div>
            <div style={{container}}>
                <p style={title}>Event Location</p>
                <div style={{marginBottom: '20px'}}>
                    <button className={onlineEvent ?styles.eventBtn :styles.eventBtnFocus} onClick={() => setOnlineEvent(false)}>Offline</button>
                    <button className={onlineEvent ?styles.eventBtnFocus :styles.eventBtn} onClick={() => setOnlineEvent(true)}>Online</button>
                </div>
                {onlineEvent 
                ? <input
                    value={onlineLink}
                    onChange={onOnlineLinkChange}
                    className={styles.onlineInput} 
                    placeholder="Input online link"
                >

                </input>
                :
                <Box>
                    <SearchLocationInput 
                        setZipCode={setZipCode}
                        setCity={setCity}
                        setState={setSelectState}
                        setAddress={setAddress}
                        address={address}
                    >
                    </SearchLocationInput>
                    <Grid container direction='row'>
                        <Box mt={1}>
                            <Typography variant="subtitle1" gutterBottom >City</Typography>
                            <TextField 
                                variant="outlined" 
                                InputProps={{ classes: { input: classes.resize } }}
                                value={city}
                                onChange={onCityChange}
                            />
                        </Box>
                        <Box mt={1} ml={1}>
                            <Typography variant="subtitle1" gutterBottom > State </Typography>
                            <Grid>
                                <FormControl variant="outlined">
                                    <Select
                                        labelId='outlined-selectState-label'
                                        id='outlined-selectState'
                                        value={selectState}
                                        onChange={onStateChange}
                                        style={{width: '130px', height: '40px'}}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select
                                        </MenuItem>
                                        {stateList.map((state, indx) => {
                                            return (
                                                <MenuItem value={state} key={indx}>
                                                    {state}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Box>
                        <div style={{marginLeft: '10px'}}></div>
                        <Box mt={1}>
                            <Typography variant="subtitle1" gutterBottom > Zip code </Typography>
                            <TextField 
                                variant="outlined" 
                                InputProps={{ classes: { input: classes.resize } }}
                                value={zipCode}
                                onChange={onZipCodeChange}
                            />
                        </Box>

                    </Grid>
                   
                    
                </Box>
                
                }
                {checkInfoValidation()}
            </div>
            
        </div>
        
    )
})

export default CEBasicInfo;