import React from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AuthContext = React.createContext()
const token = localStorage.getItem('token');
const initState = {
  isAuth: false,
  token: token,
  user: {},
  username: '',
  login_error_meg: false,
  signup_userid_error_meg: '',
  signup_email_error_meg: '',
};
class AuthProvider extends React.Component {
  constructor() {
      super()
      this.state = this.getInitialState();
      this.handle_login = this.handle_login.bind(this)
      this.handle_signup = this.handle_signup.bind(this)
      this.handle_logout = this.handle_logout.bind(this)
  }

  getInitialState() {
    if(!Boolean(token))
      return initState;

    try {
      const decoded = jwt_decode(token)
      if (!('user_id' in decoded && 'username' in decoded && 'exp' in decoded))
        return initState;

      const now = new Date().getTime();
      const unixExpireTime = decoded['exp'] * 1000;
      const isExpired = now > unixExpireTime;

      // May implement auto-refresh
      if(isExpired)
        return initState;

      return {
        isAuth: true,
        token: token,
        user: {id:decoded['user_id']},
        username: decoded['username']
      };
    } catch (e) {
        console.error(e)
        return initState;
    }
  }

  componentDidMount() {
    if(this.state.isAuth)
      this.get_current_user(token);
  }

  get_current_user = (token) => {
    let axiosConfig = {
      headers: {
          Authorization: `JWT ${token}`,
      }
    }

    axios.get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/current_user/`,
        axiosConfig
    ).then(res => {
      if(res.status == '200') {
          this.setState({
            isAuth: true,
            user: res.data,
            username: res.data.username
          })
      }
    }).catch(err => {
      console.error(err);
      this.setState(initState);
    });
  }

  handle_login = (e, data) => {
      e.preventDefault();
      let axiosConfig = {
          headers: {
              'Content-Type': 'application/json',
          }
      }

      axios.post(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/login`,
          JSON.stringify(data.user),
          axiosConfig
      )
      .then(res => {
          if(res.status == '200') {
              data.isAuth = true;
              localStorage.setItem('token', res.data.token);
              this.setState({ 
                isAuth: true,
                login_error_meg: false,
                user: res.data.user,
                username: res.data.user.username,
                token: res.data.token,
              })
            if (res.data.user.is_host) {
              this.props.history.push('/operator_center')
		        } else {
              this.props.history.push('/')
            }
          }
      }).catch(err => {
        this.setState({
          login_error_meg: true
        })
        console.log(err);
      });
  }

  handle_signup = (e, data) => {
    e.preventDefault();
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    axios.post(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/registration_web_app/`,
        JSON.stringify(data.user),
        axiosConfig
    )
    .then(res => {
        if (res.status == '203') {
          if (res.data.username) {
            this.setState({
              signup_userid_error_meg: res.data.username
            })
          }
          if (res.data.email) {
            this.setState({
              signup_email_error_meg: res.data.email
          })
          }
        }
        if(res.status == '201') {
            data.isAuth = true;
            localStorage.setItem('token', res.data.token)
            this.setState({ 
              isAuth: true,
              signup_userid_error_meg: '',
              signup_email_error_meg: '',
              username: res.data.username, 
            })
          this.props.history.push('/')
          //window.localStorage.setItem("nasu_user", null);
        }
        
    }).catch(err => {
      console.log(err);
    });
  }

  handle_logout() {
      localStorage.removeItem('token');
      this.setState({ 
        isAuth: false,
        user: {},
        username: '',
        token: '',
      })
  }

  render() {
      return (
        <AuthContext.Provider
            value={{
              isAuth: this.state.isAuth,
              login_error_meg: this.state.login_error_meg,
              signup_userid_error_meg: this.state.signup_userid_error_meg,
              signup_email_error_meg: this.state.signup_email_error_meg,
              username: this.state.username,
              user: this.state.user,
              handle_login: this.handle_login,
              handle_signup: this.handle_signup,
              handle_logout: this.handle_logout
            }}
        >
            {this.props.children}
        </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer
const AuthProviderWithRouter = withRouter(AuthProvider)

export { AuthContext, AuthProviderWithRouter, AuthConsumer }
