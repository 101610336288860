import React, { Component } from 'react';
import {
    Box, makeStyles, Paper, Divider,
} from '@material-ui/core';
import clsx from 'clsx';
// import "../../css/Registration.css";
import * as CONST from './RegistrationFormConst';
import RegistrationFormPreviewItem from './RegistrationFormPreviewItem';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: '29px 32px 19px 44px',
        '& div': {
            display: 'flex',
        }
    },
    body: {
        position: 'relative',
        padding: '40px',
        minHeight: '455px',
    },
    preivewFormGroup: {
        position: 'relative',
        display: 'grid'
    },
    previewBadge: {
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        padding: '4px 20px',
        color: '#FFFFFF',
        borderRadius: '13.5px',
        backgroundColor: '#CFCFCF'
    },
    previewPrimary: {
        color: '#5666F6',
        backgroundColor: 'rgba(86, 102, 246, 0.12)'

    }
}));

const { MODES, IMG_PATH, NO_EVNET_IMG } = CONST;

function RegistrationFormPreview(props) {
    const classes = useStyles();

    const isQuestionValid = () => {
        return props.questions && props.questions.length > 0;
    };

    const Header = (
        <div>
            <h5 style={{ fontWeight: '400' }}>Preview</h5>
            {(isQuestionValid() && props.settingMode === MODES.DISPLAY && props.formChanged) && <div className={classes.previewBadge}>Ready to generate</div>}
            {(
                isQuestionValid() && (props.settingMode === MODES.GENERATED || (props.settingMode === MODES.DISPLAY && !props.formChanged  ))
            ) && <div className={
                clsx(classes.previewBadge, classes.previewPrimary)
            }>Generated <CheckIcon style={{ marginLeft: '15px' }}></CheckIcon></div>}
        </div >
    );

    const NoForm = (
        <div style={{ margin: 0, position: 'absolute', top: 'calc(50% - 50px)', left: 'calc(50% - 40px)' }}>
            <img
                src={`${IMG_PATH}${NO_EVNET_IMG}`}
                alt="more"
            />
            <div style={{ fontWeight: 300, fontSize: '14px', color: '#A2A2A2' }}>No form yet</div>
        </div >
    );

    const previewItemList = (
        <div className={classes.preivewFormGroup}>
            {(isQuestionValid()) && props.questions.map((item, index) =>
                <RegistrationFormPreviewItem
                    key={index}
                    index={index}
                    previewItem={item}
                ></RegistrationFormPreviewItem>
            )}
        </div >
    );


    return (
        <Box className="RegistrationFormPreview">
            <Paper elevation={12}
                style={{
                    boxShadow: '0px 24px 50px rgba(184, 185, 190, 0.35)',
                    borderRadius: '15px'
                }}>
                <section className={classes.header}>
                    {Header}
                </section>
                <Divider />
                <section className={classes.body}>
                    {(isQuestionValid()) ? (previewItemList) : (NoForm)}
                </section>
                {/* <section>Actions</section> */}
            </Paper>
        </Box>);
}

export default RegistrationFormPreview;