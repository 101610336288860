import React, { Component } from 'react';


class LaunchApp extends Component {


    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        
    }

    open_iTunes() {
        window.location.replace(`https://apps.apple.com/us/app/nasufun/id1466772842`)
    }


    render() {
        const query = new URLSearchParams(this.props.location.search);
        // console.log(query.get("type"));
        // console.log(query.get("id"));
        const type = query.get("type");
        const id = query.get("id");
        if (type === "event") {
            var link = `nasufun://event/${id}`
            window.location.href = link ;
            setTimeout(this.open_iTunes, 1000);
        }
        return (
            <div>It's time to download NASFUN App!</div>
        );
    };
}

export default LaunchApp;