import React, { useState, useEffect } from 'react';
import {
  Box, Button, makeStyles,
  withStyles, Paper, Tabs, Tab, Typography
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { format } from 'date-fns';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: '500px',
    minHeight: '495px',
    backgroundColor: '#FFFFFF',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-45px',
    marginBottom: '50px',
  },
  nameText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 600,
    color: '#000',
    marginTop: '10px',
    marginBottom: '32px',
  },
  infoContainer: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '24px',
  },
  labelHeader: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
    color: '#5666F6',
    marginTop: '16px',
  },
  registrationInfoContainer: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
    border: '1px solid #E8EBFF',
    borderRadius: '22px',
    marginBottom: '30px',
    overflow: 'hidden',
    "&:last-child": {
      marginBottom: '0px'
    },
  },
  infoHeader: {
    padding: '16px',
    background: '#E8EBFF',
    color: '#5666F6',
    textAlign: 'center',
  },
  infoBody: {
    padding: '19px 29px'
  },
  table: {
    width: '100%',
    marginTop: '18px',
  },
  tableHeader: {
    fontSize: '10px',
    lineHeight: '22px',
    fontWeight: 'bold',
  },
  tableBody: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 300,
  },
  divideLine: {
    width: '100%',
    borderTop: '0.5px solid #D9D9D9',
  },
  orderTotalText: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
    marginBottom: '8px',
    marginTop: '48px',
  },
  priceText: {
    fontSize: '10px',
    lineHeight: '16px',
    color: '#757575',
  },
  lableRow: {
    marginTop: '10px',
  },
  registryInfoText: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 300,
  }
}))

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > span': {
      maxWidth: 50,
      width: '100%',
      backgroundColor: '#5666F6',
      borderRadius: '10px'
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'bolder',
  },
  '&:hover': {
    color: '#5666F6',
    opacity: 1,
  },
  '&$selected': {
    color: '#5666F6',
  },
  '&:focus': {
    color: '#5666F6',
  },
}))((props) => <Tab disableRipple {...props} />);

const PersonInfo = ({ person, ...props }) => {
  const classes = useStyles();
  // TODO: rename
  console.log('person', person)
  const { user, order, tickets, registrationInfo } = person;
  const { tax, coupon, credit, subtotal } = order;

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const renderContact = () => {
    return (
      <div className={classes.infoContainer}>
        <div className={classes.labelHeader}>Contact Info</div>
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeader}>
              <td>Email</td>
              <td>Phone</td>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.tableBody}>
              <td>{user.email}</td>
              <td>{order.phoneNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>);
  }

  const renderOrderInfo = () => {
    return (
      <div className={classes.infoContainer}>
        <div className={classes.labelHeader}>Ticket Info</div>
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeader}>
              <td style={{ width: '246px' }}># Order Number</td>
              <td>Purchased Date</td>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.tableBody}>
              <td>{order.orderNumber}</td>
              <td>{format(new Date(order.created), 'LLL d, yyyy')}</td>
            </tr>
          </tbody>
        </table>
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeader}>
              <td>Ticket type</td>
              <td></td>
              <td>Qty</td>
              <td>Price</td>
            </tr>
          </thead>
          <tbody>
            {tickets && tickets.map((ticket) => (
              <tr className={classes.tableBody} key={ticket.id}>
                <td style={{ width: '100px' }}>{ticket.name}</td>
                <td style={{ width: '146px', padding: '0 10px' }}>---------------------------</td>
                <td>{ticket.quantity}</td>
                <td>${ticket.price.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={classes.orderTotalText}>
          {`Order total: $${order.orderAmount.toFixed(2)}`}
        </div>
        <div className={classes.priceText}>{`Subtotal: $${(subtotal ? subtotal : 0).toFixed(2)}`}</div>
        <div className={classes.priceText}>{`Taxs: $${(tax ? tax : 0).toFixed(2)}`}</div>
        {coupon && <div className={classes.priceText}>{`Coupon: -$${coupon.toFixed(2)}`}</div>}
        {credit && <div className={classes.priceText}>{`Credit: -$${credit.toFixed(2)}`}</div>}
      </div>
    );
  }
  const renderRegistrationInfoItem = (registrationInfoItem) => {
    let infoItem = registrationInfoItem.registrationInfos
    
    return <div className={classes.infoBody}>
      {infoItem.map(info =>
        <div className={classes.lableRow}>
          <div className={classes.tableHeader}>{info.prompt}</div>
          <div className={classes.registryInfoText}>{info.answer}</div>
        </div>
      )}
    </div>
  }

  const renderRegistrationInfo = () => {
    return <div>
      {(registrationInfo && registrationInfo.length > 0) &&
        <>
          {registrationInfo.map((registrationInfoItem, index) =>
            registrationInfo.length > 1 ?
              <>
                <div className={classes.registrationInfoContainer}>
                  <div className={classes.infoHeader}>Attendee {index + 1}</div>
                  {renderRegistrationInfoItem(registrationInfoItem)}
                </div>
              </> :
              renderRegistrationInfoItem(registrationInfoItem)
          )}
        </>
      }
    </div>
  }

  const renderTicketInfo = () => {
    return (
      <div className={classes.dialogContainer}>
        <div className={classes.avatarContainer}>
          <img src={user.avatarUrl} style={{ width: '80px', height: '80px', zIndex: 9000, position: 'fixed', borderRadius: '50%' }} />
        </div>
        <div className={classes.nameText}>{user.firstName} {user.lastName}</div>
        {(registrationInfo && registrationInfo.length > 0) && 
        <StyledTabs value={tabIndex} indicatorColor="primary" textColor="primary"
          onChange={handleChange}
          aria-label="OrderInfoTab">
          <StyledTab label="Ticket Info" />
          <StyledTab label="Registration Info" />
        </StyledTabs>
        } 
        {!registrationInfo &&   <StyledTab label="Ticket Info" />}
        <div className={classes.divideLine}></div>
        <TabPanel value={tabIndex} index={0}>
          {renderOrderInfo()}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {renderRegistrationInfo()}
        </TabPanel>
      </div>)
  }

  return (
    renderTicketInfo()
  )
}

export default PersonInfo;
