import React, {useState, forwardRef, useImperativeHandle} from "react";
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { 
  TextField, 
  Box, 
  FormControl, 
  FormHelperText,
  Drawer,
  CssBaseline,
  Divider,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  Popover,
  IconButton,
 } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import _uniqueId from 'lodash/uniqueId';
import CETicketDrawer from './CETicketDrawer';
import {  CEMode, EventAction } from './constant';

const drawerWidth = 400;

const BlueCheckbox = withStyles({
  root: {
      '&$checked': {
      color: '#5666F6',
      },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    minHeight: '800px',
    minWidth: '375px',
    overflowY: 'scroll',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 0,
    
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 0,
  },
  paperOpen: {
    width: drawerWidth,
    backgroundColor: '#F9F9F9',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
    borderColor: 'transparent',
  },
  paperClose: {
    overflowX: 'hidden',
    width: 0,
  },
  button: {
    display: 'flex', 
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  addButton: {
      display: 'flex', 
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 369,
      height: 45,
      borderRadius: 15,
      boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
      backgroundColor: 'transparent',
      "&:hover": {
          backgroundColor: 'transparent',
      },
      marginTop: 30, 
      marginLeft: 145, 
  },
  addButtonText: {
      fontSize: 12,
      fontWeight: '400',
      color: '#8F8F8F',
      marginLeft: 18, 
  },
  closeButton: {
    color: "#000000",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 60,
    height: 60,
    "&:hover": {
        backgroundColor: 'transparent'
    }
  },
  sidebarTitle: {
      fontSize: 12,
      fontWeight: '400',
      letterSpacing: 1,
  },
  errorText: {
      fontSize: 12,
      fontWeight: '400',
      marginLeft: 327,
  },
  errorTextLeft: {
    fontSize: 12,
    fontWeight: '400',
    marginLeft: 34,
  },
  errorTextRight: {
      fontSize: 12,
      fontWeight: '400',
  },
  allTextField: {
    display: 'flex',
    justifyContent:' center',
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '100%',
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  errorTextField: {
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '100%',
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF6565",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF6565",
      border: 'solid 1px',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF6565",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12
    },
  },
  inputText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '120%',
    alignSelf:'center',
    display:'flex',
    width: '100%',
  },
  ticketAddedContainer: {
    width: 600,
    height: 'auto',
    borderRadius: 7,
    backgroundColor: '#F4F5FF',
  },
  ticketAddedTitle: {
    width: '80%',
    fontSize: 14,
    fontWeight: '500',
  },
  ticketAddedPrice: {
    fontSize: 18,
    fontWeight: '700',
    color: '#F5A626',
  },
  body1: {
    fontSize: 10,
    fontWeight: '300',
  },
  body2: {
    fontSize: 11, 
    fontWeight: '300',
  },
  ticketAddedCutoff: {
    display: 'flex', 
      width: 193,
      height: 18,
      borderRadius: 10, 
      backgroundColor: "#FFFFFF", 
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'center',
      marginBottom: 12,
      inlineSize: 'fit-content',
  }, 
  editButton: {
      width: 54, 
      height: 22,
      backgroundColor: "#FFFFFF",
      borderRadius: 11,
      marginRight: 26, 
      marginBottom: 12,
      "&:hover": {
        backgroundColor: 'transparent'
    }
  },
  editButtonText: {
      fontSize: 10,
      marginLeft: 3,
      fontWeight: '300',
  },
  cancelTicketButton: {
      width: 25,
      height: 25,
      marginLeft: 587,
  },
  popoverPaper: {
    width: 125,
    height: 32,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    backgroundColor: 'transparent'
  },
  popoverDialog: {
    width: 120,
    height: 32,
    borderRadius: 7, 
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    backgroundColor: '#C1C1C1',
    position: 'relative',
    left: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&::before': {
      content: `''`,
      position: 'absolute',
      left: '-10px',
      top: 8,
      width: 10,
      height: 10,
      borderTop: '5px solid transparent',
      borderRight: '5px solid #C1C1C1',
      borderBottom: '5px solid transparent',
    }
  },
  select: {
  "& .MuiSelect-select": {
    transition: "width 2s, height 4s",
  }
  },
  menuText: {
    fontSize: 12, 
    fontWeight: '400',
  },
  checkBox: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    "& .Mui-checked": {
      backgroundColor: 'pink',
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
    "& .MuiCheckbox-colorPrimary": {
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
  }
}));

const CETickets = forwardRef((props, ref) => {
    const classes = useStyles();
    const { draftEvent, setDraftEvent, setValidation, mode  } = props;

    const [open, setOpen] = React.useState(false);
    const [editedTicketInfoIndex, setEditedTicketInfoIndex] = React.useState(null)
    const [allTickets, setAllTickets] = React.useState(draftEvent.tickets.allTickets);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const getTickets = () => {
    // console.log(allTickets, allTickets.filter(ticket => 
    //  !('action' in ticket && 
    //     typeof ticket.id === 'number' &&
    //     ticket['action'] === EventAction.DELETE)
    //   ) )
        return allTickets.filter(ticket => 
          !('action' in ticket && 
            typeof ticket.id === 'number' &&
            ticket['action'] === EventAction.DELETE)
        )
    }
    
    const handleDrawerClose = () => {
        setEditedTicketInfoIndex(null);
        setOpen(false);
    };

    const handleCreate = (ticketInfo) => {
      const newTickets = [...allTickets];
      if(editedTicketInfoIndex !== null) {
        if(mode === CEMode.EDIT_REJECTED && 'action' in ticketInfo ) {
          ticketInfo.action = EventAction.UPDATE;
        } 
        newTickets[editedTicketInfoIndex] = ticketInfo;

      } else {
        if(mode === CEMode.EDIT_REJECTED) {
          ticketInfo.action = EventAction.CREATE;
        }
        newTickets.push(ticketInfo);
      }
      setAllTickets(newTickets);
      handleDrawerClose();
    };

    const toggleCloseTicketInfo = (ticketId) => {
      if(open) 
        return;
      const ticketOnDeleteIndex = allTickets.findIndex(t => t.id === ticketId)
      if (mode === CEMode.EDIT_REJECTED  && 'action' in allTickets[ticketOnDeleteIndex]) {
        const newAllTickets = [...allTickets];
        newAllTickets[ticketOnDeleteIndex].action = EventAction.DELETE;
        setAllTickets(newAllTickets);
      } else {
        const newTickets = allTickets.filter(ticket => ticket.id !== ticketId);
        setAllTickets(newTickets);
      }

    };

    const getAllTicketNames = () => {
      // TODO: combine date times
      return getTickets().map(t => t.ticketName);
    }

    const handleEdit = (index) => {
      if(open) 
        return;

      setEditedTicketInfoIndex(index)
      setOpen(true);
    };

    const checkInfoValidation = () => {
      if (allTickets && getTickets().length > 0 && !open) {
          setValidation(true);
          return;
      }
      setValidation(false);
    };

    useImperativeHandle(ref, () => ({
      saveDraft() {
          draftEvent.tickets.allTickets = allTickets;
          setDraftEvent(draftEvent)
      }
    }))

      return (
        <div className={classes.root}>
          <CssBaseline />
          <CETicketDrawer 
            allTickets={getTickets()} 
            open={open} 
            handleTicketCreate={handleCreate}
            handleDrawerClose={handleDrawerClose}
            editedTicketInfoIndex={editedTicketInfoIndex}
            getAllTicketNames={getAllTicketNames}
              ></CETicketDrawer>
          <main className={classes.content}>

            <p style={{ color: '#212759', fontSize: 22, fontWeight: '300', marginTop: 86, marginLeft: 145 }}>Tickets</p>
            <p style={{ color: '#000000', fontSize: 14, fontWeight: '300', marginTop: 17, marginLeft: 145 }}>
              Create your different types of tickets here.
            </p>

            { getTickets().length > 0 && getTickets().map( (ticket, index) =>
            <div key={index} style={{ marginTop: 10, marginLeft: 145 }}>
              <Box style={{ width:600 }}>
            {!open && <IconButton
                // color="#484D76"
                aria-label="cancle-ticket"
                // edge="start"
                className={classes.cancelTicketButton}
                style={{ position: 'relative', top: 15, right: 3 }}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                onClick={ () => toggleCloseTicketInfo(ticket.id) }
                >
                        <CancelIcon style={{ width: 20, height: 20, alignSelf: 'center'}} />
            </IconButton>}
        <Box className={classes.ticketAddedContainer}>    
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className={classes.ticketAddedTitle} style={{ marginLeft: 22, marginTop: 10, width: '80%', wordWrap:'break-word' }}>{ ticket.ticketName }</div>
                    <span className={classes.ticketAddedPrice} style={{ marginRight: 22, marginTop: 10 }}>${ ticket.price }</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <span className={classes.body1} style={{ marginLeft: 22, marginTop: 5 }}>{ ticket.eventStartTime.split(":", 1)[0] <= 9 ? ticket.eventStartTime.slice(1) + " AM" : ticket.eventStartTime.split(":", 1)[0] <= 11 ? ticket.eventStartTime + " AM" : ticket.eventStartTime.split(":", 1)[0] == 12 ? ticket.eventStartTime + " PM" : parseInt(ticket.eventStartTime.split(":", 1)[0]) - 12 + ":" + ticket.eventStartTime.split(":").slice(1) + " PM" } | { ticket.duration }</span>
                    <span className={classes.body1} style={{ marginRight: 22, marginTop: 5 }}>{ ticket.unlimited ? "Unlimited tickets" : "" }</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <Divider style={{ width: 555, marginTop: 10 }} />
                </div>
                    <p 
                    className={classes.body2}
                    style={{ width: '80%', wordWrap:'break-word', textAlign:'justify', marginTop: 10, marginLeft: 22 }}
                    >
                        { ticket.ticketDescription }
                    </p>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 22 }}>
                    <Box className={classes.ticketAddedCutoff}>
                        <span style={{ fontSize: 10, fontWeight: '400', color: '#000000' }}>Cutoff: { ticket.sellingCutoffDate }</span>
                    </Box>
                    {!open && <IconButton
                    color="inherit"
                    aria-label="edit"
                    className={classes.editButton}
                    disableRipple={true}
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                    onClick={ () => handleEdit(index) }
                    >
                        <div className={classes.editButtonText}>Edit</div>
                        <EditIcon style={{ marginLeft: 5 }} />
                    </IconButton>}
                </div>
            </Box>
            </Box>
            </div>
            )}

            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx({
                  [classes.addButton]: !open,
                  [classes.hide]: open,
                })}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                >
                  <span style={{ color: '#8F8F8F', fontSize: 12, fontWeight: '400', marginLeft: 18}}>Add different ticket</span>
                <AddIcon style={{ width: 20, height: 20, marginRight: 12 }} />
            </IconButton>

          </main>
          {checkInfoValidation()}
        </div>
      );
})

export default CETickets;