import React from 'react';

export const refundDateOptions = {
    "Full":[
      {key:'7', name: 'Full refund until 7 days before the event starts'},
      {key:'5', name: 'Full refund until 5 days before the event starts'},
      {key:'3', name: 'Full refund until 3 days before the event starts'},
      {key:'2', name: 'Full refund until 48 hours before the event starts'},
      {key:'1', name: 'Full refund until 24 hours before the event starts'},
    ],
    "Half":[
      {key:'7', name: '50% refund until 7 days before the event starts'},
      {key:'5', name: '50% refund until 5 days before the event starts'},
      {key:'3', name: '50% refund until 3 days before the event starts'},
      {key:'2', name: '50% refund until 48 hours before the event starts'},
      {key:'1', name: '50% refund until 24 hours before the event starts'},
    ],
    "Zero":[
      {key:'7', name: 'No refund'},
      {key:'5', name: 'No refund'},
      {key:'3', name: 'No refund'},
      {key:'2', name: 'No refund'},
      {key:'1', name: 'No refund'},
    ],
}

export const defaultEvent = {
  basicInfo: {
      name: "",
      detail: "",
      // TODO
      category: -1,
      sportIdx: -1,
      categoryName: "",
      onlineEvent: false,
      selectState: null,
      zipCode: null,
      address: null,
      city: null,
      onlineLink: ""
  },
  photos: {
      fileObj: [],
      urls: []
  },
  waivers: {
      organName: "",
      eventName: "",
      eventAddress: "",
      wavierText: "",
      ifChecked: false,
      getWavierInfo: false
  },
  advancedSetting: {
      bringNameList: [],
      bringName: "", 
      mindText: "",
      mindTextLength: 0,
      refund: "Full",
      refundDate: "7",
      refundPolicy: "",
      checkedList: {
          bringName: false,
          keepInMind: false,
          commitment: true,
          requirement: true,
      }
  },
  tickets: {
      allTickets: []
  },
  dateAndTimes: {
    ticketsDates: []
  },
};

export const EventCategoryIdNameMap = {
  "00": "Tennis",
  "01": "Table Tennis",
  "02": "Soccer",
  "03": "Bowling",
  "04": "Basketball",
  "05": "Badminton",
  "06": "Golf",
  "10": "Sailing",
  "11": "Kayaking",
  "12": "Fishing",
  "13": "Diving",
  "20": "Zipline",
  "21":  "Skiing",
  "22":  "Running",
  "23": "Horseriding",
  "24": "Hiking",
  "25": "Climbing",
  "26": "Camping",
  "27": "Air Sports",  // missing
  "30": "Yoga",
  "31": "Fencing",
  "32": "Dancing",
  "33": "Boxing",
  "34": "Archery",
  "35": "E-sports",
  "4": "Others"
}

export const EventAction = {
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
}

export const CEMode = {
  EDIT_REJECTED: 'er',
  CREATE_DRAFT: 'cd',
  EDIT_DRAFT: 'ed',
}

