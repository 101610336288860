import React, { Component } from 'react';
import {
    Box, Grid, makeStyles, Modal
} from '@material-ui/core';

// import "../../../css/Registration.css"
import RegistrationFormPreview from './RegistrationFormPreview';
import RegistrationFormSetting from './RegistrationFormSetting';
import * as CONST from './RegistrationFormConst';
import axios from 'axios';
import { Dialog, DeepEqual } from './RegistrationFormUtil';

const { REGISTRATION_FORM_SETTING_INIT, DIALOG_INIT, DIALOG_CONGRA, MODES } = CONST;
class RegistrationFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settingMode: MODES.INIT,
            setting: REGISTRATION_FORM_SETTING_INIT,
            questions: [],
            form: {},
            dialog: DIALOG_INIT,
            eventGrades: []
        };
        this.onQuestionsUpdated = this.onQuestionsUpdated.bind(this);
        this.onModeUpdated = this.onModeUpdated.bind(this);
        this.onEditingAborted = this.onEditingAborted.bind(this);
        this.onSettingUpdated = this.onSettingUpdated.bind(this);
        this.onDeleteForm = this.onDeleteForm.bind(this);
        this.onDialogConfirmed = this.onDialogConfirmed.bind(this);
        this.onDialogDismissed = this.onDialogDismissed.bind(this);
        this.isFormChanged = this.isFormChanged.bind(this);
    }

    componentDidMount() {
        this.getForm();
        this.getEvenGrades();
    }

    async getForm() {
        const { eventId } = this.props;
        let res = await axios
            .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/registration_form/`);
        if (res.data && res.data.Success) {
            const { questions, ...setting } = res.data.Data;
            this.setState({
                settingMode: (questions && questions.length > 0) ? MODES.GENERATED : MODES.INIT,
                setting: setting,
                questions: questions,
                form: res.data.Data
            });
        } else {
            this.initForm();
        }
    }

    async createOrUpdateForm() {
        const { eventId } = this.props;
        const form = { ...this.state.setting, questions: this.state.questions };
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/create_registration_form/`;
        let res = await axios
            .post(url, form);

        if (res.data && res.data.Success) {
            this.onOpenDialog(DIALOG_CONGRA, {});
            this.setState({
                settingMode: MODES.GENERATED,
                form: form
            });
        }
    }

    async deleteForm() {
        const { eventId } = this.props;
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/registration_form/delete/`;
        let res = await axios
            .delete(url);

        if (res.data && res.data.Success) {
            this.initForm();
        }
    }

    async getEvenGrades() {
        const { eventId } = this.props;
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/events/${eventId}/grades/`;
        let res = await axios
            .get(url);

        if (res.data) {
            this.setState({
                eventGrades: res.data
            })
        }
    }


    onOpenDialog(text, data) {
        const dialog = {
            open: true,
            dialog: {
                text: text,
                data: data
            }
        };
        this.setState({ dialog });
    };

    onDialogConfirmed(e) {
        this.setState({ dialog: DIALOG_INIT });
    };

    onDialogDismissed(e) {
        this.setState({ dialog: DIALOG_INIT });
    }

    onQuestionsUpdated(e) {
        const questions = e.map(item => ({ ...item }));
        this.setState({ questions });
    }

    onSettingUpdated(e) {
        const setting = { ...e };
        this.setState({ setting });
    }

    onModeUpdated(e) {
        if (e === MODES.GENERATED) {
            let res = this.createOrUpdateForm();
        } else {
            this.setState({ settingMode: e });
        }
    }

    onEditingAborted(e) {
        const { questions, ...setting } = this.state.form;

        this.setState({
            settingMode: (questions && questions.length > 0) ? MODES.GENERATED : MODES.INIT,
            setting: setting,
            questions: questions,
        });
    }

    onDeleteForm() {
        this.deleteForm();
    }

    initForm() {
        const questions = [];

        this.setState({
            settingMode: MODES.INIT,
            setting: REGISTRATION_FORM_SETTING_INIT,
            questions: questions
        });
    }

    isFormChanged() {
        const cur = { ...this.state.setting, questions: this.state.questions };
        const prev = { ...this.state.form };
        return !DeepEqual(cur, prev);
    }

    render() {
        const { questions, setting, settingMode, form, eventGrades } = this.state;
        return (
            <Box className="RegistrationFormContainer">
                <Grid container spacing={4}>
                    <Grid item sm={7} xs={12}>
                        <RegistrationFormSetting
                            questions={questions}
                            setting={setting}
                            settingMode={settingMode}
                            eventGrades={eventGrades}
                            formChanged={this.isFormChanged()}
                            onQuestionsUpdated={this.onQuestionsUpdated}
                            onSettingUpdated={this.onSettingUpdated}
                            onModeUpdated={this.onModeUpdated}
                            onEditingAborted={this.onEditingAborted}
                            onDeleteForm={this.onDeleteForm}
                        ></RegistrationFormSetting>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <RegistrationFormPreview
                            questions={questions}
                            settingMode={settingMode}
                        ></RegistrationFormPreview>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.dialog.open}
                    dialog={this.state.dialog.dialog}
                    formChanged={this.isFormChanged()}
                    onDialogDismissed={this.onDialogDismissed}
                    onDialogConfirmed={this.onDialogConfirmed}
                ></Dialog>
            </Box>);
    }
}

export default RegistrationFormContainer;