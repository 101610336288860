import React, { Component } from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';

import NewCheckout from './NewCheckout';
import "../../css/stripe.css";

class NewStripePayment extends Component {
    render() {
        return (
            <StripeProvider apiKey="pk_live_zir6jLATqn8Z4dyJG9pVDa8V">
                <div className="example">
                    <Elements>
                        <NewCheckout amount={this.props.amount} des={this.props.des}/>
                    </Elements>
                </div>
            </StripeProvider>
        )
    };
}

export default NewStripePayment;