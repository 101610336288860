import React, {useState, useEffect} from 'react';
import { makeStyles, TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width:'800px',
    height:'495px',
    backgroundColor:'#FFFFFF',
  },
  headerContainer:{
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center',
    marginTop:'-30px',
    marginBottom: '18px',
    fontSize:'16px',
    lineHeight:'19px',
    fontWeight: 300,
  },
  divideLine: {
    width: '100%',
    borderTop:'0.5px solid #D9D9D9',
  },
  bodyContainer: {
    padding: '20px 50px',
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center',
  },
  introText: {
    fontSize:'12px',
    lineHeight:'16px',
    fontWeight: 300,
  },
  helperText: {
    fontSize:'12px',
    lineHeight:'16px',
    fontWeight: 300,
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'flex-end',
    position: 'absolute',
    bottom: 0,
    right: -10,
  },
  headerText: {
    fontSize:'14px',
    lineHeight:'18px',
    fontWeight: 500,
    marginTop:'25px'
  },
  subHeaderText: {
    fontSize:'11px',
    lineHeight:'15px',
    fontWeight: 300,
    marginTop:'5px'
  },
  noteText: {
    fontSize:'12px',
    lineHeight:'15px',
    fontWeight: 'normal',
    color:"#6B6B71",
    marginBottom:'15px',
  },
  textField: {
    width: '100%',
    marginTop:'18px',
  },
  errorMessage: {
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'flex-end', 
    color:'red',
    fontSize:'12px'
  },
  sentButton: {
    width: '228px',
    height:'45px',
    borderRadius:'30px',
    fontSize: '16px',
    textTransform:'none',
    color: '#FFF',
    backgroundColor:'#5666F6',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor:'#FFF',
      color:'#5666F6',
      border:'1px solid #5666F6',
    },
    '&:disabled': {
      backgroundColor:'#CDCFE2',
      color: '#FFF',
      color: '#FFF',
    }
  }
}))

const NotifyAll = ({
  error,
  notify,
  notifyOnChange,
  validateRequired,
  validateNotify,
  handleSubmit,
  ...props
}) => {
  const [count, setCount] = useState(0);
  const classes = useStyles();

  return (
    <div className={classes.dialogContainer}>
      <div className={classes.headerContainer}>
        Notify All
      </div>
      <div className={classes.divideLine}></div>
      <div className={classes.bodyContainer}>
        <div className={classes.introText}>
          If you have significant reminders about the time or arrangement of the ticket, please notify your attendees here. If you want to cancel this ticket with an emergency reason, please firstly go back and apply for a cancellation request.
        </div>
        <div className={classes.headerText}>
          Early Bird Ticket
        </div>
        <div className={classes.subHeaderText}>
          Oct 21, 2020
        </div>
        <div style={{marginBottom:'1px', width:'100%'}}>
          <TextField
              name="notify"
              id="notify"
              value={notify}
              helperText={`${count}/1000`}
              multiline
              rows={12}
              variant="outlined"
              inputProps={{ maxLength: 1000, }}
              InputProps={{
                  classes:{
                      input: classes.introText,
                  }
              }}
              FormHelperTextProps={{
                className: classes.helperText
              }}
              className={classes.textField}
              
              onChange={(e) => {
                notifyOnChange(e, validateRequired);
                validateNotify(e.target.value, e.target.name);
                setCount(e.target.value.length);
              }}
              error={!!error.notify}
          />
         
        </div>
          {error.notify && <div className={classes.errorMessage} style={{display: 'flex', justifyContent:'flex-end', marginLeft: 'auto'}}>{error.notify}</div>}
        <div className={classes.noteText}>          
          *Attendees will also be notified by Email 
        </div>
        <Button
          variant="contained"
          className={classes.sentButton}
          disabled={!(notify && !error.notify)}
          size="medium"
          onClick={handleSubmit}
        >
            Send
        </Button>
        
        
      </div>
    </div>
  )
}

export default NotifyAll;