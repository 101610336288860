import React, { Component } from 'react';

class Waiver extends Component {
  constructor(props) {
    super(props);
  }

  render() {    
    const {eventName, orgName, address} = this.props;
    return (
      <div>
      <center><strong>Accident Waiver and Release of Liability</strong></center>

        <p>
            I hereby assume all risks of participating in the {eventName} event held by {orgName} the Date chosen. Including by way of example and not limited to, any risks that may arise from negligence or carelessness on the part of {orgName}, and/or their directors, officers, agents, coaches, representatives, or volunteers (collectively, “NASU”), from dangerous or defective equipment or property owned, maintained, or controlled by them, or because of their possible liability without fault.
        </p>
        <p>    
            I certify I am physically fit and that there are no health-related reasons which preclude my participation in this event. I certify that I have not been advised to not participate in this event by any qualified medical professional.  I acknowledge that this waiver may be used by {orgName}. 
        </p>
        <p>
            I hereby take action for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows: 
            (a) I waive, release, and discharge {orgName} from any and all liability, including but not limited to, liability arising from the negligence or fault of the entities or persons released, for my death, disability, personal injury, property damage, property theft, or actions of any kind which may hereafter occur to me including my traveling to and from this event. 
            (b) I indemnify, hold harmless, and promise not to sue {orgName} for any and all liabilities or claims made as a result of participation in this event, whether caused by negligence or otherwise. 
            (c) I acknowledge that this event may carry with it the potential for death, serious injury, and personal loss. The risks may include, but are not limited to, those caused by terrain, facilities, weather, lack of hydration, equipment, negligence, vehicular traffic, actions of myself and of other people, including, but not limited to, {eventName} organizers, participants, volunteers, spectators, and coaches. 
            (d) I release and forever discharge {orgName} from any claim whatsoever which arises or may hereafter arise on account of any first-aid treatment or other medical services rendered to me in connection with an emergency during my time with {orgName}.
            (e) I consent and agree that {orgName} may take photographs and/or digital recordings of me as participant during this event and I grant unto {orgName} all right, title, and interest in these materials. I further consent that my identity may be revealed therein or by description text or commentary. I waive any rights, claims or interest to any royalties, proceeds, or other benefits derived from such photographs or recordings.
            (f) I understand that my behavior during this event must comply with all rules and regulations in place at {address}, and release {orgName} from any liability for my behavior.
            (g) The accident waiver, release of liability and image release shall be construed broadly and to provide a release and waiver to the maximum extent permissible under the laws of the Commonwealth of Massachusetts. 
            I certify that I have read this document, and I fully understand its content. I am aware that this is a release of liability and a contract and I sign it freely and voluntarily.
        </p>
        <p>
            By checking I agree the terms above and forfeit all right to bring a suit against the party Organizer. I will take full responsibility to any injury that may occur to me and my family members.
        </p>
      </div>
    );
  }
}

export default Waiver;
