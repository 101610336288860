import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
    makeStyles, MenuItem, FormControl, Button,
    Select, Checkbox, FormControlLabel,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
// import "../../css/Registration.css";
import * as CONST from './RegistrationFormConst';
import { Dialog, Tootip, DeepEqual } from './RegistrationFormUtil';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginBottom: '20px',
        "&:last-child": {
            marginBottom: '0px'
        },
    },
    flex: {
        display: 'flex'
    },
    grid: {
        display: 'grid'
    },
    formGroupLabel: {
        display: 'flex',
        marginLeft: '2px',
        marginBottom: '12px',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    formGroupOutline: {
        border: '1px solid #C9C9C9',
        padding: '24px 28px',
        borderRadius: '7px'
    },
    formLabel: {
        display: 'flex',
        marginLeft: '2px',
        marginBottom: '12px',
        fontSize: '12px',
        fontWeight: 'bold'
    },
    formDisplay: {
        fontSize: '12px',
        lineHeight: '14px',
        marginBottom: '9px',
        "&:last-child": {
            marginBottom: '0px'
        },
        "& span": {
            marginLeft: "12px",
        }
    },
    formDisplayEdit: {
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        cursor: 'pointer',
    },
    qeustionItemActions: {
        display: 'flex',
        marginTop: '18px',
        justifyContent: 'flex-end',
    },
    qeustionItemActionBtn: {
        width: '64px',
        height: '36px',
        borderRadius: '30px',
        marginLeft: '12px',
    },
    qeustionItemActionBtnPrimay: {
        backgroundColor: '#5666F6',
    },
    questionTypeMenuItemDivider: {
        borderBottom: '0.5px solid #888888'
    },
}));

const { MODES,
    REGISTRATION_FORM_COLLECT_FROM_OPTIONS,
    REGISTRATION_FORM_COLLECT_DUE_OPTIONS,
    REGISTRATION_FORM_SETTING_INIT,
    DIALOG_INIT, DIALOG_TYPES, DIALOG_SELECT_DUE,
    IMG_PATH, EDIT_IMG } = CONST;

function RegistrationFormSettingInit(props) {
    const classes = useStyles();
    const isFormExist = false;

    const InitValue = props.setting ? props.setting : { ...REGISTRATION_FORM_SETTING_INIT };
    const [setting, setSetting] = React.useState(InitValue);

    useEffect(() => {
        console.log('effect2', props)
        const newSetting = props.setting ? props.setting : { ...REGISTRATION_FORM_SETTING_INIT };
        setSetting(newSetting);
    }, [props.setting]);

    const [dialog, setDialog] = React.useState(DIALOG_INIT);

    const onOpenDialog = (text, data) => {
        setDialog({
            open: true,
            dialog: {
                text: text,
                data: data
            }
        });
    };

    const onDialogDismissed = (e) => {
        setDialog(DIALOG_INIT);
    };


    const collectOptionTip = 'One buyer may purchase tickets on behalf of multiple attendees.'

    const handleSelect = (event) => {
        const newSetting = { ...setting, selectedTickets: event.target.value };
        setSetting(newSetting);
        // props.onSettingUpdated(newSetting);
    };

    const handleCheckAll = (event) => {
        const newSetting = { ...setting, selectAll: event.target.checked };
        setSetting(newSetting);
        // props.onSettingUpdated(newSetting);
    };

    const onEditClick = (event) => {
        props.onEditClick(event);
    };

    const handleCollectFromChange = (event) => {
        const newSetting = { ...setting, collectFrom: event.target.value };
        setSetting(newSetting);
        // props.onSettingUpdated(newSetting);
    };

    const handleCollectDueChange = (e) => {
        if (REGISTRATION_FORM_COLLECT_DUE_OPTIONS[e.target.value] ===
            REGISTRATION_FORM_COLLECT_DUE_OPTIONS.BEFORE) {
            onOpenDialog(DIALOG_SELECT_DUE, e);
        } else {
            onCollectDueChange(e)
        }
    };

    const onCollectDueChange = (event) => {
        const newSetting = { ...setting, collectDue: event.target.value };
        setSetting(newSetting);
        // props.onSettingUpdated(newSetting);
    };

    const onClickCheck = () => {
        props.onSettingClickCheck();
        props.onSettingUpdated(setting);
    };

    const onClickCancel = () => {
        props.onSettingClickCancel();
    };

    const isFormValid = () => {
        return !DeepEqual(props.setting, setting);
    };

    const onDialogConfirmed = (e) => {
        setDialog(DIALOG_INIT);
        console.log(e.data.target.value)

        if (!e)
            return;

        switch (e.text.type) {
            case DIALOG_TYPES.SELECT_DUE:
                return onCollectDueChange(e.data);
        }
    };

    const getSelectTicketNames = (selectedTickets) => {
        return selectedTickets.map(gradeId => props.eventGrades.find(g => g.id === gradeId)['gradeName']).join(', ');
    }

    const FormInitDisplay = (
        <div>
            <div className={classes.formGroupLabel}>Form Setting</div>
            <div className={clsx(classes.formGroupOutline, classes.flex)}>
                <div>
                    <div className={classes.formDisplay}>
                        <strong>Apply the form to:</strong><span>{setting.selectAll ? 'All tickets' : getSelectTicketNames(setting.selectedTickets)}</span>
                    </div>
                    <div className={classes.formDisplay}>
                        <strong>Collect registration information from:</strong><span>{REGISTRATION_FORM_COLLECT_FROM_OPTIONS[setting.collectFrom]}</span>
                    </div>
                    <div className={classes.formDisplay}>
                        <strong>Attendees will fill in the form:</strong><span>{REGISTRATION_FORM_COLLECT_DUE_OPTIONS[setting.collectDue]}</span>
                    </div>
                </div>
                {/* <IconButton variant="contained"   color="primary">
                    <EditRounded />
                </IconButton> */}
                {(props.mode === MODES.DISPLAY) && <div onClick={onEditClick} className={classes.formDisplayEdit}>
                    <img
                        src={`${IMG_PATH}${EDIT_IMG}`}
                        alt="edit"
                    />
                </div>}
            </div>
        </div >
    );

    const FormSettingEditActions = (
        <div className={classes.qeustionItemActions}>
            <Button className={classes.qeustionItemActionBtn}
                onClick={onClickCancel}>
                <span style={{ textTransform: 'none', fontSize: '16px' }}>Cancel</span>
            </Button>
            <Button className={clsx(classes.qeustionItemActionBtn, classes.qeustionItemActionBtnPrimay)}
                disabled={!isFormValid()}
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClickCheck}>
                <CheckIcon style={{
                    width: '22px',
                    height: '22px'
                }} />
            </Button>
        </div>
    );

    const FormInitSetting = (
        <div>
            <div className={classes.formGroupLabel}>Form Setting</div>
            <div className={clsx(classes.formGroupOutline, classes.grid)}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <div className={classes.formLabel}>Apply the form to: </div>
                    <Select
                        disabled={setting.selectAll}
                        multiple
                        displayEmpty
                        value={setting.selectedTickets}
                        onChange={handleSelect}
                        renderValue={(selectedTickets) => {
                            if (selectedTickets.length === 0) {
                                return <em>Specific ticket</em>;
                            }

                            return getSelectTicketNames(selectedTickets);
                        }}
                    >
                        {props.eventGrades.map((grade, index) => (
                            <MenuItem key={index} value={grade.id}>{grade.gradeName}</MenuItem>
                        ))}
                    </Select>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={setting.selectAll}
                                onChange={handleCheckAll}
                                name="all tickets"
                                color="primary"
                            />
                        }
                        label="All tickets"
                    />
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <div className={classes.formLabel}>
                        Collect registration information from: <Tootip info={collectOptionTip}></Tootip>
                    </div>
                    <Select
                        id="registraion-form-collect-select"
                        value={setting.collectFrom}
                        renderValue={(option) => REGISTRATION_FORM_COLLECT_FROM_OPTIONS[option]}
                        onChange={handleCollectFromChange}
                    >
                        {Object.keys(REGISTRATION_FORM_COLLECT_FROM_OPTIONS).map((option, index) =>
                            <MenuItem key={index} value={option}>{REGISTRATION_FORM_COLLECT_FROM_OPTIONS[option]}</MenuItem>)
                        }
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <div className={classes.formLabel}>
                        Attendees will fill in the form:
                    </div>
                    <Select
                        id="registraion-form-due-select"
                        value={setting.collectDue}
                        renderValue={(option) => REGISTRATION_FORM_COLLECT_DUE_OPTIONS[option]}
                        onChange={handleCollectDueChange}
                    >
                        {Object.keys(REGISTRATION_FORM_COLLECT_DUE_OPTIONS).map((option, index) =>
                            <MenuItem key={index} value={option}>{REGISTRATION_FORM_COLLECT_DUE_OPTIONS[option]}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>
            {(props.mode === MODES.SETTING_EDIT) && FormSettingEditActions}
        </div>
    );

    return (
        <div>
            { (props.mode === MODES.INIT || props.mode === MODES.SETTING_EDIT) ? FormInitSetting : FormInitDisplay}
            <Dialog
                open={dialog.open}
                dialog={dialog.dialog}
                onDialogDismissed={onDialogDismissed}
                onDialogConfirmed={onDialogConfirmed}
            ></Dialog>
        </div>
    );


}

export default RegistrationFormSettingInit;