import React, {useState, forwardRef, useImperativeHandle, useEffect} from "react";
import { Checkbox, FormControlLabel, TextField, Divider, Select, MenuItem } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { 
    FlexFooter, 
    FlexRow, 
    FormContainer, 
    FormTitle, 
    FormUnit, 
    LabelText, 
    MainContainer, 
    NormalText, 
    StyledButton, 
    StyledInput,
    BolderNormalText,
    FlexBetween,
    ErrorMessage,
    LeftGrid,
    RightGrid,
    PageContainer,
    TagLabel,
    SliderTitle,
} from '../../componentLibrary/page';
import { AddIcon } from '../../componentLibrary/iconLibrary';
import { Clear as ClearIcon, Check as CheckIcon } from '@material-ui/icons';
import { refundDateOptions, CEMode, EventAction } from './constant';

const BlueCheckbox = withStyles({
    root: {
        '&$checked': {
        color: '#5666F6',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5666F6",
            boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
            // borderRadius: '24px',
          },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5666F6",
            boxShadow: "0px 8px 38px rgba(143, 151, 225, 0.1)",
            border: 'solid 1px',
            // borderRadius: '24px',
        },
    },
    textField: {
        width: '600px',
        marginTop:'12px'
    },
    size: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
    },
    refund: {
        display: "flex",
        flexDirection: "row",
        justifyContent:'flex-start',
        alignItems: 'center',
        marginTop:'24px',
        marginBottom: '10px',
    },
    selectIcon: {
        top: 'calc(50%-)',
        height: '25px',
        weight: '25px',
    },
    select:{
        "&:focus": {
            backgroundColor: "#fff",
        }
    },
    commitment: {
        marginLeft: '55px',
        fontSize: '10px',
        lineHeight: '12px',
        fontWeight: 300,
        textDecorationLine: 'underline',
        cursor: 'pointer',
    },
    rightContent: {
        backgroundColor: '#F9F9F9',
        padding: '30px 40px',
        overflowY: 'auto',
        height: '100%'
    },
    divider: {
        marginBottom: '30px'
    },
    addIconBring: {
        '&:hover img': {
            cursor: 'pointer',
            filter: 'sepia(1) hue-rotate(201.5deg) saturate(12) brightness(0.95) contrast(4)'
        }
    },
}))

// advancedSetting: {
//     bringName:[] / null
//     keepInMind:
//     refund: 'Full'/ "Half" / "Zero"
//     refundDate: 
//     policy:{
//         commitment:
//         requirement:
//     }
// },

const CEAdvancedSetting = forwardRef((props, ref) => {
    const classes = useStyles();
    const { draftEvent, setDraftEvent, setValidation, mode } = props;
    const [bringNameList, setBringNameList] = useState(draftEvent.advancedSetting.bringNameList);
    const [bringName, setBringName] = useState(draftEvent.advancedSetting.bringName);
    const [mindText, setMindText] = useState(draftEvent.advancedSetting.mindText);
    const [mindTextLength, setMindTextLength] = useState(draftEvent.advancedSetting.mindTextLength);
    const [refund, setRefund] = useState(draftEvent.advancedSetting.refund);
    const [refundDate, setRefundDate] = useState(draftEvent.advancedSetting.refundDate);
    const [error, setError] = useState({});
    const [openCommit, setOpenCommit] = useState(false);
    const [openRequire, setOpenRequire] = useState(false);
    const [checkedList, setCheckedList] = useState(draftEvent.advancedSetting.checkedList);

    const handleChange = (key, value) => {
        if(key === "refund"){
            setRefund(value)
        }else if(key === "refundDate"){
            setRefundDate(value)
        }
    }

    // const advancedSettingOnchange = (key, value) => {
    //     draftEvent.advancedSetting[key] = value;
    //     setDraftEvent(draftEvent)
    // };

    const addError = (key, msg) => {
        setError({...error, [key]: msg});
    }
    
    const validateLength = (value, key) => {
        if(value.length > 50){
            addError(key, '*Each limits to 50 characters')
            return;
        }
        addError(key, null);
        return true;
    }

    const validateRequired = (vlaue, key) => {
        if(!vlaue){
            addError(key, 'This is required');
            return;
        }
        addError(key, null);
        return true;
    }

    const bringNameOnchange = (e, validFunction) => {
        if(e.target.value){
            setBringName(e.target.value)
        } else {
            setBringName('')
        }
        if(validFunction){
            validFunction(e.target.value, e.target.name)
        }
    }

    const addBringOnChlick = () => {
        if(bringName.trim().length > 0){
            setBringNameList(bringNameList => [...bringNameList, bringName]);
        }
        setBringName('')
    }

    const removeBringItme = (value) => {
        const index = bringNameList.indexOf(value);
        if (mode === CEMode.EDIT_REJECTED && typeof bringNameList[index] === 'object' && bringNameList[index].id) {
            const newList = [...bringNameList]
            newList[index].action = EventAction.DELETE
            setBringNameList(newList);
        } else {
            const newOne = bringNameList.slice();
            if(index > -1){
                newOne.splice(index, 1);
            }
            setBringNameList(newOne);
        }
    }

    const keepInMindOnChange = (e, validFunction) => {
        if(e.target.value){
            setMindText(e.target.value);
            setMindTextLength(e.target.value.length);
        } else {
            setMindText("");
            setMindTextLength(0);
        }
        if(validFunction){
            validFunction(e.target.value, e.target.name)
        }
    }

    const checkedOnChange = (e) => {
        const newValue = !checkedList[e.target.name];
        setCheckedList({
            ...checkedList,
            [e.target.name]: newValue,
        })
        if(newValue){
            addError(e.target.name, null)
            // advancedSettingOnchange(e.target.name, null);
        }
        // TODO newValue = false
    }

    const handleOpenCommit = () => {
        if(openRequire){
            setOpenRequire(false);
        }
        setOpenCommit(true);
    }

    const handleOpenRequire = () => {
        if(openCommit){
            setOpenCommit(false);
        }
        setOpenRequire(true);
    }
    
    const getBringList = () => {
        return bringNameList.filter(item => !(typeof item === 'object' && item.id && item.action === EventAction.DELETE))
    }

    function checkInfoValidation() {
        (checkedList.bringName || getBringList().length > 0) && 
        (checkedList.keepInMind || (mindTextLength > 0 && mindTextLength < 301)) &&
        (refund) &&
        (refundDate) ? setValidation(true) : setValidation(false);
    };

    useEffect(() => {
        checkInfoValidation();
    }, [checkedList, bringNameList, mindTextLength, refund, refundDate])

    useImperativeHandle(ref, () => ({
        saveDraft() {
            draftEvent.advancedSetting = {checkedList, bringNameList, mindText, mindTextLength, refund, refundDate};
            setDraftEvent(draftEvent)
        }
    }))
    
    return (
        <PageContainer>
            <LeftGrid>
                <MainContainer>
                    <FormUnit>
                        <LabelText>
                            What to bring
                        </LabelText>
                        <NormalText>
                            Is there anything you recommend for this event?  Please be detailed and add each item individually . 
                        </NormalText>
                        <FlexRow style={{marginTop:'16px'}}>
                            <div className={classes.root}>
                            <StyledInput
                                width='360px'
                                disabled = {checkedList.bringName}
                                id="bringName"
                                name="bringName"
                                value={bringName}
                                inputProps={{ maxLength: 50, minLength: 1 }}
                                onChange={(e) => bringNameList.length !== 0 ? bringNameOnchange(e, validateLength) : bringNameOnchange(e, validateRequired)}
                                error={!!error.bringName}
                            />
                            </div>
                            <div className={classes.addIconBring}>
                                <AddIcon                
                                    style={{height:'43px', width: '43px', marginLeft:'16px'}}
                                    onClick={addBringOnChlick}
                                />
                            </div>                            
                        </FlexRow>
                        {error.bringName && <ErrorMessage>{error.bringName}</ErrorMessage>}
                        {
                            (getBringList().length === 0 && !bringName) ?
                            <FormControlLabel
                                control={
                                    <BlueCheckbox
                                        color="primary"
                                        checked={checkedList.bringName}
                                        onChange={checkedOnChange}
                                        name='bringName'
                                    />
                                }
                                label={<BolderNormalText>Guests don’t need to bring anything. </BolderNormalText>}
                            /> 
                            :
                            <div style={{display:'flex', flexWrap:'wrap', marginTop:'16px', width:'600px'}}>
                                {getBringList().map((item, index) => {
                                    return (
                                    <TagLabel 
                                        key={index} 
                                        // TODO seprate comment object and text
                                        name={ item.id ? item.comment : item}
                                        content={
                                            <ClearIcon 
                                                style={{width:'12px', height:'12px', marginLeft:'12px', color:'#A9A9A9'}} 
                                                onClick={() => removeBringItme(item)}
                                            />
                                        }
                                    />
                                    )
                                })}
                            </div>       
                        }
                    </FormUnit>
                    <FormUnit>
                        <LabelText>
                            Things to keep in mind
                        </LabelText>
                        <NormalText>
                            What other additional information or suggestions do you want to provide to your guests? 
                        </NormalText>
                        <TextField
                            disabled = {checkedList.keepInMind}
                            name="keepInMind"
                            id="keepInMind"
                            value={mindText}
                            multiline
                            rows={6}
                            variant="outlined"
                            inputProps={{ maxLength: 300 }}
                            InputProps={{
                                classes:{                                    
                                    input: classes.size,
                                },
                                endAdornment: <InputAdornment style={{position: 'absolute', bottom: '10px', right: '10px'}}>
                                                {mindTextLength > 300 
                                                ?<span style={{color: '#FF6565', fontSize: 12,}}>{mindTextLength}/300</span>
                                                :<span style={{fontSize: 12, color:'#6B6B71'}}>{mindTextLength}/300</span>
                                                }
                                               </InputAdornment>
                            }}
                            className={`base-input-class ${classes.textField} ${classes.root}`}
                            onChange={(e) => {
                                keepInMindOnChange(e, validateRequired);
                                // validateLength300(e.target.value, e.target.name);
                            }}
                            error={!!error.keepInMind}
                        />
                        {error.keepInMind && <ErrorMessage>{error.keepInMind}</ErrorMessage>}
                        <FormControlLabel
                            control={
                                <BlueCheckbox
                                    color="primary"
                                    checked={checkedList.keepInMind}
                                    onChange={checkedOnChange}
                                    name='keepInMind'
                                />
                            }
                            label={<BolderNormalText>I have no additional information for guests. </BolderNormalText>}
                        />
                    </FormUnit>
                    <FormUnit>
                        <LabelText>
                            Clarify your cancellation policy
                        </LabelText>
                        <FormContainer style={{paddingTop: "16px",width: '600px', borderRadius:'15px'}}>
                            <NormalText>
                                <strong>Step 1: </strong>choose refund type
                            </NormalText>
                            <div className={classes.refund}>
                                <StyledButton                                   
                                    onClick={() => handleChange("refund", "Full")} 
                                    height="35px"
                                    padding="5px 20px"
                                    color={refund === "Full" ? "#fff" : "#999999"}  
                                    border={refund === "Full" ? null : "1px solid #999999"}
                                    backgroundColor={refund === "Full" ? '#5666F6' : refund === "Zero" ? 'lightgrey' : null}
                                >
                                    Full refund
                                </StyledButton>
                                <StyledButton 
                                    onClick={() => handleChange("refund", "Half")}
                                    height="35px"
                                    padding="5px 20px"
                                    color={refund === "Half" ? "#fff" : "#999999"}  
                                    border={refund === "Half" ? null : "1px solid #999999"}
                                    backgroundColor={refund === "Half" ? '#5666F6' : refund === "Zero" ? 'lightgrey' : null}
                                > 
                                    50% refund
                                </StyledButton>
                                <StyledButton                                 
                                    onClick={() => handleChange("refund", "Zero")} 
                                    height="35px"
                                    padding="5px 20px"
                                    color={refund === "Zero" ? "#fff" : "#999999"}  
                                    border={refund === "Zero" ? null : "1px solid #999999"}
                                    backgroundColor={refund === "Zero" ? '#5666F6' : null}
                                >
                                    No refund
                                </StyledButton>
                            </div>
                            <NormalText style={{marginTop:'30px'}}>
                                <strong>Step 2: </strong>choose one date for the refund
                            </NormalText>
                            <div className={classes.root} style={{marginTop: "20px"}}>
                                <Select                                                       
                                    name="refundDate"
                                    value={(refund === "Zero") ? "" : refundDate}
                                    disabled={(refund === "Zero")}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    variant="outlined" 
                                    classes={{icon: classes.selectIcon, select: classes.select}} 
                                    input={<StyledInput />}
                                >
                                    {refundDateOptions[refund].map((option, index) => (
                                        <MenuItem key={index} value={option.key}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </FormContainer>
                    </FormUnit>
                    <FormUnit>
                        <LabelText>
                            COVID-19 Policy
                        </LabelText>
                        <NormalText>
                            We recommend you choose both of the policies to let your event safer. Participants can see you made this commitment in the event detail. 
                        </NormalText>
                        <FlexRow style={{marginTop:'15px'}}>
                            <FormControlLabel
                                control={
                                    <BlueCheckbox 
                                        checked={checkedList.commitment}
                                        onChange={checkedOnChange}
                                        name='commitment'
                                    />
                                }
                                label={<BolderNormalText style={{width: '210px'}}>Organizer commitment to safety </BolderNormalText>}
                            />
                            <div className={classes.commitment} onClick={handleOpenCommit}>
                                View commitment
                            </div>
                        </FlexRow>
                        <FlexRow style={{paddingBottom:'30px'}}>
                            <FormControlLabel
                                control={
                                    <BlueCheckbox 
                                        checked={checkedList.requirement}
                                        onChange={checkedOnChange}
                                        name='requirement'
                                    />
                                }
                                label={<BolderNormalText style={{width: '210px'}}>Requirement for customer  </BolderNormalText>}
                            />
                            <div className={classes.commitment} onClick={handleOpenRequire}>
                                View requirement
                            </div>
                        </FlexRow>
                    </FormUnit>
                </MainContainer>
            </LeftGrid>
            {openCommit &&
                <RightGrid>
                    <div className={classes.rightContent}>
                        <div style={{marginBottom:'120px', display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <ClearIcon 
                                style={{width:'18px', height:'18px', color:'#000000', cursor: 'pointer'}} 
                                onClick={() => setOpenCommit(false)}
                            />
                        </div>
                        <SliderTitle style={{marginBottom:'30px'}}>Organizer commitment to safety</SliderTitle>
                        <Divider/>
                        <br></br><br></br>
                        <div>
                            When using the NASUFUN platform to connect either virtually or in person, 
                            all members and organizers must adhere to local laws, rules, and regulations—including those regarding COVID-19. 
                            NASUFUN may close or cancel events if they promote activities that do not comply with requirements and guidance 
                            issued by government authorities and public health officials, including the CDC and the WHO.
                        </div>
                    </div>
                </RightGrid>
            }
            {openRequire &&
                <RightGrid>
                    <div className={classes.rightContent}>
                        <div style={{marginBottom:'120px', display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <ClearIcon 
                                style={{width:'18px', height:'18px', color:'#000000', cursor: 'pointer'}} 
                                onClick={() => setOpenRequire(false)}
                            />
                        </div>
                        <SliderTitle style={{marginBottom:'30px', fontSize: '17px', fontWeight: 'bold'}}>Requirement for customer</SliderTitle>
                        <Divider classes={{root: classes.divider}}/>
                            <div>
                                <div style={{whiteSpace: 'break-spaces', fontSize: '17px', color: 'darkgrey', filter: 'brightness(0.35)'}}><CheckIcon/>   Staying home</div>
                                    <br></br><div style={{marginLeft: '30px'}}>
                                        if in the past 14 days have you had close contact with a person confirmed
                                        to have COVID-19 or in the past 24 hours experienced any of the suspect symptoms.
                                    </div><br></br><br></br><br></br>
                                <div style={{whiteSpace: 'break-spaces', fontSize: '17px', color: 'darkgrey', filter: 'brightness(0.35)'}}><CheckIcon/>    Avoiding physical contact</div>
                                    <br></br><div style={{marginLeft: '30px'}}>
                                        Complying with physical distance guidelines and remaining at least 6 ft. apart 
                                        from others unless they are from the same household.
                                    </div><br></br><br></br><br></br>
                                <div style={{whiteSpace: 'break-spaces', fontSize: '17px', color: 'darkgrey', filter: 'brightness(0.35)'}}><CheckIcon/>   Wearing mask/face covering</div>
                                    <br></br><div style={{marginLeft: '30px'}}>
                                        In conjunction with state order, masks covering the nose and mouth are required 
                                        at all times，both indoors and outdoors.
                                    </div><br></br><br></br><br></br>
                                <div style={{whiteSpace: 'break-spaces', fontSize: '17px', color: 'darkgrey', filter: 'brightness(0.35)'}}><CheckIcon/>   Wearing mask/face covering</div>
                                    <br></br><div style={{marginLeft: '30px'}}>
                                        Prevent unnecessary in-person transactions. Washing hands or using sanitizer frequently.
                                    </div><br></br><br></br><br></br>
                            </div>
                    </div>
                </RightGrid>
            }
        </PageContainer>
    )
})

export default CEAdvancedSetting;




