import React, { useState, useEffect, useRef } from 'react';
import {
    makeStyles, Button
} from '@material-ui/core';
import DayPicker from 'react-day-picker';
import { isBefore, format } from 'date-fns'
import { useHistory, useRouteMatch } from "react-router-dom";

import styles from "./ManageEvent.module.css";
import 'react-day-picker/lib/style.css';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import todayIcon from "../../assets/Group4345.png";
import endedIcon from "../../assets/Group 4345e.png";
import gradeSoldIcon from "../../assets/Group 4142.svg";
import dollar from "../../assets/dollar2.png";
import ticketIcon from "../../assets/Path9243.png";
import * as CONST from "./OCConst";

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 20px 0px',
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '800px',
        minWidth: '375px',
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    createEventButton: {
        marginLeft: '0.7%',
        marginTop: '1.1%',
        marginBottom: '1%',
        fontSize: '16px',
        background: 'transparent',
        height: '30px',
        border: '0px'
    },
    manageGuestsButton: {
        // border: '0px',
        borderRadius: '25px',
        fontWeight: '300',
        backgroundColor: '#5566F6',
        width: '121px',
        height: '35px',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
        backgroundColor: '#EDEFFF',
        color: '#5566F6'
        },
    },

}));

const { MONTH_SHORT } = CONST;

const ManageEvent = (props) => {
    const classes = useStyles();
    const { dailyPerformance, datePerformance } = props

    function Weekday({ weekday, className, localeUtils, locale }) {
        const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
        return (
            <div className={className} title={weekdayName} style={{color: '#4A5CFF', opacity: 0.41, paddingTop: 20 }}>
                {weekdayName.slice(0, 1)}
            </div>
        );
    }
    function Navbar({
        month,
        nextMonth,
        previousMonth,
        onPreviousClick,
        onNextClick,
        className,
        localeUtils,
    }) {
        const months = localeUtils.getMonths();
        const prev = months[previousMonth.getMonth()];
        const next = months[nextMonth.getMonth()];
        const now = format(month, 'MMMM yyyy')
        const styleLeft = {
            float: 'right',
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            color: '#5666F6',
            // paddingTop: '6px'

            marginTop: 27,
            marginRight: 15,
            marginLeft: 15
        };
        const styleRight = {
            float: 'right',
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            color: '#5666F6',
            // paddingTop: '6px'
            marginTop: 27,
            marginRight: 15,
        };
        const monthStyle = {
            fontSize: 19,
            color: '#4A5CFF',
            marginLeft: 10,
            lineHeight: 3.5,
        }
        const handdleNext = () => {
            setIsDay(false)
            // setIsEmpty(true)
            onNextClick();
        }
        const handlePrev = () => {
            setIsDay(false)
            // setIsEmpty(true)
            onPreviousClick();
        }
        return (
            //,  paddingBottom: '15px', paddingTop:'25px'
            <div style={{ borderBottom: 'solid 1px rgba(223, 223, 223, 1)', }}>
                <span style={monthStyle}>{now}</span>
                {/* <div style={{marginRight: '5px'}}> */}
                <button style={styleRight} onClick={() => handdleNext()}>
                    <ArrowForwardIosSharpIcon />
                </button>
                <button style={styleLeft} onClick={() => handlePrev()}>
                    <ArrowBackIosSharpIcon />
                </button>
                {/* </div> */}
            </div>
        );
    }
    function YearMonthForm({ date, localeUtils }) {
        return (
            <div style={{ fontSize: 19 }}></div>
        )
    }

    function getInitialGradeDates() {
        if (!dailyPerformance)
            return [];
        
        return dailyPerformance.map(performance => new Date(performance.eventStartTime));
    }

    function getInitialGradeSoldDates() {
        if (!dailyPerformance)
            return [];
        
        return dailyPerformance
        .filter(performance => performance.totalQuantity)
        .map(performance => new Date(performance.eventStartTime));
    }

    function getSelectDates() {
        const selectedDate = [];
        if (datePerformance) {
            selectedDate.push(new Date(datePerformance.date.eventStartTime))
        }

        return selectedDate;
    }
    
    const history = useHistory();
    const { path, url } = useRouteMatch();

    const [isDay, setIsDay] = useState(false);
    const [isEmpty, setIsEmpty] = useState();
    const [gradeDates, setGradeDates] = useState([]);
    const [gradeSoldDates, setGradeSoldDates] = useState([]);
    const [selectDates, setSelectDates] = useState([]);
    
    let prevDailyPerformance = usePrevious(dailyPerformance);

    useEffect(()=> {
        if (dailyPerformance && prevDailyPerformance && dailyPerformance.length != prevDailyPerformance.length) {
            const gradeDates = getInitialGradeDates();
            const gradeSoldDates = getInitialGradeSoldDates();
            setGradeDates(gradeDates)
            setGradeSoldDates(gradeSoldDates)    
        }
        const selectDates = getSelectDates();
        setSelectDates(selectDates)
    }, [dailyPerformance, datePerformance]) 

    

    const handleDayClick = (dayClicked, { selected, disabled }) => {
        if (!props.datePerformance || !props.dailyPerformance)
            return;

        // if (disabled) {
        //     setIsDay(false);
        //     return;
        // }
        const selectedDay = new Date(props.datePerformance.date.eventStartTime);
        const days = props.dailyPerformance ? props.dailyPerformance : [];

        days.forEach(day => {
            const dayFromList = new Date(day.eventStartTime);
            if (matchDate(dayFromList, dayClicked) && !matchDate(selectedDay, dayFromList)) {
                // setIsDay(true);
                const { eventId } = props;
                const dateId = day.date.id;
                props.getPerformanceByDate(eventId, dateId);
                return;
            }
        });

        // setIsDay(false)
    }

    const renderDaypicker = () => {

        const modifiersStyles = {
            days: {
                padding: "6px 9px",           
                fontSize: 13,                
            },
            disabled: {
                opacity: 0.5
            },
            outside: {
                backgroundColor: 'transparent',
            },
        };

        const Specialmodifiers = {
            days: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
            before: new Date(),
            // entered: mouseEntered,
        };

        const modifiers = {
            days: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
            before: new Date(),
            // entered: mouseEntered,
        };

        const styleGradeDate = {                
            color: 'white',
            backgroundColor: '#ffc107',
            cursor: 'pointer'
        }

        const styleDay = {                
            position: 'relative',
            width: '25px',
            height: '25px',
            display: 'flex',
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'            
        }

        const styleSelectDate = {
            color: 'white',
            backgroundColor: "#5666F6",      
        }

        const renderDay = (day) => {
            const date = day.getDate();
            const hasSold = !!gradeSoldDates.find(d => matchDate(d, day));

            const isGradeDate = !!gradeDates.find(d => matchDate(d, day));
            const isSelectedDate = !!selectDates.find(d => matchDate(d, day));

            const styleDate = isSelectedDate ? styleSelectDate : (isGradeDate ? styleGradeDate : {})

            const gradeSoldDateStyle = {
                position: 'absolute',
                // TODO: temp hard coded position
                bottom: '-1px',
                left: 'auto'
            };

            return (
                <div style={{position: 'relative'}}>
                <div style={{...styleDay, ...styleDate}} >                               
                    
                    <div>{date}</div>                    
                    {hasSold &&
                        <img src={gradeSoldIcon} style={gradeSoldDateStyle} />}
                </div>
                {day.toLocaleDateString('en-US') === new Date().toLocaleDateString('en-US') &&
                        <strong style={{color:'rgba(33, 39, 89, 1)', fontSize: 8, position: 'absolute', top: -10, right: -6, }}>Today </strong>} 
                
                </div>
                
            );
        };

        return (
            <div id="date-picker-container" style={{marginTop: '5px', border: "solid 1px rgba(223, 223, 223, 1)", width: '354px', height: '418px', borderRadius: 15, }}>
                <DayPicker
                    disabledDays={[{
                        before: modifiers.before,
                    },]}
                    renderDay={renderDay}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                    weekdayElement={<Weekday />}
                    navbarElement={<Navbar />}
                    onDayClick={handleDayClick}
                    // selectedDays={selectedRegularDays}
                    captionElement={({ date, localeUtils }) => (
                        <YearMonthForm
                            date={date}
                            localeUtils={localeUtils}
                        />
                    )}

                />
            </div>
        );
    }

    const onTicketClick = (ticket, date) => {
        const eventId = props.eventId;
        const prev = {
            datePerformance: {...props.datePerformance},
            dailyPerformance: [...props.dailyPerformance],
        };
        console.log({ticket, date,eventId, prev});
        history.push({
            pathname: `/manage_event/${eventId}/manage_guest`,
            state: { ticket, date, eventId, prev}
        })
    }

    const renderTickets = () => {
        if (!props.datePerformance)
            return;


        const isDay = true;

        const { date, performance } = props.datePerformance;
        const dateText = getDateText(new Date(date.eventStartTime));

        const dailySales = performance.reduce((a, b) => a + b.totalAmount, 0).toFixed(2);
        const dailyTickets = performance.reduce((a, b) => a + b.totalQuantity, 0);

        const dateIcon = isBefore(new Date(date.eventStartTime), new Date()) ?
            endedIcon :
            (matchDate(new Date(date.eventStartTime), new Date()) ?
                null :
                todayIcon
            );


        return (
            isEmpty ?
                (<div style={{ width: '60%', position: 'relative', marginLeft: '5%' }}>
                    <p style={{ paddingTop: '20%', textAlign: 'center' }}>You didn't set dates for this month</p>
                </div>) :

                <div id="performance-container" >
                    {isDay &&
                        <div style={{ position: 'relative', marginLeft: '80px' }}>
                            <div id="performance-title" style={{ height: '30px', }}>
                                <span style={{ color: 'rgba(86, 102, 246, 1)', marginRight: '10px', fontSize: '20px', fontWeight: '600' }}>{dateText}</span>
                                {/* <span style={{lingHeight:'20px', height:'15px', backgroundColor:'rgba(86, 102, 246, 1)', color:'white', width:'20px'}}>Today </span> */}
                                {dateIcon && <img src={dateIcon} style={{ marginLeft: '5px', position: 'relative', top: '-5px', height:'17px', width:'55px' }}></img>}
                                    <img src={dollar} style={{ marginLeft: '225px', position: 'relative', top: '-2px', height:'13px', width:'13px' }}></img>
                                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>Daily Sales: </span>
                                    <span style={{ color: 'rgba(86, 102, 246, 1)', fontWeight: '600' }}>$ {dailySales}</span>
                                    <img src={ticketIcon} style={{ marginLeft: '35px', position: 'relative', top: '-1px', height:'14px', width:'15px' }}></img>
                                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>Daily Tickets: </span>
                                    <span style={{ color: 'rgba(86, 102, 246, 1)', fontWeight: '600' }}>{dailyTickets}</span>
                            </div>
                            <div className={styles.ticketContainer} style={{ height: '600px', overflowY: 'auto' }}>
                                <div id="performance-ticket-list">
                                    {performance.map((ticket, index) => {
                                        const { grade, orders, totalAmount, totalQuantity } = ticket;
                                        return (
                                            <div key={index} className={styles.ticketSubContainer}>
                                                <div className="ticket-title-and-price">
                                                    <span style={{ fontWeight: '600', fontSize: '15px' }}> {grade.gradeName} </span>
                                                    <span style={{ float: 'right', fontWeight: '600', color: 'rgba(245, 166, 38, 1)', fontSize: '16px' }}>$ {grade.gradePrice}  </span>
                                                </div>
                                                <div className="ticket-time-and-performance"
                                                    style={{ marginTop: '5px', paddingBottom: '5px', borderBottom: '0.5px solid rgba(117, 136, 163, 1)' }}>
                                                    <span style={{ fontSize: '12px', fontWeight: '300' }}>{parseTimeString(grade.gradeStartTime)} |  {parseDuration(grade.gradeDuration)} </span>

                                                    <p style={{ float: 'right' }}>
                                                        <span style={{ fontWeight: '600', marginRight: '10px' }}>Ticket Sold:</span>
                                                        <span style={{ fontSize: '14px', fontWeight: '300' }}>{totalQuantity}   / {grade.gradeQuantity} </span>
                                                    </p>
                                                </div>

                                                <div className="ticket-description" style={{ paddingTop: '5px', fontWeight: '300', maxWidth: '550px', wordWrap: 'break-word' }}>
                                                    <p>{grade.gradeDescription}</p>
                                                </div>

                                                <div className={styles.ticketActions}>
                                                    <span style={{ position: 'absolute', bottom: 0, left: 0, fontSize: '12px', fontWeight: '500', backgroundColor: '#FFFFFF', borderRadius: '25px', verticalAlign: 'bottom' }}>Cutoff: {grade.sellingCutoffDate ? grade.sellingCutoffDate: 0} days before event start</span>
                                                    <Button
                                                        variant="outlined"
                                                        className={classes.manageGuestsButton}
                                                        onClick={() => onTicketClick(ticket, date)}
                                                    >
                                                        <div style={{fontSize: '12px'}}>Manage guests</div>
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>}
                </div >
        );
    }

    return (
        <div style={{ marginLeft: '-2.1%' }}>
            <div style={{ fontSize: 14, fontWeight: '300', color: "#000000", marginTop: 12 }}>
                <p>Click date to view daily performance</p>
            </div>
            <div style={{ width: '100%', minWidth: '1000px', position: 'relative', display: 'flex' }}>
                {renderDaypicker()}
                {renderTickets()}
            </div>
        </div>
    )
}

function matchDate(d1, d2) {
    return d1.getDate() == d2.getDate() &&
        d1.getMonth() == d2.getMonth() &&
        d1.getUTCFullYear() == d2.getUTCFullYear();
}

function getDateText(dateObj) {
    const month = MONTH_SHORT[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getUTCFullYear();
    return `${month} ${day}, ${year}`
}

function parseTimeString(timStr) {
    if (!timStr)
        return;
    const time = timStr.split(':'); // split it at the colons

    return `${parseHour(time[0])}:${time[1]} ${+time[0] >= 12 ? 'PM' : 'AM'}`;
}

function parseHour(hr) {
    if (!hr)
        return;
    return (+hr) > 12 ?
        (
            hr - 12 >= 10 ?
                (hr - 12).toString() :
                '0' + (hr - 12)
        ) :
        hr;
}

function parseDuration(timStr) {
    if (!timStr)
        return;

    const time = timStr.split(':'); // split it at the colons
    return `${+time[0] > 0 ? +time[0] + 'hr' : ''}${+time[1] > 0 ? +time[1] + 'min' : ''}`
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(()=> {
        ref.current = value;

    })
    return ref.current
}

export default ManageEvent;