import React, {useState, useContext, useEffect } from 'react';
import { useRouteMatch, Link, Switch, Route, useHistory} from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { AuthContext } from '../../UserAuth/AuthContext';
import ManageCoupon from './ManageCoupon';
import CreateCoupon from './CreateCoupon';
import moment from 'moment';


const ManageCouponIndex = (props) => {

    const [activeCoupon, setActiveCoupon] = useState([]);
    const [endedCoupon, setEndedCoupon] = useState([]);
    const [dataBE, setDataBE] = useState(null);

    let { path, url } = useRouteMatch();
    const history = useHistory();
    const getAllCoupons = async (userId) => {
        // const url =  `${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/${userId}/list_coupons_for_user/`;
        await axios
        .get(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/${userId}/list_coupons_for_user/`)
        .then(res => {
            const today =  moment().startOf('day');
            const active = res.data.response.filter(response => response.disabled == false && 
                    ( moment(response.validTo).isValid() && today.isBefore(moment(response.validTo).endOf('day')) )             
                );
                active.length > 0 ? setActiveCoupon(active) : setActiveCoupon(false);
            const ended = res.data.response.filter(response => response.disabled == true || 
                    ( moment(response.validTo).isValid() && moment(response.validTo).endOf('day').isBefore(today) )
                );
                ended.length > 0 ? setEndedCoupon(ended) : setEndedCoupon(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const handleEndCoupon = async couponObj => {
        const data = {
            updateDisabled: couponObj.code,
        }
        try {
            const res = await axios.post(`${window.localStorage.getItem('nasu_backend_url')}/api/v1/payment/create_or_update_promotion/`, {data})
            if (res.data.success) {
                const coupon = {...couponObj, disabled: true};
                const endCoupons = [...endedCoupon];
                endCoupons.unshift(coupon);
                setEndedCoupon(endCoupons);
                let activeCoupons = [...activeCoupon];
                activeCoupons = activeCoupons.filter(c => c.code !== coupon.code) ;
                setActiveCoupon(activeCoupons);


            }
        } catch(err) {
            console.error(err);
        }        
    }

    const handleSubmit = async data => {
        const {payload, coupon} = data;
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/payment/create_or_update_promotion/`;
        try {
            const res = await axios.post(url, { data: payload });
            if (res.data.success) {
                const activeCoupons = [...activeCoupon];
                activeCoupons.unshift(coupon);
                setActiveCoupon(activeCoupons);
            }
            history.push(`/operator_center/coupon_setting`);
        } catch(err) {
            console.error(err);
        }     
    }

    const getEventsAndGrades = async (userId) => {
        const url = `${window.localStorage.getItem('nasu_backend_url')}/api/v1/user/${userId}/list_events_for_user/`;
        let res = await axios
          .get(url);
        if (res && res.data) {
            setDataBE(res.data.response)
        }
    }
    
    useEffect(() => {
        const userId = 1;
        getAllCoupons(userId);
        getEventsAndGrades(userId);
    }, []);

    return (
        <Switch>
                <Route exact path={path}>
                    <ManageCoupon 
                        activeCoupon={activeCoupon}
                        endedCoupon={endedCoupon}
                        handleEndCoupon={handleEndCoupon}
                    />
                </Route>
                <Route exact path={`${path}/create_coupon`}>
                    <CreateCoupon 
                        handleSubmit={handleSubmit}
                        dataBE={dataBE}
                        userId={1}
                    />
                </Route>

        </Switch>
    )
}

export default ManageCouponIndex;