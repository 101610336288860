import React, {useState, useEffect} from 'react';
import { Grid, makeStyles, MenuItem, TextField, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import axios from 'axios';
import { useRouteMatch, Link } from 'react-router-dom';

const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: '7px',
      position: 'relative',
      border: '0.7px solid #000000',
      fontSize: 12,
      padding: '15px 10px 10px 10px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: '7px',
        borderColor: '#5666F6',
        backgroundColor: '#FFFFFF'
      },
    },
  }))(InputBase);

const BlueCheckbox = withStyles({
    root: {
        '&$checked': {
        color: '#5666F6',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueRadio = withStyles({
    root: {
      '&$checked': {
        color: '#5666F6',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const CssTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: '7px',
          border: '0.7px solid #000000',
        },
        '&.Mui-focused fieldset': {
            border: '0.7px solid #000000',
            borderRadius: '7px',
            borderColor: '#5666F6',
        },
        "& .MuiInputBase-root.Mui-disabled": {
            borderColor: '#999999',
        }
      },
    },
  })(TextField);

const useStyle = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0),
        minWidth: 550,
        maxWidth: 550,
    },
    couponNameStyle: {
        width: 300,
        borderRadius: 8
    },
    discountStyle: {
        width: 115,
    },
    numberOfUsesStyle: {
        width: 125,
        '& input:disabled': {
            borderColor: '#999999',
        },
    },
    validStyle: {
        width: 130,
    },
    helperText: {
        fontSize: 10,
        textAlign: 'end'
    },
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateCoupon = (props) => {
    const classes = useStyle();
    let { path, url } = useRouteMatch();
    const [index, setIndex] = useState(0) 
    const [selectEventId, setSelectEventId] = useState()
    
    const checkCouponValid = () => {
        if (couponName.length >= 3 && (discountAmount > 0 || discountRatio > 0) && (numberOfUses > 0 || unlimitedUses === true)) {
            return true;
        }
        return false;
    }

    const [selectEvent, setSelectEvent] = useState("");
    const onEventChange = (e) => {
        setIndex(e.target.value)
        setSelectEvent(e.target.value)
        setSelectEventId(props.dataBE[e.target.value].eventId)
        setSelectTickets([])
    }; 
    const [couponName, setCouponName] = useState("");
    const [couponNameValidation, setCouponNameValidation] = useState(true);
    const onCouponNameChange = (e) => {
        setCouponName(e.target.value);
        setCouponNameValidation(RegExp(/^[a-zA-Z0-9]+$/).test(e.target.value) && (e.target.value).length > 2 && (e.target.value).length < 16);
    };
    const [discountAmount, setDiscountAmount] = useState();
    const [discountAmountValidation, setDiscountAmountValidation] = useState(true);
    const onDiscountAmountChange = (e) => {
        setDiscountAmount(e.target.value);
        setDiscountAmountValidation(!isNaN(e.target.value));
    };
    const [discountRatio, setDiscountRatio] = useState();
    const [discountRatioValidation, setDiscountRatioValidation] = useState(true);
    const onDiscountRatioChange = (e) => {
        setDiscountRatio(e.target.value);
        setDiscountRatioValidation(!isNaN(e.target.value) && Number(e.target.value) <= 100);
    };
    const [numberOfUses, setNumberOfUses] = useState();
    const [numberOfUsesValidation, setNumberOfUsesValidation] = useState(true);
    const onNumberOfUsesChange = (e) => {
        setNumberOfUses(e.target.value);
        setNumberOfUsesValidation(!isNaN(e.target.value) && (e.target.value) >= 0);
    };
    const [allEvents, setAllEvents] = useState(false);
    const onAllEventsChange = (e) => {
        setAllEvents(e.target.checked);
        setSelectEvent();
        setSelectEventId();
    };
    const [unlimitedUses, setUnlimitedUses] = useState(false);
    const onUnlimitedUsesChange = (e) => {
        setUnlimitedUses(e.target.checked);
    };
    const [allTickets, setAllTickets] = useState(false);
    const onAllTicketsChange = (e) => {
        setAllTickets(e.target.checked);
        setSelectTickets([]);
    };
    const [radioButton, setRadioButton] = useState("none");
    const onRadioButtonChange = (e) => {
        setRadioButton(e.target.value);
    };
    const [validFrom, setValidFrom] = useState("");
    const [validFromValidation, setValidFromValidation] = useState(true);
    const onValidFromChange = (e) => {
        setValidFrom(e.target.value);
        setValidFromValidation(((e.target.value) <= validTo) || !validTo);
        setValidToValidation((e.target.value) <= validTo || !validTo);
    };
    const [validTo, setValidTo] = useState("");
    const [validToValidation, setValidToValidation] = useState(true);
    const onValidToChange = (e) => {
        setValidTo(e.target.value);
        setValidToValidation((e.target.value) >= validFrom || !validFrom);
        setValidFromValidation((e.target.value) >= validFrom || !validFrom);
    };
    const [selectTickets, setSelectTickets] = useState([]);
    const onTicketsChange = (event) => {
        setSelectTickets(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const {userId} = props;
        const data = {
            payload: {
                userId, 
                selectEventId, 
                allTickets, 
                couponName, 
                discountAmount,
                discountRatio, 
                numberOfUses, 
                allEvents,
                unlimitedUses,
                radioButton,
                validFrom,
                validTo,
                selectTickets : selectTickets.map(grade => grade.gradeId)
            },
            coupon: {
                code: couponName,
                eventName: allEvents ? "All Events" : props.dataBE[selectEvent].eventName,
                grades: allTickets ? [] : selectTickets.map(grade => grade.gradeName),
                discountAmount: discountAmount,
                discountRatio: discountRatio,
                validFrom: validFrom,
                validTo: validTo,
                disabled: false,
                forNewUser: radioButton == 'newUser' ? true : false,
                totalAmount: unlimitedUses ? null : numberOfUses,
                uses: 0
            }
        }
        props.handleSubmit(data)
      }

    return (
        <div class='scroll' style={{ height: '100%', width: '100%'}}>
                <Box pt={2} mb={1} ml={1}>
                    <Grid container direction='row' alignItems='center'>
                        <Grid item>
                            <Button
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                component={Link} to={{pathname: `/operator_center/coupon_setting`}}
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight='300'>
                                    Add coupon
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" />
                <Box mt={2} ml={5}>
                    <Grid>
                        <Typography variant="subtitle1" gutterBottom>
                            Select Event
                        </Typography>
                    
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                disabled = {allEvents}
                                labelId='outlined-selectEvent-label'
                                id='outlined-selectEvent'
                                value={selectEvent}
                                onChange={onEventChange}
                                displayEmpty
                                input={<BootstrapInput />}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="" disabled>
                                    Select
                                </MenuItem>
                                {props.dataBE && props.dataBE.map((event, indx) => {
                                    return (
                                        <MenuItem value={indx}>
                                            {event.eventName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <FormControlLabel 
                        control={
                            <BlueCheckbox
                                checked={allEvents}
                                onChange={onAllEventsChange}
                                name='allEvents'
                            />
                        }
                        label="All Events"
                    />
                </Box>
                { (allEvents || selectEvent != "") ?
                    <Box mt={2} ml={2} bgcolor='white' borderRadius={15} width='60rem' height='63rem'>
                        <Box pt={2} ml={3}>
                            <Box>
                                <Grid>
                                    <Box>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Coupon Name
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {couponNameValidation ?
                                            <CssTextField 
                                                required
                                                variant = 'outlined'
                                                value={couponName}
                                                onChange={onCouponNameChange}
                                                className={classes.couponNameStyle}
                                                // inputProps={{borderRadius: 50}}
                                            />
                                            :
                                            <CssTextField 
                                                required
                                                error
                                                variant = 'outlined'
                                                value={couponName}
                                                onChange={onCouponNameChange}
                                                className={classes.couponNameStyle}
                                                inputProps={{borderRadius: 8}}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                }}
                                                helperText="*Wrong format"
                                            />
                                        }
                                    </Box>
                                    <Grid>
                                        <Typography variant="caption" gutterBottom>
                                            <Box color='text.secondary' mt={0.5}>
                                                *3-15 characters without space <br />
                                                *only numbers and upper/lower letters
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={2}>
                                <Grid container direction='row' spacing={4}>
                                    <Grid item>
                                        <Box mt={1}>
                                            <Box>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Discount Type
                                                </Typography>
                                            </Box>
                                            <Box mt={1.5}>
                                                <FormControl>
                                                    {discountRatio ? 
                                                        <CssTextField
                                                            disabled
                                                            id="outlined-discount-amount"
                                                            value={discountAmount}
                                                            label="Amount"
                                                            onChange={onDiscountAmountChange}
                                                            className={classes.discountStyle}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            }}
                                                            variant="outlined"
                                                        />
                                                        :
                                                        discountAmountValidation ?
                                                        <CssTextField 
                                                            id="outlined-discount-amount"
                                                            value={discountAmount}
                                                            label="Amount"
                                                            onChange={onDiscountAmountChange}
                                                            className={classes.discountStyle}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            }}
                                                            variant="outlined"
                                                        />
                                                        :
                                                        <CssTextField 
                                                            error
                                                            id="outlined-discount-amount-error"
                                                            label="Amount"
                                                            value={discountAmount}
                                                            onChange={onDiscountAmountChange}
                                                            className={classes.discountStyle}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            }}
                                                            variant="outlined"
                                                            helperText="*Discount Unrecognized"
                                                        />
                                                    }
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item justify='center'>
                                        <Box mt={6.5}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                or
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box mt={4.5}>
                                            <FormControl>
                                                {discountAmount ? 
                                                    <CssTextField
                                                        disabled
                                                        id="outlined-discount-ratio-disabled"
                                                        value={discountRatio}
                                                        label="Ratio"
                                                        onChange={onDiscountRatioChange}
                                                        className={classes.discountStyle}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        variant="outlined"
                                                    />
                                                    :
                                                    discountRatioValidation ?
                                                        <CssTextField
                                                            id="outlined-discount-ratio"
                                                            value={discountRatio}
                                                            label="Ratio"
                                                            onChange={onDiscountRatioChange}
                                                            className={classes.discountStyle}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            variant="outlined"
                                                        />
                                                        :
                                                        <CssTextField 
                                                            error
                                                            id="outlined-discount-ratio-error"
                                                            label="Ratio"
                                                            value={discountRatio}
                                                            onChange={onDiscountRatioChange}
                                                            className={classes.discountStyle}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="s">%</InputAdornment>,
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            variant="outlined"
                                                            helperText="*Discount Unrecognized"
                                                        />
                                                }                                     
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box mt={1.2} ml={3}>
                                            <Box>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Number of Uses
                                                </Typography>
                                            </Box>
                                            <Box mt={1}>
                                                {unlimitedUses ?
                                                    <CssTextField  
                                                        required
                                                        disabled
                                                        variant = 'outlined'
                                                        value={numberOfUses}
                                                        onChange={onNumberOfUsesChange}
                                                        className={classes.numberOfUsesStyle}
                                                    />
                                                    :
                                                    numberOfUsesValidation ?
                                                        <CssTextField  
                                                            required
                                                            variant = 'outlined'
                                                            color = 'primary'
                                                            value={numberOfUses}
                                                            onChange={onNumberOfUsesChange}
                                                            className={classes.numberOfUsesStyle}
                                                        />
                                                    :
                                                        <CssTextField 
                                                            required
                                                            error
                                                            variant = 'outlined'
                                                            color = 'primary'
                                                            value={numberOfUses}
                                                            onChange={onNumberOfUsesChange}
                                                            className={classes.numberOfUsesStyle}
                                                            helperText="*Discount Unrecognized"
                                                        />
                                                }
                                            </Box>
                                            <Grid>
                                                <FormControlLabel 
                                                    control={
                                                        <BlueCheckbox
                                                            checked={unlimitedUses}
                                                            onChange={onUnlimitedUsesChange}
                                                            name='unlimitedUses'
                                                        />
                                                    }
                                                    label="Unlimited Number"
                                                />
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={-3}>
                                <Grid container direction='row' spacing={5}>
                                    <Grid item>
                                        <Box>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Valid From
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <CssTextField 
                                                variant = 'outlined'
                                                color = 'primary'
                                                type = 'date'
                                                label="Date"
                                                value={validFrom}
                                                onChange={onValidFromChange}
                                                className={classes.validStyle}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Valid To
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            {validFromValidation && validToValidation ?
                                                <CssTextField 
                                                    variant = 'outlined'
                                                    color = 'primary'
                                                    type = 'date'
                                                    label="Date"
                                                    value={validTo}
                                                    onChange={onValidToChange}
                                                    className={classes.validStyle}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                :
                                                <CssTextField 
                                                    error
                                                    variant = 'outlined'
                                                    color = 'primary'
                                                    type = 'date'
                                                    label="Date"
                                                    value={validTo}
                                                    onChange={onValidToChange}
                                                    className={classes.validStyle}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    helperText="*Invalid date"
                                                />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={5} mb={3} ml={-2}>
                                <Divider variant="middle" />
                            </Box>
                            { allEvents ?
                                null
                                :
                                <Box mt={2}>
                                    <Box>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Apply coupon to:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                                <Select
                                                    disabled = {allTickets || selectEvent === ""}
                                                    labelId='outlined-selectTicket-label'
                                                    id='outlined-selectTicket'
                                                    multiple
                                                    value={selectTickets}
                                                    onChange={onTicketsChange}
                                                    renderValue={(selected) => selected.map(grade => grade.gradeName).join(', ')}
                                                    MenuProps={MenuProps}
                                                    input={<BootstrapInput />}
                                                >   
                                                    {props.dataBE && props.dataBE[index].grades.map((grade) => (
                                                        <MenuItem value={grade}>
                                                            <BlueCheckbox checked={selectTickets.indexOf(grade) > -1} />
                                                            <ListItemText primary={grade.gradeName} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControlLabel 
                                            control={
                                                <BlueCheckbox
                                                    checked={allTickets}
                                                    onChange={onAllTicketsChange}
                                                    name='allTickets'
                                                />
                                            }
                                            label="All tickets"
                                        />
                                    </Box>
                                </Box>
                            }
                            <Box mt={3}>
                                <Box>
                                    <Typography variant="subtitle1" gutterBottom>
                                        More (optional)
                                    </Typography>
                                </Box>
                                <Box>
                                    <FormControl component="fieldset">
                                        <RadioGroup value={radioButton} onChange={onRadioButtonChange}>
                                            <Box mt={-0.5}>
                                                <FormControlLabel value="multipleUse" control={<BlueRadio />} label="One user ID can use this coupon for multiple times." />
                                            </Box>
                                            <Box mt={-1.5}>
                                                <FormControlLabel value="newUser" control={<BlueRadio/>} label="This coupon code can only be used for new user ID." />
                                            </Box>
                                            <Box mt={-1.5}>
                                                <FormControlLabel value="none" control={<BlueRadio />} label="None" />
                                            </Box>
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                            {checkCouponValid() ?
                                <Box justifyItems='center' mt={2}>
                                    <Button 
                                        variant="contained" 
                                        style={{backgroundColor:'#5666F6', color:'white', width:260, borderRadius: 30, height: 40}} 
                                        onClick={handleSubmit}
                                    >
                                        Create Coupon
                                    </Button>
                                </Box> 
                                :
                                <Box justifyItems='center' mt={2}>
                                    <Button 
                                        variant="contained" 
                                        style={{backgroundColor:'#C6C6C6', color:'white', width:260, borderRadius: 30, height: 40}} 
                                        disabled={true}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        disableTouchRipple={true}
                                    >
                                        Create Coupon
                                    </Button>
                                </Box>
                            }                           
                        </Box>
                    </Box>
                    :
                    null
                }
        </div>
    )
}

export default CreateCoupon;