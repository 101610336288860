import React, { Component } from 'react';
import {AuthConsumer} from './AuthContext';

class UserProfile extends Component {
    render() {
      
      return (
        <AuthConsumer>
          {({ username }) => (
            <div>
              <p>
                Hello {username}, NASU is working on your profile page,
                currently all your confirmation will be send to you via email.
              </p>
              <p>
                Thank you for your understanding! Please contact us via
                nasu2017@gmail.com if you have more questions.
              </p>
            </div> 
          )}
        </AuthConsumer>
      )
    };
}

export default UserProfile;